import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { isBrowser } from 'utils/browser';
import rootReducer from '.';
import Env from 'env';

export const history = Env.IS_BROWSER ? createBrowserHistory() : null;

const configureStore = (preloadedState) => {
  const enhancers = [];
  const middleware = [thunk];

  if (process.env.NODE_ENV === 'development' && isBrowser()) {
    const devToolsExtension = window && window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  if (module.hot) {
    module.hot.accept('./index', () => {
      const nextRootReducer = require('./index').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
