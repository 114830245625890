import makeStyles from '@material-ui/core/styles/makeStyles';

import { pointer, flex, center, none, absolute, transparent, auto } from 'constants/Jss';

export const useSliderStyles = makeStyles((theme) => ({
  Slider: {
    '& .slick-track': {
      margin: `${theme.spacing(2.5)}px ${auto}`,
    },
    '& ul.slick-dots': {
      bottom: theme.spacing(-6),
    },
    '& .slick-dots li button:before': {
      content: '""',
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: transparent,
      opacity: 1,
      border: `1px solid ${theme.palette.grey70}`,
    },
    '& .slick-dots li button:before::focus-visible': {
      border: `5px solid red`,
    },
    '& .slick-dots li.slick-active button:before': {
      opacity: 1,
      backgroundColor: theme.palette.grey70,
    },
  },
}));

export const useArrowStyles = makeStyles((theme) => ({
  SliderArrow: {
    width: 72,
    height: 72,
    borderRadius: '50%',
    backgroundColor: theme.palette.white,
    cursor: pointer,
    display: flex,
    alignItems: center,
    justifyContent: center,
    boxShadow: '0px 3px 6px #00000029',
    position: absolute,
    top: '50%',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      top: '100%',
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      left: '25%',
    },
  },
  SliderArrowRight: {
    right: 0,
    [theme.breakpoints.down('xs')]: {
      right: '25%',
      left: '50%',
    },
  },
  ArrowIcon: {
    fill: none,
    stroke: theme.palette.lightBlue,
    strokeWidth: 2,
    height: '100%',
    display: flex,
    alignItems: center,
    justifyContent: center,
  },
  ArrowIconLeft: {
    transform: 'rotate(90deg)',
    marginRight: theme.spacing(2),
  },
  ArrowIconRight: {
    transform: 'rotate(270deg)',
    marginLeft: theme.spacing(3),
  },
}));
