import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';

import { getProfileDetails } from 'store/profile/actions';
import { auto, bold, center, column, flex, flexStart, normal } from 'constants/Jss';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '80%',
    marginLeft: auto,
    marginRight: auto,
    display: flex,
    flexDirection: column,
    alignItems: flexStart,
    justifyContent: center,
    color: theme.palette.grey70,
    [theme.breakpoints.up('md')]: {
      height: '50vh',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  Title: {
    '&.Title': {
      fontSize: 32,
      lineHeight: '50px',
      fontFamily: 'WonderUnit',
      fontWeight: normal,
      paddingBottom: 0,
      textAlign: center,
      [theme.breakpoints.up('md')]: {
        marginLeft: 0,
      },
    },
  },
  Description: {
    fontSize: 18,
    lineHeight: '24px',
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },
  FourOFour: {
    fontFamily: 'Montserrat',
    fontSize: 136,
    fontWeight: bold,
    color: theme.palette.lightPink,
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.down('sm')]: {
      marginLeft: auto,
      marginRight: auto,
      fontSize: 110,
    },
  },
}));

const NotFound = ({ removeCommonLoader, resetTimer, isLoggedIn, isStayLoggedIn }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    removeCommonLoader();
    if (!!isLoggedIn && !isStayLoggedIn) {
      resetTimer();
    }
  }, [removeCommonLoader, resetTimer, isLoggedIn, isStayLoggedIn]);

  return (
    <Box mb={8} className={classes.root}>
      <h1 className={classNames(classes.Title, 'Title')}>{t('not_found_oops')}</h1>
      <h2 className={classNames(classes.Title, 'Title')} tabIndex={0}>
        {t('not_found_page_not_found')}
      </h2>
      <Box className={classes.FourOFour}>404</Box>
    </Box>
  );
};

NotFound.getInitialData = async ({ dispatch, language, token }) => {
  try {
    if (token) {
      await dispatch(getProfileDetails({ language, token }));
    }
  } catch (err) {
    console.error(err);
  }
};

NotFound.pageTitleKey = 'page_title_not_found';

NotFound.propTypes = {
  removeCommonLoader: PropTypes.func,
  resetTimer: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  isStayLoggedIn: PropTypes.bool,
};

export default NotFound;
