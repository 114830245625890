import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

import { Button, Dialog } from 'components';
import { useDeleteProfileDialogStyles } from './styles';

const FirstLoginDialog = ({ open, closeDialog, submit }) => {
  const { t } = useTranslation();
  const classes = useDeleteProfileDialogStyles();

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      title={t('profile_first_login_dialog_title')}
      titleBoxClass={classes.PinkPatch}
      content={
        <Box className={classes.DialogContent}>
          <Box mb={6} tabIndex={0}>
            {t('profile_first_login_dialog_text')}
          </Box>
          <Box mb={3} className={classes.ActionContainer}>
            <Button buttonClassName="Pink" onClick={submit}>
              {t('common_close')}
            </Button>
          </Box>
        </Box>
      }
    />
  );
};

FirstLoginDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default FirstLoginDialog;
