import { history } from 'store/store';

export const redirectTo = (path) => {
  const slashIndex = history.location.pathname.lastIndexOf('/');
  const newPathname = `${history.location.pathname.substring(0, slashIndex + 1)}${path}`;
  if (history.location.pathname === newPathname) {
    // For Safari
    document.body.scrollTop = 0;
    // For Chrome, Firefox, IE and Opera
    document.documentElement.scrollTop = 0;
  } else {
    history.push(newPathname);
  }
};
