import PropTypes from 'prop-types';
import { AuthProviderCodes, ExamStates, ItemTypes, LessonContentTypes } from 'constants/Constants';

export const Tag = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
});

export const CheckListItem = PropTypes.shape({
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
});

export const Lesson = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  testSuccess: PropTypes.bool,
  testResult: PropTypes.number,
  lessonStarted: PropTypes.bool,
  minutesToComplete: PropTypes.number,
});

export const Course = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  shortDescription: PropTypes.string,
  description: PropTypes.string,
  requirements: PropTypes.string,
  public: PropTypes.bool,
  totalMinutesToComplete: PropTypes.number,
  checklists: PropTypes.arrayOf(CheckListItem),
  examResult: PropTypes.number,
  examSuccess: PropTypes.bool,
  isBookmarked: PropTypes.bool,
  lessons: PropTypes.arrayOf(Lesson),
  lessonsCount: PropTypes.number,
});

export const LessonWithCourses = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  testSuccess: PropTypes.bool,
  testResult: PropTypes.number,
  lessonStarted: PropTypes.bool,
  minutesToComplete: PropTypes.number,
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ),
});

export const FiltersType = PropTypes.shape({
  tags: PropTypes.arrayOf(PropTypes.number),
  examDone: PropTypes.bool,
  duration: PropTypes.shape({
    minutesMin: PropTypes.number,
    minutesMax: PropTypes.number,
  }),
});

export const Topic = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  courses: PropTypes.arrayOf(Course),
});

export const Profile = PropTypes.shape({
  name: PropTypes.string,
  email: PropTypes.string,
  avatar: PropTypes.string,
});

export const Group = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

export const Settlement = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

export const Notification = PropTypes.shape({
  translateKey: PropTypes.string,
  content: PropTypes.node,
  type: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
});

export const ProfileDetails = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  email: PropTypes.string,
  group: Group,
  avatar: PropTypes.string,
  avatarFull: PropTypes.string,
  role: PropTypes.number,
  score: PropTypes.number,
  settlement: Settlement,
  tags: PropTypes.arrayOf(Tag),
  firstLoginDate: PropTypes.string,
  hasPassword: PropTypes.bool,
  fromSocial: PropTypes.bool,
});

export const LessonDetails = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  languageId: PropTypes.string,
  specifics: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf([LessonContentTypes.IMAGE, LessonContentTypes.TEXT, LessonContentTypes.VIDEO]),
      value: PropTypes.string,
      order: PropTypes.number,
    })
  ),
  hasTest: PropTypes.bool,
});

export const Answer = PropTypes.shape({
  id: PropTypes.number.isRequired,
  answer: PropTypes.string.isRequired,
  isGood: PropTypes.bool,
});

export const Question = PropTypes.shape({
  id: PropTypes.number.isRequired,
  imageUrl: PropTypes.string,
  question: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
  answers: PropTypes.oneOfType([PropTypes.arrayOf(Answer), PropTypes.object]).isRequired,
});

export const Test = PropTypes.shape({
  minutesToCompleteTest: PropTypes.number,
  questions: PropTypes.arrayOf(Question),
});

export const Exam = PropTypes.shape({
  minutesToCompleteExam: PropTypes.number,
  questions: PropTypes.arrayOf(Question),
});

export const Badge = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
});

export const Certificate = PropTypes.shape({
  id: PropTypes.number.isRequired,
  moduleTitle: PropTypes.string.isRequired,
  uuid: PropTypes.string,
  certificate: PropTypes.string,
  certificateThumb: PropTypes.string,
});

export const Document = PropTypes.shape({
  id: PropTypes.number,
  fileName: PropTypes.string,
  type: PropTypes.number,
  url: PropTypes.string,
  createdAt: PropTypes.string,
});

export const ClassStyle = PropTypes.any;

export const User = PropTypes.shape({
  id: PropTypes.any,
  name: PropTypes.string,
  group: PropTypes.string,
  score: PropTypes.number,
  rank: PropTypes.number,
  image: PropTypes.string,
  avatar: PropTypes.any,
});

export const ExamState = PropTypes.oneOf([ExamStates.OPEN, ExamStates.QUESTION, ExamStates.END]);

export const Location = PropTypes.shape({
  search: PropTypes.string,
  pathname: PropTypes.string,
});

export const History = PropTypes.shape({
  location: Location,
  push: PropTypes.func,
  replace: PropTypes.func,
});

export const Field = PropTypes.shape({
  name: PropTypes.any,
  value: PropTypes.any,
});

export const Form = PropTypes.shape({
  touched: PropTypes.any,
  errors: PropTypes.any,
});

export const IUrl = PropTypes.shape({
  url: PropTypes.string,
});

export const ItemType = PropTypes.oneOf([ItemTypes.Course, ItemTypes.Lesson]);

export const File = PropTypes.shape({
  name: PropTypes.string,
  filename: PropTypes.string,
  url: PropTypes.string,
  preview: PropTypes.string,
});

export const SocialToken = PropTypes.shape({
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  provider: PropTypes.oneOf([
    AuthProviderCodes.EMAIL,
    AuthProviderCodes.FACEBOOK,
    AuthProviderCodes.GOOGLE,
    AuthProviderCodes.APPLE,
  ]).isRequired,
  socialId: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  accessToken: PropTypes.string.isRequired,
});

export const RegisterInfo = PropTypes.shape({
  email: PropTypes.string,
  password: PropTypes.string,
  name: PropTypes.string,
  groupId: PropTypes.number,
  settlementId: PropTypes.number,
  groupName: PropTypes.string,
  groupType: PropTypes.number,
  provider: PropTypes.oneOf([
    AuthProviderCodes.EMAIL,
    AuthProviderCodes.FACEBOOK,
    AuthProviderCodes.GOOGLE,
    AuthProviderCodes.APPLE,
  ]),
  socialId: PropTypes.string,
  avatar: PropTypes.string,
});
