import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';

import Box from '@material-ui/core/Box';

import { useResultTableStyles } from './styles';

function getAnswers(answer, classes) {
  return values(answer.filledWords).map((word) => {
    const success = isEqual(answer.goodWords, answer.filledWords);
    return (
      <div
        key={`${answer.id}_answer`}
        className={classNames({ [classes.Success]: success, [classes.Fail]: !success })}
      >{`${word} `}</div>
    );
  });
}

function getGoodWords(answer) {
  return values(answer.goodWords).map((word) => <div key={answer.id}>{word}</div>);
}

function SingleAndMultipleSelectResultTable({ answers, isDesktopLayout }) {
  const { t } = useTranslation();
  const classes = useResultTableStyles();

  const ariaLabel = answers
    .map((answer, index) => {
      const goodWords = values(answer.goodWords);
      const filledWords = values(answer.filledWords);

      return t('aria_label_result_table_words_matching', {
        index: index + 1,
        goodWords: goodWords,
        filledWords: Array.isArray(filledWords) && filledWords.length ? filledWords : t('aria_label_no_answer'),
      });
    })
    .join(', ');

  return isDesktopLayout ? (
    <Box aria-label={ariaLabel} className={classes.ResultTableContainer}>
      <table aria-hidden={true} className={classes.WordsMatchingLayout}>
        <thead>
          <tr>
            <th scope="col" className="hidden">
              {t('result_table_header_index')}
            </th>
            <th scope="col" className="hidden">
              {t('result_table_header_solution')}
            </th>
            <th>{t('result_table_header_selected_choice')}</th>
          </tr>
        </thead>
        <tbody>
          {answers.map((answer, index) => {
            return (
              <tr key={answer.id} className={classes.TableRowBorder}>
                <td>{`${index + 1}.`}</td>
                <td>{getGoodWords(answer)}</td>
                <td>{getAnswers(answer, classes)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  ) : (
    <Box className={classes.MobileResultTableContainer}>
      <table className={classes.SecondLayout}>
        {answers.map((answer, index) => {
          return (
            <tr key={`${answer.id}_row`}>
              <td>{`${index + 1}.`}</td>
              <td>
                <div>{getGoodWords(answer)}</div>
                <div className={classes.AnswerContainer}>
                  <p className={classes.Selected}>{`${t('result_table_header_selected_choice')} `}</p>
                  {getAnswers(answer, classes)}
                </div>
              </td>
            </tr>
          );
        })}
      </table>
    </Box>
  );
}

SingleAndMultipleSelectResultTable.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.object),
  isDesktopLayout: PropTypes.bool,
};

export default SingleAndMultipleSelectResultTable;
