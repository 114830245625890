import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import BadgesPage from './BadgesPage';
import { getBadges } from 'store/profile/actions';
import { addCommonLoader } from 'store/loader/actions';

const mapStateToProps = (state) => {
  return {
    badges: state.profile.badges,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBadges,
      addCommonLoader,
    },
    dispatch
  );
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(BadgesPage);
