import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import AppSvgIcon from 'components/AppSvgIcon/AppSvgIcon'; // do not change this import

import { useExpandableBoxStyles } from './styles';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
import { unset } from 'constants/Jss';

const ExpandableBox = ({ text, minHeight, textBoxClass, alwaysExpanded, tabIndex = 0 }) => {
  const classes = useExpandableBoxStyles();
  const [expanded, setExpanded] = React.useState(alwaysExpanded || false);
  const { t } = useTranslation();

  useEffect(() => {
    setExpanded(alwaysExpanded);
  }, [alwaysExpanded]);

  return (
    <>
      <div className={classes.Wrapper}>
        <Box
          id="Box"
          className={classNames(classes.Text, textBoxClass, { Expanded: expanded })}
          style={{ maxHeight: expanded ? unset : minHeight }}
          tabIndex={tabIndex}
        >
          {text}
          <Box className={classNames(classes.ShadowBox, { Expanded: expanded })} />
        </Box>
        <div className={classNames(classes.Expander, { Expanded: expanded })} onClick={() => setExpanded(!expanded)}>
          <AppSvgIcon viewBox={`0 0 ${expanded ? '20.617 9.309' : '16.617 12.309'}`}>
            <path d="M0,0,8.894,8.894,17.789,0" />
          </AppSvgIcon>
        </div>
      </div>

      <Box
        mt={4}
        mb={4}
        className={classNames(classes.ReadMoreButtonContainer, { Expanded: expanded })}
        onClick={() => setExpanded(!expanded)}
      >
        <Button tabIndex={tabIndex} buttonClassName="Pink">
          {t('common_read_more')}
        </Button>
      </Box>
    </>
  );
};

ExpandableBox.propTypes = {
  text: PropTypes.any,
  minHeight: PropTypes.number,
  textBoxClass: PropTypes.any,
  alwaysExpanded: PropTypes.bool,
  tabIndex: PropTypes.number,
};

export default ExpandableBox;
