import { memo } from 'react';
import PropTypes from 'prop-types';

import { useSearchField } from '../../useSearchField';
import { useDesktopHeaderStyles } from '../../styles';

export const DesktopHeaderSearchField = memo(({ ariaLabel, placeholder, submit, cancel, outsideCancel }) =>
  useSearchField({
    customStyleFactory: useDesktopHeaderStyles,
    ariaLabel,
    placeholder,
    submit,
    cancel,
    outsideCancel,
    hasCloseButton: true,
  })
);

DesktopHeaderSearchField.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  outsideCancel: PropTypes.func,
};
