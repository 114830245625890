import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

import NoSsr from '@material-ui/core/NoSsr';

import LoginDialog from './LoginDialog';
import RegisterMethodDialog from './RegisterMethodDialog';
import RegisterDialog from './RegisterDialog';
import { MobileHeader } from './MobileHeader';
import { DesktopHeader } from './DesktopHeader';
import { Document, Group, Profile, Settlement } from 'types/types';
import { getPath as getPathFromUtils } from 'utils/getPath';
import {
  AuthProviderCodes,
  AuthProviderNames,
  FirebaseAccountExistsWithDifferentCredentialCode,
  NotificationTypes,
} from 'constants/Constants';
import ForgotPasswordDialog from './ForgotPasswordDialog';
import ResetPasswordDialog from './ResetPasswordDialog';
import { AcceptNewTermsDialog, ExpirationModal } from 'components';
import { getCurrentLanguage } from 'utils/language';
import { SocialService } from 'services/social';
import { selectIsStayLoggedIn } from 'store/auth/selectors';

export const Header = ({
  pages,
  history,
  login,
  logout,
  register,
  groupSearch,
  groups,
  getSettlements,
  settlements,
  topics,
  profile,
  showNotification,
  removeNotification,
  forgotPassword,
  resetPassword,
  getAuthorizedRoutes,
  setFilters,
  documents,
  checkDocuments,
  getDocuments,
  agreeDocuments,
  newDocuments,
}) => {
  const { t } = useTranslation();

  const [registerToken, setRegisterToken] = useState();

  const params = queryString.parse(history.location.search);
  const openModalType = registerToken ? 'register_details' : params.dialog;
  const isVerify = !!params.verify;
  const isNewUser = !!params['new-user'];
  const token = isString(params.token) ? params.token : '';

  const { isLoggedIn } = useSelector((state) => state.auth);
  const isStayLoggedIn = useSelector(selectIsStayLoggedIn);

  useEffect(() => {
    if (newDocuments && isLoggedIn) {
      getDocuments().then(() => history.push(`${history.location.pathname}?dialog=${t('url_new_documents')}`));
    }
  }, [newDocuments, isLoggedIn, getDocuments, history, t]);

  const showSocialProviderError = useCallback(
    (error, providerCode, translateKey) => {
      const providerName = AuthProviderNames[providerCode];
      console.error(`${providerName} provider error`, error);
      showNotification({ content: t(translateKey, { providerName }), type: NotificationTypes.error });
    },
    [showNotification, t]
  );

  const showRegistrationErrorToast = useCallback(
    () => showNotification({ translateKey: 'registration_error_message', type: NotificationTypes.error }),
    [showNotification]
  );

  const onLogoClick = () => {
    history.push(getPath(t('menu_item_home')));
  };

  const search = async ({ searchString, isClear }) => {
    if (searchString.length > 2) {
      history.push({
        pathname: getPath(t('common_search')),
        search: `?${t('url_search_term')}=${encodeURIComponent(searchString)}`,
      });
    }
    if (isClear && window && window.location && window.location.pathname.includes(getPath(t('common_search')))) {
      history.push({
        pathname: getPath(t('common_search')),
      });
      await setFilters({ searchString: null });
    }
  };

  const getPath = (linkName) => getPathFromUtils(pages, linkName);

  const getPathFromRoutes = (routes, linkName) => getPathFromUtils(routes, linkName);

  const postLogin = async (params) => {
    closeDialog();

    const getCreditentials = async (params) => {
      if (isNumber(params)) {
        try {
          const socialToken = await SocialService.loginWithProvider(params);
          if (!socialToken) {
            return;
          }
          const { provider, accessToken } = socialToken;
          return { provider, accessToken };
        } catch (error) {
          if (error.code === FirebaseAccountExistsWithDifferentCredentialCode) {
            showNotification({ translateKey: 'error_another_provider_email_address', type: NotificationTypes.error });
          } else {
            showSocialProviderError(error, params, 'login_error_social');
          }
        }
      } else {
        return params;
      }
    };

    const creditentials = await getCreditentials(params);
    if (!creditentials) {
      return;
    }

    let loginResult;
    try {
      loginResult = await login({ ...creditentials, isStayLoggedIn }, getAuthorizedRoutes);
    } catch (error) {
      if (error && error.resolved) {
        return;
      }
      throw error;
    }
    const { firstLogin, routes } = loginResult;
    if (firstLogin) {
      history.push(getPathFromRoutes(routes, t('menu_item_profile')) + `?dialog=${t('url_first_login')}`);
    } else {
      // in the case of first login, we already show a popup on the profile page
      await checkDocuments();
    }
  };

  const handleGroupSearch = async (searchText) => {
    return await groupSearch(searchText);
  };

  const handleSettlementSearch = (searchText) => {
    getSettlements(searchText);
  };

  const onRegisterSelect = async (providerCode) => {
    closeDialog();

    try {
      setRegisterToken(await SocialService.getRegisterToken(providerCode));
    } catch (error) {
      if (error.code === FirebaseAccountExistsWithDifferentCredentialCode) {
        showRegistrationErrorToast();
      } else {
        showSocialProviderError(error, providerCode, 'registration_error_social');
      }
    }
  };

  const closeRegisterDialog = () => {
    setRegisterToken(undefined);
    closeDialog();
  };

  const postRegistration = async (formValues, socialToken) => {
    const { name, organization, kkv, settlement, email, password, taxNumber } = formValues;
    const group = groups.find((group) => group.name.toLowerCase() === organization.toLowerCase());
    const settlements = await getSettlements(settlement);
    const isSocial = AuthProviderCodes.isSocial(socialToken.provider);

    try {
      await register({
        email,
        password: isSocial ? null : password,
        name,
        groupId: group ? group.id : null,
        settlementId: settlements.length ? settlements[0].id : null,
        groupName: organization,
        groupType: kkv ? 1 : 0,
        provider: socialToken.provider,
        socialId: isSocial ? socialToken.socialId : null,
        avatar: socialToken.avatar,
        taxNumber,
      });
      const translateKey = isSocial ? 'registration_confirm_social' : 'registration_confirm';
      showNotification({ translateKey, type: NotificationTypes.success });
      closeRegisterDialog();
    } catch (e) {
      showRegistrationErrorToast();
      throw e;
    }
  };

  const postForgotPassword = async ({ email }) => {
    closeDialog();
    const success = await forgotPassword(email);
    if (success) {
      showNotification({ translateKey: 'forgot_password_confirm', type: NotificationTypes.success });
    }
  };

  const postResetPassword = async ({ token, password, isNewUser }) => {
    closeDialog();
    const success = await resetPassword({ token, password });
    if (success) {
      showNotification({
        translateKey: isNewUser ? 'reset_password_and_verify_email_success' : 'reset_password_success',
        type: NotificationTypes.success,
      });
      history.push(`${history.location.pathname}?dialog=${t('url_login')}`);
    }
  };

  const putDocuments = async () => {
    try {
      await agreeDocuments({
        termsAndConditionsId: documents.termsAndConditions.id,
        privacyPolicyId: documents.privacyPolicy.id,
      });
      closeDialog();
    } catch (error) {
      closeDialogAndLogout();
    }
  };

  const closeDialog = () => {
    const param = new URLSearchParams(history.location.search);
    param.delete('dialog');
    history.push(`${history.location.pathname}${!param.toString().length ? '' : `?${param.toString()}`}`);
  };

  const closeDialogAndLogout = () => {
    closeDialog();
    history.push(`/${getCurrentLanguage(window.location.pathname)}`);
    logout(getAuthorizedRoutes);
  };

  return (
    <>
      {!!isLoggedIn && !isStayLoggedIn ? (
        <NoSsr>
          <ExpirationModal getAuthorizedRoutes={getAuthorizedRoutes} />
        </NoSsr>
      ) : null}
      <MobileHeader
        onLogoClick={onLogoClick}
        getPath={getPath}
        search={search}
        history={history}
        topics={topics}
        profile={profile}
        logout={() => logout(getAuthorizedRoutes)}
        isLoggedIn={isLoggedIn}
      />
      <DesktopHeader
        history={history}
        getPath={getPath}
        search={search}
        onLogoClick={onLogoClick}
        topics={topics}
        profile={profile}
        logout={() => logout(getAuthorizedRoutes)}
        isLoggedIn={isLoggedIn}
      />
      {isLoggedIn ? null : (
        <>
          <LoginDialog
            open={openModalType === t('url_login')}
            closeDialog={closeDialog}
            submit={postLogin}
            history={history}
            isVerify={isVerify}
          />
          <RegisterMethodDialog
            open={openModalType === t('url_register')}
            closeDialog={closeDialog}
            onSelect={onRegisterSelect}
          />
          <RegisterDialog
            open={openModalType === 'register_details'}
            socialToken={registerToken}
            closeDialog={closeRegisterDialog}
            submit={postRegistration}
            groupSearch={handleGroupSearch}
            groups={groups}
            settlementSearch={handleSettlementSearch}
            settlements={settlements}
            showNotification={showNotification}
            removeNotification={removeNotification}
            termsAndConditions={documents.termsAndConditions}
            privacyPolicy={documents.privacyPolicy}
          />
          <ForgotPasswordDialog
            open={openModalType === t('url_new_password')}
            closeDialog={closeDialog}
            submit={postForgotPassword}
            history={history}
          />
        </>
      )}
      {!isLoggedIn ? null : (
        <AcceptNewTermsDialog
          submit={putDocuments}
          open={openModalType === t('url_new_documents')}
          closeDialogAndLogout={closeDialogAndLogout}
          showNotification={showNotification}
          removeNotification={removeNotification}
          documents={documents}
        />
      )}
      <ResetPasswordDialog
        open={openModalType === t('url_reset_password')}
        closeDialog={closeDialog}
        submit={postResetPassword}
        history={history}
        token={token}
        isNewUser={isNewUser}
        termsAndConditions={documents.termsAndConditions}
        privacyPolicy={documents.privacyPolicy}
        showNotification={showNotification}
      />
    </>
  );
};

Header.propTypes = {
  pages: PropTypes.array.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  groupSearch: PropTypes.func,
  getSettlements: PropTypes.func,
  history: PropTypes.object.isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  profile: Profile,
  groups: PropTypes.arrayOf(Group),
  settlements: PropTypes.arrayOf(Settlement),
  showNotification: PropTypes.func.isRequired,
  removeNotification: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  getAuthorizedRoutes: PropTypes.func,
  setFilters: PropTypes.func,
  checkDocuments: PropTypes.func,
  getDocuments: PropTypes.func,
  agreeDocuments: PropTypes.func,
  documents: PropTypes.shape({
    termsAndConditions: Document,
    privacyPolicy: Document,
  }),
  newDocuments: PropTypes.bool,
};
