import makeStyles from '@material-ui/core/styles/makeStyles';

import { noRepeat, flex, column, spaceBetween, center, none } from 'constants/Jss';

export const useNavigationDialogStyles = makeStyles((theme) => ({
  YellowPatch: {
    backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(252,196,128)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
    backgroundPosition: '-286px -250px',
    backgroundSize: '500px 445.066px',
    backgroundRepeat: `${noRepeat}`,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 360 365'><path fill='rgb(252,196,128)' d='M204.2,0c0,0,18,89.5-23,115s-81.6,2.2-111,61c-25.6,51.2-25,87.3-70,94.7C-0.2,163.6,0.1,0,0.1,0H204.2z'/></svg>")`,
      backgroundPosition: '-57px -95px',
      backgroundSize: '360px 365px',
      backgroundRepeat: noRepeat,
    },
  },
  DialogContent: {
    display: flex,
    flexDirection: column,
    justifyContent: spaceBetween,
    alignItems: center,
  },
  CourseTitlesContainer: {
    width: '100%',
    textAlign: center,
    'list-style-type': none,
  },
}));
