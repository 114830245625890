import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MuiTextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import get from 'lodash/get';

import ErrorMessage from './ErrorMessage';
import { Field, Form } from '../../types/types';

const useStyles = makeStyles(() => {
  return {
    fullWidth: {
      width: '100%',
    },
  };
});

const TextField = ({
  field,
  form,
  fullWidth,
  showError = true,
  readOnly,
  variant = 'outlined',
  label,
  inputProps,
  ...props
}) => {
  const classes = useStyles();
  return (
    <div className={classNames({ [classes.fullWidth]: fullWidth })}>
      <MuiTextField
        {...(field || {})}
        {...props}
        variant={variant}
        label={label}
        InputProps={{
          ...(props.InputProps || {}),
          readOnly: (props.InputProps || {}).readOnly || readOnly || false,
        }}
        inputProps={{
          ...(inputProps || {}),
          'aria-label': label,
          'aria-invalid': form.errors[field.name] && form.touched[field.name] ? 'true' : null,
          'aria-describedby': form.errors[field.name] && form.touched[field.name] ? `${field.name}-error` : null,
        }}
        value={field && field.value ? field.value : ''}
        fullWidth={fullWidth}
        error={Boolean(form && field && get(form.errors, field.name) && get(form.touched, field.name))}
      />
      {showError && form && field ? (
        <ErrorMessage field={field} form={form} variant={variant} className={classes.error} />
      ) : null}
    </div>
  );
};

TextField.propTypes = {
  field: Field,
  form: Form,
  fullWidth: PropTypes.any,
  showError: PropTypes.bool,
  readOnly: PropTypes.bool,
  variant: PropTypes.string,
  label: PropTypes.string,
  InputProps: PropTypes.any,
  inputProps: PropTypes.any,
};

export default TextField;
