import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  SET_IS_LOGGED_IN,
  SET_EXPIRATION,
  SET_EXPIRATION_RESET,
  SET_TOKEN_EXPIRATION,
  SET_IS_STAY_LOGGED_IN,
} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  isLoggedIn: false,
  expiration: 0,
  expirationDenied: false,
  expirationReset: Date.now(),
  tokenExpiration: null,
  isStayLoggedIn: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REGISTER:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REGISTER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case VERIFY_EMAIL:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case VERIFY_EMAIL_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case SET_EXPIRATION:
      return {
        ...state,
        expiration: action.payload,
      };
    case SET_EXPIRATION_RESET:
      return {
        ...state,
        expirationReset: action.payload,
      };
    case SET_TOKEN_EXPIRATION:
      return {
        ...state,
        tokenExpiration: action.payload,
      };
    case SET_IS_STAY_LOGGED_IN:
      return {
        ...state,
        isStayLoggedIn: action.payload,
      };
    default:
      return state;
  }
};
