import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import SearchPage from './SearchPage';
import { getCourses, loadMoreCourses, setFilters, resetCourses } from 'store/courses/actions';
import { getLessons, loadMoreLessons } from 'store/lessons/actions';
import { showNotification } from 'store/common/actions';
import { addCommonLoader, removeCommonLoader } from 'store/loader/actions';
import { selectIsLoggedIn } from 'store/auth/selectors';

const mapStateToProps = (state) => {
  return {
    courses: state.courses.courses.data,
    lessons: state.lessons.lessons.data,
    numberOfCourses: state.courses.courses.count,
    numberOfLessons: state.lessons.lessons.count,
    filters: state.courses.filters,
    tags: state.tags.tags,
    profileTags: state.profile.details.tags,
    isLoggedIn: selectIsLoggedIn(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setFilters,
      getCourses,
      loadMoreCourses,
      resetCourses,
      getLessons,
      loadMoreLessons,
      showNotification,
      addCommonLoader,
      removeCommonLoader,
    },
    dispatch
  );
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SearchPage);
