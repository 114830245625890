import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import { Button, SearchResultNumber } from 'components';
import { ItemTypes } from 'constants/Constants';
import { Course, Lesson } from 'types/types';
import { useCardContainerStyles } from './styles';

export const CardContainer = ({
  children,
  numberOfItems,
  items,
  loadMoreItems,
  numberOfActiveFilters,
  noResultClass,
  itemType = ItemTypes.Course,
  resultClass,
  isLoading,
  skeleton,
  page: initialPage,
}) => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(initialPage || 1);
  const classes = useCardContainerStyles();

  useEffect(() => {
    if (initialPage) {
      setPage(initialPage);
    }
  }, [initialPage]);

  const loadMore = async () => {
    const newPage = page + 1;
    if (!initialPage) {
      setPage(newPage);
    }
    loadMoreItems(newPage);
  };

  return (
    <>
      {numberOfItems || skeleton ? (
        <SearchResultNumber
          numberOfItems={numberOfItems}
          resultText={itemType === ItemTypes.Course ? t('common_course') : t('course_details_lesson')}
          mobileHiddenClass={'MobileHidden'}
        />
      ) : numberOfActiveFilters > 0 && !isLoading ? (
        <Box
          ml={1.5}
          className={classNames(classes.NoResult, noResultClass, 'page-animation-exit-hidden')}
          tabIndex={0}
        >
          {t('no_result_with_filters')}
        </Box>
      ) : !isLoading ? (
        <Box
          ml={1.5}
          className={classNames(classes.NoResult, noResultClass, 'page-animation-exit-hidden')}
          tabIndex={0}
        >
          {t('no_result_without_filters')}
        </Box>
      ) : null}
      <Box className={resultClass}>{children}</Box>
      {numberOfItems <= items.length ? null : (
        <Box mb={8} className={classes.ShowMoreBox}>
          <Button
            buttonClassName="Pink"
            className={classes.ShowMoreButton}
            onClick={loadMore}
            aria-label={itemType === ItemTypes.Course ? t('alt_forward_course') : t('alt_forward_lesson')}
          >
            {t(itemType === ItemTypes.Course ? 'card_list_load_more_courses' : 'card_list_load_more_lessons')}
          </Button>
        </Box>
      )}
    </>
  );
};

CardContainer.propTypes = {
  children: PropTypes.node,
  numberOfItems: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.oneOfType([Course, Lesson])),
  loadMoreItems: PropTypes.func,
  itemType: PropTypes.oneOf([ItemTypes.Course, ItemTypes.Lesson]),
  resultClass: PropTypes.string,
  numberOfActiveFilters: PropTypes.number,
  noResultClass: PropTypes.string,
  isLoading: PropTypes.bool,
  skeleton: PropTypes.bool,
  page: PropTypes.number,
};
