import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  absolute,
  block,
  bold,
  center,
  ellipsis,
  flex,
  flexEnd,
  hidden,
  none,
  nowrap,
  pointer,
  relative,
  spaceBetween,
  vertical,
  wrap,
} from 'constants/Jss';

export const useLessonBoxStyles = makeStyles((theme) => ({
  LessonContainer: {
    width: 380,
    height: 236,
    borderRadius: 18,
    backgroundColor: theme.palette.greyF2,
    lineHeight: '30px',
    position: relative,
    padding: theme.spacing(4),
    cursor: pointer,
    border: `2px solid ${theme.palette.lightBlue}`,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      borderRadius: 0,
      paddingTop: theme.spacing(7),
    },
  },
  LessonContainerTouched: {
    border: `2px solid ${theme.palette.greenSuccess}`,
  },
  LessonBoxIconContainer: {
    left: `calc(50% - ${theme.mixins.lessonBoxIcon.size / 2}px)`,
    top: theme.mixins.lessonBoxIcon.size / -2,
    width: theme.mixins.lessonBoxIcon.size,
    height: theme.mixins.lessonBoxIcon.size,
    display: flex,
    position: absolute,
    background: theme.palette.white,
    alignItems: center,
    borderRadius: '50%',
    justifyContent: center,
  },
  LessonBoxIcon: {
    width: theme.mixins.lessonBoxIcon.size - theme.mixins.lessonBoxIcon.padding * 2,
    height: theme.mixins.lessonBoxIcon.size - theme.mixins.lessonBoxIcon.padding * 2,
    borderRadius: '50%',
  },
  LessonDescription: {
    overflow: hidden,
    color: theme.palette.blackTitle,
    fontSize: 20,
    textAlign: center,
    fontWeight: bold,
    textOverflow: ellipsis,
    display: '-webkit-box',
    '-webkit-box-orient': vertical,
    '-webkit-line-clamp': 4,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  IndicatorContainer: {
    display: flex,
    justifyContent: spaceBetween,
    flexWrap: wrap,
    '&:not(.HasResult)': {
      justifyContent: flexEnd,
    },
  },
  IconizedIndicator: {
    display: flex,
    alignItems: center,
    color: theme.palette.blackTitle,
    '& img': {
      marginRight: theme.spacing(1),
    },
    '& div': {
      whiteSpace: nowrap,
      fontSize: 21,
      fontWeight: bold,
      lineHeight: '21px',
    },
  },
  LessonCardArrow: {
    position: absolute,
    bottom: -18.5,
    left: 'calc(50% - 18.5px)',
    width: 39,
    height: 39,
    borderRadius: '50%',
    backgroundColor: theme.palette.lightBlue,
    '&:hover': {
      backgroundColor: theme.palette.lightBlueHover,
    },
    cursor: pointer,
    display: flex,
    alignItems: center,
    justifyContent: center,
  },
  LessonCardArrowTouched: {
    backgroundColor: theme.palette.greenSuccess,
    '&:hover': {
      backgroundColor: theme.palette.greenSuccessHover,
    },
  },
  ArrowIcon: {
    transform: 'rotate(270deg)',
    fill: none,
    stroke: theme.palette.white,
    strokeWidth: 2,
    width: 19.617,
    height: 11.309,
    display: flex,
    alignItems: center,
    justifyContent: center,
    marginLeft: theme.spacing(0.5),
  },
  NotificationLink: {
    color: theme.palette.black,
    marginLeft: theme.spacing(1),
    '&:hover': {
      color: theme.palette.blueA0,
    },
    [theme.breakpoints.down('sm')]: {
      display: block,
    },
  },
}));
