import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NotFound from './NotFound';
import { removeCommonLoader } from 'store/loader/actions';
import { resetTimer } from 'store/auth/actions';
import { selectIsLoggedIn, selectIsStayLoggedIn } from 'store/auth/selectors';

const mapStateToProps = (state) => {
  return {
    isLoggedIn: selectIsLoggedIn(state),
    isStayLoggedIn: selectIsStayLoggedIn(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      removeCommonLoader,
      resetTimer,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
