import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Box from '@material-ui/core/Box';

import { auto } from 'constants/Jss';
import { mapAlternate } from 'utils/mapAlternate';
import { usePrevious } from 'utils/usePrevious';
import { splitStringByBrackets } from 'utils/splitStringByBrackets';
import { getMaxElementSize } from 'utils/getMaxElementSize';
import { testCorrect, testIncorrect } from 'utils/icons';
import { useFormStyles } from './styles';

export const SubstitutionForm = ({ answers, setAnswer, answered }) => {
  const classes = useFormStyles();

  const [words, setWords] = useState([]);
  const [filledWords, setFilledWords] = useState([]);
  const [mounted, setMounted] = useState(false);
  const [calculationFinished, setCalculationFinished] = useState(false);
  const [wordSize, setWordSize] = useState({ width: 0, height: 0 });
  const [current, setCurrent] = useState([0, 0]);

  const prevAnswers = usePrevious(answers);

  useEffect(() => {
    if (prevAnswers !== answers) {
      const newWords = answers[0].words.map((word) => word);
      setWords(newWords);
      const filledWordsArray = [];
      answers.forEach((answer, index) => {
        filledWordsArray.push([]);
        mapAlternate(
          splitString(index),
          function() {},
          function() {
            filledWordsArray[index].push('');
          }
        );
      });
      setFilledWords(filledWordsArray);
      setCurrent([0, 0]);
      setCalculationFinished(false);
      setMounted(true);
    }
  }, [answers, words, prevAnswers, splitString]);

  useEffect(() => {
    if (mounted && !calculationFinished) {
      const wordBubbles = document.querySelectorAll('.wordBubbles');
      setWordSize({ width: getMaxElementSize(wordBubbles).width, height: 36 });
      setCalculationFinished(true);
    }
  }, [mounted, calculationFinished]);

  const constructAnswer = () => {
    const result = answers.map((answer, index) => ({
      id: answer.id,
      words: filledWords[index].map((filledWord) => filledWord),
    }));
    setAnswer(result);
  };

  const splitString = useCallback((index = 0) => splitStringByBrackets(answers[index].answer), [answers]);

  const replaceCurrent = (tempFilledWords) => {
    tempFilledWords.some((filledWord, wordIndex) => {
      const position = filledWord.indexOf('');
      if (position > -1) {
        setCurrent([wordIndex, position]);
        return true;
      }
    });
  };

  const onWordClick = (word, index) => {
    if (!answered && filledWords.filter((filledWord) => filledWord.some((fw) => fw === '')).length > 0) {
      const tempFilledWords = [...filledWords];
      const tempWords = [...words];
      tempWords.splice(index, 1);
      tempFilledWords[current[0]][current[1]] = word;
      replaceCurrent(tempFilledWords);
      setFilledWords(tempFilledWords);
      setWords(tempWords);
      constructAnswer();
    }
  };

  const onAnswerClick = (index, position) => {
    if (!answered) {
      if (filledWords[index][position]) {
        const tempWords = [...words];
        const tempFilledWords = [...filledWords];
        tempWords.push(filledWords[index][position]);
        tempFilledWords[index][position] = '';
        setWords(tempWords);
        setFilledWords(tempFilledWords);
        replaceCurrent(tempFilledWords);
        constructAnswer();
      } else {
        setCurrent([index, parseInt(position)]);
      }
    }
  };

  const isGood = (index, position) => answers[index].goodWords[position] === filledWords[index][position];

  return (
    mounted &&
    !!filledWords &&
    filledWords.length > 0 && (
      <Box className={classes.FormBox} ml={auto} mr={auto}>
        {answers.map((answer, index) => (
          <div className={classes.CloudContainer} key={answer.id}>
            {mapAlternate(
              splitString(index),
              function(sentencePart) {
                return sentencePart.split(' ').map((part, subIndex) => (
                  <div key={`${part}-${subIndex}`}>
                    <p className={classes.SubstitutionSentencePart}>{`${part} `}</p>
                  </div>
                ));
              },
              function(position) {
                return (
                  <>
                    <Box
                      style={{ width: wordSize.width, height: wordSize.height }}
                      display="flex"
                      onClick={() => onAnswerClick(index, position)}
                      className={classNames(classes.Cloud, classes.CloudSpacing, {
                        [classes.CloudFilled]: !!filledWords[index] && !!filledWords[index][position],
                        [classes.WordCloudFailure]: !!answered && !isGood(index, position),
                        [classes.CloudSuccess]: !!answered && !!isGood(index, position),
                        [classes.BubbleCurrent]:
                          current[0] === index &&
                          current[1] == position &&
                          !!filledWords[index] &&
                          !filledWords[index][position],
                        [classes.SubstitutionAnswered]: !!answered,
                        [classes.AnsweredSize]: !!answered && filledWords[index][position] !== '',
                      })}
                    >
                      {!!filledWords[index] && filledWords[index][position] && <>{filledWords[index][position]}</>}
                      {!!answered && (
                        <Box className={classes.AnsweredIconContainer} right="5px">
                          {isGood(index, position) ? testCorrect() : testIncorrect()}
                        </Box>
                      )}
                    </Box>
                    {!!answered &&
                      filledWords[index][position] &&
                      filledWords[index][position] !== answers[index].goodWords[position] && (
                        <div
                          className={classNames(
                            classes.Cloud,
                            classes.CloudFilled,
                            classes.CloudSpacing,
                            classes.CloudSuccess,
                            classes.AnsweredSize,
                            {
                              [classes.SubstitutionAnswered]: !!answered,
                            }
                          )}
                          style={{ width: wordSize.width, height: wordSize.height }}
                        >
                          {answers[index].goodWords[position]}
                          <Box className={classes.AnsweredIconContainer} right="5px">
                            {testCorrect()}
                          </Box>
                        </div>
                      )}
                    {!!answered && !!filledWords[index] && !filledWords[index][position] && (
                      <div
                        style={{ width: wordSize.width, height: wordSize.height }}
                        className={classNames(
                          classes.Cloud,
                          classes.CloudFilled,
                          classes.CloudSpacing,
                          classes.CloudSuccess,
                          classes.AnsweredSize,
                          {
                            [classes.SubstitutionAnswered]: !!answered,
                          }
                        )}
                      >
                        {answers[index].goodWords[position]}
                        <Box className={classes.AnsweredIconContainer} right="5px">
                          {testCorrect()}
                        </Box>
                      </div>
                    )}
                  </>
                );
              }
            )}
          </div>
        ))}
        <div className={classes.Divider} />
        <Box className={classes.WordsContainer} display="flex" justifyContent="center">
          {words.map((word, index) => (
            <div
              onClick={() => onWordClick(word, index)}
              key={`${index}-${word}`}
              className={classNames(classes.Cloud, classes.CloudFilled, classes.CloudSpacing, 'wordBubbles')}
            >
              {word}
            </div>
          ))}
        </Box>
      </Box>
    )
  );
};

SubstitutionForm.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      words: PropTypes.arrayOf(PropTypes.string).isRequired,
      goodWords: PropTypes.arrayOf(PropTypes.string),
      isGood: PropTypes.bool,
    })
  ),
  setAnswer: PropTypes.func,
  answered: PropTypes.bool,
};
