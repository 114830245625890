import {
  GET_DOCUMENTS,
  GET_DOCUMENTS_FAILED,
  GET_DOCUMENTS_SUCCESS,
  AGREE_DOCUMENTS,
  AGREE_DOCUMENTS_FAILED,
  AGREE_DOCUMENTS_SUCCESS,
  CHECK_DOCUMENTS,
  CHECK_DOCUMENTS_FAILED,
  CHECK_DOCUMENTS_SUCCESS,
} from './types';
import * as DocumentService from 'services/documents';

export const getDocuments = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DOCUMENTS });

      const data = await DocumentService.getDocuments();

      dispatch({
        type: GET_DOCUMENTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({ type: GET_DOCUMENTS_FAILED });
      throw error;
    }
  };
};

export const checkDocuments = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: CHECK_DOCUMENTS });

      const { isThereNew } = await DocumentService.checkDocuments();

      dispatch({
        type: CHECK_DOCUMENTS_SUCCESS,
        payload: isThereNew,
      });
    } catch (error) {
      dispatch({ type: CHECK_DOCUMENTS_FAILED });
      throw error;
    }
  };
};

export const agreeDocuments = ({ termsAndConditionsId, privacyPolicyId }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: AGREE_DOCUMENTS });

      await DocumentService.agreeDocuments(termsAndConditionsId, privacyPolicyId);

      dispatch({
        type: AGREE_DOCUMENTS_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: AGREE_DOCUMENTS_FAILED });
      throw error;
    }
  };
};
