import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { interval } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

import { getTime } from 'utils/time';
import { useRemainingTimeStyles } from './styles';

const RemainingTime = ({ timeLimitInSeconds, timeout, tabIndex }) => {
  const classes = useRemainingTimeStyles();
  const { t } = useTranslation();
  const [time, setTime] = React.useState(timeLimitInSeconds);
  const [ariaLabel, setAriaLabel] = React.useState('');

  useEffect(() => {
    const start = Date.now();
    const subscription = interval(500)
      .pipe(
        map(() => {
          const elapsedSec = Math.floor((Date.now() - start) / 1000);
          return Math.max(timeLimitInSeconds - elapsedSec, 0);
        }),
        distinctUntilChanged()
      )
      .subscribe((remainingSec) => {
        setTime(remainingSec);
        if (remainingSec === 0) {
          timeout();
        }
      });

    return () => subscription.unsubscribe();
  }, [timeLimitInSeconds, timeout]);

  const onFocus = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    setAriaLabel(t('alt_test_page_remaining_time', { minutes, seconds }));
  };

  return (
    <Box className={classes.TimeStrip} tabIndex={tabIndex} aria-label={ariaLabel} onFocus={onFocus}>
      <Box aria-hidden={true}>{t('test_page_remaining_time')}</Box>
      <Box ml={2} className={classes.TimeNumbers} aria-hidden={true}>
        {getTime(time)}
      </Box>
    </Box>
  );
};

export default RemainingTime;

RemainingTime.propTypes = {
  timeLimitInSeconds: PropTypes.number.isRequired,
  timeout: PropTypes.func.isRequired,
  tabIndex: PropTypes.oneOf([-1, 0]),
};
