import request from 'utils/request';
import { Methods } from 'utils/http';
import Settings from 'env';

const BASE_URL = '/certificates';

export const getCertificates = async (language, page, token) => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}?page=${page}`,
    language,
  };
  if (token) {
    requestData.headers = { Authorization: token };
  }
  return await request(requestData);
};

export const getCertificate = async (language, id, token) => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/${id}`,
    language,
  };
  if (token) {
    requestData.headers = { Authorization: token };
  }
  return await request(requestData);
};

export const getPublicCertificate = async (uuid) => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/social/${uuid}`,
    baseUrl: Settings.STORAGE_BASE_URL,
  };
  return await request(requestData);
};
