import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  DELETE_PROFILE,
  DELETE_PROFILE_FAILED,
  DELETE_PROFILE_SUCCESS,
  GET_BADGES,
  GET_BADGES_FAILED,
  GET_BADGES_SUCCESS,
  GET_PROFILE_DETAILS,
  GET_PROFILE_DETAILS_FAILED,
  GET_PROFILE_DETAILS_SUCCESS,
  GET_SETTLEMENTS,
  GET_SETTLEMENTS_FAILED,
  GET_SETTLEMENTS_SUCCESS,
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_PROFILE_DATA,
} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  details: {},
  settlements: [],
  badges: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOGOUT:
      return INITIAL_STATE;
    case SET_PROFILE_DATA:
      return {
        ...state,
        details: {
          ...state.details,
          ...action.payload,
          tags: action.payload.tags || state.details.tags,
        },
      };
    case GET_PROFILE_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case GET_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: {
          ...state.details,
          ...action.payload,
        },
      };
    case GET_PROFILE_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        details: { ...state.details, hasPassword: true },
        loading: false,
      };
    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_SETTLEMENTS:
      return {
        ...state,
        loading: true,
      };
    case GET_SETTLEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        settlements: action.payload,
      };
    case GET_SETTLEMENTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case GET_BADGES:
      return {
        ...state,
        loading: true,
      };
    case GET_BADGES_SUCCESS:
      return {
        ...state,
        loading: false,
        badges: action.payload || [],
      };
    case GET_BADGES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
