import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

import { Course, Lesson } from 'types/types';
import { useCourseDetailPageStyles } from './styles';
import { lessonCountIndicator, simplifiedTimeIndicator } from 'utils/icons';
import TopLeftPatch from 'assets/images/course-details-top-left-patch.png';
import TopRightPatch from 'assets/images/course-details-top-right-patch.png';
import BottomLeftPatch from 'assets/images/course-details-bottom-left-patch.png';
import BottomRightPatch from 'assets/images/course-details-bottom-right-patch.png';
import { LessonCard } from 'components/LessonCard/LessonCard';
import theme from 'theme';
import { useWindowSize } from 'utils/useWindowSize';

export const Lessons = ({ history, course, lessons, showNotification }) => {
  const { t } = useTranslation();
  const classes = useCourseDetailPageStyles();
  const { totalMinutesToComplete, totalXp, lessonsCount } = course;
  const { isDesktop } = useWindowSize(theme.breakpoints.values);

  return (
    <>
      <img src={TopLeftPatch} className={classNames(classes.Patch, classes.TopPatch, classes.LeftPatch)} alt="" />
      <img src={TopRightPatch} className={classNames(classes.Patch, classes.TopPatch, classes.RightPatch)} alt="" />
      {lessons.length > 3 ? (
        <>
          <img
            src={BottomLeftPatch}
            className={classNames(classes.Patch, classes.BottomPatch, classes.LeftPatch)}
            alt=""
          />
          <img
            src={BottomRightPatch}
            className={classNames(classes.Patch, classes.BottomPatch, classes.RightPatch)}
            alt=""
          />
        </>
      ) : null}
      <Box mb={5} className="PageTitleContainer">
        <h2 className="Title" tabIndex={0}>
          {t('course_details_lessons')}
        </h2>
        <div className="TitleBorder" />
      </Box>
      <Box className={classes.IndicatorWrapper}>
        <Box mt={2} className={classes.IndicatorContainer}>
          <Box
            mr={7}
            className={classes.IconizedIndicator}
            tabIndex={0}
            aria-label={t('alt_number_of_lessons', { lessons: lessonsCount })}
          >
            {lessonCountIndicator(lessonsCount, t, theme.palette.lightPink)}
          </Box>
          <Box
            mr={7}
            className={classNames(classes.IconizedIndicator, classes.TimeIndicator)}
            tabIndex={0}
            aria-label={t('alt_minutes_to_complete', { minutes: totalMinutesToComplete })}
          >
            {simplifiedTimeIndicator(totalMinutesToComplete, t, theme.palette.greenSuccess)}
          </Box>
          <Box className={classes.XP} tabIndex={0} aria-label={t('alt_available_xp', { xp: totalXp })}>
            <span aria-hidden={true}>{`${t('common_xp')}`}</span> <span aria-hidden={true}>{`${totalXp}`}</span>
          </Box>
        </Box>
        <Box
          mt={3}
          className={classNames(classes.XP, 'Xs')}
          tabIndex={0}
          aria-label={t('alt_available_xp', { xp: totalXp })}
        >
          <div aria-hidden={true}>{`${totalXp} ${t('common_xp')}`}</div>
        </Box>
      </Box>
      {isDesktop
        ? lessons
            .filter((lesson) => lessons.indexOf(lesson) % 3 === 0)
            .map((lesson) => {
              const index = lessons.indexOf(lesson);
              return (
                <Box className={classes.Triplet} key={index}>
                  <Box className={classes.SingleLessonContainer}>
                    <LessonCard history={history} lesson={lesson} showNotification={showNotification} index={index} />
                  </Box>
                  {lessons[index + 1] ? (
                    <Box
                      className={classNames(classes.DoubleLessonContainer, {
                        [classes.SingleBoxInDoubleContainer]: !lessons[index + 2],
                      })}
                    >
                      <LessonCard
                        history={history}
                        lesson={lessons[index + 1]}
                        showNotification={showNotification}
                        index={index + 1}
                      />
                      {lessons[index + 2] ? (
                        <LessonCard
                          history={history}
                          lesson={lessons[index + 2]}
                          showNotification={showNotification}
                          index={index + 2}
                        />
                      ) : null}
                    </Box>
                  ) : null}
                </Box>
              );
            })
        : lessons.map((lesson) => (
            <Box className={classes.SingleLessonContainer} key={lessons.indexOf(lesson)}>
              <LessonCard
                history={history}
                lesson={lesson}
                showNotification={showNotification}
                index={lessons.indexOf(lesson)}
              />
            </Box>
          ))}
    </>
  );
};

Lessons.propTypes = {
  history: PropTypes.object,
  course: Course.isRequired,
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      totalMinutes: PropTypes.string,
      totalXp: PropTypes.string,
      count: PropTypes.number,
      data: PropTypes.arrayOf(Lesson),
    })
  ),
  showNotification: PropTypes.func.isRequired,
};
