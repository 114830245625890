import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import { getCourseById } from 'store/courses/actions';
import { ClassStyle, Course, History, Topic } from 'types/types';
import { courseDetailPageStyles } from './styles';
import { ShortDescription } from './ShortDescription';
import { WhatToLearn } from './WhatToLearn';
import { Requirements } from './Requirements';
import { DetailedDescription } from './DetailedDescription';
import { Lessons } from './Lessons';
import { Button, ExpandableBox, FavoriteCheckBox, TopPageNavigationArrows } from 'components';
import { TagList } from './TagList';
import { ApiErrorMessages, NotificationTypes } from 'constants/Constants';
import { decimals } from 'utils/decimals';
import { getProfileDetails } from 'store/profile/actions';
import { containsErrorMessage } from 'utils/error';
import { navigationArrow } from 'utils/icons';
import { isBoolean } from 'utils/isBoolean';
import { titleFocus } from 'utils/titleFocus';
import theme from 'theme';
import { withFirstFocus } from 'utils/withFirstFocus';
import { RunStates } from 'components/Tutorial/Tutorial';
import {
  getCoursePageTutorialReady,
  getHomePageTutorialReady,
  getTopicsPageTutorialReady,
  setCoursePageTutorialReady,
} from 'utils/localStorage';
import { StepIndexes } from 'components/Tutorial/Tutorial';

class CourseDetailPage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      CourseDetailContainer: ClassStyle,
      TopContent: ClassStyle,
      ExamResultBox: ClassStyle,
      ExamDoneText: ClassStyle,
      ExamDoneValue: ClassStyle,
      CourseDetailPageTitle: ClassStyle,
      AddToFavourites: ClassStyle,
      HeartBox: ClassStyle,
      ExamButtonBox: ClassStyle,
      ExamButtonWrapper: ClassStyle,
      WhiteWrapper: ClassStyle,
      TopicArrowLabel: ClassStyle,
      TopicArrow: ClassStyle,
      BackToTopicContainer: ClassStyle,
    }),
    history: History,
    t: PropTypes.func,
    loading: PropTypes.bool.isRequired,
    course: Course,
    isLoggedIn: PropTypes.bool.isRequired,
    showNotification: PropTypes.func.isRequired,
    getExam: PropTypes.func.isRequired,
    getCourseById: PropTypes.func,
    addCommonLoader: PropTypes.func,
    removeCommonLoader: PropTypes.func,
    topics: PropTypes.arrayOf(Topic),
    windowWidth: PropTypes.number,
    onClick: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired,
    firstFocusRef: PropTypes.any.isRequired,
    tutorial: PropTypes.object.isRequired,
    setTutorialState: PropTypes.func.isRequired,
  };

  static pageTitleKey = 'page_title_course_detail';

  state = {
    topic: null,
    windowSize: 0,
  };

  navigating = false;

  static async getInitialData({ dispatch, language, req, token }) {
    const reqParams = req.url.split('/');
    const id = reqParams[reqParams.length - 1];
    await dispatch(getCourseById(id, language));
    if (token) {
      await dispatch(getProfileDetails({ language, token }));
    }
    titleFocus(dispatch);
  }

  componentDidMount() {
    this.props.addCommonLoader();
    const params = this.props.history.location.pathname.split('/');
    const topicId = params[3];
    const topic = this.props.topics.find(({ id }) => id === parseInt(topicId));
    this.setState({ topic });

    if (topic) {
      const { runState } = this.props.tutorial;
      switch (runState) {
        case RunStates.NOT_RUNNIG:
          if (!getCoursePageTutorialReady()) {
            this.props.setTutorialState({ runState: RunStates.WAITING, stepIndex: StepIndexes.TutorialCourse1 });
          }
          break;
        default:
          break;
      }
    } else {
      this.props.setTutorialState({ runState: RunStates.NOT_RUNNIG, stepIndex: 0 });
    }
  }

  componentDidUpdate() {
    const { runState, stepIndex } = this.props.tutorial;
    switch (runState) {
      case RunStates.WAITING:
        switch (stepIndex) {
          case StepIndexes.TutorialTopics2:
            if (!this.navigating) {
              this.navigating = true;
              this.backToTopicClick();
            }
            break;
          case StepIndexes.TutorialCourse1:
            if (this.isLoaded()) {
              if (this.props.course.lessons.length) {
                this.props.setTutorialState({ runState: RunStates.RUNNING });
              } else {
                this.props.setTutorialState({ runState: RunStates.NOT_RUNNIG, stepIndex: 0 });
              }
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  componentWillUnmount() {
    if (getHomePageTutorialReady() && getTopicsPageTutorialReady()) {
      setCoursePageTutorialReady();
      this.props.setTutorialState({ runState: RunStates.NOT_RUNNIG, stepIndex: 0 });
    } else if (getHomePageTutorialReady() && !getTopicsPageTutorialReady()) {
      setCoursePageTutorialReady();
      this.props.setTutorialState({ runState: RunStates.WAITING, stepIndex: StepIndexes.TutorialTopics1 });
    } else if (!getHomePageTutorialReady() && !getTopicsPageTutorialReady()) {
      this.props.setTutorialState({ runState: RunStates.WAITING, stepIndex: StepIndexes.TutorialHome1 });
    }
  }

  isLoaded() {
    const { loading, course } = this.props;
    return !loading && !!course;
  }

  startExam = async () => {
    const { history, t, course, getExam, showNotification } = this.props;
    try {
      await getExam(course.id);
    } catch (error) {
      if (containsErrorMessage(error, ApiErrorMessages.COURSE_HAS_NO_EXAM)) {
        showNotification({
          translateKey: 'course_details_no_exam',
          type: NotificationTypes.error,
        });
        return;
      } else if (error && error.resolved) {
        return;
      }
      throw error;
    }
    const examUrl = window.location.pathname + '/' + t('url_exam');
    history.replace(examUrl);
  };

  filterCourses = (courses) => courses.filter((el) => el.public);

  backToTopicClick = () => {
    const { history, t } = this.props;
    const topicUrl = window.location.pathname.split('/' + t('url_courses'))[0];
    history.push(topicUrl);
  };

  render() {
    const {
      classes,
      course,
      t,
      history,
      isLoggedIn,
      showNotification,
      windowWidth,
      onClick,
      onKeyDown,
      firstFocusRef,
    } = this.props;
    const { topic } = this.state;
    if (!course || !topic) return null;
    const { lessons, tags } = course;
    return isEmpty(lessons) ? null : (
      <>
        <Box mt={2} className={classes.CourseDetailContainer} onClick={onClick} onKeyDown={onKeyDown}>
          <Box
            mb={5}
            className={classNames('PageTitleContainer', classes.CourseDetailPageTitle, {
              LoggedIn: isLoggedIn,
              WithResult: isLoggedIn && (course.examSuccess || course.examSuccess === false),
            })}
          >
            <h1 className="Title" tabIndex={0} ref={firstFocusRef}>
              {course.title}
            </h1>
            <div className="TitleBorder" />
            <Box
              tabIndex={0}
              role="link"
              aria-label={t('course_details_back_to_topic')}
              display="flex"
              alignItems="center"
              className={classNames(classes.BackToTopicContainer, { WithResult: isBoolean(course.examSuccess) })}
              onClick={this.backToTopicClick}
            >
              {navigationArrow(classes.TopicArrow)}
              <Box fontWeight="bold" className={classes.TopicArrowLabel}>
                {t('course_details_back_to_topic')}
              </Box>
            </Box>
            {!isLoggedIn ? null : <FavoriteCheckBox course={course} label={t('course_details_favourites')} />}
          </Box>
          {!isLoggedIn ? null : (
            <Box
              className={classNames(classes.TopContent, {
                WithResult: isLoggedIn && (course.examSuccess || course.examSuccess === false),
              })}
            >
              {!course.examSuccess && course.examSuccess !== false ? null : course.examSuccess ? (
                <Box
                  className={classNames(classes.ExamResultBox, 'Success')}
                  tabIndex={0}
                  aria-label={t('alt_course_card_pass', { examResult: decimals(course.examResult) })}
                >
                  <Box className={classes.ExamDoneText} aria-hidden={true}>
                    {t('course_details_exam_done')}
                  </Box>
                  <Box className={classes.ExamDoneValue} aria-hidden={true}>{`${decimals(course.examResult)}%`}</Box>
                </Box>
              ) : (
                <Box
                  className={classes.ExamResultBox}
                  tabIndex={0}
                  aria-label={t('alt_course_card_fail', { examResult: decimals(course.examResult) })}
                >
                  <Box className={classes.ExamDoneText} aria-hidden={true}>
                    {t('course_details_exam_failed')}
                  </Box>
                  <Box className={classes.ExamDoneValue} aria-hidden={true}>{`${decimals(course.examResult)}%`}</Box>
                </Box>
              )}
            </Box>
          )}
          <TopPageNavigationArrows
            searchArray={this.filterCourses(topic.courses)}
            searchedObj={course}
            target="id"
            label={t('common_course')}
          />
          <ExpandableBox
            text={
              <>
                <ShortDescription course={course} />
                <WhatToLearn course={course} />
                <Requirements course={course} />
                <DetailedDescription course={course} />
                <TagList tags={tags} />
              </>
            }
            tabIndex={-1}
            minHeight={340}
            alwaysExpanded={windowWidth >= theme.breakpoints.values.sm}
          />
          <Lessons history={history} course={course} lessons={lessons} showNotification={showNotification} />
          <Box mt={12} mb={12} className={classes.ExamButtonBox}>
            <Box className={classes.ExamButtonWrapper}>
              <Box className={classes.WhiteWrapper}>
                {/* TODO decision need to conditonal render by course.hasExam */}
                <Button
                  buttonClassName="Pink"
                  large
                  onClick={
                    isLoggedIn
                      ? this.startExam
                      : () =>
                          showNotification({
                            translateKey: 'course_details_login_to_exam_access',
                            type: NotificationTypes.warning,
                          })
                  }
                >
                  {t('common_exam')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
}

export default hoistStatics(
  withTranslation()(withStyles(courseDetailPageStyles)(withFirstFocus(CourseDetailPage))),
  CourseDetailPage
);
