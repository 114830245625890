import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';

import { topicsPageStyles } from './styles';
import ExpandableBox from 'components/ExpandableBox/ExpandableBox';
import { Filters } from 'components/Filter/Filters';
import { getProfileTags, getTags } from 'store/tags/actions';
import { getTopics } from 'store/topics/actions';
import { getCoursesForTopic, resetCourses, setFilters } from 'store/courses/actions';
import { ClassStyle, Course, FiltersType, Tag, Topic } from 'types/types';
import { MobileFilter } from 'components/Filter/MobileFilter';
import { CardContainer } from 'components/CardContainer/CardContainer';
import { ItemTypes } from 'constants/Constants';
import { getNumberOfActiveFilters } from 'utils/filters';
import { getProfileDetails } from 'store/profile/actions';
import { CourseCardList } from 'components';
import { titleFocus } from 'utils/titleFocus';
import { RunStates } from 'components/Tutorial/Tutorial';
import {
  getCoursePageTutorialReady,
  getHomePageTutorialReady,
  getTopicsPageTutorialReady,
  setTopicsPageTutorialReady,
} from 'utils/localStorage';
import { StepIndexes } from 'components/Tutorial/Tutorial';
import { getPath } from 'utils/getPath';

class TopicsPage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      TopicsPageContainer: ClassStyle,
      IntroText: ClassStyle,
      MobileIntroText: ClassStyle,
      MobileIntroTextBox: ClassStyle,
      NoResult: ClassStyle,
      CoursesContainer: ClassStyle,
    }),
    tags: PropTypes.arrayOf(Tag),
    filters: FiltersType,
    setFilters: PropTypes.func,
    loading: PropTypes.bool.isRequired,
    topics: PropTypes.arrayOf(Topic),
    courses: PropTypes.arrayOf(Course),
    numberOfCourses: PropTypes.number,
    getCoursesForTopic: PropTypes.func,
    loadMoreCourses: PropTypes.func,
    history: PropTypes.object,
    profileTags: PropTypes.arrayOf(Tag),
    isLoading: PropTypes.bool,
    shouldShowLoading: PropTypes.bool,
    getProfileDetails: PropTypes.func,
    addCommonLoader: PropTypes.func,
    removeCommonLoader: PropTypes.func,
    loadMoreCoursesForTopic: PropTypes.func,
    tutorial: PropTypes.object.isRequired,
    setTutorialState: PropTypes.func.isRequired,
    pages: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  static pageTitleKey = 'page_title_topics';

  static async getInitialData({ dispatch, language, token, pathname, search, firstLoad }) {
    if (!firstLoad) {
      dispatch(resetCourses());
    }

    try {
      const topics = await dispatch(getTopics(language));
      const moduleId = topics.find((topic) => topic.pathname === pathname).id;
      const params = queryString.parse(search);

      const [profile] = await Promise.all([
        token && dispatch(getProfileDetails({ token })),
        dispatch(getTags(language)),
        dispatch(getCoursesForTopic({ moduleId }, language)),
        token && dispatch(getProfileTags({ language, token })),
      ]);

      await dispatch(
        setFilters({
          tags: params && params.filter && profile && profile.tags && token ? profile.tags.map((tag) => tag.id) : [],
          examDone: null,
          duration: null,
          searchString: null,
        })
      );

      titleFocus(dispatch);
    } catch (err) {
      console.error(err);
    }
  }

  state = {
    page: 1,
  };

  navigating = false;

  cardListRef = createRef();

  componentDidMount() {
    const { runState } = this.props.tutorial;
    switch (runState) {
      case RunStates.NOT_RUNNIG:
        if (!getTopicsPageTutorialReady()) {
          this.props.setTutorialState({ runState: RunStates.WAITING, stepIndex: StepIndexes.TutorialTopics1 });
        }
        break;
      default:
        break;
    }
  }

  async componentDidUpdate(prevProps) {
    const { filters, getCoursesForTopic, history, pages, t } = this.props;

    if (!isEqual(filters, prevProps.filters)) {
      const module = this.getTopic();

      if (module) {
        this.setState({ page: 1 });

        await getCoursesForTopic({
          moduleId: module.id,
          searchText: filters.searchString && filters.searchString.length ? filters.searchString : null,
          tags: filters.tags.length ? filters.tags : null,
          examDone: filters.examDone === true || filters.examDone === false ? filters.examDone : null,
          minutesMin: (filters.duration && filters.duration.minutesMin) || null,
          minutesMax: (filters.duration && filters.duration.minutesMax) || null,
          page: 1,
        });
      }
    }

    const { runState, stepIndex } = this.props.tutorial;
    switch (runState) {
      case RunStates.WAITING:
        switch (stepIndex) {
          case StepIndexes.TutorialHome2:
            if (!this.navigating) {
              this.navigating = true;
              history.push(getPath(pages, t('menu_item_home')));
            }
            break;
          case StepIndexes.TutorialTopics1:
          case StepIndexes.TutorialTopics2:
            if (this.isLoaded()) {
              this.props.setTutorialState({ runState: RunStates.RUNNING });
            }
            break;
          case StepIndexes.TutorialCourse1:
            if (!this.navigating && this.cardListRef.current) {
              this.navigating = true;
              this.cardListRef.current.clickTutorialCard();
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  componentWillUnmount() {
    if (getHomePageTutorialReady() && !getCoursePageTutorialReady()) {
      setTopicsPageTutorialReady();
      this.props.setTutorialState({ runState: RunStates.NOT_RUNNIG, stepIndex: StepIndexes.TutorialCourse1 });
    } else if (getHomePageTutorialReady() && getCoursePageTutorialReady()) {
      setTopicsPageTutorialReady();
      this.props.setTutorialState({ runState: RunStates.NOT_RUNNIG, stepIndex: 0 });
    }
  }

  isLoaded() {
    const { loading, shouldShowLoading, topics } = this.props;
    return !loading && !shouldShowLoading && !!topics.length && !!this.getTopic();
  }

  getTopic = () => {
    const { topics, history } = this.props;
    return topics.find((topic) => topic.pathname === history.location.pathname);
  };

  search = ({ searchString }) => {
    const { setFilters } = this.props;
    setFilters({ searchString: searchString.length ? searchString : null });
  };

  loadMore = async (page) => {
    const { filters, loadMoreCoursesForTopic } = this.props;
    const module = this.getTopic();

    this.setState({ page });

    await loadMoreCoursesForTopic({
      moduleId: module.id,
      searchText: filters.searchString && filters.searchString.length ? filters.searchString : null,
      tags: filters.tags.length ? filters.tags : null,
      examDone: filters.examDone === true || filters.examDone === false ? filters.examDone : null,
      minutesMin: (filters.duration && filters.duration.minutesMin) || null,
      minutesMax: (filters.duration && filters.duration.minutesMax) || null,
      page,
    });
  };

  render() {
    const {
      classes,
      tags,
      filters,
      setFilters,
      courses,
      numberOfCourses,
      profileTags,
      isLoading,
      shouldShowLoading,
    } = this.props;
    const { page } = this.state;
    const topic = this.getTopic();

    return !topic ? null : (
      <div className={classes.TopicsPageContainer}>
        <Box mb={6} className={classes.IntroText} tabIndex={0}>
          {topic.description}
        </Box>
        <Box mb={4} className={classes.MobileIntroText}>
          <ExpandableBox
            text={topic.description}
            minHeight={170}
            textBoxClass={classes.MobileIntroTextBox}
            alwaysExpanded
          />
        </Box>
        <Box className="TutorialTopics1">
          <Filters
            search={this.search}
            tags={tags}
            filters={filters}
            setFilters={setFilters}
            profileTags={profileTags}
            itemType={ItemTypes.Course}
          />
          <MobileFilter
            search={this.search}
            tags={tags}
            filters={filters}
            setFilters={setFilters}
            numberOfItems={numberOfCourses}
            profileTags={profileTags}
            itemType={ItemTypes.Course}
            isDataLoading={shouldShowLoading}
          />
        </Box>
        <div aria-live="polite">
          <CardContainer
            items={courses || []}
            numberOfItems={numberOfCourses}
            loadMoreItems={this.loadMore}
            itemType={ItemTypes.Course}
            numberOfActiveFilters={getNumberOfActiveFilters(filters)}
            noResultClass={classes.NoResult}
            isLoading={isLoading}
            skeleton={shouldShowLoading}
            page={page}
          >
            <Box mt={2} className={classes.CoursesContainer}>
              <CourseCardList ref={this.cardListRef} onTopicsPage courses={courses} skeleton={shouldShowLoading} />
            </Box>
          </CardContainer>
        </div>
      </div>
    );
  }
}

export default hoistStatics(withTranslation()(withStyles(topicsPageStyles)(TopicsPage)), TopicsPage);
