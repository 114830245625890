import { memo } from 'react';
import PropTypes from 'prop-types';

import { useSearchField } from '../../useSearchField';
import { useMobileFilterStyles } from '../../styles';

export const MobileFilterSearchField = memo(({ ariaLabel, placeholder, submit, searchOnEmpty }) =>
  useSearchField({
    customStyleFactory: useMobileFilterStyles,
    ariaLabel,
    placeholder,
    submit,
    searchOnEmpty,
  })
);

MobileFilterSearchField.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  searchOnEmpty: PropTypes.bool,
};
