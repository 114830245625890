import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';

import { aboutPageStyles } from './styles';
import Bp from 'assets/images/bp.jpg';
import Image from 'components/Image/Image';
import { getPath } from 'utils/getPath';
import { getProfileDetails } from 'store/profile/actions';
import hoistStatics from 'hoist-non-react-statics';
import { titleFocus } from 'utils/titleFocus';
import { ClassStyle } from '../../types/types';

class AboutPage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      AboutPage: ClassStyle,
      First: ClassStyle,
      Link: ClassStyle,
      Last: ClassStyle,
    }),
    t: PropTypes.func,
    pages: PropTypes.array,
  };

  static pageTitleKey = 'page_title_about';

  static async getInitialData({ dispatch, language, token }) {
    try {
      if (token) {
        await dispatch(getProfileDetails({ language, token }));
      }
      titleFocus(dispatch);
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    const { classes, t, pages } = this.props;
    return (
      <Box className={classes.AboutPage}>
        <Box mt={6} mb={5} className={classes.First} tabIndex={0}>
          {t('about_page_paragraph_1')}
        </Box>
        <Box mb={5} tabIndex={0}>
          <span>{t('about_page_paragraph_2a')}</span>
          <span className="Bold">{t('about_page_paragraph_2b')}</span>
          <span>{t('about_page_paragraph_2c')}</span>
          <span className="Bold">{t('about_page_paragraph_2d')}</span>
        </Box>
        <Box mb={5}>
          <Image src={Bp} alt="" aria-label="" aria-hidden={true} />
        </Box>
        <Box mb={5} tabIndex={0}>
          <div>{t('about_page_paragraph_3a')}</div>
          <a
            href="https://mtu.gov.hu/cikkek/tevekenysegunk"
            className={classNames('Bold', classes.Link)}
            target="_blank"
          >
            {t('about_page_paragraph_3b')}
          </a>
        </Box>
        <Box mb={5} tabIndex={0}>
          {t('about_page_paragraph_4')}
        </Box>
        <Box mb={5} className={classes.Last} tabIndex={0}>
          <div>{t('about_page_paragraph_5a')}</div>
          <Link to={getPath(pages, t('menu_item_knowledge_store'))} className={classNames('Bold', classes.Link)}>
            {t('about_page_paragraph_5b')}
          </Link>
        </Box>
      </Box>
    );
  }
}

export default hoistStatics(withTranslation()(withStyles(aboutPageStyles)(AboutPage)), AboutPage);
