import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Box from '@material-ui/core/Box';

import { Button, Dialog, OverallErrorMessages } from 'components';
import { useDeleteProfileDialogStyles } from 'modules/ProfilePage/styles';
import { useAcceptNewTermsDialogStyles } from './styles';
import { auto } from 'constants/Jss';
import { MandatoryLinks } from '../MandatoryLinks/MandatoryLinks';
import { Document } from 'types/types';
import { useAcceptLinks } from 'components/MandatoryLinks/useAcceptLinks';

const AcceptNewTermsDialog = ({
  open,
  closeDialogAndLogout,
  submit,
  showNotification,
  removeNotification,
  documents,
}) => {
  const { t } = useTranslation();
  const classes = useDeleteProfileDialogStyles();
  const extraClasses = useAcceptNewTermsDialogStyles();

  const validationSchema = Yup.object().shape({
    acceptTerms: Yup.mixed().oneOf([true], t('form_must_accept')),
  });

  const parts = useAcceptLinks(documents.termsAndConditions, documents.privacyPolicy);

  return (
    <Dialog
      open={open}
      onClose={(e) => {
        removeNotification();
        closeDialogAndLogout(e);
      }}
      title={t('form_terms_has_been_changed')}
      titleBoxClass={classNames(classes.PinkPatch, extraClasses.PinkPatch)}
      content={
        <Box className={classes.DialogContent}>
          <Box mb={6} className={extraClasses.Description} tabIndex={0}>
            {t('form_terms_has_been_changed_text')}
          </Box>
          <Formik
            initialValues={{
              acceptTerms: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(e) => submit(e)}
          >
            {({ values, errors, touched, handleChange, handleSubmit, setFieldTouched }) => (
              <form onSubmit={() => {}}>
                <OverallErrorMessages />
                <Box className={extraClasses.Links} ml={auto} mr={auto}>
                  <MandatoryLinks
                    fieldName="acceptTerms"
                    checkboxClass={extraClasses.Checkbox}
                    fieldValue={values.acceptTerms}
                    onChange={handleChange}
                    setFieldTouched={setFieldTouched}
                    error={errors.acceptTerms ? errors.acceptTerms : ''}
                    showNotification={showNotification}
                    parts={parts}
                  />
                </Box>
                {errors.acceptTerms && touched.acceptTerms ? (
                  <label className={extraClasses.ErrorText}>{errors.acceptTerms}</label>
                ) : (
                  ''
                )}
                <Box mt={8} mb={3} className={classes.ActionContainer}>
                  <Button buttonClassName="DarkBlue" onClick={handleSubmit}>
                    {t('form_terms_has_been_changed_accept_button_text')}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      }
    />
  );
};

AcceptNewTermsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialogAndLogout: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  removeNotification: PropTypes.func.isRequired,
  documents: PropTypes.shape({
    termsAndConditions: Document,
    privacyPolicy: Document,
  }),
};

export default AcceptNewTermsDialog;
