import makeStyles from '@material-ui/core/styles/makeStyles';
import { center, flex, none, pointer } from 'constants/Jss';

export const useTopNavigationArrowsStyles = makeStyles((theme) => ({
  Arrow: {
    width: 37,
    height: 37,
    borderRadius: '50%',
    backgroundColor: theme.palette.lightBlue,
    '&:hover': {
      backgroundColor: theme.palette.lightBlueHover,
    },
    cursor: pointer,
    display: flex,
    alignItems: center,
    justifyContent: center,
  },
  ArrowLabel: {
    color: theme.palette.blackTitle,
  },
  ArrowIcon: {
    fill: none,
    stroke: theme.palette.white,
    strokeWidth: 2,
    width: 19.617,
    height: 11.309,
  },
  ArrowIconLeft: {
    transform: 'rotate(90deg)',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
  ArrowIconRight: {
    transform: 'rotate(270deg)',
    marginTop: theme.spacing(0.25),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0.5),
  },
}));
