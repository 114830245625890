import { memo } from 'react';
import PropTypes from 'prop-types';

import { useSearchField } from '../../useSearchField';
import { useMobileHeaderStyles } from '../../styles';

export const MobileHeaderSearchField = memo(({ ariaLabel, placeholder, submit, cancel }) =>
  useSearchField({
    customStyleFactory: useMobileHeaderStyles,
    ariaLabel,
    placeholder,
    submit,
    cancel,
  })
);

MobileHeaderSearchField.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};
