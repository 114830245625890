import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Box from '@material-ui/core/Box';

import { redirectTo } from 'utils/redirectTo';
import { createSpaceOrEnterHandler } from 'utils/eventUtils';
import { navigationArrow } from 'utils/icons';

import { useTopNavigationArrowsStyles } from './styles';

const TopPageNavigationArrows = ({ searchArray, searchedObj, target, label }) => {
  const { t } = useTranslation();

  const classes = useTopNavigationArrowsStyles();

  const getPositionInArray = () => {
    if (searchArray) {
      return searchArray.findIndex((element) => element[target] === searchedObj[target]);
    }
    return -1;
  };

  const getHistory = (direction = 'prev') => {
    redirectTo(
      direction === 'prev'
        ? searchArray[getPositionInArray() - 1][target]
        : searchArray[getPositionInArray() + 1][target]
    );
  };

  const prevOnClick = () => {
    getHistory('prev');
  };

  const nextOnClick = () => {
    getHistory('next');
  };

  return (
    <Box mb={5} display="flex" justifyContent={getPositionInArray() < 1 ? 'flex-end' : 'space-between'}>
      {getPositionInArray() > 0 && (
        <Box display="flex" alignItems="center">
          <Box
            aria-label={`${t('common_prev')} ${label}`}
            onClick={prevOnClick}
            onKeyPress={createSpaceOrEnterHandler(prevOnClick)}
            role="link"
            tabIndex={0}
            className={classes.Arrow}
            mr={1}
          >
            {navigationArrow(classNames(classes.ArrowIcon, classes.ArrowIconLeft))}
          </Box>
          <Box className={classes.ArrowLabel} fontSize={16}>
            <Box fontWeight={700}>{t('common_prev')}</Box>
            <Box>{label}</Box>
          </Box>
        </Box>
      )}
      {getPositionInArray() < searchArray.length - 1 && searchArray.length !== 1 && (
        <Box display="flex" alignItems="center">
          <Box textAlign="right" className={classes.ArrowLabel} fontSize={16}>
            <Box fontWeight={700}>{t('common_next')}</Box>
            <Box>{label}</Box>
          </Box>
          <Box
            ml={1}
            onClick={nextOnClick}
            onKeyPress={createSpaceOrEnterHandler(nextOnClick)}
            aria-label={`${t('common_next')} ${label}`}
            role="link"
            tabIndex={0}
            className={classes.Arrow}
          >
            {navigationArrow(classNames(classes.ArrowIcon, classes.ArrowIconRight))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

TopPageNavigationArrows.propTypes = {
  searchArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchedObj: PropTypes.object.isRequired,
  target: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default TopPageNavigationArrows;
