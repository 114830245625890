import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import IconButton from '@material-ui/core/IconButton';
import { hidden, none, pointer, absolute, transparent, relative } from 'constants/Jss';

const useStyles = makeStyles((theme) => ({
  FilePicker: {
    width: theme.mixins.filePicker.width,
    height: theme.mixins.filePicker.height,
    position: relative,
  },
  input: {
    color: transparent,
    position: absolute,
    top: 0,
    left: 0,
    width: theme.mixins.filePicker.width,
    height: theme.mixins.filePicker.height,
    outline: none,
    '&::-webkit-file-upload-button': {
      visibility: hidden,
    },
    cursor: pointer,
  },
  button: {
    '&.MuiIconButton-root': {
      width: theme.mixins.filePicker.width,
      height: theme.mixins.filePicker.height,
      padding: 0,
    },
  },
  svg: {
    '&.MuiSvgIcon-root': {
      width: theme.mixins.filePicker.width,
      height: theme.mixins.filePicker.height,
      color: theme.palette.primary.main,
    },
  },
}));

const FilePicker = ({ onChange, containerClass, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.FilePicker, containerClass)}>
      <IconButton className={classes.button}>
        <AddCircleOutlineIcon className={classes.svg} />
      </IconButton>
      <input type="file" name="file" onChange={onChange} className={classes.input} {...props} />
    </div>
  );
};

FilePicker.propTypes = {
  onChange: PropTypes.func,
  containerClass: PropTypes.string,
};

export default FilePicker;
