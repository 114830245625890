import request from 'utils/request';
import { Methods } from 'utils/http';
import queryString from 'query-string';

const BASE_URL = '/lessons';

export const getLessons = async ({ page = 1, searchText, language, tags, minutesMin, minutesMax }) => {
  const params = {
    page,
    sortBy: 'title',
    order: 'asc',
    searchText,
    minutesMin,
    minutesMax,
  };
  for (let p in params) {
    if (!params[p] && params[p] !== false) {
      delete params[p];
    }
  }
  let query = queryString.stringify(params);
  if (tags) {
    for (let tag of tags) {
      query += `&tags[]=${tag}`;
    }
  }
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/search?${query}`,
    language,
  });
};

export const getLessonById = async (id, language, token) => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/${id}`,
    language,
  };
  if (token) {
    requestData.headers = { Authorization: token };
  }
  return await request(requestData);
};
