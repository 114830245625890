import {
  absolute,
  auto,
  block,
  bold,
  center,
  fixed,
  flex,
  flexEnd,
  hidden,
  inherit,
  none,
  noRepeat,
  pointer,
  relative,
  transparent,
  underline,
  uppercase,
} from 'constants/Jss';
import Szechenyi2020 from 'assets/images/2020.png';
import Szechenyi2020Mobile from 'assets/images/2020-mobile.png';

export const appStyles = (theme) => ({
  '@global': {
    'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active, input:-internal-autofill-selected': {
      '-webkit-box-shadow': '0 0 0 1000px white inset',
    },
    '@keyframes lds-dual-ring': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      },
    },
    '.fade': {
      '&.enter': {
        opacity: 0,
        position: relative,
        '&.enter-active': {
          position: relative,
          opacity: 1,
          transition: 'opacity 300ms ease-in-out',
        },
      },
      '&.exit': {
        opacity: 1,
        position: relative,
        '&.exit-active': {
          opacity: 0,
          position: absolute,
          top: 0,
          left: 0,
          transition: 'opacity 300ms ease-in-out',
        },
        '& .page-animation-exit-hidden': {
          visibility: hidden,
        },
      },
    },
    '.Page': {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.mixins.mobileHeader.height,
      },
      '& > div': {
        paddingTop: theme.spacing(6),
        marginTop: 0,
        marginBottom: 0,
        paddingBottom: theme.spacing(10),
        [theme.breakpoints.down('sm')]: {
          paddingTop: theme.spacing(10),
          paddingBottom: theme.spacing(22),
          marginLeft: 0,
          marginRight: 0,
          width: '100%',
        },
      },
    },
    '.PageTitleContainer': {
      display: block,
      width: '100%',
    },
    '.TitleBorder': {
      width: 75,
      height: 4,
      backgroundColor: theme.palette.green,
      marginLeft: auto,
      marginRight: auto,
      '&.Cyan': {
        backgroundColor: theme.palette.cyan,
      },
    },
    '.Title': {
      fontFamily: 'Montserrat',
      fontSize: 32,
      fontWeight: 900,
      textAlign: center,
      paddingBottom: theme.spacing(1),
      maxWidth: 700,
      marginLeft: auto,
      marginRight: auto,
      color: theme.palette.blackTitle,
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },

      '&.HomePageTitle': {
        maxWidth: inherit,
      },
    },
    '.H1Style': {
      marginTop: 0,
      marginBottom: 0,
      lineHeight: 1.43,
      color: theme.palette.blackTitle,
    },
    '.Description': {
      color: theme.palette.grey70,
      lineHeight: '30px',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        lineHeight: '18px',
      },
    },
    '.Hidden': {
      display: none,
    },
    '.Bold': {
      fontWeight: bold,
    },
    '.CloseText': {
      fontFamily: 'Montserrat',
      fontWeight: bold,
      fontSize: 16,
      color: theme.palette.blackTitle,
      cursor: pointer,
    },
    '.Link': {
      color: theme.palette.cyan,
      fontWeight: bold,
      textDecoration: underline,
      cursor: pointer,
      '&:hover, &.active': {
        color: theme.palette.cyanHover,
      },
    },
    h1: {
      fontFamily: 'Montserrat',
      fontWeight: 900,
      fontSize: 45,
      marginTop: 0,
      marginBottom: 0,
    },
    h2: {
      '&:not(.Title)': {
        fontSize: 24,
        fontWeight: bold,
        color: theme.palette.cyan,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
      },
      '&.Title': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    a: {
      color: theme.palette.cyan,
      '&:hover': {
        color: theme.palette.cyanHover,
      },
    },
    '*': {
      '-webkit-tap-highlight-color': transparent,
      '-ms-touch-action': 'manipulation',
      'touch-action': 'manipulation',
    },
    '.FocusShown *:focus': {
      outlineColor: theme.palette.focusColor,
      outlineStyle: auto,
      outlineWidth: 1,
    },
    '.FocusHidden *:focus': {
      outline: none,
    },
    body: {
      margin: 0,
      padding: 0,
      height: '100%',
      color: theme.palette.blackTitle,
      [theme.breakpoints.down('sm')]: {
        height: `calc(100% - ${theme.mixins.mobileHeader.height}px)`,
      },
    },
  },
  CookieButton: {
    color: `${theme.palette.white} !important`,
    fontSize: '14px !important',
    borderRadius: '22px !important',
    padding: '12px 24px !important',
    backgroundColor: `${theme.palette.lightPink} !important`,
    textTransform: `${uppercase} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.lightPinkHover} !important`,
    },
    '&:focus': {
      outlineColor: theme.palette.focusColor,
      outlineStyle: auto,
      outlineWidth: 1,
    },
  },
  Szechenyi2020Container: {
    [theme.breakpoints.down('xs')]: {
      display: flex,
      justifyContent: flexEnd,
      background: theme.palette.footerBlue,
    },
    [theme.breakpoints.up('sm')]: {
      position: fixed,
      bottom: 0,
      right: 0,
      zIndex: 5,
    },
  },
  Szechenyi2020: {
    width: 282,
    height: 180,
    backgroundImage: `url(${Szechenyi2020})`,
    backgroundRepeat: noRepeat,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${Szechenyi2020Mobile})`,
      width: 235,
      height: 150,
    },
  },
});
