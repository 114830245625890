import * as TopicsService from 'services/topics';
import { GET_TOPICS, GET_TOPICS_FAILED, GET_TOPICS_SUCCESS } from './types';
import { getTopicsRoute } from 'utils/getTopicsRoute';

export const getTopics = (language) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_TOPICS });

      const topics = await TopicsService.getTopics(language);

      for (let topic of topics) {
        topic.pathname = getTopicsRoute(topic).path;
      }

      dispatch({
        type: GET_TOPICS_SUCCESS,
        payload: topics,
      });

      return topics;
    } catch (error) {
      dispatch({ type: GET_TOPICS_FAILED });
      throw error;
    }
  };
};
