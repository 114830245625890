import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { withWindowWidth } from 'components';
import CertificatesPage from './CertificatesPage';
import { getCertificates } from 'store/certificates/actions';
import { selectCertificates } from 'store/certificates/selectors';
import { selectIsLoggedIn } from 'store/auth/selectors';
import { addCommonLoader } from 'store/loader/actions';
import { showNotification } from 'store/common/actions';

const mapStateToProps = (state) => {
  return {
    certificates: selectCertificates(state),
    profileDetails: state.profile.details,
    isLoggedIn: selectIsLoggedIn(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCertificates,
      addCommonLoader,
      showNotification,
    },
    dispatch
  );
};

export default compose(withRouter, withWindowWidth, connect(mapStateToProps, mapDispatchToProps))(CertificatesPage);
