import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

import { useMandatoryLinksStyles } from './styles';
import { NotificationTypes } from 'constants/Constants';

export const MandatoryLinks = ({
  fieldName,
  fieldValue,
  onChange,
  setFieldTouched,
  error,
  parts,
  showNotification,
}) => {
  const classes = useMandatoryLinksStyles();
  const { t } = useTranslation();

  const [links, setLinks] = React.useState([]);

  React.useEffect(() => {
    setLinks(parts.filter((part) => !!part.link).map((part) => ({ link: part.link, opened: false })));
  }, [parts]);

  const isOpened = ({ link }) => {
    const foundLink = links.find((linkObj) => linkObj.link === link);
    return !!foundLink && foundLink.opened;
  };

  const markOpened = ({ link }) => {
    setLinks(links.map((linkObj) => (linkObj.link === link ? { link, opened: true } : linkObj)));
  };

  const disabled = links.some(({ opened }) => !opened);

  return (
    <Box className={classes.MandatoryLinkBox}>
      <Box tabIndex={0}>
        {parts.map((part) => {
          return part.link ? (
            <a
              href={part.link}
              target="_blank"
              className={classNames(classes.Link, { [classes.Visited]: isOpened(part) })}
              onClick={() => markOpened(part)}
              key={parts.indexOf(part)}
            >
              {part.text}
            </a>
          ) : (
            <span key={parts.indexOf(part)}>{part.text}</span>
          );
        })}
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            name={fieldName}
            className={classes.Checkbox}
            value={fieldValue}
            onChange={onChange}
            onBlur={() => setFieldTouched(fieldName, true)}
            error={error}
            disabled={disabled}
            tabIndex={0}
          />
        }
        label=""
        onBlur={() => setFieldTouched(fieldName, true)}
        onClick={() =>
          disabled && showNotification({ translateKey: 'errors_open_documents', type: NotificationTypes.error })
        }
        aria-label={t('alt_accept_terms_and_conditions')}
      />
    </Box>
  );
};

MandatoryLinks.propTypes = {
  fieldName: PropTypes.string,
  fieldValue: PropTypes.any,
  onChange: PropTypes.func,
  setFieldTouched: PropTypes.func,
  error: PropTypes.any,
  parts: PropTypes.array,
  showNotification: PropTypes.func,
};
