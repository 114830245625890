import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import { Skeleton, FavoriteCheckBox } from 'components';
import { useCourseCardStyles, useMobileCourseCardStyles } from './styles';
import { getUrl } from 'utils/urlFromImagePath';
import { timeIndicator } from 'utils/icons';
import { getCurrentLanguage } from 'utils/language';
import { isBoolean } from 'utils/isBoolean';
import { Course } from 'types/types';
import { bold } from 'constants/Jss';
import { decimals } from 'utils/decimals';
import { getTopicsCoursesUrl } from 'utils/getTopicsCoursesUrl';

const MAX_TAGS = 3;
const MAX_MOBILE_TAGS = 2;

export const CourseCard = forwardRef(({ course, className, history, skeleton = false, onClick, topicId }, ref) => {
  const classes = useCourseCardStyles();
  const mobileClasses = useMobileCourseCardStyles();
  const { t } = useTranslation();
  const { totalMinutesToComplete, title = '', id, image, imageAlt, examSuccess, examResult } = course || {};
  const tags = (course || {}).tags || [];
  const isRegular = !skeleton;

  const redirectToCourseDetails = () => {
    if (onClick) {
      onClick();
    } else if (isRegular) {
      const language = getCurrentLanguage(history.location.pathname);
      if (history.location.pathname.includes(`/${t('url_topics')}/`)) {
        const topicsId = history.location.pathname.substring(history.location.pathname.lastIndexOf('/') + 1);
        history.push(getTopicsCoursesUrl(language, topicsId, id));
      } else if (topicId) {
        history.push(getTopicsCoursesUrl(language, topicId, id));
      }
    }
  };

  const onKeyPress = (event) => {
    if ((event.key === 'Enter' || event.key === ' ') && event.target === event.currentTarget) {
      event.preventDefault();
      redirectToCourseDetails();
    }
  };

  const examDone = isBoolean(examSuccess);
  const areeExamDone = examDone
    ? `, ${t(examSuccess ? 'alt_course_card_pass' : 'alt_course_card_fail', { examResult })}`
    : '';
  const ariaLabel = `${t('common_course')} ${title} ${areeExamDone} ${t('alt_course_card_time', {
    time: totalMinutesToComplete,
  })}`;

  return (
    <>
      <div
        className={classNames(classes.CourseCard, className)}
        tabIndex={0}
        aria-label={ariaLabel}
        role="application"
        onKeyPress={onKeyPress}
        ref={ref}
      >
        <div
          role="link"
          onClick={redirectToCourseDetails}
          className={classNames(classes.ImageContainer, classes.Image, 'ImageContainer')}
        >
          {isRegular ? (
            <div
              className={classNames(classes.CourseImage, 'CourseImage')}
              aria-label={imageAlt}
              aria-hidden="true"
              style={{ backgroundImage: `url("${getUrl(image)}")` }}
            />
          ) : (
            <Skeleton width="100%" height="100%" variant="rect" />
          )}
        </div>
        {examDone && (
          <Box className={classNames(classes.CourseDonePatch, 'CourseDonePatch', { Success: examSuccess })}>
            <Box mt={0.5} className={classes.ExamDoneText}>
              {examSuccess ? t('course_details_exam_done') : t('course_details_exam_failed')}
            </Box>
            <Box className={classes.ExamDoneValue}>{`${decimals(examResult)}%`}</Box>
          </Box>
        )}
        <div className={classes.InfoContainer}>
          <Box className={classes.SymbolContainer} mb={3}>
            <Box className={classes.TimeIndicator}>
              {isRegular ? timeIndicator(totalMinutesToComplete, t) : <Skeleton height={19} width={70} />}
            </Box>
            {!!course && <FavoriteCheckBox onCard course={course} />}
          </Box>
          <Box role="link" onClick={redirectToCourseDetails} className={classes.TitleContainer} mb={1}>
            {isRegular ? title.trim() : <Skeleton height={25} width={200} maxWidth="100%" />}
          </Box>
          <Box aria-hidden="true" className={classes.TagContainer}>
            {isRegular
              ? tags.slice(0, MAX_TAGS).map((tag) => (
                  <Box key={tag.id} className={classes.Tag} mr={1} mt={1}>
                    {tag.title}
                  </Box>
                ))
              : null}
            {isRegular && tags.length > MAX_TAGS ? <Box style={{ fontWeight: bold }}>...</Box> : null}
            {skeleton
              ? Array.from({ length: MAX_TAGS }).map((_, i) => (
                  <Box key={`skeleton_${i}`} mr={1} mt={1}>
                    <Skeleton width={150} maxWidth="100%" height={23} />
                  </Box>
                ))
              : null}
          </Box>
        </div>
      </div>
      <div
        className={classNames(mobileClasses.CourseCard, className)}
        onClick={redirectToCourseDetails}
        tabIndex={0}
        aria-label={ariaLabel}
        role="link"
        onKeyPress={onKeyPress}
      >
        <Box className={classNames(classes.TimeIndicator, mobileClasses.TimeIndicator)}>
          {isRegular ? timeIndicator(totalMinutesToComplete, t) : <Skeleton height={19} width={70} />}
        </Box>
        {!!course && <FavoriteCheckBox onCard course={course} />}
        {examDone && (
          <Box className={classNames(mobileClasses.CourseDonePatch, { Success: examSuccess })}>
            <Box className={mobileClasses.ExamDoneValue}>{`${decimals(examResult)}%`}</Box>
            <Box className={mobileClasses.ExamDoneText}>
              {examSuccess ? t('course_details_exam_done') : t('course_details_exam_failed')}
            </Box>
          </Box>
        )}
        <Box role="link" className={mobileClasses.ImageAndTitleContainer}>
          <div className={mobileClasses.ImageWrapper}>
            {isRegular ? (
              <div
                className={mobileClasses.ImageContainer}
                style={{ backgroundImage: `url("${getUrl(image)}")` }}
                aria-hidden={true}
                aria-label={imageAlt}
              />
            ) : (
              <div className={mobileClasses.ImageContainer}>
                <Skeleton width="100%" height="100%" variant="rect" />
              </div>
            )}
          </div>
          <div role="link" className={mobileClasses.TitleWrapper}>
            <div className={mobileClasses.TitleContainer}>
              {isRegular ? title.trim() : <Skeleton height={25} width="100%" />}
            </div>
          </div>
        </Box>
        <Box mt={1} className={mobileClasses.TagContainer}>
          {isRegular
            ? tags.slice(0, MAX_MOBILE_TAGS).map((tag) => (
                <Box key={tag.id} className={classNames(classes.Tag, mobileClasses.Tag)} mr={1}>
                  {tag.title}
                </Box>
              ))
            : null}
          {isRegular && tags.length > MAX_MOBILE_TAGS ? <Box style={{ fontWeight: bold }}>...</Box> : null}
          {skeleton
            ? Array.from({ length: MAX_MOBILE_TAGS }).map((_, i) => (
                <Box key={`skeleton_${i}`} mr={1} width="40%">
                  <Skeleton width="100%" height={23} />
                </Box>
              ))
            : null}
        </Box>
      </div>
    </>
  );
});

CourseCard.propTypes = {
  course: Course,
  className: PropTypes.string,
  history: PropTypes.object,
  skeleton: PropTypes.bool,
  onClick: PropTypes.func,
  topicId: PropTypes.number,
};
