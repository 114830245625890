import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Button } from 'components';
import { checkmarkSvg } from 'utils/icons';
import { isIOS } from 'utils/browser';

import { useExamTestOpeningFooterStyles } from './styles';

const ExamTestOpeningFooter = ({ onButtonClick, buttonLabel, onChange, label, checked }) => {
  const classes = useExamTestOpeningFooterStyles();

  return (
    <Box className={classNames(classes.ExamTestFooter, 'Center', { IOs: isIOS() })}>
      <Box className={classNames(classes.ExamTestOpeningFooter, 'Center')}>
        <FormControlLabel
          className={classes.AccessibilityContainer}
          classes={{ label: classes.AccessibilityLabel }}
          control={
            <Checkbox
              icon={<div className={classes.AccessibilityCheckbox} />}
              classes={{ root: classes.AccessibilityCheckboxRoot }}
              checked={checked}
              onChange={onChange}
              checkedIcon={<div className={classes.AccessibilityCheckbox}>{checkmarkSvg()}</div>}
            />
          }
          label={label}
        />
        <Button buttonClassName="Pink" onClick={onButtonClick}>
          {buttonLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default ExamTestOpeningFooter;

ExamTestOpeningFooter.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};
