import { Regex } from 'constants/Constants';

export const validateTaxNumber = (taxNumber) => {
  if (typeof taxNumber !== 'string') {
    return false;
  }

  const matches = taxNumber.match(Regex.TaxNumber);

  if (!matches) {
    return false;
  }

  const multipliers = [9, 7, 3, 1, 9, 7, 3];

  const checkNumber = +taxNumber.charAt(7);

  const sum = multipliers.reduce((acc, multiplier, index) => {
    return acc + +taxNumber.charAt(index) * multiplier;
  }, 0);

  let last = sum % 10;

  if (last > 0) {
    last = 10 - last;
  }

  return last === checkNumber;
};
