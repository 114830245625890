import React from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import { Course } from 'types/types';
import { useCourseDetailPageStyles } from './styles';
import AppSvgIcon from 'components/AppSvgIcon/AppSvgIcon'; // do not change this import

export const WhatToLearn = ({ course }) => {
  const { t } = useTranslation();
  const classes = useCourseDetailPageStyles();
  return (
    <>
      <Box mb={5} className="PageTitleContainer">
        <h2 className="Title" tabIndex={0}>
          {t('course_details_what_to_learn')}
        </h2>
        <div className="TitleBorder" />
      </Box>
      <Box mb={12} className={classes.WhatToLearnContainer}>
        <Grid container>
          {course.checklists.map((item) => (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.GridItem} key={item.id}>
              <Box className={classes.ItemContainer}>
                <Box className={classes.TickIconContainer}>
                  <AppSvgIcon viewBox="0 0 33.502 25.678" aria-hidden={true}>
                    <path
                      fill="rgb(254,204,82)"
                      d="M32.9,55.223l-2.94-2.94a2.087,2.087,0,0,0-2.939,0l-14.179,14.2L6.484,60.107a2,2,0,0,0-1.47-.605,2,2,0,0,0-1.47.605l-2.94,2.94a2.088,2.088,0,0,0,0,2.94l7.824,7.824,2.94,2.94a2.087,2.087,0,0,0,2.94,0l2.94-2.94L32.9,58.162a2.088,2.088,0,0,0,0-2.94Z"
                      transform="translate(0 -51.678)"
                    />
                  </AppSvgIcon>
                </Box>
                <Box className={classes.ItemDescription} tabIndex={0}>
                  {item.description}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

WhatToLearn.propTypes = {
  course: Course.isRequired,
};
