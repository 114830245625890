import axios from 'axios';
import { RequestTimeout } from 'constants/Constants';

export const downloadFileAsBlob = async (url) => {
  const response = await axios.get(url, {
    responseType: 'blob',
    timeout: RequestTimeout,
  });
  return response.data;
};
