import React, { forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';

import { absolute, inlineBlock, relative, transparent, unset, uppercase } from 'constants/Jss';

const styles = (theme) => ({
  root: {
    position: relative,
    display: inlineBlock,
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: absolute,
    top: 'calc(50% - 12px)',
    right: 'calc(50% - 12px)',
  },
  fullWidth: {
    width: '100%',
  },
  button: {
    height: '100%',
    borderRadius: 20,
    textTransform: uppercase,
    '&.Large': {
      fontSize: 21,
      lineHeight: 1,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(9),
      paddingRight: theme.spacing(9),
      borderRadius: 35,
    },
    '&.Cyan': {
      color: theme.palette.white,
      backgroundColor: theme.palette.cyan,
      '&:hover': {
        backgroundColor: theme.palette.cyanHover,
      },
    },
    '&.Blue': {
      color: theme.palette.white,
      backgroundColor: theme.palette.lightBlue,
      '&:hover': {
        backgroundColor: theme.palette.lightBlueHover,
      },
    },
    '&.Pink': {
      color: theme.palette.white,
      backgroundColor: theme.palette.lightPink,
      '&:hover': {
        backgroundColor: theme.palette.lightPinkHover,
      },
      '&.Secondary': {
        color: theme.palette.lightPink,
        backgroundColor: theme.palette.white,
        border: `${theme.mixins.button.borderWidth}px solid ${theme.palette.lightPink}`,
        '&:hover': {
          color: theme.palette.lightPinkHover,
          border: `${theme.mixins.button.borderWidth}px solid ${theme.palette.lightPinkHover}`,
        },
      },
    },
    '&.DarkBlue': {
      color: theme.palette.white,
      backgroundColor: theme.palette.darkBlue,
      '&:hover': {
        backgroundColor: theme.palette.darkBlueHover,
      },
      '&.Secondary': {
        color: theme.palette.darkBlue,
        backgroundColor: theme.palette.white,
        border: `${theme.mixins.button.borderWidth}px solid ${theme.palette.darkBlue}`,
        '&:hover': {
          color: theme.palette.darkBlueHover,
          border: `${theme.mixins.button.borderWidth}px solid ${theme.palette.darkBlueHover}`,
        },
      },
    },
    '&.Header': {
      color: theme.palette.headerBlue,
      backgroundColor: theme.palette.white,
      border: `${theme.mixins.button.borderWidth}px solid ${theme.palette.white}`,
      paddingTop: theme.spacing(0.25),
      paddingBottom: `${theme.spacing(0.25)}px !important`,
      textTransform: 'uppercase !important',
      '&:hover': {
        color: `${theme.palette.white} !important`,
        backgroundColor: transparent,
      },
      '&.Secondary': {
        color: `${theme.palette.white} !important`,
        backgroundColor: transparent,
        border: `${theme.mixins.button.borderWidth}px solid ${theme.palette.white}`,
        '&:hover': {
          color: `${theme.palette.headerBlue} !important`,
          backgroundColor: theme.palette.white,
        },
        '& > .MuiButton-label:visited': {
          color: unset,
        },
      },
    },
  },
});

const Button = forwardRef(
  (
    {
      ml = 0,
      mt = 0,
      mr = 0,
      mb = 0,
      classes,
      loading,
      disabled,
      className,
      fullWidth,
      buttonClassName,
      buttonContainerClassName,
      variant = 'contained',
      color = 'primary',
      large,
      buttonRef,
      ...props
    },
    ref
  ) => {
    return (
      <Box
        ref={ref}
        className={classNames(classes.root, className, { [classes.fullWidth]: fullWidth }, buttonContainerClassName)}
        ml={ml}
        mt={mt}
        mr={mr}
        mb={mb}
      >
        <MuiButton
          ref={buttonRef}
          disabled={loading || disabled}
          className={classNames(classes.button, { Large: large }, buttonClassName)}
          fullWidth={fullWidth}
          variant={variant}
          color={color}
          {...props}
        />
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </Box>
    );
  }
);

Button.propTypes = {
  ml: PropTypes.number,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  classes: PropTypes.object,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  buttonContainerClassName: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  color: PropTypes.string,
  large: PropTypes.bool,
  buttonRef: PropTypes.any,
};

export default withStyles(styles)(Button);
