import makeStyles from '@material-ui/core/styles/makeStyles';
import { auto, bold, flex, rowReverse } from 'constants/Jss';

export const useMandatoryLinksStyles = makeStyles((theme) => ({
  MandatoryLinkBox: {
    display: flex,
    flexDirection: rowReverse,
    '& label': {
      marginRight: 0,
    },
  },
  Link: {
    fontWeight: bold,
    color: theme.palette.cyan,
    '&:hover': {
      color: theme.palette.cyanHover,
    },
  },
  Visited: {
    color: theme.palette.darkBlue,
    '&:hover': {
      color: theme.palette.darkBlueHover,
    },
  },
  Checkbox: {
    marginRight: theme.spacing(3),
    color: `${theme.palette.whiteHover} !important`,
    '& svg': {
      fill: theme.palette.checkboxBorder,
    },
    '&:hover': {
      backgroundColor: theme.palette.whiteHover,
    },
    '&.Mui-checked': {
      '& svg': {
        fill: `${theme.palette.lightBlue} !important`,
      },
      '&:hover': {
        backgroundColor: `${theme.palette.whiteHover} !important`,
      },
    },
    '&.Mui-focusVisible': {
      outlineColor: theme.palette.focusColor,
      outlineStyle: auto,
      outlineWidth: 1,
    },
  },
}));
