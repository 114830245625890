import * as CertificatesService from 'services/certificates';

import {
  GET_CERTIFICATES,
  GET_CERTIFICATES_FAILED,
  GET_CERTIFICATES_SUCCESS,
  GET_CERTIFICATE,
  GET_CERTIFICATE_FAILED,
  GET_CERTIFICATE_SUCCESS,
} from './types';

export const getCertificates = ({ language, page, token }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CERTIFICATES });

      const data = await CertificatesService.getCertificates(language, page, token);
      dispatch({
        type: GET_CERTIFICATES_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({ type: GET_CERTIFICATES_FAILED });
      throw error;
    }
  };
};

export const getCertificate = ({ language, id, token }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CERTIFICATE });

      const data = await CertificatesService.getCertificate(language, id, token);
      dispatch({
        type: GET_CERTIFICATE_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({ type: GET_CERTIFICATE_FAILED });
      throw error;
    }
  };
};

export const getPublicCertificate = ({ uuid }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CERTIFICATE });

      const data = await CertificatesService.getPublicCertificate(uuid);
      dispatch({
        type: GET_CERTIFICATE_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({ type: GET_CERTIFICATE_FAILED });
      throw error;
    }
  };
};
