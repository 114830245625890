import React from 'react';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

import { timeIndicator } from 'utils/icons';
import { Course } from 'types/types';
import { useCourseDetailPageStyles } from './styles';

export const ShortDescription = ({ course }) => {
  const { t } = useTranslation();
  const classes = useCourseDetailPageStyles();
  return (
    <>
      <Box
        mb={3}
        className={classNames('Mobile', classes.Time)}
        tabIndex={0}
        aria-label={t('alt_minutes_to_complete', { minutes: course.totalMinutesToComplete })}
      >
        {timeIndicator(course.totalMinutesToComplete, t)}
      </Box>
      <Box mb={12} className={classes.DescriptionContainer}>
        <Box className={classes.Description} tabIndex={0}>
          {course.shortDescription}
        </Box>
        <Box
          mt={4}
          className={classes.Time}
          tabIndex={0}
          aria-label={t('alt_minutes_to_complete', { minutes: course.totalMinutesToComplete })}
        >
          {timeIndicator(course.totalMinutesToComplete, t)}
        </Box>
      </Box>
    </>
  );
};

ShortDescription.propTypes = {
  course: Course.isRequired,
};
