import { useEffect, useMemo, useState } from 'react';

const isDesktop = (screenSize) => {
  switch (screenSize) {
    case 'xs':
    case 'sm':
      return false;
    case 'md':
    case 'lg':
    case 'xl':
    default:
      return true;
  }
};

export const useWindowSize = (breakpoints) => {
  const breakpointList = useMemo(
    () =>
      Array.from(Object.keys(breakpoints), (screenSize) => ({ screenSize, width: breakpoints[screenSize] })).sort(
        (a, b) => a.width - b.width
      ),
    [breakpoints]
  );

  const [windowSize, setWindowSize] = useState({
    innerWidth: undefined,
    innerHeight: undefined,
    screenSize: breakpointList[0].screenSize,
    isDesktop: false,
  });

  useEffect(() => {
    const onResize =
      !!window &&
      (() => {
        const { innerWidth, innerHeight } = window;
        const { screenSize } = breakpointList.find(
          ({ width }, index) =>
            index >= breakpointList.length - 1 || (width <= innerWidth && innerWidth < breakpointList[index + 1].width)
        );
        setWindowSize({ innerWidth, innerHeight, screenSize, isDesktop: isDesktop(screenSize) });
      });

    if (onResize) {
      onResize();
      window.addEventListener('resize', onResize);
    }

    return () => onResize && window.removeEventListener('resize', onResize);
  }, [breakpointList]);

  return windowSize;
};
