import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useProfileTagStyles } from './styles';
import { Tag } from 'types/types';
import { CheckBox } from '@material-ui/icons';
import { EmptyBoxIcon } from 'utils/icons';

export const ProfileTagsDialogContent = ({ tags, checkedTags, setTags }) => {
  const classes = useProfileTagStyles();
  const firstRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (firstRef.current) {
        firstRef.current.focus();
      }
    }, 500);
  }, []);

  const onCheckboxesChange = (id) => {
    setTags(id);
  };

  return (
    <div className={classes.TagContainer} style={{ minWidth: Math.min(tags.length * 40, 800, window.innerWidth - 32) }}>
      {tags.map((tag, index) => (
        <FormControlLabel
          inputRef={index === 0 ? firstRef : undefined}
          key={tag.id}
          control={
            <Checkbox
              icon={<EmptyBoxIcon />}
              checkedIcon={<CheckBox role={undefined} />}
              checked={checkedTags ? checkedTags.includes(tag.id) : false}
              onChange={() => onCheckboxesChange(tag.id)}
            />
          }
          label={tag.title}
        />
      ))}
    </div>
  );
};

ProfileTagsDialogContent.propTypes = {
  tags: PropTypes.arrayOf(Tag),
  checkedTags: PropTypes.arrayOf(PropTypes.number),
  setTags: PropTypes.func,
};
