import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import replace from 'lodash/replace';

import Box from '@material-ui/core/Box';

import { useResultTableStyles } from './styles';

function getFilledSentence(answer, className) {
  return replace(answer, /\[{2}(.*?[\w\düÜöÖóÓőŐúÚéÉáÁűŰíÍ.]+)\]{2}/gm, `<span class=${className}>$1</span>`);
}

function getFilledWords(answer, classes, t) {
  const normalizedFilledWords = answer.filledWords.map((word) => (word === null ? t('result_table_empty') : word));
  return (normalizedFilledWords || []).map((word, index) => {
    const success = answer.goodWords[index] === normalizedFilledWords[index];
    return (
      <>
        <span className={classNames({ [classes.Success]: success, [classes.Fail]: !success })}>{`${word} `}</span>
        {answer.filledWords.length - 1 > index ? <span>| </span> : null}
      </>
    );
  });
}

function SingleAndMultipleSelectResultTable({ answers, isDesktopLayout }) {
  const { t } = useTranslation();
  const classes = useResultTableStyles();

  const ariaLabel = answers
    .map((answer, index) => {
      const solutions = answer.goodWords.map((word, index) => {
        return {
          goodWord: word,
          filledWord: answer.filledWords.map((word) => (word === null ? t('result_table_empty') : word))[index],
        };
      });
      let solutionIndex = 0;
      const solution = replace(answer.answer, /\[{2}(.*?\w+)\]{2}/gm, function() {
        const solutionText = `${t('exam_result_summary_correct_solution')}: ${solutions[solutionIndex].goodWord}, ${t(
          'exam_result_summary_selected_solution'
        )}: ${solutions[solutionIndex].filledWord}.`;
        solutionIndex++;
        return solutionText;
      });

      return t('aria_label_result_table_sentence_filler', {
        index: index + 1,
        answer: solution,
      });
    })
    .join(', ');

  return isDesktopLayout ? (
    <Box aria-label={ariaLabel} className={classes.ResultTableContainer}>
      <table aria-hidden={true}>
        <thead>
          <tr>
            <th scope="col" className="hidden">
              {t('result_table_header_index')}
            </th>
            <th scope="col" className="hidden">
              {t('result_table_header_solution')}
            </th>
            <th>{t('result_table_header_selected_choice')}</th>
          </tr>
        </thead>
        <tbody>
          {answers.map((answer, index) => (
            <tr key={answer.id} className={classes.TableRowBorder}>
              <td>{`${index + 1}.`}</td>
              <td>
                <div dangerouslySetInnerHTML={{ __html: getFilledSentence(answer.answer, classes.Success) }} />
              </td>
              <td>{getFilledWords(answer, classes, t)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  ) : (
    <Box className={classes.MobileResultTableContainer}>
      <table className={classes.SecondLayout}>
        {answers.map((answer, index) => (
          <tr key={answer.id}>
            <td>{`${index + 1}.`}</td>
            <td>
              <div dangerouslySetInnerHTML={{ __html: getFilledSentence(answer.answer, classes.Success) }} />
              <div className={classes.AnswerContainer}>
                <p className={classes.Selected}>{`${t('result_table_header_selected_choice')} `}</p>
                {getFilledWords(answer, classes, t)}
              </div>
            </td>
          </tr>
        ))}
      </table>
    </Box>
  );
}

SingleAndMultipleSelectResultTable.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.object),
  isDesktopLayout: PropTypes.bool,
};

export default SingleAndMultipleSelectResultTable;
