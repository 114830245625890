import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';

import { useTopListSearchStyles } from './styles';
import { DefaultSearchField, SearchResultNumber } from 'components';

export const TopListSearch = ({ search, setClearSearch, numberOfItems }) => {
  const classes = useTopListSearchStyles();
  const { t } = useTranslation();

  const searchRef = useRef();

  const onSearchSubmit = useCallback((searchString) => search({ searchString }), [search]);

  setClearSearch(() => searchRef.current.clear());

  return (
    <>
      <Box mb={4} className={classes.SearchContainer}>
        <DefaultSearchField
          ref={searchRef}
          ariaLabel={t('common_search')}
          placeholder={t('common_search')}
          submit={onSearchSubmit}
          searchOnEmpty={true}
        />
      </Box>
      <SearchResultNumber numberOfItems={numberOfItems} resultText={t('toplist_search_result')} />
    </>
  );
};

TopListSearch.propTypes = {
  search: PropTypes.func,
  setClearSearch: PropTypes.func,
  numberOfItems: PropTypes.number,
};
