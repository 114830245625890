import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { withWindowWidth } from 'components';
import CourseDetailPage from './CoursesDetailPage';
import { getCourseById } from 'store/courses/actions';
import { showNotification } from 'store/common/actions';
import { getExam } from 'store/exams/actions';
import { addCommonLoader, removeCommonLoader } from 'store/loader/actions';
import { selectIsLoggedIn } from 'store/auth/selectors';
import { setTutorialState } from 'store/tutorial/actions';

const mapStateToProps = (state) => {
  return {
    loading: state.courses.loading,
    course: state.courses.course,
    isLoggedIn: selectIsLoggedIn(state),
    topics: state.topics.topics,
    tutorial: state.tutorial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCourseById,
      getExam,
      showNotification,
      addCommonLoader,
      removeCommonLoader,
      setTutorialState,
    },
    dispatch
  );
};

export default compose(withRouter, withWindowWidth, connect(mapStateToProps, mapDispatchToProps))(CourseDetailPage);
