import React, { useEffect, useState, useCallback, useRef, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import { auto } from 'constants/Jss';
import { ArrowKeys } from 'constants/Constants';
import { mapAlternate } from 'utils/mapAlternate';
import { selectArrow, testCorrect, testIncorrect } from 'utils/icons';
import { usePrevious } from 'utils/usePrevious';
import { splitStringByBrackets } from 'utils/splitStringByBrackets';
import theme from 'theme';
import { useFormStyles } from './styles';

export const AccessibleSubstitutionForm = ({ answers, setAnswer, answered }) => {
  const classes = useFormStyles();
  const { t } = useTranslation();

  const [words, setWords] = useState([]);
  const [filledWords, setFilledWords] = useState();
  const elementRef = useRef(answers.map(() => createRef()));
  const prevAnswers = usePrevious(answers);

  useEffect(() => {
    if (answers !== prevAnswers) {
      elementRef.current = answers.map(() => createRef());
      const newWords = answers[0].words.map((word) => word);
      setWords(newWords);

      const filledWordsArray = [];
      answers.forEach((answer, index) => {
        filledWordsArray.push([]);
        mapAlternate(
          splitString(index),
          function() {},
          function() {
            filledWordsArray[index].push('');
          }
        );
      });
      setFilledWords(filledWordsArray);
    }
  }, [answers, splitString, words, prevAnswers]);

  const onChange = (event, index, position) => {
    const newWords = [...filledWords];
    for (let i = 0; i < newWords.length; i++) {
      const foundIndex = newWords[i].indexOf(event.target.value);
      if (foundIndex > -1) {
        newWords[i][foundIndex] = '';
      }
    }

    newWords[index][position] = event.target.value;
    setFilledWords(newWords);
    elementRef.current[index].current.focus();

    constructAnswer();
  };

  const onSelectKeyDown = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === ArrowKeys.UP || keyCode === ArrowKeys.DOWN) {
      e.stopPropagation();
    }
  };

  const isAFilledWord = (word) => {
    return filledWords.some((filledWord) => filledWord.includes(word));
  };

  const isGood = (index, position) => answers[index].goodWords[position] === filledWords[index][position];

  const splitString = useCallback((index = 0) => splitStringByBrackets(answers[index].answer), [answers]);

  const constructAriaLabel = (index) => {
    if (filledWords && filledWords[index]) {
      return mapAlternate(
        splitString(index),
        function(sentence) {
          return sentence;
        },
        function(position) {
          if (filledWords[index][position]) {
            if (answered) {
              if (isGood(index, position)) {
                return t('exam_substitution_your_answers_correct', { word: filledWords[index][position] });
              } else {
                return `${t('exam_substitution_your_answers_incorrect', {
                  word: filledWords[index][position],
                })}. ${t('exam_substitution_correct_answer', { word: answers[index].goodWords[position] })}`;
              }
            }
            return filledWords[index][position];
          } else {
            return answered
              ? `${t('exam_substitution_no_answer')}. ${t('exam_substitution_correct_answer', {
                  word: answers[index].goodWords[position],
                })}`
              : t('exam_substitution_select_word');
          }
        }
      );
    }
  };

  const constructAnswer = () => {
    const result = answers.map((answer, index) => ({
      id: answer.id,
      words: filledWords[index].map((filledWord) => filledWord),
    }));
    setAnswer(result);
  };

  return (
    !!filledWords &&
    filledWords.length > 0 &&
    answers === prevAnswers && (
      <Box className={classes.FormBox} ml={auto} mr={auto}>
        {answers.map((answer, index) => (
          <Box
            role="application"
            ref={elementRef.current[index]}
            tabIndex={0}
            aria-label={constructAriaLabel(index).toString()}
            key={answer.id}
            display="flex"
            flexWrap="wrap"
            alignItems="center"
          >
            {mapAlternate(
              splitString(index),
              function(sentencePart) {
                return sentencePart.split(' ').map((part, subIndex) => (
                  <div key={subIndex}>
                    <p className={classes.SubstitutionSentencePart}>{`${part} `}</p>
                  </div>
                ));
              },
              function(position) {
                return (
                  <FormControl
                    key={position}
                    className={classNames(classes.SubstitutionWordSpacing, classes.SubstitutionFormControl)}
                  >
                    {!!filledWords &&
                      (!answered ? (
                        <Select
                          SelectDisplayProps={{
                            role: 'button',
                            'aria-haspopup': 'listbox',
                            onKeyDownCapture: onSelectKeyDown,
                          }}
                          MenuProps={{
                            classes: {
                              list: classes.SelectList,
                              paper: classes.SelectListPaper,
                            },
                          }}
                          disableUnderline
                          onChange={(event) => {
                            onChange(event, index, position);
                          }}
                          className={classNames(
                            classes.WordCloud,
                            classes.SubstitutionWordSpacing,
                            classes.SubstitutionSelect,
                            {
                              [classes.WordCloudFilled]: !!filledWords[index][position],
                            }
                          )}
                          value={filledWords[index][position]}
                          displayEmpty
                          classes={{ selectMenu: classes.SelectMenu }}
                          IconComponent={() => selectArrow(theme.palette.switchOffLabel, theme.spacing(1), 'none')}
                        >
                          <MenuItem className={classes.WordCloudItem} value="" disabled={!filledWords[index][position]}>
                            {t('common_choose')}
                          </MenuItem>
                          {words.map((word) => (
                            <MenuItem
                              className={classNames(classes.WordCloudItem, {
                                [classes.WordCloudItemFilled]: isAFilledWord(word),
                              })}
                              key={word}
                              value={word}
                              aria-label={
                                isAFilledWord(word) && word !== filledWords[index][position]
                                  ? `${word}. ${t('exam_substitution_warning')}`
                                  : word
                              }
                            >
                              <pre className={classes.WordCloudItemPre}>{word}</pre>
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <Box
                          className={classNames({ [classes.SubstitutionAnsweredContainer]: !!answered })}
                          display="flex"
                        >
                          <Box
                            className={classNames(
                              classNames(
                                classes.Cloud,
                                classes.WordCloud,
                                classes.WordCloudPadding,
                                classes.SubstitutionWordSpacing,
                                classes.WordCloudAnswered,
                                {
                                  [classes.WordCloudSuccess]: isGood(index, position),
                                  [classes.WordCloudFailure]: !isGood(index, position),
                                  [classes.SubstitutionAnswered]: !!answered,
                                  [classes.AnsweredSize]: filledWords[index][position] !== '',
                                }
                              )
                            )}
                          >
                            {filledWords[index][position]}
                            {answered && (
                              <Box className={classes.AnsweredIconContainer} right="5px">
                                {isGood(index, position) ? testCorrect() : testIncorrect()}
                              </Box>
                            )}
                          </Box>
                          {!isGood(index, position) && (
                            <Box
                              className={classNames(
                                classNames(
                                  classes.Cloud,
                                  classes.WordCloud,
                                  classes.WordCloudPadding,
                                  classes.SubstitutionWordSpacing,
                                  classes.WordCloudAnswered,
                                  classes.WordCloudSuccess,
                                  classes.AnsweredSize,
                                  classes.AccessibleSubstitutionAnswered,
                                  { [classes.SubstitutionAnswered]: !!answered }
                                )
                              )}
                            >
                              {answers[index].goodWords[position]}
                              {
                                <Box className={classes.AnsweredIconContainer} right="5px">
                                  {testCorrect()}
                                </Box>
                              }
                            </Box>
                          )}
                        </Box>
                      ))}
                  </FormControl>
                );
              }
            )}
          </Box>
        ))}
      </Box>
    )
  );
};

AccessibleSubstitutionForm.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string.isRequired,
      words: PropTypes.arrayOf(PropTypes.string).isRequired,
      goodWords: PropTypes.arrayOf(PropTypes.string),
      id: PropTypes.number.isRequired,
      isGood: PropTypes.bool,
    })
  ),
  setAnswer: PropTypes.func,
  answered: PropTypes.bool,
};
