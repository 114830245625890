import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';

export const IconButtonBase = forwardRef(
  ({ className, iconFactory, buttonClass, iconClass, width, height, ...props }, ref) => (
    <IconButton ref={ref} className={classNames(buttonClass, className)} {...props}>
      {iconFactory({ iconClass, width, height })}
    </IconButton>
  )
);

IconButtonBase.propTypes = {
  className: PropTypes.string,
  iconFactory: PropTypes.func.isRequired,
  buttonClass: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
