import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import TopicsPage from './TopicsPage';
import { getCoursesForTopic, loadMoreCoursesForTopic, resetCourses, setFilters } from 'store/courses/actions';
import { getProfileDetails } from 'store/profile/actions';
import { addCommonLoader, removeCommonLoader } from 'store/loader/actions';
import { setTutorialState } from 'store/tutorial/actions';

const mapStateToProps = (state) => {
  return {
    tags: state.tags.tags,
    loading: state.topics.loading,
    topics: state.topics.topics,
    courses: state.courses.courses.data,
    numberOfCourses: state.courses.courses.count,
    filters: state.courses.filters,
    profileTags: state.profile.details.tags,
    tutorial: state.tutorial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setFilters,
      resetCourses,
      getCoursesForTopic,
      loadMoreCoursesForTopic,
      getProfileDetails,
      addCommonLoader,
      removeCommonLoader,
      setTutorialState,
    },
    dispatch
  );
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TopicsPage);
