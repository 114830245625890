export const ModalTypes = {
  delete: 'delete',
  user: 'user',
};

export const TableRowsPerPage = 5;

export const MaxSkeletonCard = 4;

export const Roles = {
  Admin: 'admin',
  User: 'user',
};

export const MaxTopics = 4;

export const CardsPerPage = 12;

export const TopCardHeight = 198;

export const BadgePopupWidth = 450;

export const NotificationTypes = {
  success: 'success',
  warning: 'warning',
  error: 'error',
  info: 'info',
};

export const ArrowKeys = {
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
};

export const Regex = {
  Password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!"#$%&'*+,./:;=?@_~]{8,24}$/,
  TaxNumber: /^(\d{7})(\d)-([1-5])-(0[2-9]|[13][0-9]|2[02-9]|4[0-4]|51)$/,
};

export const ScrollToTopVisible = 300;
export const StickyTimeVisibleAt = 110;

export const LottieAnimationDuration = 1000;

export const SocialLinks = {
  facebook: 'https://www.facebook.com/magyarturisztikaiugynokseg',
  linkedIn: 'https://www.linkedin.com/company/18043674/',
  instagram: 'https://www.instagram.com/csodasmagyarorszag/',
};

export const FooterLinks = {
  mtu: 'https://mtu.gov.hu/',
  csodasMagyarorszag: 'https://csodasmagyarorszag.hu',
  addressMapLink:
    'https://www.google.com/maps/place/Budapest,+Kacsa+u.+15,+1027/@47.5100559,19.0347333,17z/data=!3m1!4b1!4m5!3m4!1s0x4741dc1b6247b86d:0x12a21d34087c8ef0!8m2!3d47.5100559!4d19.036922',
};

export const LessonContentTypes = {
  TEXT: 0,
  VIDEO: 1,
  IMAGE: 2,
};

export const QuestionTypes = {
  SINGLE_CHOICE: 0,
  MULTIPLE_CHOICE: 1,
  TRUE_OR_FALSE: 2,
  SUBSTITUTION: 3,
  PAIRING: 4,
};

export const YoutubeUrlPrefix = 'https://www.youtube.com/embed/';

export const visiblePageLinks = 5;

export const ItemTypes = {
  Course: 'course',
  Lesson: 'lesson',
};

export const TopListTypes = {
  User: 'user',
  Group: 'group',
};

export const SnackTime = 7000;

export const GroupTypes = {
  UNDEFINED: 0,
  KKV: 1,
};

export const SystemRoles = {
  ADMIN: 1,
  MANAGER: 2,
  CONTENT_MANAGER: 3,
  VALIDATOR: 4,
  USER: 5,
};

export const TagFilterSize = {
  Wide: 816,
  Narrow: 520,
};

export const ExamStates = {
  OPEN: 'OPEN',
  QUESTION: 'QUESTION',
  END: 'END',
  BADGES: 'BADGES',
};

export const MinDesktopWidth = 1139.98;

export const QuestionElementId = 'question';

export const RequestTimeout = 10000;

export const AuthProviderCodes = {
  EMAIL: 0,
  FACEBOOK: 1,
  GOOGLE: 2,
  APPLE: 3,
  isSocial: (providerCode) => {
    switch (providerCode) {
      case AuthProviderCodes.EMAIL:
        return false;
      case AuthProviderCodes.FACEBOOK:
      case AuthProviderCodes.GOOGLE:
      case AuthProviderCodes.APPLE:
        return true;
      default:
        throw new Error(`Unknown AuthProviderCode: ${providerCode}`);
    }
  },
};

export const AuthProviderNames = {
  [AuthProviderCodes.EMAIL]: 'Email',
  [AuthProviderCodes.FACEBOOK]: 'Facebook',
  [AuthProviderCodes.GOOGLE]: 'Google',
  [AuthProviderCodes.APPLE]: 'Apple',
};

export const FirebasePopupClosedCode = 'auth/popup-closed-by-user';
export const FirebaseAccountExistsWithDifferentCredentialCode = 'auth/account-exists-with-different-credential';

export const ApiErrorMessages = {
  UNIQUE_FIELD: 'error_unique_field',
  TOKEN_EXPIRED: 'Token has expired and can no longer be refreshed',
  COURSE_HAS_NO_EXAM: 'This course does not contain any exams',
  OLD_PASSWORD_IS_INCORRECT: 'Old password is incorrect',
  NEW_PASSWORD_IS_INCORRECT: 'New password is incorrect',
  TAX_NUMBER_UNIQUE: 'taxNumber.error_unique_field',
  TAX_NUMBER_INVALID_FORMAT: 'taxNumber.error_validation',
  EMAIL_UNIQUE: 'email.error_unique_field',
};

export const MinSearchTextLength = 3;
