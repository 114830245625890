import {
  ADD_LOADER,
  ADD_NOTIFICATION,
  REMOVE_LOADER,
  REMOVE_NOTIFICATION,
  SET_FILTER_PARAMS,
} from './types';

export const showNotification = ({ translateKey, content, type }) => {
  return {
    type: ADD_NOTIFICATION,
    payload: {
      translateKey,
      content,
      type,
    },
  };
};

export const removeNotification = () => {
  return {
    type: REMOVE_NOTIFICATION,
  };
};

export const setFilterParams = (data) => {
  return {
    type: SET_FILTER_PARAMS,
    payload: data,
  };
};

export const addLoader = () => ({ type: ADD_LOADER });

export const removeLoader = () => ({ type: REMOVE_LOADER });
