import makeStyles from '@material-ui/core/styles/makeStyles';
import { transparent } from 'constants/Jss';

export const useRadioButtonStyles = makeStyles((theme) => ({
  Border: {
    width: 27,
    height: 27,
    border: `1px solid ${theme.palette.radioButtonBorder}`,
    borderRadius: '50%',
    backgroundColor: transparent,
    padding: 4,
    '&.coloredBorder': {
      '&.blue': {
        border: `2px solid ${theme.palette.lightBlue}`,
      },
      '&.green': {
        border: `2px solid ${theme.palette.greenSuccess}`,
      },
      '&.red': {
        border: `2px solid ${theme.palette.lightPink}`,
      },
    },
  },
  Button: {
    height: '100%',
    width: '100%',
    borderRadius: '50%',
    '&.blue': {
      backgroundColor: theme.palette.lightBlue,
    },
    '&.green': {
      backgroundColor: theme.palette.greenSuccess,
    },
    '&.red': {
      backgroundColor: theme.palette.lightPink,
    },
  },
}));
