import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import TopListPage from './TopListPage';
import { getTopList, getTopListOfGroups, setPage, setPageOfGroups } from 'store/topList/actions';
import { showNotification } from 'store/common/actions';
import { addCommonLoader, removeCommonLoader } from 'store/loader/actions';

const mapStateToProps = (state) => {
  return {
    topList: state.topList.topList,
    topListOfGroups: state.topList.topListOfGroups,
    myId: state.profile.details.id,
    myGroupId: state.profile.details.group ? state.profile.details.group.id : -1,
    myRole: state.profile.details.role,
    myGroupPublic: state.profile.details.group ? state.profile.details.group.public : true,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getTopList,
      getTopListOfGroups,
      setPage,
      setPageOfGroups,
      showNotification,
      addCommonLoader,
      removeCommonLoader,
    },
    dispatch
  );
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TopListPage);
