import makeStyles from '@material-ui/core/styles/makeStyles';
import { pointer, transparent, unset } from 'constants/Jss';

export const useDropdownMenuStyles = makeStyles((theme) => ({
  DropdownButton: {
    textTransform: 'none !important',
    color: `${theme.palette.white} !important`,
    fontSize: '14px !important',
    paddingBottom: '5px !important',
    '& svg': {
      width: 20,
      height: 14,
    },
  },
  AvatarButton: {
    backgroundColor: transparent,
    marginLeft: theme.spacing(2),
    borderRadius: '50%',
    padding: 0,
    minWidth: 0,
  },
  MenuListContainer: {
    top: '14px !important',
    zIndex: 10,
    padding: 2,
    width: unset,
    height: unset,
    backgroundColor: unset,
  },
  MenuList: {
    maxWidth: 244,
    padding: 0,
  },
  Avatar: {
    backgroundColor: theme.palette.avatarGrey,
    cursor: pointer,
    '&.NoAvatar > .MuiAvatar-img': {
      width: '60%',
      height: '60%',
    },
  },
}));
