import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { MinSearchTextLength, NotificationTypes } from 'constants/Constants';
import { isEnterEvent, isEscapeEvent } from 'utils/eventUtils';
import useOutsideClick from 'utils/useOutsideClick';
import { showNotification } from 'store/common/actions';
import { RecognizeErrors } from 'tools/VoiceRecognizer/RecognizeErrors';
import { useSearchFieldStyles } from './styles';
import { ClearButton } from './components/buttons/ClearButton';
import { VoiceButton } from './components/buttons/VoiceButton';
import { SearchButton } from './components/buttons/SearchButton';
import { CloseButton } from './components/buttons/CloseButton';
import { useVoiceRecognizer } from 'tools/VoiceRecognizer';

const normalizeSearchText = (searchText) => searchText.trim();

export const useSearchField = ({
  customStyleFactory,
  ariaLabel,
  placeholder,
  submit,
  cancel,
  outsideCancel,
  hasCloseButton = false,
  searchOnEmpty = false,
  ref,
}) => {
  const classes = useSearchFieldStyles();
  const customClasses = customStyleFactory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const containerRef = useRef();
  const inputRef = useRef();

  const [searchText, setSearchText] = useState('');

  const isValid = normalizeSearchText(searchText).length >= MinSearchTextLength;
  const showClearButton = searchText.length > 0;
  const [recognizerState, toggleListening] = useVoiceRecognizer();
  const {
    isBrowserSupported: voiceRecognizerSupported,
    isListening,
    isSpeechFinal,
    transcript,
    errorCode,
  } = recognizerState;
  useEffect(() => {
    if (!errorCode) {
      if (isListening || isSpeechFinal) {
        setSearchText(transcript);
      }
      if (isSpeechFinal) {
        inputRef.current.focus();
      }
    } else {
      switch (errorCode) {
        case RecognizeErrors.ABORTED:
          break;
        case RecognizeErrors.NO_SPEECH:
          dispatch(showNotification({ translateKey: 'voice_search_error_no_speech', type: NotificationTypes.info }));
          break;
        default:
          console.error(errorCode);
          dispatch(showNotification({ translateKey: 'voice_search_error_default', type: NotificationTypes.error }));
          break;
      }
    }
  }, [isListening, isSpeechFinal, transcript, errorCode, dispatch]);
  const showCloseButton = !!cancel && hasCloseButton;

  useImperativeHandle(ref, () => ({
    clear: () => setSearchText(''),
  }));

  const onChange = useCallback(({ target }) => setSearchText(target.value), []);

  const onClear = useCallback(() => {
    setSearchText('');
    inputRef.current.focus();
  }, []);

  const onSubmit = useCallback(() => {
    if (isValid) {
      submit(normalizeSearchText(searchText));
    }
  }, [submit, isValid, searchText]);

  const onCancel = useCallback(() => {
    if (cancel) {
      cancel();
    } else {
      onClear();
    }
  }, [cancel, onClear]);

  const onKeyUp = useCallback(
    (event) => {
      if (isEnterEvent(event)) {
        onSubmit();
      } else if (isEscapeEvent(event)) {
        onCancel();
      }
    },
    [onSubmit, onCancel]
  );

  const onOutsideClick = useCallback(() => {
    if (outsideCancel) {
      outsideCancel();
    } else if (cancel) {
      cancel();
    }
  }, [cancel, outsideCancel]);

  useOutsideClick(containerRef, onOutsideClick);

  useEffect(() => {
    if (cancel) {
      inputRef.current.focus();
    }
  }, [cancel]);

  useEffect(() => {
    if (searchOnEmpty && searchText === '') {
      submit('');
    }
  }, [searchText, searchOnEmpty, submit]);

  return (
    <div ref={containerRef} className={classNames(classes.Container, customClasses.Container, { invalid: !isValid })}>
      <div className={classes.InputContainer}>
        <input
          ref={inputRef}
          className={classNames(classes.Input, customClasses.Input)}
          type="search"
          placeholder={placeholder}
          value={searchText}
          aria-label={ariaLabel}
          onChange={onChange}
          onKeyUp={onKeyUp}
          autoComplete="off"
        />
      </div>
      {showClearButton && (
        <>
          <ClearButton width={12} height={12} aria-label={t('alt_search_field_clear')} onClick={onClear} />
          <div className={classes.SeparatorConatiner}>
            <div className={classes.Separator}></div>
          </div>
        </>
      )}
      {voiceRecognizerSupported && (
        <VoiceButton
          className={classNames({ isListening })}
          width={18}
          height={24}
          aria-label={t('alt_search_field_voice')}
          onClick={toggleListening}
        />
      )}
      <SearchButton
        width={27}
        height={27}
        aria-label={t('alt_search_field_search')}
        onClick={onSubmit}
        disabled={!isValid}
      />
      {showCloseButton && (
        <CloseButton
          role="application"
          width={20}
          height={20}
          aria-label={t('alt_search_field_close')}
          onClick={onCancel}
        />
      )}
    </div>
  );
};

useSearchField.propTypes = {
  customStyleFactory: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func,
  outsideCancel: PropTypes.func,
  hasCloseButton: PropTypes.bool,
  searchOnEmpty: PropTypes.bool,
  ref: PropTypes.any,
};
