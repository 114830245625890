import * as TopListService from 'services/topList';
import {
  GET_TOP_LIST,
  GET_TOP_LIST_FAILED,
  GET_TOP_LIST_OF_GROUPS,
  GET_TOP_LIST_OF_GROUPS_FAILED,
  GET_TOP_LIST_OF_GROUPS_SUCCESS,
  GET_TOP_LIST_SUCCESS,
  SET_PAGE,
  SET_PAGE_OF_GROUPS,
} from './types';

export const getTopList = ({ language, page, userId, searchText, token }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_TOP_LIST });

      const topList = await TopListService.getTopList({ language, page, userId, searchText, token });

      dispatch({
        type: GET_TOP_LIST_SUCCESS,
        payload: topList,
      });

      return topList;
    } catch (error) {
      dispatch({ type: GET_TOP_LIST_FAILED });
      throw error;
    }
  };
};

export const getTopListOfGroups = ({ language, page, groupId, searchText }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_TOP_LIST_OF_GROUPS });

      const topList = await TopListService.getTopListOfGroups({ language, page, groupId, searchText });

      dispatch({
        type: GET_TOP_LIST_OF_GROUPS_SUCCESS,
        payload: topList,
      });

      return topList;
    } catch (error) {
      dispatch({ type: GET_TOP_LIST_OF_GROUPS_FAILED });
      throw error;
    }
  };
};

export const setPage = (page) => {
  return async (dispatch) => {
    dispatch({ type: SET_PAGE, payload: page });
  };
};

export const setPageOfGroups = (page) => {
  return async (dispatch) => {
    dispatch({ type: SET_PAGE_OF_GROUPS, payload: page });
  };
};
