import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import Checkbox from '@material-ui/core/Checkbox';
import { EmptyBoxIcon } from 'utils/icons';
import { CheckBox } from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import i18 from 'i18n';

import { useCheckboxFilterStyles } from './styles';

export const DURATION_VALUES = {
  filter_max_10: { minutesMax: 10 },
  filter_max_15: { minutesMax: 15 },
  filter_max_20: { minutesMax: 20 },
  filter_min_21: { minutesMin: 21 },
};

export const getDurationLabel = (key) => {
  const value = DURATION_VALUES[key];

  return value.minutesMax
    ? i18.t('filter_max_minute', { minute: value.minutesMax })
    : i18.t('filter_min_minute', { minute: value.minutesMin });
};

export const getDurationAriaLabel = (key) => {
  const value = DURATION_VALUES[key];

  return value.minutesMax
    ? i18.t('alt_filter_max_minute', { minute: value.minutesMax })
    : i18.t('alt_filter_min_minute', { minute: value.minutesMin });
};

export const DurationFilter = ({ filter, setFilters }) => {
  const classes = useCheckboxFilterStyles();

  const onChange = (durationKey) => {
    setFilters({ duration: isEqual(filter, DURATION_VALUES[durationKey]) ? null : DURATION_VALUES[durationKey] });
  };

  return (
    <div className={classes.CheckboxFilterContainer}>
      {Object.keys(DURATION_VALUES).map((key) => (
        <FormControlLabel
          key={key}
          control={
            <Checkbox
              role="menuitemcheckbox"
              icon={<EmptyBoxIcon />}
              checkedIcon={<CheckBox role={undefined} />}
              checked={isEqual(filter, DURATION_VALUES[key])}
              onChange={() => onChange(key)}
            />
          }
          label={getDurationLabel(key)}
          aria-label={getDurationAriaLabel(key)}
        />
      ))}
    </div>
  );
};

DurationFilter.propTypes = {
  filter: PropTypes.shape({
    minutesMin: PropTypes.number,
    minutesMax: PropTypes.number,
  }),
  setFilters: PropTypes.func,
};
