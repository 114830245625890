import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Tag } from 'types/types';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';

import { useTagFilterStyles } from './styles';
import Switch from '../Switch/Switch';
import { ItemTypes, TagFilterSize } from 'constants/Constants';
import { selectIsLoggedIn } from 'store/auth/selectors';
import { CheckBox } from '@material-ui/icons';
import { EmptyBoxIcon } from 'utils/icons';

export const TagFilter = ({ tags, filter, setFilters, profileTags, itemType }) => {
  const { t } = useTranslation();
  const classes = useTagFilterStyles();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const [checked, setChecked] = React.useState(false);
  useEffect(() => {
    if (
      filter.length &&
      profileTags &&
      isEqual(
        filter,
        profileTags.map((tag) => tag.id)
      )
    ) {
      setChecked(true);
    }
  }, [filter, profileTags]);

  const onCheckboxesChange = async (id) => {
    const checkedTagIds = [...filter];
    await setFilters({
      tags: checkedTagIds.includes(id) ? checkedTagIds.filter((checkedId) => checkedId !== id) : [...checkedTagIds, id],
    });
    setChecked(false);
  };

  const clearAllTags = () => {
    setFilters({ tags: [] });
    setChecked(false);
  };

  const showMyInterest = () => {
    setFilters({ tags: !checked ? profileTags.map((tag) => tag.id) : [] });
    setChecked(!checked);
  };

  return (
    <div className={classes.TagFilterWrapper}>
      <Box className={classes.QuickFilterContainer}>
        <Box className={classes.ClearBox}>
          <div className={classes.ActionText} onClick={clearAllTags} tabIndex={0} role="button">
            {t('topics_page_clear_filter')}
          </div>
        </Box>
        {isLoggedIn && profileTags && profileTags.length ? (
          <Box className={classes.InterestBox}>
            <div className={classes.ActionText} onClick={showMyInterest}>
              {t('topics_page_my_interest')}
            </div>
            <Box ml={2}>
              <Switch
                mini
                switchClassName="filterSwitch"
                checked={checked}
                onClick={showMyInterest}
                aria-label={t('alt_show_my_interest')}
              />
            </Box>
          </Box>
        ) : null}
      </Box>
      <div
        className={classes.TagFilterContainer}
        style={{
          minWidth: Math.min(
            tags.length * 50,
            isLoggedIn && itemType === ItemTypes.Course ? TagFilterSize.Wide - 16 : TagFilterSize.Narrow - 16
          ),
        }}
      >
        {tags.map((tag) => (
          <FormControlLabel
            key={tag.id}
            control={
              <Checkbox
                role="menuitemcheckbox"
                icon={<EmptyBoxIcon />}
                checkedIcon={<CheckBox role={undefined} />}
                checked={filter ? filter.includes(tag.id) : false}
                onChange={() => onCheckboxesChange(tag.id)}
              />
            }
            label={tag.title}
          />
        ))}
      </div>
    </div>
  );
};

TagFilter.propTypes = {
  tags: PropTypes.arrayOf(Tag),
  filter: PropTypes.arrayOf(PropTypes.number),
  setFilters: PropTypes.func,
  profileTags: PropTypes.arrayOf(Tag),
  itemType: PropTypes.oneOf([ItemTypes.Course, ItemTypes.Lesson]).isRequired,
};
