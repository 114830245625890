import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from 'components';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { bold, flexStart, none } from 'constants/Jss';

const useStyles = makeStyles((theme) => {
  return {
    ButtonSocial: {
      borderRadius: 2,
      justifyContent: flexStart,
      textTransform: none,
      fontWeight: bold,
      paddingLeft: 15,
      paddingRight: 15,
      boxShadow: `0px 1px 2px ${theme.palette.socialOpacity}`,
      '&.facebook': {
        backgroundColor: theme.palette.facebookBlue,
        color: theme.palette.white,
      },
      '&.google': {
        backgroundColor: theme.palette.white,
        color: theme.palette.grey73,
        boxShadow: `0px 1px 2px ${theme.palette.googleOpacity}`,
      },
      '&.apple': {
        backgroundColor: theme.palette.appleBlack,
        color: theme.palette.white,
      },
    },
  };
});

export const SocialButton = ({ buttonClassName, icon, ...props }) => (
  <Button
    buttonClassName={classNames(useStyles().ButtonSocial, buttonClassName)}
    startIcon={icon}
    fullWidth={true}
    {...props}
  />
);

SocialButton.propTypes = {
  buttonClassName: PropTypes.string,
  icon: PropTypes.node,
};
