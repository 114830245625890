import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { Header } from './Header';
import { forgotPassword, login, register, resetPassword } from 'store/auth/actions';
import { groupSearch } from 'store/groups/actions';
import { removeNotification, showNotification } from 'store/common/actions';
import { getSettlements, logout } from 'store/profile/actions';
import { setFilters } from 'store/courses/actions';
import { checkDocuments, getDocuments, agreeDocuments } from 'store/documents/actions';

const mapStateToProps = (state) => {
  return {
    topics: state.topics.topics,
    profile: state.profile.details,
    groups: state.groups.groups,
    settlements: state.profile.settlements,
    documents: state.documents.documents,
    newDocuments: state.documents.newDocuments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      login,
      logout,
      register,
      groupSearch,
      showNotification,
      removeNotification,
      forgotPassword,
      resetPassword,
      getSettlements,
      setFilters,
      checkDocuments,
      getDocuments,
      agreeDocuments,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
