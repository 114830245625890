import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';
import CookieConsent from 'react-cookie-consent';

import AppLoader from './AppLoader';
import Main from 'modules/Main';
import { SnackbarContent } from 'components';
import { getTopics } from 'store/topics/actions';
import { appStyles } from './styles';
import { ClassStyle, Notification } from 'types/types';
import { SnackTime } from 'constants/Constants';
import { getDocuments } from 'store/documents/actions';
import Settings from 'env';
import Cookies from 'js-cookie';

class App extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      Szechenyi2020Container: ClassStyle,
      Szechenyi2020: ClassStyle,
      CookieButton: ClassStyle,
    }),
    t: PropTypes.func,
    loading: PropTypes.bool,
    notification: Notification,
    tokenExpiresAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    routes: PropTypes.arrayOf(PropTypes.object),
    getAuthorizedRoutes: PropTypes.func,
    removeNotification: PropTypes.func,
    setIsStayLoggedIn: PropTypes.func,
  };

  static async getInitialData({ dispatch, language }) {
    const [topics] = await Promise.all([dispatch(getTopics(language)), dispatch(getDocuments())]);
    return topics;
  }

  state = {
    snackOpen: false,
    loading: false,
  };

  clearNotificationTimeout = null;
  loaderTimeout = null;

  componentDidMount() {
    document.addEventListener('keydown', this.handleTab, false);
    document.addEventListener('mousedown', this.handleClick, false);
    document.body.classList.add('FocusHidden');
    const stayLoggedIn = Cookies.get(Settings.API_TOKEN_STAY_IN);
    if (stayLoggedIn) {
      this.props.setIsStayLoggedIn(!!stayLoggedIn);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { notification, removeNotification, loading, t } = this.props;
    if (notification) {
      const content = notification.content || t(notification.translateKey);
      const prevContent = prevProps.notification
        ? prevProps.notification.content || t(prevProps.notification.translateKey)
        : null;
      if (this.state.snackOpen && content !== prevContent) {
        this.clearNotificationTimeout && clearTimeout(this.clearNotificationTimeout);
        this.clearNotificationTimeout = setTimeout(removeNotification, SnackTime);
      } else if (!this.state.snackOpen && !prevState.snackOpen) {
        this.setState({ snackOpen: true });
        this.clearNotificationTimeout = setTimeout(removeNotification, SnackTime);
      }
    }

    if (!prevProps.loading && loading) {
      this.loaderTimeout = setTimeout(() => this.setLoading(true), 2000);
    }

    if (!loading && prevProps.loading) {
      this.clearLoaderTimeout();
      this.setLoading(false);
    }
  }

  componentWillUnmount() {
    this.clearLoaderTimeout();
    document.removeEventListener('keydown', this.handleTab, false);
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  setLoading = (loading) => {
    this.setState({ loading });
  };

  clearLoaderTimeout = () => {
    if (this.loaderTimeout) {
      clearTimeout(this.loaderTimeout);
      this.loaderTimeout = null;
    }
  };

  setSnackOpen = (snackOpen) => {
    this.setState({ snackOpen });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setSnackOpen(false);
    this.props.removeNotification();
  };

  handleTab = (e) => {
    if (e.key === 'Tab') {
      document.body.classList.add('FocusShown');
      document.body.classList.remove('FocusHidden');
    }
  };

  handleClick = () => {
    document.body.classList.add('FocusHidden');
    document.body.classList.remove('FocusShown');
  };

  render() {
    const { notification, routes, t, getAuthorizedRoutes, classes } = this.props;
    const props = { ...this.props };
    delete props['classes'];
    return (
      <>
        <CssBaseline />
        {this.state.loading ? <AppLoader show /> : null}
        {notification ? (
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackOpen}
            onClose={this.handleClose}
            TransitionComponent={Slide}
          >
            <SnackbarContent
              variant={notification.type}
              message={notification.content || t(notification.translateKey)}
              onClose={this.handleClose}
            />
          </Snackbar>
        ) : null}
        <Main routes={routes} {...props} getAuthorizedRoutes={getAuthorizedRoutes} />
        <div className={classes.Szechenyi2020Container}>
          <div className={classes.Szechenyi2020} />
        </div>
        <CookieConsent
          location="bottom"
          buttonText={t('cookie_button_text')}
          style={{ backgroundColor: '#000000BB' }}
          buttonClasses={classes.CookieButton}
        >
          {t('cookie_text')}
        </CookieConsent>
      </>
    );
  }
}

export default withTranslation()(withStyles(appStyles)(App));
