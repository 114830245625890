import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { block, center, fixed, flex, inlineBlock, none } from 'constants/Jss';

const useStyles = makeStyles((theme) => ({
  AppLoader: {
    display: none,
    justifyContent: center,
    alignItems: center,
    height: '100%',
    position: fixed,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1200,
    background: fade(theme.palette.white, 0.5),
    '&.show': {
      display: flex,
    },
  },
  CircularLoaderContainer: {
    display: inlineBlock,
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  CircularLoader: {
    display: block,
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: theme.spacing(1),
    borderRadius: '50%',
    border: `6px solid ${theme.palette.headerBlue}`,
    borderColor: `${theme.palette.headerBlue} transparent ${theme.palette.headerBlue} transparent`,
    animationName: 'lds-dual-ring',
    animationDuration: '1.2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
}));

const AppLoader = ({ show = false }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.AppLoader, { show })}>
      <div className={classes.CircularLoaderContainer}>
        <div className={classes.CircularLoader} />
      </div>
    </div>
  );
};

AppLoader.propTypes = {
  show: PropTypes.bool,
};

export default AppLoader;
