import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import ExamPage from './ExamPage';
import { withWindowWidth } from 'components';
import { validateExam } from 'store/exams/actions';
import { showNotification } from 'store/common/actions';
import { removeCommonLoader } from 'store/loader/actions';
import { getExam } from 'store/exams/actions';

const mapStateToProps = (state) => {
  return {
    exam: state.exams.exam,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      validateExam,
      showNotification,
      removeCommonLoader,
      getExam,
    },
    dispatch
  );
};

export default compose(withRouter, withWindowWidth, connect(mapStateToProps, mapDispatchToProps))(ExamPage);
