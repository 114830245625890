import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  absolute,
  auto,
  bold,
  center,
  column,
  flex,
  flexEnd,
  flexStart,
  none,
  noRepeat,
  nowrap,
  pointer,
  relative,
  spaceBetween,
  uppercase,
  wrap,
  transparent,
} from 'constants/Jss';

export const courseDetailPageStyles = (theme) => ({
  CourseDetailContainer: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  TopContent: {
    color: theme.palette.grey70,
    fontSize: 14,
    fontWeight: bold,
    display: flex,
    justifyContent: flexEnd,
    alignItems: flexStart,
    [theme.breakpoints.up('md')]: {
      position: absolute,
      top: theme.spacing(1),
      right: 0,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: column,
      alignItems: flexEnd,
      justifyContent: spaceBetween,
      '&.WithResult': {
        marginTop: theme.spacing(6),
      },
    },
  },
  CourseDetailPageTitle: {
    position: relative,
    [theme.breakpoints.down('sm')]: {
      '&.LoggedIn': {
        marginTop: theme.spacing(10),
        '&.WithResult': {
          marginTop: theme.spacing(17),
        },
      },
    },
  },
  ExamResultBox: {
    backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(252,196,128)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
    '&.Success': {
      backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(185,217,136)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
    },
    backgroundPosition: 'calc(100% + 95px) -18px',
    backgroundSize: '212px 216px',
    backgroundRepeat: noRepeat,
    color: theme.palette.black,
    width: 103,
    height: 194,
    display: flex,
    flexDirection: column,
    alignItems: center,
    justifyContent: center,
    [theme.breakpoints.down('sm')]: {
      marginRight: -theme.spacing(2),
      marginTop: -theme.spacing(4),
      backgroundPosition: 'calc(100% + 75px) -18px',
      backgroundSize: '169px 172px',
      width: 82,
      height: 154,
    },
  },
  ExamDoneText: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: bold,
    textTransform: uppercase,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  ExamDoneValue: {
    fontFamily: 'Montserrat',
    fontSize: 24,
    fontWeight: bold,
    lineHeight: 1.15,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  ExamButtonBox: {
    position: relative,
    borderTop: `1px solid ${theme.palette.grey70}`,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      borderTopColor: transparent,
    },
  },
  ExamButtonWrapper: {
    width: '100%',
    position: absolute,
    top: 0,
    transform: 'translate(0, -50%)',
    display: flex,
    justifyContent: center,
  },
  WhiteWrapper: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    backgroundColor: theme.palette.white,
  },
  TopicArrow: {
    stroke: `${theme.palette.lightBlue} !important`,
    fill: none,
    strokeWidth: 2,
    width: 19.617,
    height: 11.309,
    transform: 'rotate(90deg)',
    marginRight: theme.spacing(1),
  },
  TopicArrowLabel: {
    color: theme.palette.blackTitle,
    marginTop: theme.spacing(0.5),
  },
  BackToTopicContainer: {
    position: absolute,
    top: theme.spacing(-6),
    right: theme.spacing(19),
    '&:hover': {
      cursor: pointer,
    },
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(-7.75),
      '&.WithResult': {
        top: theme.spacing(-15.5),
      },
    },
  },
});

export const useCourseDetailPageStyles = makeStyles((theme) => ({
  DescriptionContainer: {
    width: '100%',
    padding: theme.spacing(4),
    color: theme.palette.blackTitle,
    lineHeight: '30px',
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% + ${theme.spacing(4)}px)`,
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      marginBottom: theme.spacing(8),
    },
  },
  Time: {
    display: flex,
    width: '100%',
    alignItems: center,
    justifyContent: center,
    [theme.breakpoints.up('md')]: {
      '&.Mobile': {
        display: none,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(.Mobile)': {
        display: none,
      },
    },
  },
  WhatToLearnContainer: {
    width: '100%',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.white,
    border: `1px solid ${theme.palette.grey70}`,
    color: theme.palette.blackTitle,
    [theme.breakpoints.down('sm')]: {
      border: 0,
      padding: 0,
      marginBottom: theme.spacing(8),
    },
  },
  ItemContainer: {
    display: flex,
  },
  ItemDescription: {
    fontSize: 16,
  },
  TickIconContainer: {
    display: flex,
    alignItems: flexStart,
    justifyContent: center,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  GridItem: {
    paddingBottom: theme.spacing(3),
    '&:nth-child(2n+1):nth-last-child(-n+2), &:nth-child(2n+1):nth-last-child(-n+2) ~ div': {
      paddingBottom: 0,
    },
  },
  NarrowBox: {
    width: '100%',
    maxWidth: 960,
    lineHeight: '30px',
    color: theme.palette.blackTitle,
    marginLeft: auto,
    marginRight: auto,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(8),
      padding: `0 ${theme.spacing(2)}px`,
    },
  },
  IndicatorWrapper: {
    width: '100%',
    display: flex,
    flexWrap: wrap,
    justifyContent: center,
  },
  IndicatorContainer: {
    width: '100%',
    display: flex,
    justifyContent: center,
    fontSize: 21,
    fontWeight: bold,
    flexWrap: wrap,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  IconizedIndicator: {
    display: flex,
    alignItems: center,
    color: theme.palette.blackTitle,
  },
  TimeIndicator: {
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  XP: {
    color: theme.palette.cyan,
    whiteSpace: nowrap,
    '& > span:last-child': {
      color: theme.palette.blackTitle,
    },
    [theme.breakpoints.up('sm')]: {
      '&.Xs': {
        display: none,
      },
    },
    [theme.breakpoints.down('xs')]: {
      '&.Xs': {
        fontSize: 21,
        fontWeight: bold,
      },
      '&:not(.Xs)': {
        display: none,
      },
    },
  },
  Patch: {
    position: 'absolute',
    [theme.breakpoints.down(theme.breakpoints.values.hiddenImages)]: {
      display: none,
    },
  },
  TopPatch: {
    marginTop: 58,
  },
  BottomPatch: {
    marginTop: 715,
  },
  LeftPatch: {
    left: 0,
  },
  RightPatch: {
    right: 0,
  },
  Triplet: {
    maxWidth: 950,
    marginLeft: auto,
    marginRight: auto,
  },
  SingleLessonContainer: {
    width: '100%',
    display: flex,
    justifyContent: center,
    [theme.breakpoints.down('xs')]: {
      width: `calc(100% + ${theme.spacing(4)}px)`,
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    },
  },
  DoubleLessonContainer: {
    width: '100%',
    display: flex,
    justifyContent: spaceBetween,
  },
  SingleBoxInDoubleContainer: {
    justifyContent: center,
  },
}));

export const useTagListStyles = makeStyles((theme) => ({
  TagListBox: {
    display: flex,
    [theme.breakpoints.down('sm')]: {
      flexDirection: column,
      marginBottom: theme.spacing(6),
    },
  },
  TagListLabel: {
    fontSize: 16,
    fontWeight: bold,
    whiteSpace: nowrap,
    color: theme.palette.blackTitle,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  TagsContainer: {
    display: flex,
    flexWrap: wrap,
  },
  Tag: {
    backgroundColor: theme.palette.greyF7,
    color: theme.palette.blackTitle,
    fontWeight: bold,
    fontSize: 14,
    padding: '5px 5px 4px 6px',
    lineHeight: 1,
    borderRadius: 3,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
}));
