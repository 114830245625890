import { createMuiTheme } from '@material-ui/core/styles';

import { Montserrat, MontserratBold, MontserratExtraBold, WonderUnit, WonderUnitBold } from './fonts';
import { auto, none, pointer, transparent, visible } from 'constants/Jss';
import { isBrowser } from 'utils/browser';

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  mixins: {
    page: {
      mainPadding: '8%',
      topSpace: defaultTheme.spacing(15),
      sideSpace: defaultTheme.spacing(5),
    },
    sideBar: {
      width: 300,
      mobileBreakpoint: 'md',
    },
    xpBubble: {
      size: 140,
    },
    filePicker: {
      width: 40,
      height: 40,
    },
    header: {
      height: 60,
      contentSize: 44,
    },
    mobileHeader: {
      height: 60,
    },
    scrollToTop: {
      size: 60,
      mobileSize: 42,
    },
    homeSearch: {
      height: 66,
    },
    homePageAboutUsImage: {
      size: 120,
    },
    pagination: {
      size: 44,
    },
    lessonBoxIcon: {
      size: 96,
      padding: 13,
    },
    loginForm: {
      width: 410,
    },
    registerForm: {
      width: 570,
    },
    topList: {
      cardIconSize: 94,
      cardIconPadding: 6,
      searchHeight: 66,
    },
    testPage: {
      footerHeight: 104,
      mobileFooterHeight: 66,
    },
    switch: {
      width: 74,
      widthWithLabel: 82,
      height: 37,
      mobileWidth: 62,
      mobileHeight: 31,
      mini: {
        width: 48,
        widthWithLabel: 71,
        height: 24,
      },
    },
    filePreview: {
      size: 200,
      mobileSize: 127,
    },
    button: {
      borderWidth: 2,
    },
  },
  overrides: {
    MuiCard: {
      root: {
        overflow: visible,
      },
    },
    MuiButton: {
      root: {
        fontSize: 16,
        paddingTop: defaultTheme.spacing(0.5),
        paddingBottom: defaultTheme.spacing(0.5),
        paddingLeft: defaultTheme.spacing(3),
        paddingRight: defaultTheme.spacing(3),
        '&$disabled': {
          opacity: 0.5,
        },
        '&:focus': {
          outline: none,
        },
      },
      contained: {
        boxShadow: none,
        '&:hover': {
          boxShadow: none,
        },
      },
      outlined: {
        '&$disabled': {
          border: '1px solid',
        },
      },
      startIcon: {
        marginRight: 20,
      },
    },
    MuiIconButton: {
      root: {
        cursor: pointer,
        borderRadius: '50%',
        '&:focus': {
          outline: none,
        },
        '&:hover': {
          backgroundColor: '#0e2e70',
        },
        '& svg': {
          fill: defaultTheme.palette.common.white,
        },
        '&$disabled': {
          backgroundColor: transparent,
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [WonderUnit, WonderUnitBold, Montserrat, MontserratBold, MontserratExtraBold],
      },
    },
    MuiDialogActions: {
      root: {
        paddingLeft: defaultTheme.spacing(3),
        paddingRight: defaultTheme.spacing(3),
        paddingBottom: defaultTheme.spacing(2),
      },
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          marginLeft: 0,
        },
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: 640,
      },
    },
    MuiDialogContent: {
      root: {
        overflowY: visible,
      },
    },
    MuiTablePagination: {
      toolbar: {
        paddingLeft: defaultTheme.spacing(2),
        paddingRight: defaultTheme.spacing(2),
        paddingTop: defaultTheme.spacing(1),
        paddingBottom: defaultTheme.spacing(1),
      },
    },
    MuiInputLabel: {
      outlined: {
        backgroundColor: defaultTheme.palette.common.white,
        paddingLeft: `${defaultTheme.spacing(1)}px!important`,
        paddingRight: `${defaultTheme.spacing(1)}px!important`,
      },
      formControl: {
        '& + .MuiInput-root': {
          marginTop: defaultTheme.spacing(2),
        },
      },
    },
    MuiListItemText: {
      root: {
        marginTop: 0,
        marginBottom: 0,
      },
      primary: {
        fontSize: 14,
      },
    },
    MuiFormControl: {
      root: {
        backgroundColor: defaultTheme.palette.common.white,
        borderRadius: 4,
      },
    },
    MuiFormControlLabel: {
      label: {
        lineHeight: 1.1,
      },
    },
    MuiDrawer: {
      root: {
        top: '60px !important',
        '& .MuiBackdrop-root': {
          top: 60,
        },
        maxHeight: '100%',
        overflowY: auto,
      },
    },
    MuiContainer: {
      maxWidthLg: {
        maxWidth: '1140px !important',
      },
    },
    MuiList: {
      root: {
        '&:focus': {
          outline: none,
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#FF5C6C',
        },
      },
    },
    MuiAutocomplete: {
      endAdornment: {
        top: 'calc(50% - 17px)',
        lineHeight: '1.1875em',
      },
      inputRoot: {
        lineHeight: 2,
        // '&&[class*="MuiOutlinedInput-root"]': {
        //   paddingTop: 0,
        //   paddingBottom: 0,
        // },
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: transparent,
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#333',
      dark: '#2C2C2C',
      hover: '#444',
      contrast: '#FFF',
    },
    background: {
      default: '#FFF',
      dark: '#333',
    },
    black: '#000',
    blackTitle: '#231F20',
    blackOpacity: '#00000042',
    grey2C: '#2C2C2C',
    grey64: '#646F81',
    grey6E: '#6E6E6E',
    grey70: '#707070',
    grey73: '#737373',
    grey80: '#808080',
    greyB3: '#b3b3b3',
    greyCC: '#ccc',
    greyD9: '#d9d9d9',
    greyE3: '#E3E3E3',
    greyE5: '#e5e5e5',
    greyF2: '#f2f2f2',
    greyF9: '#F9F9F9',
    greyF7: '#f7f7f7',
    white: '#FFF',
    whiteHover: '#0000000A',
    searchBg: '#7070701C',
    checkboxBorder: '#c9c9c9',
    radioButtonBorder: '#7070704D',
    answerBorder: '#7070704D',
    pairingBorder: '#70707033',
    switchOnTrack: '#267D68',
    switchOnThumb: '#FFF',
    switchOnLabel: '#FFF',
    switchOffTrack: '#F9F9F9',
    switchOffThumb: '#838383',
    switchOffLabel: '#2D2B2C',
    greyLoginLine: '#70707057',
    errorColor: '#f44336',
    yellow: '#FCC480',
    xpYellow: '#CB4E0B',
    bluishGrey: '#B4BBC7',
    cyan: '#267D68',
    cyanHover: '#035827',
    lightBlue: '#3578BB',
    lightBlueHover: '#0B7DB1',
    lightPink: '#E91639',
    lightPinkHover: '#E91C1C',
    darkBlue: '#003375',
    darkBlueHover: '#023787',
    headerLightBlue: '#14387f',
    headerBlue: '#0e2e70',
    footerBlue: '#051440',
    blueA0: '#0000A0',
    blue1C: '#1C479D',
    blue50: '#508FCE',
    greenSuccess: '#5E8028',
    greenSuccessHover: '#B9D988',
    green: '#55B99A',
    activeFiltersBackground: '#7C51A1',
    focusColor: '#4D90FE',
    facebookBlue: '#3B5998',
    appleBlack: '#2D2B2C',
    socialOpacity: '#00000029',
    googleOpacity: '#00000098',
    examResultBlue: '#0B7DB1',
    currentBubble: '#0B7DB11A',
    avatarGrey: '#919191',
  },
  typography: {
    fontFamily: ['WonderUnit', 'sans-serif'].join(','),
    fontSize: 18,
    h1: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontWeight: 900,
    },
  },
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      hiddenImages: 1600,
      md: 1140,
      sm: 768,
    },
  },
});

// Let the theme object be available quickly during development
if (process.env.NODE_ENV === 'development' && isBrowser()) {
  // eslint-disable-next-line no-console
  console.log('theme', theme);
}

export default theme;
