import { bold, center, column, flex, pointer, spaceBetween, transparent } from 'constants/Jss';

const certificateWidth = 285;

export const certificatesPageStyles = (theme) => ({
  CertificatesPageContainer: {
    width: '100%',
    marginBottom: theme.spacing(40),
    [theme.breakpoints.down(theme.breakpoints.values.hiddenImages)]: {
      marginBottom: 0,
    },
  },
  CertificateContainer: {
    display: flex,
    flexDirection: column,
    alignItems: center,
    marginTop: theme.spacing(7),
  },
  CertificateImageBox: {
    width: certificateWidth,
    backgroundColor: theme.palette.grey70,
    border: `1px solid ${theme.palette.black}`,
    '&:hover': {
      cursor: pointer,
    },
  },
  CertificateShownImageBox: {
    backgroundColor: transparent,
    border: `1px solid ${theme.palette.black}`,
  },
  CertificateImage: {
    width: '100%',
    height: '100%',
  },
  ModuleTitle: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: bold,
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(3),
    maxWidth: certificateWidth,
    height: 50,
    textAlign: center,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      marginTop: theme.spacing(1),
    },
  },
  CertificateFooter: {
    display: flex,
    alignItems: center,
    maxWidth: certificateWidth,
    width: '100%',
    padding: `${theme.spacing(1)}px 0 1px 0`,
    borderTop: `1px solid ${theme.palette.greyLoginLine}`,
    borderBottom: `1px solid ${theme.palette.greyLoginLine}`,
    justifyContent: spaceBetween,
  },
  DownloadBox: {
    '&:hover': {
      cursor: pointer,
    },
  },
});
