import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { auto } from 'constants/Jss';
import { useFormStyles } from './styles';
import { CheckboxAnswer } from './CheckboxAnswer';
import { Answer } from 'types/types';

export const MultipleChoiceForm = ({ answers, setAnswer, answered = false, questionElementId }) => {
  const classes = useFormStyles();
  const [values, setValues] = React.useState([]);

  useEffect(() => {
    setAnswer(values);
  }, [setAnswer, values]);

  useEffect(() => {
    setValues([]);
  }, [answers]);

  const toggleAnswer = (index) => {
    if (!answered) {
      if (values.includes(index)) {
        setValues(values.filter((value) => value !== index));
      } else {
        setValues([...values, index]);
      }
    }
  };

  return (
    <Box className={classes.FormBox} ml={auto} mr={auto}>
      {answers.map((answer, i) => (
        <CheckboxAnswer
          key={answer.id}
          answer={answer}
          answered={answered}
          checked={values.includes(i)}
          questionElementId={questionElementId}
          toggleAnswer={() => toggleAnswer(i)}
        />
      ))}
    </Box>
  );
};

MultipleChoiceForm.propTypes = {
  answers: PropTypes.arrayOf(Answer),
  setAnswer: PropTypes.func,
  answered: PropTypes.bool,
  questionElementId: PropTypes.string.isRequired,
};
