import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation, Trans } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Player } from '@lottiefiles/react-lottie-player';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';

import { examPageStyles } from './styles';
import OpeningPatch from 'assets/images/test-opening-patch-center.png';
import ClosingPatchSuccess from 'assets/images/exam-closing-patch-success.png';
import ClosingPatchFailed from 'assets/images/exam-closing-patch-failed.png';
import CertificateIcon from 'assets/images/certificate-icon.png';
import {
  Button,
  Dialog,
  ExamTestOpeningFooter,
  Image,
  ProgressBar,
  RemainingTime,
  QuestionImage,
  SingleChoiceForm,
  MultipleChoiceForm,
  TrueOrFalseForm,
  AccessibleSubstitutionForm,
  SubstitutionForm,
  PairingForm,
} from 'components';
import { closeButton } from 'utils/icons';
import {
  ExamStates,
  NotificationTypes,
  QuestionElementId,
  QuestionTypes,
  BadgePopupWidth,
  ScrollToTopVisible,
  LottieAnimationDuration,
} from 'constants/Constants';
import { decimals } from 'utils/decimals';
import { ClassStyle, Exam, ExamState, History } from 'types/types';
import { getProfileDetails } from 'store/profile/actions';
import { isIOS } from 'utils/browser';
import { getCurrentLanguage } from 'utils/language';
import { titleFocus } from 'utils/titleFocus';
import { isSmallWidth } from 'utils/isSmallWidth';
import { getPath } from 'utils/getPath';
import { animate } from 'utils/animate';
import ExamResultDialog from './ExamResultDialog/ExamResultDialog';
import theme from 'theme';

class ExamPage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      CloseButton: ClassStyle,
      InnerContent: ClassStyle,
      OpeningContent: ClassStyle,
      ImageContainer: ClassStyle,
      OpeningImage: ClassStyle,
      IntroText: ClassStyle,
      ExamFooter: ClassStyle,
      ClosingContent: ClassStyle,
      PatchBox: ClassStyle,
      PatchResult: ClassStyle,
      EndExamTitle: ClassStyle,
      ColoredResult: ClassStyle,
      ResultDescription: ClassStyle,
      BadgeContainer: ClassStyle,
      ManyBadgeTextContainer: ClassStyle,
      BadgeBorder: ClassStyle,
      Congratulations: ClassStyle,
      ProfileLink: ClassStyle,
      BadgeBox: ClassStyle,
      Badge: ClassStyle,
      BadgeImageBox: ClassStyle,
      BadgeImage: ClassStyle,
      BadgeTitle: ClassStyle,
      BadgeSubTitle: ClassStyle,
      BadgeDescription: ClassStyle,
      CertificateContainer: ClassStyle,
      CertificateBox: ClassStyle,
      CertificateText: ClassStyle,
      CertificatesLink: ClassStyle,
      QuestionContent: ClassStyle,
      ProgressBarContainer: ClassStyle,
      StickyTimeContainer: ClassStyle,
      StickyTimeVisible: ClassStyle,
      StickyTimeContainerIos: ClassStyle,
      Image: ClassStyle,
      QuestionWithAnswersContainer: ClassStyle,
      Question: ClassStyle,
      QuestionLabel: ClassStyle,
      Content: ClassStyle,
      AppLoader: ClassStyle,
      CircularLoaderContainer: ClassStyle,
      CircularLoader: ClassStyle,
      NoPadding: ClassStyle,
    }),
    history: History,
    t: PropTypes.func,
    exam: Exam,
    examState: ExamState,
    validateExam: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    pages: PropTypes.arrayOf(PropTypes.object),
    removeCommonLoader: PropTypes.func,
    getExam: PropTypes.func,
    windowWidth: PropTypes.number,
  };

  static pageTitleKey = 'page_title_exam';

  static async getInitialData({ dispatch, language, token }) {
    try {
      if (token) {
        await dispatch(getProfileDetails({ language, token }));
      }
      titleFocus(dispatch);
    } catch (err) {
      console.error(err);
    }
  }

  state = {
    examState: ExamStates.OPEN,
    questionIndex: null,
    badgeIndex: 0,
    answer: [],
    stickyTimeVisible: false,
    isLoading: false,
    isAccessible: false,
    courseId: null,
    isExamResultDialogOpen: false,
    examResult: {},
  };

  endExamTimeout = null;
  forceCloseTimout = null;
  questions = [];

  innerContent = createRef();
  staticTimer = createRef();
  progressBar = createRef();
  lottiePlayer = createRef();
  badgeContentRef = createRef();

  async componentDidMount() {
    const { history, getExam, exam } = this.props;

    const params = history.location.pathname.split('/');
    const courseId = params[5];
    this.setState({ courseId });
    if (isEmpty(exam)) {
      await getExam(courseId);
    }
    this.props.removeCommonLoader();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.examState === ExamStates.QUESTION &&
      this.innerContent.current &&
      prevState.questionIndex !== this.state.questionIndex &&
      this.props.windowWidth >= theme.breakpoints.values.sm
    ) {
      setTimeout(() => {
        if (this.innerContent.current) {
          animate('scrollTop', this.innerContent.current, document.body.scrollHeight);
        }
      }, ScrollToTopVisible);
    }
    if (
      prevState.examState !== this.props.examState &&
      this.innerContent.current &&
      this.staticTimer.current &&
      this.props.exam.minutesToCompleteExam
    ) {
      this.innerContent.current.onscroll = () => {
        if (this.state.examState === ExamStates.END) {
          return;
        }

        const top = this.staticTimer.current ? this.staticTimer.current.getBoundingClientRect().top : 0;

        if (top === 0 && !this.state.stickyTimeVisible) {
          this.setState({ stickyTimeVisible: true });
        } else if (top > 0 && this.state.stickyTimeVisible) {
          this.setState({ stickyTimeVisible: false });
        }
      };
    }
    if (this.badgeContentRef.current !== null) {
      setTimeout(() => this.badgeContentRef.current.focus(), 500);
    }
  }

  componentWillUnmount() {
    this.endExamTimeout && clearTimeout(this.endExamTimeout);
    this.forceCloseTimout && clearTimeout(this.forceCloseTimout);
  }

  goToExam = async () => {
    try {
      if (this.state.isAccessible) {
        await this.props.getExam(
          this.state.courseId,
          getCurrentLanguage(this.props.history.location.pathname),
          this.state.isAccessible
        );
      }
      const {
        exam: { questions },
      } = this.props;

      if (!questions) {
        this.closeExam();
        return;
      }

      this.questions.push({
        id: questions[0].id,
        answers: [],
      });

      this.setState({ examState: ExamStates.QUESTION, questionIndex: 0 });
    } catch (err) {
      console.error(err);
    }
  };

  closeExam = () => {
    const { history, t } = this.props;
    const courseUrl = window.location.pathname.split('/' + t('url_exam'))[0];

    history.replace(courseUrl);
  };

  handleExamResultDialog = (isOpen) => {
    this.setState({ isExamResultDialogOpen: isOpen });
  };

  getCloseButton = () => {
    const { classes, t } = this.props;

    return (
      <Box
        className={classes.CloseButton}
        onClick={this.closeExam}
        tabIndex={0}
        role="button"
        aria-label={t('common_close')}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            this.closeExam();
          }
        }}
      >
        <Box className="CloseText" aria-hidden={true}>
          {t('common_close')}
        </Box>
        {closeButton()}
      </Box>
    );
  };

  animationStop = (event) => {
    if (event === 'load' && this.lottiePlayer.current) {
      setTimeout(() => this.lottiePlayer.current.pause(), LottieAnimationDuration);
    }
  };

  openingContent = () => {
    const { classes, t } = this.props;

    return (
      <Box className={classNames(classes.InnerContent, classes.OpeningContent)}>
        {this.getCloseButton()}
        <Box className={classes.ImageContainer}>
          <Box mt={9} mb={2}>
            <Image className={classes.OpeningImage} src={OpeningPatch} aria-hidden={true} />
          </Box>
        </Box>
        <Box mb={7} className="PageTitleContainer">
          <h1 className="Title" tabIndex={0}>
            {t('exam_page')}
          </h1>
          <div className="TitleBorder" />
        </Box>
        <Box className={classes.IntroText} tabIndex={0}>
          {t('exam_page_intro_text1')}
        </Box>
        <Box className={classes.IntroText} tabIndex={0}>
          {t('exam_page_intro_text2')}
        </Box>
        <Box className={classes.IntroText} tabIndex={0}>
          {t('exam_page_intro_text3')}
        </Box>
        <Box className={classes.IntroText} tabIndex={0}>
          {t('exam_page_intro_text4')}
        </Box>
      </Box>
    );
  };

  endExamContent = () => {
    const { classes, t, pages } = this.props;
    const { examResult, examSuccess, receivedXp, isAchievedCertificate } = this.state.examResult || {};

    return (
      <Box className={classNames(classes.InnerContent, classes.ClosingContent)}>
        {this.getCloseButton()}
        <Box className={classes.ImageContainer}>
          <Box mt={3} mb={2} className={classes.PatchBox}>
            <Image src={examSuccess ? ClosingPatchSuccess : ClosingPatchFailed} aria-hidden={true} />
            <Box tabIndex={0} aria-label={`${decimals(receivedXp)} ${t('common_xp')}`} className={classes.PatchResult}>
              <Box className="value" aria-hidden={true}>
                {decimals(receivedXp)}
              </Box>
              <Box className="percentage" aria-hidden={true}>
                {t('common_xp')}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mb={3} pl={2} pr={2} className={classNames('PageTitleContainer', classes.EndExamTitle)}>
          <h1 className="Title" tabIndex={0}>
            {examSuccess ? t('exam_succeeded') : t('exam_failed')}
          </h1>
          <div className="TitleBorder" />
        </Box>
        <Box
          mb={3}
          className={classNames(classes.ColoredResult, { Green: examSuccess, Pink: !examSuccess })}
          tabIndex={0}
          aria-label={decimals(examResult) + ' ' + t('alt_percent')}
        >
          <div aria-hidden={true}>{`${decimals(examResult)}%`}</div>
        </Box>
        {examSuccess ? (
          <Box>
            <Box className={classes.ResultDescription} tabIndex={0}>
              {t('exam_succeeded_description1')}
            </Box>
            <Box className={classes.ResultDescription} tabIndex={0}>
              {t('exam_succeeded_description2')}
            </Box>
            {!!isAchievedCertificate && (
              <>
                <Box my={1} className="TitleBorder" />
                <Box mt={5} className={classes.CertificateContainer}>
                  <Box tabIndex={0} className={classes.CertificateBox}>
                    <img src={CertificateIcon} alt="" />
                    <Box className={classes.CertificateText} aria-label={`${t('alt_exam_certificate_text')}`}>
                      <Box>
                        <Trans
                          i18nKey="exam_certificate_text_part1"
                          components={[
                            <Link
                              className={classes.CertificatesLink}
                              target="_blank"
                              key="exam_certificate_text_part1"
                              to={getPath(pages, t('menu_item_certificates'))}
                            />,
                          ]}
                        />
                      </Box>
                      <Box>{t('exam_certificate_text_part2')}</Box>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        ) : (
          <Box className={classes.ResultDescription} tabIndex={0}>
            {t('exam_failed_description')}
          </Box>
        )}
      </Box>
    );
  };

  endExamFooter = () => {
    const { classes, t } = this.props;
    const { isAccessible } = this.state;
    return (
      <Box
        justifyContent="space-between"
        className={classNames(classes.ExamFooter, 'Center', { IOs: isIOS(), Accessible: isAccessible })}
      >
        <Box maxWidth={1280} width="100%" display="flex" justifyContent="space-between">
          <Button buttonClassName="DarkBlue Secondary" onClick={() => this.handleExamResultDialog(true)}>
            {t('check_exam_results')}
          </Button>
          <Button buttonClassName="Pink" onClick={this.closeExam}>
            {t('common_forward')}
          </Button>
        </Box>
        {this.state.examResult && this.state.examResult.resultExplain ? (
          <ExamResultDialog
            isOpen={this.state.isExamResultDialogOpen}
            resultExplain={this.state.examResult.resultExplain}
            handleDialog={this.handleExamResultDialog}
          />
        ) : null}
      </Box>
    );
  };

  badgeContent = () => {
    const { classes, t, windowWidth } = this.props;
    const { badges } = this.state.examResult || {};
    const { badgeIndex } = this.state;

    const badgeJson = require(`assets/jsons/badge${badges[badgeIndex]?.id}.json`);

    return (
      <Box className={classNames(classes.InnerContent, classes.ClosingContent)}>
        <Box className={classes.ImageContainer}>
          <Player
            style={{ width: windowWidth < theme.breakpoints.values.sm ? '100%' : BadgePopupWidth, height: '100%' }}
            autoplay
            src={badgeJson}
            onEvent={this.animationStop}
            ref={this.lottiePlayer}
          ></Player>
        </Box>
        <Box
          ref={this.badgeContentRef}
          tabIndex={0}
          aria-label={`${t('exam_congratulations_text_part1')} ${badges[badgeIndex].name} ${t(
            'exam_congratulations_text_part2'
          )} ${badges[badgeIndex].description}`}
        >
          <Box
            aria-hidden={true}
            mb={5}
            pl={2}
            pr={2}
            className={classNames('PageTitleContainer', classes.EndExamTitle)}
          >
            <h1 aria-hidden={true} className="Title">
              {t('exam_congratulations_text_part1')}
            </h1>
            <div className="TitleBorder" />
          </Box>
          <Box aria-hidden={true} mb={1} className={classes.BadgeTitle}>
            {badges[badgeIndex].name}
          </Box>
          <Box aria-hidden={true} className={classes.BadgeSubTitle}>
            {t('exam_congratulations_text_part2')}
          </Box>
          <Box aria-hidden={true} className={classes.BadgeDescription}>
            {badges[badgeIndex].description}
          </Box>
        </Box>
      </Box>
    );
  };

  badgeFooter = () => {
    const { classes, t } = this.props;
    const { isAccessible } = this.state;
    return (
      <Box className={classNames(classes.ExamFooter, 'Center', { IOs: isIOS(), Accessible: isAccessible })}>
        <Button buttonClassName="Pink" onClick={this.nextBadge}>
          {t('common_forward')}
        </Button>
      </Box>
    );
  };

  addLoader = async () => {
    await this.setState({ isLoading: true });
  };

  removeLoader = async () => {
    await this.setState({ isLoading: false });
  };

  nextQuestion = () => {
    const {
      exam: { questions },
    } = this.props;
    const { questionIndex } = this.state;

    if (questions[questionIndex + 1]) {
      this.setState({ questionIndex: questionIndex + 1 }, () => {
        this.questions.push({
          id: questions[this.state.questionIndex].id,
          answers: [],
        });

        this.setState({
          examState: ExamStates.QUESTION,
          stickyTimeVisible: false,
        });
      });
    } else {
      this.goToEndExam();
    }
  };

  nextBadge = () => {
    const { badgeIndex, examResult } = this.state;
    const { badges } = examResult;

    if (badges[badgeIndex + 1]) {
      this.setState({ badgeIndex: badgeIndex + 1, examState: ExamStates.BADGES, stickyTimeVisible: false });
    } else {
      this.setState({ examState: ExamStates.END });
    }
  };

  outOfTime = () => {
    const {
      exam: { questions },
    } = this.props;
    const { questionIndex } = this.state;

    for (let i = questionIndex + 1; i < questions.length; i++) {
      this.questions.push({
        id: questions[i].id,
        answers: [],
      });
    }

    this.goToEndExam();
  };

  goToEndExam = async () => {
    const { validateExam } = this.props;
    const { badgeIndex } = this.state;
    const reqParams = window.location.pathname.split('/');
    const courseId = reqParams[reqParams.length - 2];

    try {
      await this.addLoader();
      const examResult = await validateExam(courseId, {
        questions: this.questions,
      });
      await this.removeLoader();
      if (examResult) {
        this.endExamTimeout && clearTimeout(this.endExamTimeout);
        this.forceCloseTimout && clearTimeout(this.forceCloseTimout);
        await this.removeLoader();
      }

      if (examResult.badgesCount > 0 && badgeIndex < examResult.badgesCount) {
        this.setState({ examState: ExamStates.BADGES, examResult });
      } else {
        this.setState({ examState: ExamStates.END, examResult });
      }
    } catch (e) {
      this.endExamTimeout = setTimeout(this.goToEndExam, 1000);
      if (!this.forceCloseTimout) {
        await this.addLoader();
        this.forceCloseTimout = setTimeout(this.forceClose, 60000);
      }
    }
  };

  forceClose = async () => {
    const { showNotification } = this.props;
    this.endExamTimeout && clearTimeout(this.endExamTimeout);
    this.forceCloseTimout && clearTimeout(this.forceCloseTimout);
    await this.removeLoader();
    showNotification({ translateKey: 'exam_connection_failed_notification', type: NotificationTypes.error });
    this.closeExam();
  };

  isSubstituteQuestion = () => {
    const { exam } = this.props;
    const { questions } = exam;
    const { questionIndex } = this.state;

    return questions[questionIndex].type === QuestionTypes.SUBSTITUTION;
  };

  questionContent = () => {
    const { classes, exam, t } = this.props;
    const { minutesToCompleteExam, questions } = exam;
    const { questionIndex, isAccessible } = this.state;
    const questionType = {
      0: t('question_type_select'),
      1: t('question_type_multiple_select'),
      2: t('question_type_true_false'),
      3: t('question_type_sentence_completion'),
      4: t('question_type_word_pairing'),
    }[questions[questionIndex].type];

    return (
      <Box ref={this.innerContent} className={classNames(classes.InnerContent, classes.QuestionContent)}>
        {this.getCloseButton()}
        <Box mt={4} mb={minutesToCompleteExam ? 4 : 6} className={classes.ProgressBarContainer}>
          <ProgressBar
            ref={this.progressBar}
            currentQuestion={questionIndex + 1}
            numberOfQuestions={questions.length}
          />
        </Box>
        {!minutesToCompleteExam ? null : (
          <>
            <Box
              ref={this.staticTimer}
              className={classNames(classes.StickyTimeContainer, {
                [classes.StickyTimeVisible]: this.state.stickyTimeVisible,
                [classes.StickyTimeContainerIos]: isIOS(),
              })}
            >
              <RemainingTime timeLimitInSeconds={minutesToCompleteExam * 60} timeout={this.outOfTime} tabIndex={0} />
            </Box>
            <Box mb={6} />
          </>
        )}
        <QuestionImage questions={questions} questionIndex={questionIndex} />
        <Container>
          <Box className={classes.QuestionWithAnswersContainer}>
            <Box
              mb={5}
              className={classes.Question}
              tabIndex={0}
              role="application"
              aria-label={t('test_and_exam_question', {
                type: questionType,
                question: questions[questionIndex].question,
              })}
            >
              {questions[questionIndex].question}
              <div id={QuestionElementId} className={classes.QuestionLabel} />
            </Box>
          </Box>
          {questions[questionIndex].type === QuestionTypes.SINGLE_CHOICE ? (
            <SingleChoiceForm answers={questions[questionIndex].answers} setAnswer={this.setAnswer} />
          ) : questions[questionIndex].type === QuestionTypes.MULTIPLE_CHOICE ? (
            <MultipleChoiceForm
              answers={questions[questionIndex].answers}
              setAnswer={this.setAnswer}
              questionElementId={QuestionElementId}
            />
          ) : questions[questionIndex].type === QuestionTypes.TRUE_OR_FALSE ? (
            <TrueOrFalseForm answers={questions[questionIndex].answers} setAnswer={this.setAnswer} />
          ) : (this.isSubstituteQuestion() && isAccessible) ||
            (this.isSubstituteQuestion() && !isAccessible && isSmallWidth()) ? (
            <AccessibleSubstitutionForm answers={questions[questionIndex].answers} setAnswer={this.setAnswer} />
          ) : this.isSubstituteQuestion() && !isAccessible && !isSmallWidth() ? (
            <SubstitutionForm answers={questions[questionIndex].answers} setAnswer={this.setAnswer} />
          ) : questions[questionIndex].type === QuestionTypes.PAIRING ? (
            <PairingForm answers={questions[questionIndex].answers} setAnswer={this.setAnswer} />
          ) : null}
        </Container>
      </Box>
    );
  };

  questionFooter = () => {
    const { t, classes } = this.props;
    const { isAccessible } = this.state;
    return (
      <Box className={classNames(classes.ExamFooter, { IOs: isIOS(), Accessible: isAccessible })}>
        <Button buttonClassName="Pink" onClick={this.nextQuestion}>
          {t('common_forward')}
        </Button>
      </Box>
    );
  };

  renderContent = () => {
    const { t } = this.props;
    const { examState, isAccessible } = this.state;

    switch (examState) {
      case ExamStates.OPEN:
        return (
          <>
            {this.openingContent()}
            <ExamTestOpeningFooter
              label={t('exam_accessibility_start')}
              buttonLabel={t('common_forward')}
              checked={isAccessible}
              onButtonClick={this.goToExam}
              onChange={this.onAccessibilityChange}
            />
          </>
        );
      case ExamStates.QUESTION:
        return (
          <>
            {this.questionContent()}
            {this.questionFooter()}
          </>
        );
      case ExamStates.BADGES:
        return (
          <>
            {this.badgeContent()}
            {this.badgeFooter()}
          </>
        );
      case ExamStates.END:
        return (
          <>
            {this.endExamContent()}
            {this.endExamFooter()}
          </>
        );
      default:
        return null;
    }
  };

  setAnswer = (answers) => {
    const {
      exam: { questions },
    } = this.props;
    const { questionIndex } = this.state;
    this.questions[this.questions.length - 1].answers = answers.map((answer) => {
      if (isObject(answer) && !!answer.words) {
        return {
          id: answer.id,
          words: answer.words,
        };
      }
      if (isObject(answer) && !!answer.matchAnswers) {
        return { matchAnswers: answer.matchAnswers };
      }
      return {
        id: questions[questionIndex].answers[answer].id,
      };
    });
    this.setState({ answer: answers });
  };

  onAccessibilityChange = () => {
    const { isAccessible } = this.state;
    this.setState({ isAccessible: !isAccessible });
  };

  render() {
    const { classes } = this.props;
    const { isLoading } = this.state;

    return (
      <Dialog
        open={true}
        alwaysFullScreen
        onClose={this.closeExam}
        isExamOrTest
        content={
          <Box className={classes.Content}>
            {this.renderContent()}
            <div className={classNames(classes.AppLoader, { show: isLoading })}>
              <div className={classes.CircularLoaderContainer}>
                <div className={classes.CircularLoader} />
              </div>
            </div>
          </Box>
        }
        contentClass={classes.NoPadding}
        noClose
      />
    );
  }
}

export default hoistStatics(withTranslation()(withStyles(examPageStyles)(ExamPage)), ExamPage);
