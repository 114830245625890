import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { absolute, bold, center, column, flex, hidden, inherit, spaceBetween } from 'constants/Jss';

import SuccessIcon from 'assets/images/exam-success-icon.png';
import FailedIcon from 'assets/images/exam-failed-icon.png';
import ExamCardDivider from 'assets/images/exam-card-divider.png';
import ExamCardDividerMobile from 'assets/images/exam-card-divider-mobile.png';

import Settings from 'env';

import { TYPE_NAMES } from './types';
import TrueOrFalseResultTable from './type-tables/TrueOrFalseResultTable';
import SingleAndMultipleSelectResultTable from './type-tables/SingleAndMultipleSelectResultTable';
import SentenceFillerResultTable from './type-tables/SentenceFillerResultTable';
import WordsMatchingResultTable from './type-tables/WordsMatchingResultTable';

const useStyles = makeStyles((theme) => {
  return {
    ResultCard: {
      width: '100%',
      padding: '0 120px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 50px',
        fontSize: 16,
      },

      '&.mobileResultCard': {
        padding: 0,
      },

      '& p': {
        margin: 0,
      },
    },
    CardContainer: {
      display: flex,
      flexDirection: column,
      alignItems: center,
      width: inherit,
      overflowX: hidden,
    },
    ResultCardHeader: {
      width: inherit,
      display: flex,
      justifyContent: spaceBetween,
      marginTop: 40,
    },
    CardTypeIndicator: {
      color: theme.palette.examResultBlue,
    },
    ResultCardImageContainer: {
      marginTop: 50,
      [theme.breakpoints.down('sm')]: {
        marginTop: 30,
      },
    },
    ResultCardQuestionContainer: {
      textAlign: center,
      margin: '30px 0px 50px',
      [theme.breakpoints.down('sm')]: {
        margin: '30px 0 30px',
      },
    },
    ResultIndicator: {
      display: flex,
      justifyContent: center,
      alignItems: center,
      margin: '20px 0px 50px',
    },
    ResultIndicatorText: {
      marginLeft: 23,
      fontWeight: bold,
      fontSize: 18,

      '&.success': {
        color: theme.palette.greenSuccess,
      },
      '&.fail': {
        color: theme.palette.lightPink,
      },
    },
    DialogPatch: {
      position: absolute,
      top: 0,
      left: 0,
    },
  };
});

const ResultCard = ({ result, index, isDesktopLayout }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const resultType = {
    0: {
      typeName: TYPE_NAMES.SINGLE_SELECT,
      typeLabel: t('question_type_select'),
    },
    1: {
      typeName: TYPE_NAMES.MULTIPLE_SELECT,
      typeLabel: t('question_type_multiple_select'),
    },
    2: {
      typeName: TYPE_NAMES.TRUE_OR_FALSE,
      typeLabel: t('question_type_true_false'),
    },
    3: {
      typeName: TYPE_NAMES.SENTENCE_FILLER,
      typeLabel: t('question_type_sentence_completion'),
    },
    4: {
      typeName: TYPE_NAMES.WORDS_MATCHING,
      typeLabel: t('question_type_word_pairing'),
    },
  }[result.type];

  const resultTable = {
    [TYPE_NAMES.SINGLE_SELECT]: (
      <SingleAndMultipleSelectResultTable answers={result.answers} isDesktopLayout={isDesktopLayout} />
    ),
    [TYPE_NAMES.MULTIPLE_SELECT]: (
      <SingleAndMultipleSelectResultTable answers={result.answers} isDesktopLayout={isDesktopLayout} />
    ),
    [TYPE_NAMES.TRUE_OR_FALSE]: <TrueOrFalseResultTable answers={result.answers} isDesktopLayout={isDesktopLayout} />,
    [TYPE_NAMES.SENTENCE_FILLER]: (
      <SentenceFillerResultTable answers={result.answers} isDesktopLayout={isDesktopLayout} />
    ),
    [TYPE_NAMES.WORDS_MATCHING]: (
      <WordsMatchingResultTable answers={result.answers} isDesktopLayout={isDesktopLayout} />
    ),
  }[resultType.typeName];

  const cardHeaderAriaLabel = `${index + 1}. ${t('result_card_task')}, ${t('result_card_type')}: ${
    resultType.typeLabel
  }.`;
  const questionAriaLabel = `${t('result_card_question')}: ${result.question}.`;

  return (
    <Box
      tabIndex={0}
      key={result.id}
      className={isDesktopLayout ? classes.ResultCard : classNames(classes.ResultCard, 'mobileResultCard')}
    >
      <Box className={classes.CardContainer}>
        <div aria-label={cardHeaderAriaLabel} className={classes.ResultCardHeader}>
          <p aria-hidden={true}>
            <b>{`${index + 1}. ${t('result_card_task')}`}</b>
          </p>
          <p aria-hidden={true}>
            <b>
              {`${t('result_card_type')}: `}
              {isDesktopLayout ? null : <br />}
              <span className={classes.CardTypeIndicator}>{resultType.typeLabel}</span>
            </b>
          </p>
        </div>
        {result.image ? (
          <div className={classes.ResultCardImageContainer}>
            <img tabIndex={-1} src={Settings.STORAGE_BASE_URL + result.image} width={'100%'} alt={result.imageAlt} />
          </div>
        ) : null}
        <div aria-label={questionAriaLabel} className={classes.ResultCardQuestionContainer}>
          <p>
            <b>{`${t('result_card_question')}:`}</b>
          </p>
          <p>{result.question}</p>
        </div>
        {resultTable}
        <div className={classes.ResultIndicator}>
          {result.isSuccess ? (
            <>
              <img src={SuccessIcon} alt="" />
              <span className={classNames(classes.ResultIndicatorText, 'success')}>
                {t('result_indicator_text_success')}
              </span>
            </>
          ) : (
            <>
              <img src={FailedIcon} alt="" />
              <span className={classNames(classes.ResultIndicatorText, 'fail')}>{t('result_indicator_text_fail')}</span>
            </>
          )}
        </div>
        <img src={isDesktopLayout ? ExamCardDivider : ExamCardDividerMobile} alt="" />
      </Box>
    </Box>
  );
};

ResultCard.propTypes = {
  result: PropTypes.object,
  index: PropTypes.number,
  count: PropTypes.number,
  isDesktopLayout: PropTypes.bool,
};

export default ResultCard;
