import request from 'utils/request';
import { Methods } from 'utils/http';

const BASE_URL = '/groups';

export const groupSearch = async (searchText) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/search?searchText=${searchText}`
  });
};
