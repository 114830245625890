import { GET_DASHBOARD, GET_DASHBOARD_SUCCESS, GET_DASHBOARD_FAILED } from './types';
import { GET_COURSE_BY_ID_SUCCESS } from '../courses/types';
import { reduceCourses } from '../courses/reducers';

const INITIAL_STATE = {
  loading: false,
  error: null,
  dashboard: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD:
      return {
        ...state,
        loading: true,
      };
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboard: action.payload || [],
      };
    case GET_DASHBOARD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_COURSE_BY_ID_SUCCESS:
      return reduceGetCourseByIdSuccess(state, action.payload);
    default:
      return state;
  }
};

const reduceGetCourseByIdSuccess = (state, updatedCourse) => {
  const { dashboard: topics } = state;
  const nextTopics = reduceTopics(topics, updatedCourse);
  return nextTopics === topics ? state : { ...state, dashboard: nextTopics };
};

const reduceTopics = (topics, updatedCourse) => {
  let changed = false;
  const nextTopics = topics.map((topic) => {
    const nextTopic = reduceTopic(topic, updatedCourse);
    changed |= nextTopic !== topic;
    return nextTopic;
  });
  return changed ? nextTopics : topics;
};

const reduceTopic = (topic, updatedCourse) => {
  const { courses } = topic;
  const nextCourses = reduceCourses(courses, updatedCourse);
  return nextCourses === courses ? topic : { ...topic, courses: nextCourses };
};
