import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';

import { Button, Image, LessonCard, Slider, TopPageNavigationArrows } from 'components';
import { getLessonById } from 'store/lessons/actions';
import { getCourseById } from 'store/courses/actions';
import { getProfileDetails } from 'store/profile/actions';
import { ClassStyle, History, LessonDetails, Course } from 'types/types';
import { lessonDetailPageStyles } from './styles';
import { LessonContentTypes, YoutubeUrlPrefix } from 'constants/Constants';
import Settings from 'env';
import LessonPatch from 'assets/images/lesson-patch.png';
import { closeButton } from 'utils/icons';
import { titleFocus } from 'utils/titleFocus';
import theme from 'theme';
import { withFirstFocus } from 'utils/withFirstFocus';

class LessonDetailPage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      LessonTextItem: ClassStyle,
      VideoContainer: ClassStyle,
      DesktopVideo: ClassStyle,
      Video: ClassStyle,
      MobileVideo: ClassStyle,
      Image: ClassStyle,
      LessonDetailContainer: ClassStyle,
      LessonCloseButtonContainer: ClassStyle,
      LessonItemsContainer: ClassStyle,
      TestButtonWrapper: ClassStyle,
      TestButtonContainer: ClassStyle,
      TestButton: ClassStyle,
      LessonPatch: ClassStyle,
      CourseTitle: ClassStyle,
      CourseTitleDescription: ClassStyle,
      CarouselSlideContainer: ClassStyle,
      LessonCardCarousel: ClassStyle,
    }),
    history: History,
    t: PropTypes.func,
    lesson: LessonDetails,
    course: Course,
    getTest: PropTypes.func,
    showNotification: PropTypes.func,
    addCommonLoader: PropTypes.func,
    removeCommonLoader: PropTypes.func,
    loading: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired,
    firstFocusRef: PropTypes.any.isRequired,
  };

  static pageTitleKey = 'page_title_lesson_detail';

  sliderRef = createRef();

  state = {
    width: 0,
  };

  static async getInitialData({ dispatch, language, req, token }) {
    const reqParams = req.url.split('/');
    const id = reqParams[reqParams.length - 1];
    const courseId = reqParams[reqParams.length - 3];
    try {
      if (token) {
        await Promise.all([
          dispatch(getLessonById(id, language, token)),
          dispatch(getCourseById(courseId, language)),
          dispatch(getProfileDetails({ language, token })),
        ]);
      }
      titleFocus(dispatch);
    } catch (err) {
      console.error(err);
    }
  }

  componentDidMount() {
    this.props.addCommonLoader();
    this.setState({ width: window.innerWidth });
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  textItem = (item) => {
    const { classes } = this.props;
    return (
      <Box className={classes.LessonTextItem}>
        <div dangerouslySetInnerHTML={{ __html: item.value }} />
      </Box>
    );
  };

  getYoutubeUrl = (url) => {
    const parts = url.split('?v=');
    return YoutubeUrlPrefix + parts[parts.length - 1];
  };

  videoItem = (item) => {
    const { classes } = this.props;
    const src = item.value.includes('youtube') ? this.getYoutubeUrl(item.value) : item.value;
    return (
      <Box>
        <div className={classes.VideoContainer}>
          <iframe
            className={classNames(classes.DesktopVideo, classes.Video)}
            width="768"
            height="100%"
            src={src}
            frameBorder={0}
            allowFullScreen
          />
          <iframe
            className={classNames(classes.MobileVideo, classes.Video)}
            width="100%"
            height="100%"
            src={src}
            frameBorder={0}
            allowFullScreen
          />
        </div>
      </Box>
    );
  };

  imageItem = (item) => {
    const { classes } = this.props;
    return (
      <Box>
        <img
          tabIndex={item.valueAlt ? 0 : -1}
          alt={item.valueAlt}
          className={classes.Image}
          src={Settings.STORAGE_BASE_URL + item.value}
          aria-hidden={item.valueAlt ? false : true}
        />
      </Box>
    );
  };

  closeLesson = () => {
    const { history, t } = this.props;
    const courseUrl = window.location.pathname.split('/' + t('url_lessons'))[0];
    history.push(courseUrl);
  };

  startTest = async () => {
    const { history, t, lesson, getTest } = this.props;
    await getTest(lesson.id);
    const testUrl = window.location.pathname + '/' + t('url_test');
    history.replace(testUrl);
  };

  getLessonPositionInArray = () => {
    const { course, lesson } = this.props;
    if (course) {
      return course.lessons.findIndex((element) => element.id === lesson.id);
    }
    return -1;
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  onFirstLessonFocus = (index) => {
    if (!index && this.sliderRef.current) {
      this.sliderRef.current.slickGoTo(0);
    }
  };

  render() {
    const { classes, lesson, t, course, history, loading, onClick, onKeyDown, firstFocusRef } = this.props;
    const { width } = this.state;

    return !lesson || !course ? null : (
      <div className={classes.LessonDetailContainer} onClick={onClick} onKeyDown={onKeyDown}>
        <Box mb={5} className="PageTitleContainer">
          <h1 className="Title" tabIndex={0} ref={firstFocusRef}>
            {lesson.title}
          </h1>
          <div className="TitleBorder" />
        </Box>
        <Box
          className={classes.LessonCloseButtonContainer}
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.closeLesson();
            }
          }}
        >
          <Box className="CloseText" onClick={this.closeLesson} role="button" aria-label={t('common_close')}>
            {t('common_close')}
          </Box>
          {closeButton(this.closeLesson)}
        </Box>
        {course && lesson && (
          <TopPageNavigationArrows
            searchArray={course.lessons}
            searchedObj={lesson}
            target="id"
            label={t('course_details_lesson')}
          />
        )}
        <div className={classes.LessonItemsContainer}>
          {lesson.specifics
            ? lesson.specifics.map((item, i) => (
                <Box mb={8} key={i}>
                  {item.type === LessonContentTypes.IMAGE
                    ? this.imageItem(item)
                    : item.type === LessonContentTypes.VIDEO
                    ? this.videoItem(item)
                    : this.textItem(item)}
                </Box>
              ))
            : null}
          <Box className={classes.TestButtonWrapper}>
            <Box className={classes.TestButtonContainer}>
              {lesson.hasTest ? (
                <Button buttonClassName={classNames(classes.TestButton, 'Pink')} onClick={this.startTest}>
                  {t('common_test')}
                </Button>
              ) : (
                <Button buttonClassName={classNames(classes.TestButton, 'Pink')} onClick={this.closeLesson}>
                  {t('lesson_details_back_to_the_course')}
                </Button>
              )}
            </Box>
            <Box tabIndex={0}>
              <Box mt={6} mb={2} className="PageTitleContainer">
                <Box className="Title">{t('lesson_more')}</Box>
                <div className="TitleBorder" />
              </Box>
              <Box className={classes.CourseTitle}>{course.title}</Box>
              <Box className={classNames(classes.CourseTitle, classes.CourseTitleDescription)}>
                {t('lesson_course_title')}
              </Box>
            </Box>
          </Box>
        </div>
        {!loading && (
          <Slider
            centerPadding="370px"
            initialSlide={this.getLessonPositionInArray()}
            centerMode={width >= theme.breakpoints.values.md}
            slidesToShow={1}
            dots={width >= theme.breakpoints.values.md}
            ref={this.sliderRef}
          >
            {course.lessons.map((courseLesson, index) => (
              <Box className={classes.CarouselSlideContainer} key={courseLesson.title}>
                <LessonCard
                  containerClass={classes.LessonCardCarousel}
                  history={history}
                  lesson={courseLesson}
                  index={index}
                  isOnLessonPage
                  onFocus={() => this.onFirstLessonFocus(index)}
                />
              </Box>
            ))}
          </Slider>
        )}
        <Image className={classes.LessonPatch} src={LessonPatch} aria-hidden={true} />
      </div>
    );
  }
}

export default hoistStatics(
  withTranslation()(withStyles(lessonDetailPageStyles)(withFirstFocus(LessonDetailPage))),
  LessonDetailPage
);
