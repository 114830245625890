import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import isNumber from 'lodash/isNumber';

import { auto } from 'constants/Jss';
import { useFormStyles } from './styles';
import { isSpaceOrEnterEvent } from 'utils/eventUtils';
import { usePrevious } from 'utils/usePrevious';
import { getMaxElementSize } from 'utils/getMaxElementSize';
import { testCorrect, testIncorrect } from 'utils/icons';

export const PairingForm = ({ answers, setAnswer, answered, innerContentRef, isAccessible }) => {
  const classes = useFormStyles();
  const { t } = useTranslation();
  const [wordKeys, setWordKeys] = useState([]);
  const [wordValues, setWordValues] = useState([]);
  const [currentPair, setCurrentPair] = useState(['', '']);
  const [pairs, setPairs] = useState([]);
  const [mounted, setMounted] = useState(false);
  const [firstHalfSize, setFirstHalfSize] = useState({ width: 0, height: 0 });
  const [secondHalfSize, setSecondHalfSize] = useState({ width: 0, height: 0 });
  const [calculationFinished, setCalculationFinished] = useState(false);
  const leftColRef = useRef();
  const rightColRef = useRef();
  const dividerRef = useRef();
  const prevAnswers = usePrevious(answers);

  useEffect(() => {
    const newWordKeys = answers.shuffledWords.wordsKey.map((word) => word);
    const newWordValues = answers.shuffledWords.wordsValue.map((word) => word);
    setWordKeys(newWordKeys);
    setWordValues(newWordValues);
    setAnswer([{ matchAnswers: {} }]);
    setCurrentPair(['', '']);
    setPairs([]);
    setCalculationFinished(false);
    setMounted(true);
  }, [answers, setAnswer]);

  useEffect(() => {
    if (mounted && !calculationFinished) {
      const firstHalf = document.querySelectorAll('.firstHalf');
      const secondHalf = document.querySelectorAll('.secondHalf');
      setFirstHalfSize(getMaxElementSize(firstHalf));
      setSecondHalfSize(getMaxElementSize(secondHalf));
      setCalculationFinished(true);
    }
  }, [mounted, calculationFinished]);

  useEffect(() => {
    if (isNumber(currentPair[0]) && isNumber(currentPair[1])) {
      onPairing();
    }
  }, [onPairing, currentPair]);

  useEffect(() => {
    if (answers !== prevAnswers) {
      handleKeyFocus();
    }
  }, [answers, prevAnswers, pairs, handleKeyFocus]);

  useEffect(() => {
    if (answered) {
      if (innerContentRef.current) {
        innerContentRef.current.scrollTop = isAccessible
          ? dividerRef.current.offsetTop
          : dividerRef.current.offsetTop - document.querySelector('.sticky-timer').getBoundingClientRect().height;
      }
    }
  }, [answered, pairs, innerContentRef, isAccessible]);

  const onPairing = useCallback(() => {
    const newPair = { wordKey: currentPair[0], wordValue: currentPair[1] };
    const newPairs = [...pairs];
    newPairs.push(newPair);
    setCurrentPair(['', '']);
    setPairs(newPairs);
  }, [currentPair, pairs]);

  const handleValueFocus = () => {
    if (wordValues.some((wordValue, index) => !isValueInPairs(wordValue) && index !== currentPair[1])) {
      rightColRef.current.focus({ preventScroll: true });
    }
  };

  const onKeyClick = (index) => {
    if (currentPair[0] === index && !isKeyInPairs(wordKeys[index]) && currentPair[1] === '' && !answered) {
      const temp = [...currentPair];
      temp[0] = '';
      setCurrentPair(temp);
    } else if (!isKeyInPairs(wordKeys[index]) && !answered) {
      const temp = [...currentPair];
      temp[0] = index;
      setCurrentPair(temp);
      handleValueFocus();
    }
  };

  const handleKeyFocus = useCallback(() => {
    if (wordKeys.some((wordKey, index) => !isKeyInPairs(wordKey) && index !== currentPair[0])) {
      leftColRef.current.focus({ preventScroll: true });
    }
  }, [currentPair, wordKeys, isKeyInPairs]);

  const onValueClick = (index) => {
    if (currentPair[1] === index && !isValueInPairs(wordValues[index]) && currentPair[0] === '' && !answered) {
      const temp = [...currentPair];
      temp[1] = '';
      setCurrentPair(temp);
    } else if (!isValueInPairs(wordValues[index]) && !answered) {
      const temp = [...currentPair];
      temp[1] = index;
      setCurrentPair(temp);
      handleKeyFocus();
    }
  };

  const onPairClick = (index) => {
    if (!answered) {
      const newPairs = [...pairs];
      const newWordKeys = [...wordKeys];
      const newWordValues = [...wordValues];
      const newKey = newPairs[index].wordKey;
      const newValue = newPairs[index].wordValue;
      newWordKeys[newKey] = answers.shuffledWords.wordsKey[newKey];
      newWordValues[newValue] = answers.shuffledWords.wordsValue[newValue];
      setWordKeys(newWordKeys);
      setWordValues(newWordValues);
      newPairs.splice(index, 1);
      setPairs(newPairs);
      handleKeyFocus();
    }
  };

  useEffect(() => {
    if (pairs && pairs.length > 0) {
      const matchAnswers = Object.assign(
        ...pairs.map((pair) => ({
          [answers.shuffledWords.wordsKey[pair.wordKey]]: answers.shuffledWords.wordsValue[pair.wordValue],
        }))
      );
      setAnswer([{ matchAnswers }]);
    }
  }, [pairs, answers, setAnswer]);

  const findGoodIndex = (key) =>
    answers.inOrderWords.wordsKey.findIndex((wordKey) => wordKey === answers.shuffledWords.wordsKey[key]);

  const isGood = (key, value) => {
    const index = findGoodIndex(key);
    return index >= 0 && answers.inOrderWords.wordsValue[index] === answers.shuffledWords.wordsValue[value];
  };

  const isKeyInPairs = useCallback(
    (key) => pairs.some((pair) => answers.shuffledWords.wordsKey[pair.wordKey] === key),
    [answers, pairs]
  );

  const isValueInPairs = (value) => pairs.some((pair) => answers.shuffledWords.wordsValue[pair.wordValue] === value);

  const constructAnswersAriaLabel = (key, value) => {
    if (answered) {
      if (isGood(key, value)) {
        return t('exam_pairs_your_answers_correct', {
          key: answers.shuffledWords.wordsKey[key],
          value: answers.shuffledWords.wordsValue[value],
        });
      } else {
        return t('exam_pairs_your_answers_incorrect', {
          key: answers.shuffledWords.wordsKey[key],
          value: answers.shuffledWords.wordsValue[value],
        });
      }
    }
    return t('exam_pairs_your_answers', {
      key: answers.shuffledWords.wordsKey[key],
      value: answers.shuffledWords.wordsValue[value],
    });
  };

  return (
    !!mounted && (
      <Box className={classes.FormBox} ml={auto} mr={auto}>
        <Box>
          <Box className={classes.PairingMainContainer}>
            <Box className={classes.PairingSubContainer}>
              <Box
                role="application"
                aria-label={t('exam_pairs_first_half')}
                tabIndex={!answered ? 0 : -1}
                ref={leftColRef}
                className={classes.PairingContainer}
                px={3}
              >
                {wordKeys.map((wordKey, index) => (
                  <Box
                    role="button"
                    tabIndex={!isKeyInPairs(wordKey) ? (!answered ? 0 : -1) : -1}
                    onClick={() => {
                      onKeyClick(index);
                    }}
                    onKeyPress={(e) => {
                      if (isSpaceOrEnterEvent(e)) {
                        onKeyClick(index);
                      }
                    }}
                    mt={!index ? 1 : 0}
                    mb={1}
                    className={classNames('firstHalf', classes.Cloud, classes.CloudFilled, classes.ClickableWord, {
                      [classes.PairCloudEmpty]: isKeyInPairs(wordKey),
                      [classes.PairCloudSelected]: index === currentPair[0],
                    })}
                    key={`${answers.shuffledWords.wordsKey[index]}-${index}`}
                    aria-label={
                      index === currentPair[0]
                        ? t('exam_pairs_selected', { word: answers.shuffledWords.wordsKey[index] })
                        : answers.shuffledWords.wordsKey[index]
                    }
                    style={{
                      width: calculationFinished ? firstHalfSize.width : 'auto',
                    }}
                  >
                    {wordKey}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box className={classes.PairingSubContainer}>
              <Box
                role="application"
                aria-label={t('exam_pairs_second_half')}
                tabIndex={!answered ? 0 : -1}
                ref={rightColRef}
                className={classes.PairingContainer}
                px={3}
              >
                {wordValues.map((wordValue, index) => (
                  <Box
                    tabIndex={!isValueInPairs(wordValue) ? (!answered ? 0 : -1) : -1}
                    onClick={() => {
                      onValueClick(index);
                    }}
                    onKeyPress={(e) => {
                      if (isSpaceOrEnterEvent(e)) {
                        onValueClick(index);
                      }
                    }}
                    mt={!index ? 1 : 0}
                    mb={1}
                    className={classNames('secondHalf', classes.Cloud, classes.CloudFilled, classes.ClickableWord, {
                      [classes.PairCloudEmpty]: isValueInPairs(wordValue),
                      [classes.PairCloudSelected]: index === currentPair[1],
                    })}
                    key={`${answers.shuffledWords.wordsValue[index]}-${index}`}
                    aria-label={
                      index === currentPair[1]
                        ? t('exam_pairs_selected', { word: answers.shuffledWords.wordsValue[index] })
                        : answers.shuffledWords.wordsValue[index]
                    }
                    role="button"
                    style={{
                      width: calculationFinished ? secondHalfSize.width : 'auto',
                    }}
                  >
                    {wordValue}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Box ref={dividerRef} className={classes.Divider} />
          <Box
            role="application"
            aria-label={t('alt_answers')}
            tabIndex={pairs && pairs.length > 0 ? 0 : -1}
            mb={3}
            display="flex"
            flexDirection="column"
          >
            {pairs.map((pair, index) => (
              <Box
                mb={1}
                className={classes.PairingBottomContainer}
                key={`${index}-${answers.shuffledWords.wordsKey[pair.wordKey]}`}
              >
                <Box
                  onClick={() => {
                    onPairClick(index);
                  }}
                  onKeyPress={(e) => {
                    if (isSpaceOrEnterEvent(e)) {
                      onPairClick(index);
                    }
                  }}
                  tabIndex={0}
                  role={answered ? 'application' : 'button'}
                  className={classNames(classes.PairingContainer, classes.ClickableWord, classes.BottomContainer)}
                  px={3}
                  mr={3}
                  aria-label={constructAnswersAriaLabel(pair.wordKey, pair.wordValue)}
                >
                  <Box
                    mt={1}
                    mb={1}
                    className={classNames(classes.Cloud, classes.CloudFilled, {
                      [classes.CloudSuccess]: !!answered && isGood(pair.wordKey, pair.wordValue),
                      [classes.WordCloudFailure]: !!answered && !isGood(pair.wordKey, pair.wordValue),
                    })}
                    style={{
                      width: firstHalfSize.width,
                    }}
                  >
                    {answers.shuffledWords.wordsKey[pair.wordKey]}
                  </Box>
                  <Box
                    mb={1}
                    className={classNames(classes.Cloud, classes.CloudFilled, {
                      [classes.CloudSuccess]: !!answered && isGood(pair.wordKey, pair.wordValue),
                      [classes.WordCloudFailure]: !!answered && !isGood(pair.wordKey, pair.wordValue),
                    })}
                    style={{
                      width: secondHalfSize.width,
                    }}
                  >
                    {answers.shuffledWords.wordsValue[pair.wordValue]}
                  </Box>
                  <Box>
                    {!!answered && (
                      <Box position="absolute" right={10} bottom={0}>
                        {isGood(pair.wordKey, pair.wordValue) ? testCorrect() : testIncorrect()}
                      </Box>
                    )}
                  </Box>
                </Box>
                {!!answered && !isGood(pair.wordKey, pair.wordValue) && (
                  <Box
                    aria-label={t('exam_pairs_correct_answers', {
                      key: answers.inOrderWords.wordsKey[findGoodIndex(pair.wordKey)],
                      value: answers.inOrderWords.wordsValue[findGoodIndex(pair.wordKey)],
                    })}
                    tabIndex={0}
                    className={classes.BottomContainer}
                    role="application"
                  >
                    <Box className={classes.PairGoodWordsTitle}>{`${t('common_correct_answers')}:`}</Box>
                    <Box className={classes.PairGoodWordsAnswer}>
                      {answers.inOrderWords.wordsKey[findGoodIndex(pair.wordKey)]}
                    </Box>
                    <Box className={classes.PairGoodWordsAnswer}>
                      {answers.inOrderWords.wordsValue[findGoodIndex(pair.wordKey)]}
                    </Box>
                  </Box>
                )}
              </Box>
            ))}
            {!!answered &&
              pairs.length !== answers.inOrderWords.wordsKey.length &&
              answers.inOrderWords.wordsKey
                .filter((key) => {
                  return !pairs.map((pair) => answers.shuffledWords.wordsKey[pair.wordKey]).includes(key);
                })
                .map((key, index) => (
                  <Box
                    mb={1}
                    tabIndex={0}
                    aria-label={`${t('exam_pairs_no_answers')}. ${t('exam_pairs_correct_answers', {
                      key,
                      value: answers.inOrderWords.wordsValue[answers.inOrderWords.wordsKey.indexOf(key)],
                    })}`}
                    className={classes.PairingBottomContainer}
                    key={`${index}-${key}`}
                    role="application"
                  >
                    <Box
                      className={classNames(classes.PairingContainer, classes.ClickableWord, classes.BottomContainer)}
                      px={3}
                      mr={3}
                    >
                      <Box
                        style={{
                          width: firstHalfSize.width,
                        }}
                        mt={1}
                        mb={1}
                        className={classNames(classes.Cloud, classes.CloudFilled)}
                      >
                        {key}
                      </Box>
                      <Box
                        style={{
                          width: secondHalfSize.width,
                        }}
                        mb={1}
                        className={classNames(classes.Cloud, classes.CloudFilled)}
                      >
                        {answers.inOrderWords.wordsValue[answers.inOrderWords.wordsKey.indexOf(key)]}
                      </Box>
                    </Box>
                    <Box className={classes.BottomContainer}>
                      <Box className={classes.PairGoodWordsTitle}>{`${t('common_correct_answers')}:`}</Box>
                      <Box className={classes.PairGoodWordsAnswer}>{key}</Box>
                      <Box className={classes.PairGoodWordsAnswer}>
                        {answers.inOrderWords.wordsValue[answers.inOrderWords.wordsKey.indexOf(key)]}
                      </Box>
                    </Box>
                  </Box>
                ))}
          </Box>
        </Box>
      </Box>
    )
  );
};

PairingForm.propTypes = {
  answers: PropTypes.shape({
    inOrderWords: PropTypes.shape({
      wordsKey: PropTypes.arrayOf(PropTypes.string),
      wordsValue: PropTypes.arrayOf(PropTypes.string),
    }),
    shuffledWords: PropTypes.shape({
      wordsKey: PropTypes.arrayOf(PropTypes.string),
      wordsValue: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }),
  setAnswer: PropTypes.func,
  answered: PropTypes.bool,
  innerContentRef: PropTypes.any,
  isAccessible: PropTypes.bool,
};
