import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useCourseDetailPageStyles, useTagListStyles } from './styles';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Tag } from 'types/types';

export const TagList = ({ tags }) => {
  const { t } = useTranslation();
  const classes = useTagListStyles();
  const commonClasses = useCourseDetailPageStyles();
  return (
    <Box mb={12} className={classNames(classes.TagListBox, commonClasses.NarrowBox)}>
      <Box mr={4} className={classes.TagListLabel}>
        {t('course_details_tag_list_label')}
      </Box>
      <Box className={classes.TagsContainer}>
        {tags.map((tag) => (
          <Box mr={2} mb={2.5} className={classes.Tag} key={tags.indexOf(tag)}>
            {tag.title}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

TagList.propTypes = {
  tags: PropTypes.arrayOf(Tag),
};
