import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import MSnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';

import { NotificationTypes } from 'constants/Constants';
import { center, flex, nowrap } from 'constants/Jss';

const variantIcon = (className, variant) => {
  switch (variant) {
    case NotificationTypes.success:
      return <CheckCircleIcon className={className} />;
    case NotificationTypes.error:
      return <ErrorIcon className={className} />;
    case NotificationTypes.info:
      return <InfoIcon className={className} />;
    case NotificationTypes.warning:
      return <WarningIcon className={className} />;
    default:
      return null;
  }
};

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.yellow,
  },
  warning: {
    backgroundColor: amber[700],
  },
  warningText: {
    color: theme.palette.black,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(2),
  },
  message: {
    display: flex,
    alignItems: center,
  },
  CloseIcon: {
    '&:hover': {
      backgroundColor: theme.palette.whiteHover,
    },
  },
  CloseWarningIcon: {
    '& svg': {
      fill: theme.palette.black,
    },
  },
  NoWrap: {
    flexWrap: nowrap,
  },
}));

const SnackbarContent = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { className, message, onClose, variant, ...other } = props;

  useEffect(() => {
    if (document.getElementById('bookmark-notification-link')) {
      document.getElementById('bookmark-notification-link').focus();
    }
    if (document.getElementById('lesson-notification-link')) {
      document.getElementById('lesson-notification-link').focus();
    }
  }, []);

  return (
    <MSnackbarContent
      ref={ref}
      className={classNames(classes[variant], classes.NoWrap, className)}
      classes={{ root: variant === NotificationTypes.warning ? classes.warningText : null }}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {variantIcon(classNames(classes.icon, classes.iconVariant), variant)}
          {message}
        </span>
      }
      action={
        <IconButton
          aria-label={t('common_close')}
          color="inherit"
          onClick={onClose}
          className={classNames(classes.CloseIcon, {
            [classes.CloseWarningIcon]: variant === NotificationTypes.warning,
          })}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      }
      {...other}
    />
  );
});

SnackbarContent.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

export default SnackbarContent;
