import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Box from '@material-ui/core/Box';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { flex, center, pointer, absolute, auto, block } from 'constants/Jss';
import { NotificationTypes } from 'constants/Constants';

import { isBoolean } from 'utils/isBoolean';
import { stopEnterPropagation, stopPropagation } from 'utils/eventUtils';
import { heartIcon } from 'utils/icons';
import { Course } from 'types/types';
import { getCourseById, bookmarkCourse } from 'store/courses/actions';
import { showNotification } from 'store/common/actions';
import { selectIsLoggedIn } from 'store/auth/selectors';

const useStyles = makeStyles((theme) => {
  return {
    AddToFavourites: {
      display: flex,
      alignItems: center,
      marginTop: theme.spacing(0.625),
      cursor: pointer,
      '& svg': {
        width: 22.454,
        height: 19.584,
      },
      position: absolute,
      top: -theme.spacing(6),
      right: 0,
      fontSize: 16,
      [theme.breakpoints.down('sm')]: {
        right: theme.spacing(2.5),
        top: -theme.spacing(7.75),
        '&.WithResult': {
          top: -theme.spacing(15.5),
        },
      },
      [theme.breakpoints.down('xs')]: {
        right: theme.spacing(1),
      },
    },
    HeartIconContainer: {
      display: flex,
      alignItems: center,
      cursor: pointer,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        position: absolute,
        top: 0,
        height: 30,
        '&.Center': {
          right: 'calc(50% - 10px)',
        },
        '&:not(.Center)': {
          right: theme.spacing(1),
        },
      },
      '& svg': {
        width: 20,
        height: 18,
      },
    },
    HeartBox: {
      display: flex,
      alignItems: center,
      margin: 0,
    },
    HeartBoxSpacing: {
      marginRight: theme.spacing(1),
    },
    Checkbox: {
      padding: 0,
      '&.Mui-focusVisible': {
        outlineColor: theme.palette.focusColor,
        outlineStyle: auto,
        outlineWidth: 1,
      },
    },
    NotificationLink: {
      color: theme.palette.black,
      marginLeft: theme.spacing(1),
      '&:hover': {
        color: theme.palette.blueA0,
      },
      [theme.breakpoints.down('sm')]: {
        display: block,
      },
    },
  };
});

const heartBorder = heartIcon(false);
const heartFilled = heartIcon(true);

const FavoriteCheckBox = ({ course, label, onCard }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [isBookmarked, setBookmarked] = useState(undefined);

  useEffect(() => setBookmarked(course.isBookmarked), [course]);

  const onFavoriteChange = async () => {
    if (isLoggedIn) {
      setBookmarked(!course.isBookmarked);
      await dispatch(bookmarkCourse({ id: course.id, isBookmarked: !course.isBookmarked }));
      await dispatch(getCourseById(course.id));
    } else {
      dispatch(
        showNotification({
          content: (
            <Box textAlign="center">
              <Trans
                i18nKey="bookmark_login"
                components={[
                  <Link
                    id="bookmark-notification-link"
                    className={classes.NotificationLink}
                    key="bookmark_login"
                    aria-label={t('alt_bookmark_login')}
                    to={`${history.location.pathname}?dialog=${t('url_login')}`}
                  />,
                ]}
              />
            </Box>
          ),
          type: NotificationTypes.warning,
        })
      );
    }
  };

  return (
    isBoolean(isBookmarked) && (
      <Box
        className={classNames(
          {
            [classes.AddToFavourites]: !onCard,
            [classes.HeartIconContainer]: onCard,
            WithResult: isBoolean(course.examSuccess),
          },
          'Center'
        )}
        onKeyPress={stopEnterPropagation}
      >
        <Box mr={1} className={classNames(classes.HeartBox, { [classes.HeartBoxSpacing]: !onCard })}>
          <Checkbox
            className={classNames(classes.Checkbox)}
            disableRipple
            tabIndex={0}
            icon={heartBorder}
            checkedIcon={heartFilled}
            checked={isBookmarked}
            onChange={onFavoriteChange}
            onClick={stopPropagation}
            inputProps={{ 'aria-label': t('alt_bookmark_course') }}
          />
        </Box>
        {!!label && <Box aria-hidden={true}>{label}</Box>}
      </Box>
    )
  );
};

FavoriteCheckBox.propTypes = {
  course: Course,
  label: PropTypes.string,
  onCard: PropTypes.bool,
};

export default FavoriteCheckBox;
