export const GET_COURSES_FOR_TOPIC = 'GET_COURSES_FOR_TOPIC';
export const GET_COURSES_FOR_TOPIC_SUCCESS = 'GET_COURSES_FOR_TOPIC_SUCCESS';
export const GET_COURSES_FOR_TOPIC_FAILED = 'GET_COURSES_FOR_TOPIC_FAILED';

export const GET_COURSES = 'GET_COURSES';
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSES_FAILED = 'GET_COURSES_FAILED';

export const RESET_COURSES = 'RESET_COURSES';
export const RESET_ONGOING_COURSES = 'RESET_ONGOING_COURSES';
export const RESET_BOOKMARKED_COURSES = 'RESET_BOOKMARKED_COURSES';
export const RESET_COURSES_WITH_EXAMS = 'RESET_COURSES_WITH_EXAMS';

export const SET_FILTERS = 'SET_FILTERS';

export const SET_SORT_BY = 'SET_SORT_BY';

export const GET_COURSE_BY_ID = 'GET_COURSE_BY_ID';
export const GET_COURSE_BY_ID_SUCCESS = 'GET_COURSE_BY_ID_SUCCESS';
export const GET_COURSE_BY_ID_FAILED = 'GET_COURSE_BY_ID_FAILED';

export const LOAD_MORE_COURSES = 'LOAD_MORE_COURSES';
export const LOAD_MORE_COURSES_SUCCESS = 'LOAD_MORE_COURSES_SUCCESS';
export const LOAD_MORE_COURSES_FAILED = 'LOAD_MORE_COURSES_FAILED';

export const GET_COURSES_WITH_EXAMS = 'GET_COURSES_WITH_EXAMS';
export const GET_COURSES_WITH_EXAMS_SUCCESS = 'GET_COURSES_WITH_EXAMS_SUCCESS';
export const GET_COURSES_WITH_EXAMS_FAILED = 'GET_COURSES_WITH_EXAMS_FAILED';

export const LOAD_MORE_COURSES_WITH_EXAMS = 'LOAD_MORE_COURSES_WITH_EXAMS';
export const LOAD_MORE_COURSES_WITH_EXAMS_SUCCESS = 'LOAD_MORE_COURSES_WITH_EXAMS_SUCCESS';
export const LOAD_MORE_COURSES_WITH_EXAMS_FAILED = 'LOAD_MORE_COURSES_WITH_EXAMS_FAILED';

export const GET_ONGOING_COURSES = 'GET_ONGOING_COURSES';
export const GET_ONGOING_COURSES_SUCCESS = 'GET_ONGOING_COURSES_SUCCESS';
export const GET_ONGOING_COURSES_FAILED = 'GET_ONGOING_COURSES_FAILED';

export const LOAD_MORE_ONGOING_COURSES = 'LOAD_MORE_ONGOING_COURSES';
export const LOAD_MORE_ONGOING_COURSES_SUCCESS = 'LOAD_MORE_ONGOING_COURSES_SUCCESS';
export const LOAD_MORE_ONGOING_COURSES_FAILED = 'LOAD_MORE_ONGOING_COURSES_FAILED';

export const GET_BOOKMARKED_COURSES = 'GET_BOOKMARKED_COURSES';
export const GET_BOOKMARKED_COURSES_SUCCESS = 'GET_BOOKMARKED_COURSES_SUCCESS';
export const GET_BOOKMARKED_COURSES_FAILED = 'GET_BOOKMARKED_COURSES_FAILED';

export const LOAD_MORE_BOOKMARKED_COURSES = 'LOAD_MORE_BOOKMARKED_COURSES';
export const LOAD_MORE_BOOKMARKED_COURSES_SUCCESS = 'LOAD_MORE_BOOKMARKED_COURSES_SUCCESS';
export const LOAD_MORE_BOOKMARKED_COURSES_FAILED = 'LOAD_MORE_BOOKMARKED_COURSES_FAILED';

export const BOOKMARK_COURSE = 'BOOKMARK_COURSE';
export const BOOKMARK_COURSE_SUCCESS = 'BOOKMARK_COURSE_SUCCESS';
export const BOOKMARK_COURSE_FAILED = 'BOOKMARK_COURSE_FAILED';
