export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_FAILED = 'GET_DOCUMENTS_FAILED';

export const CHECK_DOCUMENTS = 'CHECK_DOCUMENTS';
export const CHECK_DOCUMENTS_SUCCESS = 'CHECK_DOCUMENTS_SUCCESS';
export const CHECK_DOCUMENTS_FAILED = 'CHECK_DOCUMENTS_FAILED';

export const AGREE_DOCUMENTS = 'AGREE_DOCUMENTS';
export const AGREE_DOCUMENTS_SUCCESS = 'AGREE_DOCUMENTS_SUCCESS';
export const AGREE_DOCUMENTS_FAILED = 'AGREE_DOCUMENTS_FAILED';
