import React from 'react';
import PropTypes from 'prop-types';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const Skeleton = ({ maxWidth, style, ...props }) => {
  const appliedStyle = { ...(style || {}) };

  if (maxWidth) {
    appliedStyle.maxWidth = maxWidth;
  }

  return <MuiSkeleton {...props} style={appliedStyle} />;
};

Skeleton.propTypes = {
  maxWidth: PropTypes.any,
  style: PropTypes.any,
};

export default Skeleton;
