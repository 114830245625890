import { GROUP_SEARCH, GROUP_SEARCH_FAILED, GROUP_SEARCH_SUCCESS } from './types';
import * as GroupService from 'services/groups';

export const groupSearch = (searchText) => {
  return async (dispatch) => {
    dispatch({
      type: GROUP_SEARCH,
    });

    try {
      const groups = await GroupService.groupSearch(searchText);

      dispatch({
        type: GROUP_SEARCH_SUCCESS,
        payload: groups,
      });

      return groups;
    } catch (error) {
      dispatch({
        type: GROUP_SEARCH_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
