import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiSwitch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import classNames from 'classnames';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const Switch = ({
  mini,
  label,
  onChange,
  onClick,
  inlineOnLabel = '',
  inlineOffLabel = '',
  disabled,
  labelClass,
  labelPlacement = 'start',
  rootClass,
  switchClassName,
  ...props
}) => {
  const classes = useStyles();
  const hasInlineLabel = Boolean(inlineOnLabel || inlineOffLabel);

  return (
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      onChange={onChange}
      onClick={onClick}
      classes={{
        root: rootClass,
        labelPlacementStart: classes.formControlLabelPlacementStart,
        label: classNames(classes.formControlLabel, labelClass),
      }}
      disabled={disabled}
      control={
        <div className={classes.container}>
          <MuiSwitch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            disabled={disabled}
            classes={{
              root: classNames(classes.root, {
                [classes.miniRoot]: mini,
                [classes.rootWithInlineLabel]: hasInlineLabel,
              }),
              switchBase: classNames(
                switchClassName ? classes[switchClassName] : classes.switch,
                mini ? classes.miniSwitchBase : classes.switchBase
              ),
              thumb: classNames(classes.thumb, { [classes.miniThumb]: mini }),
              track: classes.track,
              checked: classes.checked,
            }}
            {...props}
          />
          {hasInlineLabel ? (
            <div
              className={classNames(classes.inlineLabel, {
                [classes.inlineLabelOn]: props.checked,
                [classes.inlineLabelOff]: !props.checked,
                [classes.inlineLabelDisabled]: disabled,
              })}
              aria-hidden="true"
            >
              {props.checked ? inlineOnLabel : inlineOffLabel}
            </div>
          ) : null}
        </div>
      }
    />
  );
};

Switch.propTypes = {
  mini: PropTypes.any,
  label: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  inlineOnLabel: PropTypes.any,
  inlineOffLabel: PropTypes.any,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  labelClass: PropTypes.string,
  labelPlacement: PropTypes.oneOf(['bottom', 'end', 'start', 'top']),
  rootClass: PropTypes.string,
  switchClassName: PropTypes.string,
};

export default Switch;
