export default {
  API_BASE_URL: 'https://api.hozzaferheto.turizmusakademia.hu/api',
  STORAGE_BASE_URL: 'https://api.hozzaferheto.turizmusakademia.hu',
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyAWVEAfhFvmAMr-usIt6jB5-wS4yMQCPAM',
    authDomain: 'mtu-e-learning.firebaseapp.com',
    projectId: 'mtu-e-learning',
    storageBucket: 'mtu-e-learning.appspot.com',
    messagingSenderId: '737227343440',
    appId: '1:737227343440:web:139b7da002f13423b3b355',
    databaseURL: 'https://mtu-e-learning.firebaseio.com',
    measurementId: '',
  },
};
