import { auto, bold, center, flex, flexEnd, grid } from 'constants/Jss';

export const badgesPageStyles = (theme) => ({
  BadgesPageContainer: {
    width: '100%',
    color: theme.palette.blackTitle,
  },
  BadgeContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.hiddenImages)]: {
      marginBottom: theme.spacing(8),
    },
  },
  BadgeBox: {
    display: grid,
    'grid-template-columns': 'repeat(auto-fill, minmax(230px, 1fr))',
    [theme.breakpoints.down(700)]: {
      'grid-template-columns': 'repeat(auto-fill, minmax(134px, 1fr))',
    },
  },
  Badge: {
    textAlign: center,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down(440)]: {
      '&:nth-child(2n + 1)': {
        paddingLeft: 0,
        paddingRight: theme.spacing(1),
      },
      '&:nth-child(2n)': {
        paddingLeft: theme.spacing(1),
        paddingRight: 0,
      },
    },
  },
  BadgeImageBox: {
    width: '100%',
    height: 180,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 115,
    },
    display: flex,
    alignItems: flexEnd,
    justifyContent: center,
  },
  BadgeImage: {
    width: 180,
    height: auto,
    [theme.breakpoints.down('xs')]: {
      width: 115,
      height: auto,
    },
  },
  BadgeTitle: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: bold,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      marginTop: theme.spacing(1),
    },
  },
  BadgeDescription: {
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
});
