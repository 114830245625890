import HomePage from 'modules/HomePage';
import CoursesPage from 'modules/CoursesPage';
import ExamsPage from 'modules/ExamsPage';
import AboutPage from 'modules/AboutPage';
import KnowledgeStorePage from 'modules/KnowledgeStorePage';
import SearchPage from 'modules/SearchPage';
import TopListPage from 'modules/TopListPage';
import ProfilePage from 'modules/ProfilePage';
import BadgesPage from 'modules/BadgesPage';
import CertificatesPage from 'modules/CertificatesPage';
import CertificatePage from 'modules/CertificatePage';
import VerifyEmail from 'modules/VerifyEmail/VerifyEmail';
import CourseDetailPage from 'modules/CourseDetailPage';
import LessonDetailPage from 'modules/LessonDetailPage';
import TestPage from 'modules/TestPage';
import ExamPage from 'modules/ExamPage';
import NotFound from 'components/NotFound';
import Page from 'components/Page/Page';
import { Roles } from 'constants/Constants';
import withSSR from 'utils/withSSR';

const routes = [
  {
    path: '/hu',
    component: withSSR(Page(HomePage, 'HomePage')),
    linkName: 'Főoldal',
    exact: true,
  },
  {
    path: '/hu/kurzusaim',
    component: withSSR(Page(CoursesPage, 'CoursesPage', 'Kurzusaim')),
    linkName: 'Kurzusaim',
    exact: true,
    authorizedRoles: [Roles.User],
  },
  {
    path: '/hu/temak/:id/kurzusok/:id',
    component: withSSR(Page(CourseDetailPage, 'CourseDetailPage')),
    exact: true,
  },
  {
    path: '/hu/temak/:id/kurzusok/:id/vizsga',
    component: withSSR(Page(ExamPage, 'ExamPage')),
    exact: true,
    authorizedRoles: [Roles.User],
  },
  {
    path: '/hu/temak/:id/kurzusok/:id/leckek/:id',
    component: withSSR(Page(LessonDetailPage, 'LessonDetailPage')),
    exact: true,
    authorizedRoles: [Roles.User],
  },
  {
    path: '/hu/temak/:id/kurzusok/:id/leckek/:id/teszt',
    component: withSSR(Page(TestPage, 'TestPage')),
    exact: true,
    authorizedRoles: [Roles.User],
  },
  {
    path: '/hu/vizsgaim',
    component: withSSR(Page(ExamsPage, 'ExamsPage', 'Vizsgáim')),
    linkName: 'Vizsgáim',
    exact: true,
    authorizedRoles: [Roles.User],
  },
  {
    path: '/hu/rolunk',
    component: withSSR(Page(AboutPage, 'AboutPage', 'Rólunk')),
    linkName: 'Rólunk',
    exact: true,
  },
  {
    path: '/hu/tudastar',
    component: withSSR(Page(KnowledgeStorePage, 'KnowledgeStorePage', 'Tudástár')),
    linkName: 'Tudástár',
    exact: true,
  },
  {
    path: '/hu/kereses',
    component: withSSR(Page(SearchPage, 'SearchPage', 'Keresés')),
    linkName: 'Keresés',
    exact: true,
  },
  {
    path: '/hu/profilom',
    component: withSSR(Page(ProfilePage, 'ProfilePage', 'Profilom')),
    linkName: 'Profilom',
    exact: true,
    authorizedRoles: [Roles.User],
  },
  {
    path: '/hu/jelvenyeim',
    component: withSSR(Page(BadgesPage, 'BadgesPage', 'Jelvényeim')),
    linkName: 'Jelvényeim',
    exact: true,
    authorizedRoles: [Roles.User],
  },
  {
    path: '/hu/tanusitvanyaim',
    component: withSSR(Page(CertificatesPage, 'CertificatesPage', 'Tanúsítványaim')),
    linkName: 'Tanúsítványaim',
    exact: true,
    authorizedRoles: [Roles.User],
  },
  {
    path: '/hu/ranglista',
    component: withSSR(Page(TopListPage, 'TopListPage', 'Ranglista')),
    linkName: 'Ranglista',
    exact: true,
    authorizedRoles: [Roles.User],
  },
  {
    path: '/hu/verify-email',
    exact: true,
    component: VerifyEmail,
  },
  {
    path: `/hu/404`,
    component: withSSR(Page(NotFound, 'NotFoundPage')),
    status: 404,
    exact: true,
  },
  {
    path: '/hu/tanusitvanyaim/:uuid',
    component: withSSR(Page(CertificatePage, 'CertificatePage')),
    exact: true,
  },
  {
    redirect: true,
    to: '/hu/404',
  },
];

export default routes;
