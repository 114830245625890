import {
  absolute,
  auto,
  block,
  bold,
  center,
  column,
  flex,
  flexEnd,
  none,
  noRepeat,
  normal,
  nowrap,
  start,
} from 'constants/Jss';
import { testPageStyles } from '../TestPage/styles';

export const examPageStyles = (theme) => ({
  ...testPageStyles(theme),
  ClosingContent: {
    backgroundImage:
      `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(0,158,221)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
      `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(185,217,136)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
    backgroundPosition: '-281px -160px, calc(100% + 221px) 100%',
    backgroundSize: '500px 445.066px, 500px 445.066px',
    backgroundRepeat: `${noRepeat}, ${noRepeat}`,
    textAlign: center,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: none,
    },
  },
  ExamFooter: {
    width: '100%',
    height: theme.mixins.testPage.footerHeight,
    minHeight: theme.mixins.testPage.footerHeight,
    backgroundColor: theme.palette.greyF2,
    border: 0,
    borderTop: `4px solid ${theme.palette.bluishGrey}`,
    display: flex,
    alignItems: center,
    justifyContent: center,
    animation: none,
    borderRadius: 0,
    margin: '0 !important',
    [theme.breakpoints.down('sm')]: {
      height: theme.mixins.testPage.mobileFooterHeight,
      minHeight: theme.mixins.testPage.mobileFooterHeight,
      position: 'sticky',
      bottom: 0,
      '&.IOs': {
        position: '-webkit-sticky',
      },
    },
    '&.Accessible': {
      backgroundColor: theme.palette.white,
    },
  },
  PatchResult: {
    position: absolute,
    width: 120,
    textAlign: center,
    top: 'calc(50% - 30px)',
    right: 12,
    color: theme.palette.white,
    fontFamily: 'Montserrat',
    fontWeight: bold,
    '& .value': {
      fontSize: 48,
      lineHeight: 1,
    },
    '& .percentage': {
      fontSize: 18,
    },
    [theme.breakpoints.down('sm')]: {
      width: 52,
      top: 'calc(50% - 20px)',
      '& .value': {
        fontSize: 21,
      },
      '& .percentage': {
        fontSize: 14,
      },
    },
  },
  EndExamTitle: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  ManyBadgeTextContainer: {
    width: '100%',
    display: flex,
    flexDirection: column,
    alignItems: center,
  },
  BadgeBorder: {
    width: 190,
    height: 3,
    backgroundColor: theme.palette.lightBlue,
  },
  Congratulations: {
    color: theme.palette.lightPink,
    fontWeight: bold,
  },
  ProfileLink: {
    fontWeight: normal,
  },
  BadgeBox: {
    display: flex,
    justifyContent: center,
    alignItems: center,
    [theme.breakpoints.down('sm')]: {
      flexDirection: column,
    },
  },
  BadgeImageBox: {
    width: '100%',
    height: 240,
    [theme.breakpoints.down('sm')]: {
      height: 180,
    },
    [theme.breakpoints.down('xs')]: {
      height: 115,
    },
    display: flex,
    alignItems: flexEnd,
    justifyContent: center,
  },
  BadgeImage: {
    width: 240,
    height: auto,
    [theme.breakpoints.down('sm')]: {
      width: 180,
    },
    [theme.breakpoints.down('xs')]: {
      width: 115,
    },
  },
  BadgeTitle: {
    fontSize: 55,
    color: theme.palette.blue1C,
    fontWeight: bold,
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
    },
  },
  BadgeSubTitle: {
    fontSize: 25,
    color: theme.palette.blackTitle,
    marginBottom: theme.spacing(1),
    fontWeight: bold,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  BadgeDescription: {
    fontSize: 21,
    color: theme.palette.blackTitle,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  CertificateContainer: {
    display: flex,
    justifyContent: center,
  },
  CertificateBox: {
    display: flex,
    justifyContent: center,
    alignItems: center,
    background: theme.palette.greenSuccess,
    borderRadius: 10,
    padding: 30,
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px',
      padding: 15,
      display: block,
    },
  },
  CertificateText: {
    marginLeft: 10,
    textAlign: start,
    color: theme.palette.white,
    lineHeight: 1.6,
    [theme.breakpoints.down('sm')]: {
      textAlign: center,
      marginTop: 20,
      marginLeft: 0,
      padding: '0 10px',
    },
  },
  CertificatesLink: {
    color: theme.palette.white,
    fontWeight: bold,
    whiteSpace: nowrap,
  },
});
