import * as ExamService from 'services/exams';
import {
  GET_EXAM,
  GET_EXAM_FAILED,
  GET_EXAM_SUCCESS,
  VALIDATE_EXAM,
  VALIDATE_EXAM_FAILED,
  VALIDATE_EXAM_SUCCESS,
} from './types';

export const getExam = (courseId, language, accessibility) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_EXAM });

      const data = await ExamService.getExam(courseId, language, accessibility);

      dispatch({
        type: GET_EXAM_SUCCESS,
        payload: data,
      });

      return !!data.questions;
    } catch (error) {
      dispatch({ type: GET_EXAM_FAILED });
      throw error;
    }
  };
};

export const validateExam = (courseId, answers) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VALIDATE_EXAM });

      const data = await ExamService.validateExam(courseId, answers);

      dispatch({
        type: VALIDATE_EXAM_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({ type: VALIDATE_EXAM_FAILED });
      throw error;
    }
  };
};
