import getArraySortComparer from 'utils/getArraySortComparer';
import {
  BOOKMARK_COURSE,
  BOOKMARK_COURSE_FAILED,
  BOOKMARK_COURSE_SUCCESS,
  GET_BOOKMARKED_COURSES,
  GET_BOOKMARKED_COURSES_FAILED,
  GET_BOOKMARKED_COURSES_SUCCESS,
  GET_COURSE_BY_ID,
  GET_COURSE_BY_ID_FAILED,
  GET_COURSE_BY_ID_SUCCESS,
  GET_COURSES,
  GET_COURSES_FAILED,
  GET_COURSES_FOR_TOPIC,
  GET_COURSES_FOR_TOPIC_FAILED,
  GET_COURSES_FOR_TOPIC_SUCCESS,
  GET_COURSES_SUCCESS,
  GET_COURSES_WITH_EXAMS,
  GET_COURSES_WITH_EXAMS_FAILED,
  GET_COURSES_WITH_EXAMS_SUCCESS,
  GET_ONGOING_COURSES,
  GET_ONGOING_COURSES_FAILED,
  GET_ONGOING_COURSES_SUCCESS,
  LOAD_MORE_BOOKMARKED_COURSES,
  LOAD_MORE_BOOKMARKED_COURSES_FAILED,
  LOAD_MORE_BOOKMARKED_COURSES_SUCCESS,
  LOAD_MORE_COURSES,
  LOAD_MORE_COURSES_FAILED,
  LOAD_MORE_COURSES_SUCCESS,
  LOAD_MORE_COURSES_WITH_EXAMS,
  LOAD_MORE_COURSES_WITH_EXAMS_FAILED,
  LOAD_MORE_COURSES_WITH_EXAMS_SUCCESS,
  LOAD_MORE_ONGOING_COURSES,
  LOAD_MORE_ONGOING_COURSES_FAILED,
  LOAD_MORE_ONGOING_COURSES_SUCCESS,
  RESET_BOOKMARKED_COURSES,
  RESET_COURSES,
  RESET_COURSES_WITH_EXAMS,
  RESET_ONGOING_COURSES,
  SET_FILTERS,
  SET_SORT_BY,
} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  courses: {
    count: 0,
    data: [],
  },
  filters: {
    tags: [],
    examDone: null,
    duration: null,
    searchString: null,
  },
  sortBy: 'title',
  course: null,
  coursesWithExams: {
    count: 0,
    data: [],
  },
  ongoingCourses: {
    count: 0,
    data: [],
  },
  bookmarkedCourses: {
    count: 0,
    data: [],
  },
};

const reduceCoursesState = (coursesState, updatedCourse) => {
  const { count, data } = coursesState;
  const nextData = reduceCourses(data, updatedCourse);
  return nextData === data ? coursesState : { count, data: nextData };
};

export const reduceCourses = (courses, { id, isBookmarked }) => {
  return courses.find((course) => course.id === id)
    ? courses.map((course) => (course.id === id ? { ...course, isBookmarked } : course))
    : courses;
};

const reduceBookmarkedCoursesState = (coursesState, updatedCourse) => {
  const { count, data } = coursesState;
  const nextCount = updatedCourse.isBookmarked ? count + 1 : count - 1;

  const index = data.findIndex(({ id }) => id === updatedCourse.id);
  const contains = index >= 0;

  if (contains === updatedCourse.isBookmarked) {
    return { count: nextCount, data };
  }

  const nextData = [...data];
  if (contains) {
    nextData.splice(index, 1);
  } else {
    nextData.push(updatedCourse);
    if (nextData.length < nextCount) {
      nextData.sort(getArraySortComparer(nextData, 'title')).pop();
    }
  }
  return { count: nextCount, data: nextData };
};

const reduceLoadMoreBookmarkedCoursesSuccess = (state, { count, data }) => {
  const nextData = [...state.bookmarkedCourses.data];
  data.forEach((course) => {
    const foundIndex = nextData.findIndex(({ id }) => id === course.id);
    if (foundIndex >= 0) {
      nextData[foundIndex] = course;
    } else {
      nextData.push(course);
    }
  });
  return {
    ...state,
    loading: false,
    bookmarkedCourses: {
      count,
      data: nextData,
    },
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COURSES:
      return {
        ...state,
        loading: true,
      };
    case GET_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: action.payload,
      };
    case GET_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_COURSES_FOR_TOPIC:
      return {
        ...state,
        loading: true,
      };
    case GET_COURSES_FOR_TOPIC_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: action.payload,
      };
    case GET_COURSES_FOR_TOPIC_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOAD_MORE_COURSES:
      return {
        ...state,
        loading: true,
      };
    case LOAD_MORE_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: {
          count: action.payload.count,
          data: [...state.courses.data, ...action.payload.data],
        },
      };
    case LOAD_MORE_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_COURSES:
      return {
        ...state,
        courses: {
          count: 0,
          data: [],
        },
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: {
          tags: action.payload.tags || state.filters.tags,
          examDone:
            action.payload.examDone === true || action.payload.examDone === false || action.payload.examDone === null
              ? action.payload.examDone
              : state.filters.examDone,
          duration:
            action.payload.duration || action.payload.duration === null
              ? action.payload.duration
              : state.filters.duration,
          searchString:
            action.payload.searchString || action.payload.searchString === null
              ? action.payload.searchString
              : state.filters.searchString,
        },
      };
    case SET_SORT_BY:
      return {
        ...state,
        sortBy: action.payload,
      };
    case GET_COURSE_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_COURSE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: reduceCoursesState(state.courses, action.payload),
        course: action.payload,
        coursesWithExams: reduceCoursesState(state.coursesWithExams, action.payload),
        ongoingCourses: reduceCoursesState(state.ongoingCourses, action.payload),
        bookmarkedCourses: reduceBookmarkedCoursesState(state.bookmarkedCourses, action.payload),
      };
    case GET_COURSE_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_COURSES_WITH_EXAMS:
      return {
        ...state,
        loading: true,
      };
    case GET_COURSES_WITH_EXAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        coursesWithExams: action.payload,
      };
    case GET_COURSES_WITH_EXAMS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_COURSES_WITH_EXAMS:
      return {
        ...state,
        coursesWithExams: {
          count: 0,
          data: [],
        },
      };
    case LOAD_MORE_COURSES_WITH_EXAMS:
      return {
        ...state,
        loading: true,
      };
    case LOAD_MORE_COURSES_WITH_EXAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        coursesWithExams: {
          count: action.payload.count,
          data: [...state.coursesWithExams.data, ...action.payload.data],
        },
      };
    case LOAD_MORE_COURSES_WITH_EXAMS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ONGOING_COURSES:
      return {
        ...state,
        loading: true,
      };
    case GET_ONGOING_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        ongoingCourses: action.payload,
      };
    case GET_ONGOING_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_ONGOING_COURSES:
      return {
        ...state,
        ongoingCourses: {
          count: 0,
          data: [],
        },
      };
    case LOAD_MORE_ONGOING_COURSES:
      return {
        ...state,
        loading: true,
      };
    case LOAD_MORE_ONGOING_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        ongoingCourses: {
          count: action.payload.count,
          data: [...state.ongoingCourses.data, ...action.payload.data],
        },
      };
    case LOAD_MORE_ONGOING_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case BOOKMARK_COURSE:
      return {
        ...state,
        loading: true,
      };
    case BOOKMARK_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case BOOKMARK_COURSE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_BOOKMARKED_COURSES:
      return {
        ...state,
        loading: true,
      };
    case GET_BOOKMARKED_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        bookmarkedCourses: action.payload,
      };
    case GET_BOOKMARKED_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_BOOKMARKED_COURSES:
      return {
        ...state,
        bookmarkedCourses: {
          count: 0,
          data: [],
        },
      };
    case LOAD_MORE_BOOKMARKED_COURSES:
      return {
        ...state,
        loading: true,
      };
    case LOAD_MORE_BOOKMARKED_COURSES_SUCCESS:
      return reduceLoadMoreBookmarkedCoursesSuccess(state, action.payload);
    case LOAD_MORE_BOOKMARKED_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
