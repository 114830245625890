import request from 'utils/request';
import { Methods } from 'utils/http';

const BASE_URL = '/tags';

export const getTags = async (language) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}`,
    language,
  });
};

export const getProfileTags = async (language, token) => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/search?type=1`,
    language,
  };
  if (token) {
    requestData.headers = { Authorization: token };
  }
  return await request(requestData);
};
