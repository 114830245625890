import { decode, encode } from './base64';
import Settings from 'env';
import { isBrowser } from './browser';

export const getStorageItem = (key) => {
  const value = window.localStorage.getItem(key);
  return decode(value);
};

export const setStorageItem = (key, value) => {
  window.localStorage.setItem(key, encode(value));
};

export const removeStorageItem = (key) => {
  window.localStorage.removeItem(key);
};

const getItem = (key, defaultValue) => {
  const valueString = isBrowser() ? localStorage.getItem(key) : null;
  return valueString === null ? defaultValue : JSON.parse(valueString);
};

const setItem = (key, value) => {
  if (isBrowser()) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const getHomePageTutorialReady = () => {
  return getItem(Settings.HOME_PAGE_TUTORIAL_READY, false);
};

export const setHomePageTutorialReady = () => {
  setItem(Settings.HOME_PAGE_TUTORIAL_READY, true);
};

export const getTopicsPageTutorialReady = () => {
  return getItem(Settings.TOPICS_PAGE_TUTORIAL_READY, false);
};

export const setTopicsPageTutorialReady = () => {
  setItem(Settings.TOPICS_PAGE_TUTORIAL_READY, true);
};

export const getCoursePageTutorialReady = () => {
  return getItem(Settings.COURSE_PAGE_TUTORIAL_READY, false);
};

export const setCoursePageTutorialReady = () => {
  setItem(Settings.COURSE_PAGE_TUTORIAL_READY, true);
};
