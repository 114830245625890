import React, { Component } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

export const withWindowWidth = (WrappedComponent) => {
  class WithWindowWidth extends Component {
    constructor() {
      super();
      this.state = {
        width: 0,
      };
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleWindowSizeChange);
      this.setState({ width: window.innerWidth });
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
      this.setState({ width: window.innerWidth });
    };

    render() {
      return <WrappedComponent windowWidth={this.state.width} {...this.props} />;
    }
  }
  hoistNonReactStatic(WithWindowWidth, WrappedComponent);
  return WithWindowWidth;
};
