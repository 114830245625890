import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  absolute,
  bold,
  center,
  column,
  cover,
  ellipsis,
  flex,
  flexEnd,
  flexStart,
  hidden,
  none,
  noRepeat,
  nowrap,
  pointer,
  relative,
  spaceBetween,
  uppercase,
  vertical,
  wrap,
} from 'constants/Jss';

export const useCourseCardStyles = makeStyles((theme) => ({
  CourseCard: {
    width: 250,
    position: relative,
    boxShadow: '0px 5px 20px #00000019',
    '&:hover': {
      boxShadow: '0px 5px 20px #00000041',
    },
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    height: 400,
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  ImageContainer: {
    position: relative,
    width: '100%',
    height: 150,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  Image: {
    overflowY: hidden,
    display: flex,
    width: '100%',
    height: 150,
    cursor: pointer,
  },
  InfoContainer: {
    padding: theme.spacing(2),
    display: flex,
    flexDirection: column,
    width: '100%',
    height: 250,
  },
  SymbolContainer: {
    display: flex,
    justifyContent: spaceBetween,
    fontSize: 12,
  },
  TimeIndicator: {
    display: flex,
    alignItems: center,

    '& svg': {
      height: '14px !important',
    },
  },
  ClockIcon: {
    width: 14,
    height: 14,
  },
  TitleContainer: {
    fontSize: 18,
    fontWeight: bold,
    overflow: hidden,
    textOverflow: ellipsis,
    color: theme.palette.grey70,
    display: '-webkit-box',
    '-webkit-box-orient': vertical,
    '-webkit-line-clamp': 3,
    cursor: pointer,
  },
  TagContainer: {
    flexGrow: 1,
    display: flex,
    flexWrap: wrap,
    alignItems: flexEnd,
    alignContent: flexEnd,
  },
  Tag: {
    fontSize: 12,
    backgroundColor: theme.palette.greyF2,
    borderRadius: 3,
    padding: '3px 10px',
    whiteSpace: nowrap,
    maxWidth: 182,
    overflowX: hidden,
    textOverflow: ellipsis,
  },
  CourseImage: {
    width: '100%',
    backgroundSize: cover,
    backgroundRepeat: noRepeat,
  },
  CourseDonePatch: {
    position: absolute,
    top: 0,
    right: 0,
    backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(252, 196, 128)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
    '&.Success': {
      backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(185, 217, 136)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
    },
    backgroundSize: '141.55px 126px',
    backgroundPosition: 'calc(100% + 64px) 8px',
    backgroundRepeat: noRepeat,
    width: 80,
    height: 150,
    display: flex,
    flexDirection: column,
    alignItems: flexEnd,
    justifyContent: center,
    paddingRight: theme.spacing(1),
  },
  ExamDoneText: {
    fontFamily: 'Montserrat',
    fontSize: 6,
    fontWeight: bold,
    textTransform: uppercase,
  },
  ExamDoneValue: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: bold,
    lineHeight: 1.15,
  },
}));

export const useMobileCourseCardStyles = makeStyles((theme) => ({
  CourseCard: {
    position: relative,
    boxShadow: '0px 5px 20px #00000019',
    '&:hover': {
      boxShadow: '0px 5px 20px #00000041',
    },
    backgroundColor: theme.palette.white,
    height: 162,
    padding: theme.spacing(1),
    paddingTop: 38,
    display: flex,
    flexDirection: column,
    cursor: pointer,
    [theme.breakpoints.up('md')]: {
      display: none,
    },
  },
  ImageAndTitleContainer: {
    display: flex,
  },
  ImageWrapper: {
    paddingBottom: 15,
    cursor: pointer,
  },
  ImageContainer: {
    width: 65,
    height: 65,
    minWidth: 65,
    backgroundPosition: center,
    backgroundSize: cover,
    backgroundRepeat: noRepeat,
  },
  MobileImage: {
    width: '100%',
    height: 65,
  },
  TitleWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: flex,
    flexDirection: column,
    justifyContent: spaceBetween,
    flexGrow: 1,
    height: 80,
  },
  TitleContainer: {
    fontSize: 14,
    fontWeight: bold,
    overflow: hidden,
    textOverflow: ellipsis,
    color: theme.palette.grey70,
    display: '-webkit-box',
    '-webkit-box-orient': vertical,
    '-webkit-line-clamp': 4,
    lineHeight: '19px',
  },
  TagContainer: {
    width: '100%',
    display: flex,
    flexWrap: wrap,
    flexGrow: 1,
    alignItems: center,
    alignContent: center,
    justifyContent: flexStart,
    overflow: hidden,
  },
  Tag: {
    maxWidth: 'calc(50% - 21px)',
  },
  CourseDonePatch: {
    position: absolute,
    top: 0,
    right: 0,
    backgroundColor: theme.palette.yellow,
    '&.Success': {
      backgroundColor: theme.palette.greenSuccessHover,
    },
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    width: 60,
    height: 30,
    display: flex,
    flexDirection: column,
    alignItems: center,
    justifyContent: center,
    paddingLeft: 10,
  },
  ExamDoneText: {
    fontFamily: 'Montserrat',
    fontSize: 6,
    fontWeight: bold,
    textTransform: uppercase,
  },
  ExamDoneValue: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: bold,
    lineHeight: '18px',
  },
  TimeIndicator: {
    fontSize: 12,
    position: absolute,
    top: 0,
    left: theme.spacing(1),
    height: 30,
    display: flex,
  },
  HeartIconContainer: {
    fontSize: 12,
    position: absolute,
    top: 0,
    height: 30,
    display: flex,
    '&.Center': {
      right: 'calc(50% - 10px)',
    },
    '&:not(.Center)': {
      right: theme.spacing(1),
    },
  },
}));
