export const getMaxElementSize = (elementList) => {
  const size = { width: 0, height: 0 };
  if (elementList instanceof NodeList) {
    elementList.forEach((element) => {
      const rect = element.getBoundingClientRect();
      if (rect.height > size.height) {
        size.height = rect.height;
      }
      if (rect.width > size.width) {
        size.width = rect.width;
      }
    });
  }
  return size;
};
