import { GET_TEST, GET_TEST_SUCCESS, GET_TEST_FAILED } from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  test: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        test: action.payload,
      };
    case GET_TEST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
