import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  absolute,
  auto,
  center,
  column,
  fixed,
  flex,
  flexEnd,
  none,
  noRepeat,
  pointer,
  relative,
  right,
  spaceBetween,
  wrap,
  unset,
  flexStart,
  left,
} from 'constants/Jss';
import logo from 'assets/images/mtuBlue.png';

export const useFooterStyles = makeStyles((theme) => ({
  Footer: {
    background: theme.palette.footerBlue,
    width: '100%',
    color: theme.palette.white,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(24),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: relative,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(20),
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 560,
      paddingBottom: theme.spacing(1.5),
    },
    '& a:hover': {
      color: theme.palette.lightBlue,
    },
    '& a:focus': {
      color: theme.palette.lightBlue,
    },
  },
  FooterBox: {
    display: 'flex',
    justifyContent: 'center',
    color: unset,
    width: unset,
    position: unset,
    background: unset,
    padding: unset,
    minHeight: unset,
  },
  ScrollToTop: {
    display: flex,
    opacity: 0,
    position: fixed,
    bottom: 474,
    left: 0,
    width: theme.mixins.scrollToTop.size,
    height: theme.mixins.scrollToTop.size,
    borderRadius: theme.mixins.scrollToTop.size / 2,
    color: theme.palette.white,
    backgroundColor: theme.palette.lightPink,
    justifyContent: center,
    alignItems: center,
    cursor: pointer,
    '& svg': {
      width: (theme.mixins.scrollToTop.size / 3) * 2,
      height: (theme.mixins.scrollToTop.size / 3) * 2,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 0,
      left: 16,
      bottom: 16,
      width: theme.mixins.scrollToTop.mobileSize,
      height: theme.mixins.scrollToTop.mobileSize,
      borderRadius: theme.mixins.scrollToTop.mobileSize / 2,
      '& svg': {
        width: (theme.mixins.scrollToTop.mobileSize / 3) * 2,
        height: (theme.mixins.scrollToTop.mobileSize / 3) * 2,
      },
    },
    '&.Visible': {
      opacity: 1,
    },
    transition: 'opacity 0.3s ease-in-out',
    zIndex: 10,
  },
  MainContainer: {
    padding: 0,
    borderBottom: `1px solid ${theme.palette.white}`,
    display: flex,
    position: unset,
    backgroundColor: unset,
    opacity: unset,
    cursor: unset,
    height: unset,
    borderRadius: unset,
    alignItems: unset,
    justifyContent: unset,
    [theme.breakpoints.down('xs')]: {
      flexDirection: column,
    },
  },
  MTULogo: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(5),
      alignSelf: center,
    },
  },
  CopyRightContainer: {
    padding: 0,
  },
  GridContainer: {
    width: 550,
    height: 240,
    marginLeft: auto,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 340,
    },
  },
  FooterTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    width: unset,
    height: unset,
    marginLeft: unset,
  },
  FooterText: {
    fontSize: 12,
    lineHeight: 1.5,
    fontFamily: unset,
    '& a': {
      textDecoration: none,
      color: theme.palette.white,
    },
    '&:focus': {
      color: theme.palette.lightBlue,
    },
  },
  Column: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(6),
    },
  },
  LogoWrapper: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: absolute,
    top: -theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      top: -theme.spacing(19),
    },
  },
  LogoContainer: {
    marginLeft: auto,
    marginRight: auto,
    padding: 0,
    position: unset,
    top: unset,
  },
  LogoAndSocialBox: {
    display: flex,
    justifyContent: spaceBetween,
    minHeight: theme.spacing(5),
    padding: unset,
    marginLeft: unset,
    marginRight: unset,
    [theme.breakpoints.down('sm')]: {
      flexDirection: column,
      alignItems: center,
      justifyContent: center,
      minHeight: theme.spacing(17),
    },
  },
  LogoBox: {
    width: 240,
    height: auto,
    minHeight: theme.spacing(5),
    backgroundImage: `url(${logo})`,
    backgroundSize: '100%',
    backgroundRepeat: noRepeat,
  },
  SocialBox: {
    display: flex,
    width: unset,
    height: unset,
    minHeight: unset,
    backgroundSize: unset,
    backgroundImage: unset,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  Logo: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
    '& svg': {
      width: 32,
      height: 32,
    },
  },
  DesktopOnly: {
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  CopyRight: {
    fontSize: 12,
    width: '100%',
    textAlign: right,
    display: flex,
    justifyContent: flexEnd,
    flexWrap: wrap,
    '& a': {
      textDecoration: none,
      color: theme.palette.white,
    },
  },
  PreFormatted: {
    whiteSpace: 'pre',
    textAlign: left,
    justifyContent: flexStart,
  },
}));
