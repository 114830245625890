export const GET_LESSONS = 'GET_LESSONS';
export const GET_LESSONS_SUCCESS = 'GET_LESSONS_SUCCESS';
export const GET_LESSONS_FAILED = 'GET_LESSONS_FAILED';

export const GET_LESSON_BY_ID = 'GET_LESSON_BY_ID';
export const GET_LESSON_BY_ID_SUCCESS = 'GET_LESSON_BY_ID_SUCCESS';
export const GET_LESSON_BY_ID_FAILED = 'GET_LESSON_BY_ID_FAILED';

export const LOAD_MORE_LESSONS = 'LOAD_MORE_LESSONS';
export const LOAD_MORE_LESSONS_SUCCESS = 'LOAD_MORE_LESSONS_SUCCESS';
export const LOAD_MORE_LESSONS_FAILED = 'LOAD_MORE_LESSONS_FAILED';
