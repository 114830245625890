import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import HomePage from './HomePage';
import { getDashboard } from 'store/dashboard/actions';
import { getProfileDetails, logout } from 'store/profile/actions';
import { addCommonLoader, removeCommonLoader } from 'store/loader/actions';
import { selectIsLoggedIn } from 'store/auth/selectors';
import { setTutorialState } from 'store/tutorial/actions';

const mapStateToProps = (state) => {
  return {
    loading: state.dashboard.loading,
    dashboard: state.dashboard.dashboard,
    profileTags: state.profile.details.tags,
    id: state.profile.details.id,
    isLoggedIn: selectIsLoggedIn(state),
    tutorial: state.tutorial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDashboard,
      getProfileDetails,
      addCommonLoader,
      removeCommonLoader,
      logout,
      setTutorialState,
    },
    dispatch
  );
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(HomePage);
