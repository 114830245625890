import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';

import noImage from 'assets/images/no-image.png';
import noImageSquare from 'assets/images/no-image-square.png';

const useStyles = makeStyles(() => ({
  Image: {
    maxWidth: '100%',
  },
}));

const Image = ({ src, className, square, alt, ...props }) => {
  const [isError, setError] = useState(false);
  const classes = useStyles();

  return (
    <img
      src={isError || !src ? (square ? noImageSquare : noImage) : src}
      onError={() => setError(true)}
      className={classNames(classes.Image, className, { Error: isError })}
      alt={alt || ''}
      {...props}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  square: PropTypes.bool,
  alt: PropTypes.string,
};

export default Image;
