import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { EmptyBoxIcon } from 'utils/icons';
import { CheckBox } from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';

import { useCheckboxFilterStyles } from './styles';

export const ExamsFilter = ({ filter, setFilters }) => {
  const classes = useCheckboxFilterStyles();
  const { t } = useTranslation();

  const onChange = (examDone) => {
    setFilters({ examDone: filter === examDone ? null : examDone });
  };

  const icon = <EmptyBoxIcon />;
  const checkedIcon = <CheckBox role={undefined} />;

  return (
    <div className={classes.CheckboxFilterContainer}>
      <FormControlLabel
        control={
          <Checkbox
            role="menuitemcheckbox"
            icon={icon}
            checkedIcon={checkedIcon}
            checked={filter === true}
            onChange={() => onChange(true)}
          />
        }
        label={t('topics_page_exam_done')}
      />
      <FormControlLabel
        control={
          <Checkbox
            role="menuitemcheckbox"
            icon={icon}
            checkedIcon={checkedIcon}
            checked={filter === false}
            onChange={() => onChange(false)}
          />
        }
        label={t('topics_page_exam_to_be_done')}
      />
    </div>
  );
};

ExamsFilter.propTypes = {
  filter: PropTypes.bool,
  setFilters: PropTypes.func,
};
