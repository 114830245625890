import axios from 'axios';
import Settings from 'env';
import { Methods, STATUS } from './http';
import { logout } from 'store/profile/actions';
import { resetTimer } from 'store/auth/actions';
import { getAuthorizedRoutesForExport as getAuthorizedRoutes, store } from 'client';
import { getToken, removeToken } from './refreshToken';
import Cookies from 'js-cookie';
import { ADD_NOTIFICATION } from 'store/common/types';
import { NotificationTypes } from 'constants/Constants';
import { isBrowser } from './browser';
import * as Constants from 'constants/Constants';
import { redirectToLanguageRoot } from './routes';
import { removeCookies } from './removeCookies';

export const requestCreator = axios.create({});

requestCreator.interceptors.response.use(
  async (response) => response,
  async (err) => {
    const { response, config } = err || {};

    if (response && config) {
      const { error, message, errors } = response.data;

      if (
        response.status !== STATUS.BAD_REQUEST &&
        response.status !== STATUS.UNAUTHORIZED &&
        message !== Constants.ApiErrorMessages.TOKEN_EXPIRED
      ) {
        err.resolved = true;
        store.dispatch({
          type: ADD_NOTIFICATION,
          payload: {
            translateKey:
              error ||
              message ||
              `${response.status} ${response.statusText}` ||
              (errors && errors.length ? errors[0] : null),
            type: NotificationTypes.error,
          },
        });

        if (response.status === STATUS.INTERNAL_SERVER_ERROR) {
          removeCookies();
        }
      }

      if (response.status === STATUS.UNAUTHORIZED && isBrowser()) {
        err.resolved = true;
        if (config.url.includes(Settings.LOGIN_RESOURCE) || config.url.includes(Settings.SOCIAL_LOGIN_RESOURCE)) {
          store.dispatch({
            type: ADD_NOTIFICATION,
            payload: {
              translateKey: 'invalid_credentials',
              type: NotificationTypes.error,
            },
          });
        } else {
          removeToken();
          redirectToLanguageRoot();
        }
      }
    }

    return Promise.reject(err);
  }
);

export default async ({
  resource = '/',
  method = Methods.GET,
  data,
  headers,
  language,
  skipReset = false,
  baseUrl,
} = {}) => {
  const newHeaders = headers ? { ...headers } : {};
  const url = `${baseUrl || Settings.API_BASE_URL}${resource}`;
  let { token } = getToken();
  if (!token && newHeaders && newHeaders.Authorization) {
    token = newHeaders.Authorization;
  }
  if (!language) {
    language = Cookies.get(Settings.I18NEXT) ? Cookies.get(Settings.I18NEXT).toUpperCase() : 'HU';
  }
  if (token) {
    if (isBrowser() && !skipReset && !store.getState().auth.isStayLoggedIn) {
      store.dispatch(resetTimer());
    }
    if (store.getState().auth.isStayLoggedIn) {
      const exp = Cookies.get(Settings.API_TOKEN_EXPIRES_AT);
      const expDate = new Date(0);
      expDate.setUTCSeconds(exp);
      if (expDate.getTime() < Date.now() && resource !== '/auth/logout') {
        await store.dispatch(logout(getAuthorizedRoutes));
      }
    }

    newHeaders['Authorization'] = 'Bearer ' + token;
  }

  newHeaders['Accept-Language'] = language;

  const { data: response } = await requestCreator.request({
    method,
    url,
    data,
    headers: newHeaders,
    timeout: Constants.RequestTimeout,
  });
  return response;
};
