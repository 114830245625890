import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  absolute,
  auto,
  block,
  bold,
  breakWord,
  center,
  column,
  contain,
  ellipsis,
  flex,
  flexEnd,
  flexStart,
  grid,
  hidden,
  left,
  none,
  noRepeat,
  normal,
  pointer,
  relative,
  right,
  spaceBetween,
  vertical,
  visible,
  wrap,
} from 'constants/Jss';
import ProfileBg from 'assets/images/profileBg.png';

export const profilePageStyles = (theme) => ({
  ProfilePageContainer: {
    width: '100%',
    color: theme.palette.blackTitle,
  },
  BeginningOfLearningContainer: {
    position: absolute,
    top: theme.spacing(8),
    right: theme.spacing(8),
    display: flex,
    alignItems: center,
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(3),
      right: theme.spacing(2),
    },
  },
  BeginningOfLearningData: {
    lineHeight: 1,
    textAlign: right,
  },
  BeginningOfLearningLabel: {
    fontSize: 14,
  },
  BeginningOfLearningDate: {
    fontSize: 16,
    color: theme.palette.lightPink,
    fontWeight: bold,
  },
  CalendarIcon: {
    width: 33,
    height: 33,
  },
  ProfileDetailsContainer: {
    display: flex,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: column,
      alignItems: center,
      marginBottom: theme.spacing(8),
    },
  },
  XPBox: {
    display: flex,
    alignItems: flexEnd,
    justifyContent: flexStart,
    width: theme.mixins.xpBubble.size,
  },
  XPBubble: {
    display: flex,
    alignItems: center,
    justifyContent: center,
    width: theme.mixins.xpBubble.size,
    height: theme.mixins.xpBubble.size,
    borderRadius: '50%',
    color: theme.palette.white,
    backgroundColor: theme.palette.xpYellow,
  },
  XPLabel: {
    textAlign: center,
    fontSize: 25,
  },
  XPValueContainer: {
    display: flex,
    fontWeight: bold,
    alignItems: flexEnd,
    justifyContent: center,
  },
  XPValue: {
    fontSize: 31,
    lineHeight: '31px',
  },
  XPText: {
    fontSize: 18,
  },
  AvatarContainer: {
    display: flex,
    justifyContent: flexEnd,
    paddingTop: 53,
    paddingRight: 85,
    width: `calc(50% - ${theme.mixins.xpBubble.size / 2}px)`,
    height: 250,
    backgroundImage: `url(${ProfileBg})`,
    backgroundSize: contain,
    backgroundPosition: '100% center',
    backgroundRepeat: noRepeat,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      paddingRight: 0,
      paddingTop: 35,
      display: flex,
      alignItems: flexStart,
      justifyContent: center,
      width: 200,
      height: 175,
    },
  },
  ProfileDataContainer: {
    padding: theme.spacing(3),
    '&:not(.Mobile)': {
      width: `calc(50% - ${theme.mixins.xpBubble.size / 2}px)`,
      marginBottom: theme.mixins.xpBubble.size,
      [theme.breakpoints.down('sm')]: {
        display: none,
      },
    },
    '&.Mobile': {
      paddingLeft: 0,
      paddingRight: 0,
      display: flex,
      flexDirection: column,
      alignItems: center,
      [theme.breakpoints.up('md')]: {
        display: none,
      },
    },
  },
  Avatar: {
    backgroundColor: theme.palette.avatarGrey,
    width: 118,
    height: 118,
    '&.NoAvatar > .MuiAvatar-img': {
      width: '50%',
      height: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: 84,
      height: 84,
    },
  },
  NameContainer: {
    fontFamily: 'Montserrat',
    fontSize: 32,
    fontWeight: bold,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  GroupContainer: {
    fontSize: 18,
    wordWrap: 'anywhere',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  EmailContainer: {
    fontSize: 18,
    fontWeight: bold,
    color: theme.palette.lightPink,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  SectionTitleContainer: {
    fontSize: 42,
    fontWeight: bold,
    '& .SectionTitle': {
      paddingBottom: theme.spacing(1),
    },
  },
  PersonalDataContainer: {
    '& > div:first-child': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(5),
      },
    },
  },
  ChangePasswordContainer: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(5),
    },
  },
  DeleteProfileContainer: {
    width: '100%',
    height: 56,
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.greyCC}`,
    position: relative,
    [theme.breakpoints.down('sm')]: {
      justifyContent: center,
      marginBottom: theme.spacing(4),
    },
  },
  DeleteActionContainer: {
    position: absolute,
    right: 0,
    height: 31,
    display: flex,
    alignItems: center,
    cursor: pointer,
  },
  DeleteProfileText: {
    fontSize: 16,
  },
  DeleteProfileIcon: {
    fill: theme.palette.lightPink,
  },
  ProfileTagsBox: {
    display: block,
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  MobileProfileTagsBox: {
    display: none,
    [theme.breakpoints.down('sm')]: {
      display: block,
    },
  },
  MobilePatchContainer: {
    [theme.breakpoints.up('md')]: {
      display: none,
    },
    height: 240,
    '& .Patch': {
      position: 'absolute',
      width: 142,
      height: auto,
      '&.LeftPatch': {
        left: 0,
      },
      '&.RightPatch': {
        right: 0,
      },
    },
  },
});

export const useCommonStyles = makeStyles(() => ({
  SubmitNewPasswordContainer: {
    display: flex,
    justifyContent: center,
  },
}));

export const usePasswordChangeFormStyles = makeStyles((theme) => ({
  FormBox: {
    display: flex,
    alignItems: flexStart,
    justifyContent: spaceBetween,
    [theme.breakpoints.down('sm')]: {
      flexDirection: column,
      alignItems: center,
      marginBottom: theme.spacing(3),
      '& > div': {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  FullWidth: {
    width: '100%',
  },
  Patch: {
    position: 'absolute',
    marginTop: 60,
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  LeftPatch: {
    left: 0,
  },
  RightPatch: {
    right: 0,
  },
  SectionTitleContainer: profilePageStyles(theme).SectionTitleContainer,
}));

export const useProfileDataFormStyles = makeStyles((theme) => ({
  ClearIndicator: {
    '&:hover': {
      backgroundColor: theme.palette.white,
    },
    '&.Filled': {
      visibility: visible,
    },
    '& svg': {
      fill: theme.palette.avatarGrey,
    },
  },
  FileUploadBox: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      paddingBottom: theme.spacing(6),
    },
  },
  FormBox: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  GridBox: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  KKVBox: {
    display: flex,
    alignItems: center,
    fontSize: 16,
    fontWeight: normal,
  },
  ProfileSwitchLabel: {
    fontSize: 18,
    textAlign: left,
    marginLeft: theme.spacing(1),
  },
  StayLoggedInLabelRoot: {
    margin: `${theme.spacing(8)}px 0 ${theme.spacing(5)}px 0`,
  },
  AnonymLabelRoot: {
    margin: 0,
  },
  AnonymLabel: {
    width: 182,
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
  },
  Tooltip: {
    position: 'static !important',
  },
  GroupAutocomplete: {
    width: '100%',
  },
  GroupTooltip: {
    marginRight: theme.spacing(-3),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
}));

export const useTagsDialogStyles = makeStyles((theme) => ({
  TagsDialogContainer: {
    position: relative,
    '& ul': {
      paddingTop: theme.spacing(4),
      borderRadius: 4,
      color: theme.palette.white,
      backgroundColor: theme.palette.lightPink,
      marginTop: theme.spacing(1.25),
      position: relative,
      outline: none,
      maxWidth: 1108,
    },
    '& ul.Top': {
      marginTop: 0,
      marginBottom: theme.spacing(1.25),
    },
    '& > div': {
      zIndex: 10,
    },
  },
  AddTagContainer: {
    width: '100%',
    display: flex,
    justifyContent: spaceBetween,
    borderBottom: `1px solid ${theme.palette.blackTitle}`,
    paddingBottom: theme.spacing(1),
  },
  AddTagLabel: {
    fontSize: 18,
    fontWeight: bold,
    lineHeight: '36px',
  },
  AddTagButton: {
    borderRadius: '50%',
    width: 36,
    height: 36,
    minWidth: 36,
    padding: 0,
  },
  ProfileTagsContainer: {
    display: flex,
    flexWrap: wrap,
  },
  ProfileTag: {
    fontSize: 18,
    fontWeight: bold,
    lineHeight: 1,
    position: relative,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(7),
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.cyan,
    borderRadius: 36,
    color: theme.palette.white,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  ClearTag: {
    position: absolute,
    top: 'calc(50% - 6px)',
    right: 11,
    fill: theme.palette.white,
    padding: 0,
    '& svg': {
      width: 12,
      height: 12,
    },
  },
}));

export const useProfileTagStyles = makeStyles((theme) => ({
  TagContainer: {
    display: grid,
    maxHeight: 300,
    maxWidth: 'calc(100% - 32px)',
    overflowY: auto,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    'grid-template-columns': 'repeat(auto-fill, minmax(183px, 1fr))',
    '& .MuiFormControlLabel-label': {
      maxWidth: 160,
      fontSize: 16,
      wordBreak: breakWord,
      overflow: hidden,
      textOverflow: ellipsis,
      display: '-webkit-box',
      '-webkit-box-orient': vertical,
      '-webkit-line-clamp': 2,
    },
  },
}));

export const useDeleteProfileDialogStyles = makeStyles((theme) => ({
  PinkPatch: {
    backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
    backgroundPosition: '-286px -250px',
    backgroundSize: '500px 445.066px',
    backgroundRepeat: `${noRepeat}`,
    '& .TitleBorder': {
      backgroundColor: theme.palette.lightPink,
    },
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 360 365'><path fill='rgb(242,125,131)' d='M204.2,0c0,0,18,89.5-23,115s-81.6,2.2-111,61c-25.6,51.2-25,87.3-70,94.7C-0.2,163.6,0.1,0,0.1,0H204.2z'/></svg>")`,
      backgroundPosition: '-57px -95px',
      backgroundSize: '360px 365px',
      backgroundRepeat: noRepeat,
    },
  },
  DialogContent: {
    display: flex,
    flexDirection: column,
    justifyContent: spaceBetween,
    alignItems: center,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    textAlign: center,
    maxWidth: 700,
    marginLeft: auto,
    marginRight: auto,
  },
  ActionContainer: {
    display: flex,
    alignItems: center,
    justifyContent: center,
  },
  Center: {
    textAlign: center,
  },
}));
