import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useFilterStyles } from 'components/Filter/styles';

export const ProfileTagsDialog = ({ content, open, anchorRef, menuId, handleClose }) => {
  const classes = useFilterStyles();
  const { t } = useTranslation();
  return (
    <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
      {({ TransitionProps, placement }) => {
        return (
          <Grow {...TransitionProps}>
            <Paper role="application">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id={menuId} className={classNames({ Top: placement === 'top' })}>
                  {content}
                  <Box mt={1} mr={1} className={classes.CloseIconContainer} tabIndex={-1}>
                    <IconButton
                      className={classes.CloseIconButton}
                      onClick={handleClose}
                      tabIndex={0}
                      aria-label={t('common_close')}
                    >
                      <CloseIcon className={classes.CloseIcon} />
                    </IconButton>
                  </Box>
                  <Box mt={2} className={classes.CloseButtonContainer}>
                    <Button buttonClassName={classes.CloseButton} onClick={handleClose}>
                      {t('common_close')}
                    </Button>
                  </Box>
                  <div className={classNames(classes.Triangle, { Top: placement === 'top' })} />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        );
      }}
    </Popper>
  );
};

ProfileTagsDialog.propTypes = {
  content: PropTypes.node,
  open: PropTypes.bool,
  anchorRef: PropTypes.any,
  menuId: PropTypes.any,
  handleClose: PropTypes.func,
};
