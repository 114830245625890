import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';

import { useSearchField } from '../../useSearchField';
import { useDefaultStyles } from '../../styles';

export const DefaultSearchField = memo(
  forwardRef(({ ariaLabel, placeholder, submit, searchOnEmpty }, ref) =>
    useSearchField({
      customStyleFactory: useDefaultStyles,
      ariaLabel,
      placeholder,
      submit,
      searchOnEmpty,
      ref,
    })
  )
);

DefaultSearchField.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  searchOnEmpty: PropTypes.bool,
};
