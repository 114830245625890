import request from 'utils/request';
import { Methods } from 'utils/http';

const BASE_URL = '/scoreboard';

export const getTopList = async ({ language, page, userId, searchText, token }) => {
  if (!page && !userId) return null;
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/user?${page ? 'page=' + page : 'userId=' + userId}${
      searchText ? '&searchText=' + encodeURIComponent(searchText) : ''
    }`,
    language,
  };
  if (token) {
    requestData.headers = { Authorization: token };
  }
  return await request(requestData);
};

export const getTopListOfGroups = async ({ language, page, groupId, searchText, token }) => {
  if (!page && !groupId) return null;
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/group?${page ? 'page=' + page : 'groupId=' + groupId}${
      searchText ? '&searchText=' + encodeURIComponent(searchText) : ''
    }`,
    language,
  };
  if (token) {
    requestData.headers = { Authorization: token };
  }
  return await request(requestData);
};
