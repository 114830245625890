import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import { AuthProviderCodes } from 'constants/Constants';
import { Button, Dialog, SeparatorOr, FacebookButton, GoogleButton, AppleButton } from 'components';
import { usePatches, useRegisterDialogStyles, useSocialStyles } from './styles';

const RegisterMethodDialog = ({ open, closeDialog, onSelect }) => {
  const { t } = useTranslation();
  const classes = useRegisterDialogStyles();
  const socialSlasses = useSocialStyles();
  const patchClasses = usePatches();

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      title={t('menu_item_register')}
      titleClass={classes.Title}
      titleBoxClass={patchClasses.YellowPatch}
      content={
        <Box className={socialSlasses.SocialButtonsContainer} mb={10}>
          <FacebookButton
            onClick={() => onSelect(AuthProviderCodes.FACEBOOK)}
            type="button"
            tabIndex={0}
            aria-label={t('alt_register_button_facebook')}
            id="FacebookLoginButton"
          >
            {t('form_register_button_facebook_text')}
          </FacebookButton>
          <SeparatorOr />
          <AppleButton
            onClick={() => onSelect(AuthProviderCodes.APPLE)}
            type="button"
            tabIndex={0}
            aria-label={t('alt_register_button_apple')}
            id="AppleLoginButton"
          >
            {t('form_register_button_apple_text')}
          </AppleButton>
          <SeparatorOr />
          <GoogleButton
            onClick={() => onSelect(AuthProviderCodes.GOOGLE)}
            type="button"
            tabIndex={0}
            aria-label={t('alt_register_button_google')}
            id="GoogleLoginButton"
          >
            {t('form_register_button_google_text')}
          </GoogleButton>
          <SeparatorOr />
          <Button
            buttonClassName="DarkBlue"
            fullWidth={true}
            onClick={() => onSelect(AuthProviderCodes.EMAIL)}
            type="button"
            tabIndex={0}
            aria-label={t('alt_register_button_email')}
            id="EmailLoginButton"
          >
            {t('form_register_button_email_text')}
          </Button>
        </Box>
      }
    />
  );
};

RegisterMethodDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default RegisterMethodDialog;
