import React from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

import { Course } from 'types/types';
import { useCourseDetailPageStyles } from './styles';

export const Requirements = ({ course }) => {
  const { t } = useTranslation();
  const classes = useCourseDetailPageStyles();
  return !course.requirements ? null : (
    <>
      <Box mb={5} className="PageTitleContainer">
        <h2 className="Title" tabIndex={0}>{t('course_details_requirements')}</h2>
        <div className="TitleBorder" />
      </Box>
      <Box mb={12} className={classes.NarrowBox} tabIndex={0}>
        {course.requirements}
      </Box>
    </>
  );
};

Requirements.propTypes = {
  course: Course.isRequired,
};
