import { absolute, auto, none, pointer, relative, visible } from 'constants/Jss';

export const styles = (theme) => ({
  container: {
    position: relative,
  },
  root: {
    width: theme.mixins.switch.width,
    height: theme.mixins.switch.height,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      width: theme.mixins.switch.mobileWidth,
      height: theme.mixins.switch.mobileHeight,
    },
    '& .Mui-disabled': {
      '& + $track': {
        opacity: 0.5,
      },
      '&$checked': {
        color: theme.palette.switchInactiveThumb,
        opacity: 0.5,
      },
    },
  },
  miniRoot: {
    width: theme.mixins.switch.mini.width,
    height: theme.mixins.switch.mini.height,
    overflow: visible,
  },
  rootWithInlineLabel: {
    '&$root': {
      width: theme.mixins.switch.widthWithLabel,
      '& $switchBase': {
        '&$checked': {
          transform: `translateX(${theme.mixins.switch.widthWithLabel - theme.mixins.switch.height}px)`,
          [theme.breakpoints.down('sm')]: {
            transform: `translateX(${theme.mixins.switch.widthWithLabel - theme.mixins.switch.mobileHeight}px)`,
          },
        },
      },
    },
    '&$miniRoot': {
      width: theme.mixins.switch.mini.widthWithLabel,
      '& $miniSwitchBase': {
        '&$checked': {
          transform: `translateX(${theme.mixins.switch.mini.widthWithLabel - theme.mixins.switch.mini.height}px)`,
        },
      },
    },
  },
  switchBase: {
    padding: 0,
    '&$checked': {
      transform: `translateX(${theme.mixins.switch.width - theme.mixins.switch.height}px)`,
    },
    [theme.breakpoints.down('sm')]: {
      '&$checked': {
        transform: `translateX(${theme.mixins.switch.mobileHeight}px)`,
      },
    },
  },
  miniSwitchBase: {
    top: 1,
    left: 1,
    padding: 0,
    '&$checked': {
      transform: `translateX(${theme.mixins.switch.mini.width - theme.mixins.switch.mini.height}px)`,
    },
  },
  switch: {
    '&$checked': {
      color: `${theme.palette.switchOnThumb} !important`,
      '& + .MuiSwitch-track': {
        backgroundColor: `${theme.palette.switchOnTrack} !important`,
      },
    },
    '&:not($checked)': {
      color: `${theme.palette.switchOffThumb} !important`,
      '& + .MuiSwitch-track': {
        backgroundColor: `${theme.palette.switchOffTrack} !important`,
      },
    },
  },
  filterSwitch: {
    '&$checked': {
      color: `${theme.palette.lightPink} !important`,
      '& + .MuiSwitch-track': {
        backgroundColor: `${theme.palette.white} !important`,
      },
    },
    '&:not($checked)': {
      color: `${theme.palette.white} !important`,
      '& + .MuiSwitch-track': {
        backgroundColor: `${theme.palette.greyD9} !important`,
      },
    },
  },
  thumb: {
    width: theme.mixins.switch.height,
    height: theme.mixins.switch.height,
    boxShadow: 'none !important',
    [theme.breakpoints.down('sm')]: {
      width: theme.mixins.switch.mobileHeight,
      height: theme.mixins.switch.mobileHeight,
    },
  },
  miniThumb: {
    width: theme.mixins.switch.mini.height - 2,
    height: theme.mixins.switch.mini.height - 2,
    [theme.breakpoints.down('sm')]: {
      width: theme.mixins.switch.mini.height - 2,
      height: theme.mixins.switch.mini.height - 2,
    },
  },
  track: {
    borderRadius: 19,
    border: `1px solid ${theme.palette.searchBg}`,
    opacity: '1 !important',
  },
  inlineLabel: {
    position: absolute,
    lineHeight: '24px',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: pointer,
    userSelect: none,
    fontSize: 13,
  },
  inlineLabelOn: {
    left: theme.spacing(1.5),
    color: theme.palette.switchOnLabel,
  },
  inlineLabelOff: {
    right: theme.spacing(1.5),
    color: theme.palette.switchOffLabel,
  },
  inlineLabelDisabled: {
    opacity: 0.5,
  },
  formControlLabelPlacementStart: {
    marginLeft: 0,
    marginRight: 0,
  },
  formControlLabel: {
    marginRight: theme.spacing(2),
    fontSize: 16,
    lineHeight: 1,
    color: theme.palette.primary.main,
  },
  checked: {},
  focusVisible: {
    outlineColor: theme.palette.focusColor,
    outlineStyle: auto,
    outlineWidth: 1,
    outlineOffset: 4,
  },
});
