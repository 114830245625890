import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Container from '@material-ui/core/Container';

import logo from 'assets/images/mtu.png';
import { MenuItem } from './MenuItem';
import { DropdownMenu } from 'components/DropdownMenu/DropdownMenu';
import Button from 'components/Button/Button';
import { AppSvgIcon, DesktopHeaderSearchField, SearchButton } from 'components';
import { useDesktopHeaderStyles } from './styles';
import { Profile } from 'types/types';
import { getUrl } from 'utils/urlFromImagePath';
import { certificateIcon, lockedBadgeIcon } from 'utils/icons';
import { useFirstFocus } from 'utils/useFirstFocus';

export const DesktopHeader = ({
  onLogoClick,
  getPath,
  search,
  history,
  topics,
  profile,
  logout: logoutAction,
  isLoggedIn,
}) => {
  const classes = useDesktopHeaderStyles();
  const { t } = useTranslation();

  const [topicsOpen, setTopicsOpen] = React.useState(false);
  const [profileOpen, setProfileOpen] = React.useState(false);
  const [aboutOpen, setAboutOpen] = React.useState(false);
  const [searchOpen, setSearchOpen] = React.useState(false);

  const topicsAnchorRef = React.useRef(null);
  const profileAnchorRef = React.useRef(null);
  const aboutAnchorRef = React.useRef(null);
  const firstFocusRef = React.useRef(null);
  const searchButtonRef = React.useRef(null);

  const handleTopicsToggle = () => {
    setTopicsOpen((prevTopicsOpen) => !prevTopicsOpen);
  };

  const handleTopicsClose = (event) => {
    if (topicsAnchorRef.current && topicsAnchorRef.current.contains(event.target)) {
      return;
    }
    setTopicsOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevTopicsOpen = React.useRef(topicsOpen);
  React.useEffect(() => {
    if (prevTopicsOpen.current === true && topicsOpen === false) {
      topicsAnchorRef.current.focus();
    }

    prevTopicsOpen.current = topicsOpen;
  }, [topicsOpen]);

  const handleProfileToggle = () => {
    setProfileOpen((prevProfileOpen) => !prevProfileOpen);
  };

  const handleProfileClose = (event) => {
    if (profileAnchorRef.current && profileAnchorRef.current.contains(event.target)) {
      return;
    }
    setProfileOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevProfileOpen = React.useRef(profileOpen);
  React.useEffect(() => {
    if (prevProfileOpen.current === true && profileOpen === false) {
      profileAnchorRef && profileAnchorRef.current && profileAnchorRef.current.focus();
    }

    prevProfileOpen.current = profileOpen;
  }, [profileOpen]);

  const handleAboutToggle = () => {
    setAboutOpen((prevAboutOpen) => !prevAboutOpen);
  };

  const handleAboutClose = (event) => {
    if (aboutAnchorRef.current && aboutAnchorRef.current.contains(event.target)) {
      return;
    }
    setAboutOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevAboutOpen = React.useRef(aboutOpen);
  React.useEffect(() => {
    if (prevAboutOpen.current === true && aboutOpen === false) {
      aboutAnchorRef.current.focus();
    }

    prevAboutOpen.current = aboutOpen;
  }, [aboutOpen]);

  const closeSubmenus = (event) => {
    handleTopicsClose(event);
    handleProfileClose(event);
    handleAboutClose(event);
  };

  const logout = (event) => {
    logoutAction();
    handleProfileClose(event);
  };

  const openSearch = useCallback(() => setSearchOpen(true), []);

  const closeSearch = useCallback(() => {
    setSearchOpen(false);
    searchButtonRef.current.focus();
  }, []);

  const closeSearchOutside = useCallback(() => {
    setSearchOpen(false);
  }, []);

  const onSearchSubmit = useCallback((searchString) => search({ searchString }), [search]);

  const onSubMenuBlur = () => {
    setTopicsOpen(false);
    setAboutOpen(false);
    setProfileOpen(false);
  };

  const [onClick, onKeyDown] = useFirstFocus(firstFocusRef);

  return (
    <Box className={classes.Header} onClick={onClick} onKeyDown={onKeyDown}>
      <AppSvgIcon className={classes.HeaderBg} viewBox="0 0 1280 60" aria-label="">
        <g transform="translate(-1012 587)">
          <g transform="translate(196.673 121.794)">
            <path
              fill="rgb(20,56,127)"
              d="M382.327,378.569c0-61.993,49.637-112.26,110.813-112.26s110.786-50.248,110.786-112.247c0,62,49.6,112.247,110.793,112.247S825.5,316.577,825.5,378.563h-.106l.106.007c0,61.993-49.637,112.26-110.82,112.26S603.9,541.071,603.9,603.077c0-62.006-49.61-112.247-110.793-112.247s-110.78-50.267-110.78-112.254l.113-.007Z"
              transform="translate(890.828 -1258.609)"
            />
            <path
              fill="rgb(20,56,127)"
              d="M382.327,378.569c0-61.993,49.637-112.26,110.813-112.26s110.786-50.248,110.786-112.247c0,62,49.6,112.247,110.793,112.247S825.5,316.577,825.5,378.563h-.106l.106.007c0,61.993-49.637,112.26-110.82,112.26S603.9,541.071,603.9,603.077c0-62.006-49.61-112.247-110.793-112.247s-110.78-50.267-110.78-112.254l.113-.007Z"
              transform="translate(669 -1034.179)"
            />
          </g>
        </g>
      </AppSvgIcon>
      <Container maxWidth="lg">
        <Box ml={-2} mr={-2} className={classes.HeaderGrid}>
          <Box className={classes.LogoContainer} marginRight={4}>
            <Avatar
              ref={firstFocusRef}
              className={classes.Logo}
              src={logo}
              onClick={onLogoClick}
              tabIndex={0}
              role="link"
              alt={t('alt_link_to_home_page')}
              aria-label={t('alt_link_to_home_page')}
              id="page-logo"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onLogoClick();
                }
              }}
            />
          </Box>
          <Box padding={1} width={1}>
            <div className={classes.SearchContainer}>
              <SearchButton
                width={24}
                height={24}
                iconClass={classes.SearchIcon}
                aria-label={t('alt_open_search')}
                onClick={openSearch}
                ref={searchButtonRef}
              />
              <div className={classes.SearchInput}>
                {searchOpen ? (
                  <DesktopHeaderSearchField
                    ariaLabel={t('common_search_placeholder')}
                    placeholder={t('common_search_placeholder')}
                    submit={onSearchSubmit}
                    cancel={closeSearch}
                    outsideCancel={closeSearchOutside}
                  />
                ) : (
                  <div onClick={openSearch}>{t('common_search')}</div>
                )}
              </div>
            </div>
          </Box>
          <Box padding={1} className={classNames(classes.MenuContainer, { [classes.MenuContainerHidden]: searchOpen })}>
            <List component="nav" className={classes.List}>
              <MenuItem linkName={t('menu_item_home')} to={getPath(t('menu_item_home'))} onClick={closeSubmenus} />
              <DropdownMenu
                menuListId="topics-menu"
                buttonContainerClassName="TopicsDropdownContainer"
                open={topicsOpen}
                dropdownTitle={<Trans i18nKey="menu_item_topics" />}
                anchorRef={topicsAnchorRef}
                handleToggle={handleTopicsToggle}
                handleClose={handleTopicsClose}
              >
                {topics
                  ? topics.map((theme, themeIndex) => (
                      <MenuItem
                        key={theme.id}
                        onClick={handleTopicsClose}
                        linkName={theme.title}
                        to={getPath(theme.title)}
                        onBlur={themeIndex === topics.length - 1 ? onSubMenuBlur : null}
                        submenu
                      />
                    ))
                  : null}
              </DropdownMenu>
              {isLoggedIn ? (
                <MenuItem
                  linkName={t('menu_item_courses')}
                  to={getPath(t('menu_item_courses'))}
                  onClick={closeSubmenus}
                />
              ) : null}
              {isLoggedIn ? (
                <>
                  <MenuItem
                    linkName={t('menu_item_exams')}
                    to={getPath(t('menu_item_exams'))}
                    onClick={closeSubmenus}
                  />
                  <MenuItem
                    linkName={t('menu_item_top_list')}
                    to={getPath(t('menu_item_top_list'))}
                    onClick={closeSubmenus}
                  />
                </>
              ) : null}
              <DropdownMenu
                menuListId="about-menu"
                containerClassName="AboutDropdownContainer"
                open={aboutOpen}
                dropdownTitle={<Trans i18nKey="menu_item_about" />}
                anchorRef={aboutAnchorRef}
                handleToggle={handleAboutToggle}
                handleClose={handleAboutClose}
              >
                <MenuItem
                  onClick={handleAboutClose}
                  linkName={t('menu_item_about')}
                  to={getPath(t('menu_item_about'))}
                  submenu
                />
                <MenuItem
                  onClick={handleAboutClose}
                  linkName={t('menu_item_knowledge_store')}
                  to={getPath(t('menu_item_knowledge_store'))}
                  submenu
                  onBlur={onSubMenuBlur}
                />
              </DropdownMenu>
              {isLoggedIn ? (
                <DropdownMenu
                  menuListId="profile-menu"
                  open={profileOpen}
                  avatar={profile.avatar && getUrl(profile.avatar)}
                  anchorRef={profileAnchorRef}
                  handleToggle={handleProfileToggle}
                  handleClose={handleProfileClose}
                  ariaLabel={t('menu_item_profile_menu')}
                >
                  <MenuItem
                    onClick={handleProfileClose}
                    linkName={t('menu_item_profile')}
                    to={getPath(t('menu_item_profile'))}
                    submenu
                    hasIcon
                    submenuClassName={classes.SubMenu}
                  >
                    <PersonIcon />
                  </MenuItem>
                  {!!profile.isShowCertificates && (
                    <MenuItem
                      onClick={handleProfileClose}
                      linkName={t('menu_item_certificates')}
                      to={getPath(t('menu_item_certificates'))}
                      submenu
                      hasIcon
                      submenuClassName={classes.SubMenu}
                    >
                      {certificateIcon()}
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={handleProfileClose}
                    linkName={t('menu_item_badges')}
                    to={getPath(t('menu_item_badges'))}
                    submenu
                    hasIcon
                    submenuClassName={classes.SubMenu}
                  >
                    {lockedBadgeIcon()}
                  </MenuItem>
                  <MenuItem
                    onClick={logout}
                    linkName={t('menu_item_logout')}
                    to={getPath(t('menu_item_home'))}
                    submenu
                    hasIcon
                    onBlur={onSubMenuBlur}
                    submenuClassName={classes.SubMenu}
                  >
                    <ExitToAppIcon />
                  </MenuItem>
                </DropdownMenu>
              ) : null}
              {!isLoggedIn ? (
                <>
                  <Box className={classes.LoginButtonContainer} marginLeft={2} marginRight={2}>
                    <Button
                      buttonClassName="Header Secondary"
                      onClick={() =>
                        history.push(
                          `${history.location.pathname}${
                            history.location.search ? history.location.search + '&' : '?'
                          }dialog=${t('url_login')}`
                        )
                      }
                    >
                      {t('menu_item_login')}
                    </Button>
                  </Box>
                  <Box className={classes.RegistrationButtonContainer} visibility="visible" marginRight={2}>
                    <Button
                      buttonClassName="Header"
                      onClick={() => history.push(`${history.location.pathname}?dialog=${t('url_register')}`)}
                    >
                      {t('menu_item_register')}
                    </Button>
                  </Box>
                </>
              ) : null}
            </List>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

DesktopHeader.propTypes = {
  onLogoClick: PropTypes.func.isRequired,
  getPath: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  profile: Profile,
  logout: PropTypes.func,
  isLoggedIn: PropTypes.bool,
};
