import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { Router } from 'react-router-dom';
import { useSSR } from 'react-i18next';
import 'i18n';
import Cookies from 'js-cookie';

import App from 'modules';
import configureStore, { history } from 'store/store';
import theme from 'theme';
import routes from 'routes';
import { filterRoutes } from 'components';
import { getTopicsRoute } from 'utils/getTopicsRoute';
import { isBrowser } from 'utils/browser';
import Settings from 'env';
import { Roles } from 'constants/Constants';

const preloadedState = isBrowser() ? window.__PRELOADED_STATE__ : {};

const initialI18nStore = isBrowser() ? window.__I18N_STORE__ : null;
const initialLanguage = isBrowser() ? window.__INITIAL_LANGUAGE__ : null;

if (isBrowser()) {
  delete window.__INITIAL_DATA__;
  delete window.__PRELOADED_STATE__;
  delete window.__I18N_STORE__;
  delete window.__INITIAL_LANGUAGE__;
}

export const store = configureStore(preloadedState);
export let getAuthorizedRoutesForExport;

if (isBrowser()) {
  const getAuthorizedRoutes = (token) =>
    filterRoutes({ routes, role: Cookies.get(Settings.API_TOKEN) || token ? Roles.User : null });

  const Main = () => {
    const [authorizedRoutes, setAuthorizedRoutes] = React.useState(
      getAuthorizedRoutes(Cookies.get(Settings.API_TOKEN) || null)
    );

    useSSR(initialI18nStore, initialLanguage);

    for (let topic of store.getState().topics.topics) {
      authorizedRoutes.splice(1, 0, getTopicsRoute(topic));
    }

    const resetAuthorizedRoutes = (token) => {
      const routes = getAuthorizedRoutes(token);
      setAuthorizedRoutes(routes);
      return routes;
    };

    getAuthorizedRoutesForExport = resetAuthorizedRoutes;

    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Router history={history}>
            <App routes={authorizedRoutes} getAuthorizedRoutes={resetAuthorizedRoutes} />
          </Router>
        </Provider>
      </ThemeProvider>
    );
  };

  hydrate(<Main />, document.getElementById('root'), () => {
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  });

  if (module.hot) {
    module.hot.accept();
  }
}
