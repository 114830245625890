import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';

import { Button } from 'components/index';
import noAvatar from 'assets/images/no-image-3.png';
import { useDropdownMenuStyles } from './styles';
import { useTranslation } from 'react-i18next';

export const DropdownMenu = ({
  menuListId,
  open,
  dropdownTitle,
  avatar,
  anchorRef,
  handleToggle,
  handleClose,
  children,
  buttonClassName,
  buttonContainerClassName,
  ariaLabel,
}) => {
  const { t } = useTranslation();
  const classes = useDropdownMenuStyles();
  return (
    <Fragment>
      {dropdownTitle ? (
        <Button
          ref={anchorRef}
          buttonClassName={classNames(classes.DropdownButton, buttonClassName)}
          buttonContainerClassName={buttonContainerClassName}
          aria-controls={open ? menuListId : undefined}
          aria-haspopup={true}
          aria-expanded={open ? 'true' : 'false'}
          variant="text"
          onClick={handleToggle}
        >
          {dropdownTitle}
          <ExpandMoreIcon role={undefined} />
        </Button>
      ) : (
        <Button
          ref={anchorRef}
          aria-controls={open ? menuListId : undefined}
          onClick={handleToggle}
          aria-haspopup={true}
          aria-label={ariaLabel}
          aria-expanded={open ? 'true' : 'false'}
          tabIndex={0}
          buttonClassName={classes.AvatarButton}
        >
          <Avatar
            component="span"
            src={avatar || noAvatar}
            className={classNames(classes.Avatar, { NoAvatar: !avatar })}
            alt={t('alt_profile_picture')}
          />
        </Button>
      )}
      <Popper
        className={classes.MenuListContainer}
        open={!!open}
        anchorEl={anchorRef.current}
        transition={true}
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className={classes.MenuList} autoFocusItem={!!open} id={menuListId}>
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

DropdownMenu.propTypes = {
  menuListId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  anchorRef: PropTypes.object.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  dropdownTitle: PropTypes.node,
  avatar: PropTypes.string,
  children: PropTypes.node.isRequired,
  buttonClassName: PropTypes.any,
  buttonContainerClassName: PropTypes.any,
  ariaLabel: PropTypes.string,
};
