import { GROUP_SEARCH, GROUP_SEARCH_SUCCESS, GROUP_SEARCH_FAILED } from './types';

const INITIAL_STATE = {
  error: null,
  groups: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GROUP_SEARCH:
      return state;
    case GROUP_SEARCH_SUCCESS:
      return {
        ...state,
        groups: action.payload,
      };
    case GROUP_SEARCH_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
