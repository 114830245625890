import { absolute, auto, center, flex, none, relative, bold } from 'constants/Jss';

export const lessonDetailPageStyles = (theme) => ({
  LessonDetailContainer: {
    width: '100%',
    '& .PageTitleContainer': {
      width: 768,
      marginLeft: auto,
      marginRight: auto,
    },
    [theme.breakpoints.down('sm')]: {
      '& .PageTitleContainer': {
        width: '100%',
      },
    },
  },
  LessonItemsContainer: {
    width: 768,
    marginLeft: auto,
    marginRight: auto,
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% + ${theme.spacing(4)}px)`,
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    },
  },
  LessonTextItem: {
    color: theme.palette.blackTitle,
    backgroundColor: theme.palette.greyF7,
    lineHeight: '30px',
    padding: theme.spacing(3),
  },
  Image: {
    width: '100%',
    height: auto,
  },
  TestButtonWrapper: {
    position: relative,
    [theme.breakpoints.down('sm')]: {
      height: auto,
    },
  },
  TestButtonContainer: {
    backgroundColor: theme.palette.greyF7,
    display: flex,
    alignItems: center,
    justifyContent: center,
    padding: theme.spacing(1),
  },
  TestButton: {
    zIndex: 1,
  },
  CourseTitle: {
    color: theme.palette.cyan,
    fontSize: 24,
    fontWeight: bold,
    textAlign: center,
  },
  CourseTitleDescription: {
    color: theme.palette.blackTitle,
    marginTop: theme.spacing(1),
  },
  LessonPatch: {
    '&.Error': {
      display: none,
    },
    [theme.breakpoints.up('md')]: {
      position: relative,
      width: 995,
      maxWidth: 995,
      height: 364,
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      position: relative,
      width: '100%',
      height: auto,
      marginTop: theme.spacing(12),
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  LessonCloseButtonContainer: {
    position: absolute,
    top: theme.spacing(7),
    right: theme.spacing(7),
    display: flex,
    alignItems: center,
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(3),
      right: theme.spacing(3),
    },
  },
  VideoContainer: {
    width: '100%',
    position: relative,
    height: 0,
    paddingBottom: '56.25%',
  },
  Video: {
    position: absolute,
    top: 0,
    left: 0,
  },
  DesktopVideo: {
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  MobileVideo: {
    [theme.breakpoints.up('md')]: {
      display: none,
    },
  },
  CarouselSlideContainer: {
    display: `${flex} !important`,
    justifyContent: center,
  },
  LessonCardCarousel: {
    marginRight: 20,
    marginLeft: 20,
  },
});
