import { isBrowser } from 'utils/browser';
import { removeCommonLoader } from 'store/loader/actions';

export const titleFocus = (dispatch) => {
  if (isBrowser()) {
    const titles = document.getElementsByTagName('h1');
    titles && titles.length && titles[0].focus();
    dispatch(removeCommonLoader());
  }
};
