import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Cookies from 'js-cookie';

import withStyles from '@material-ui/core/styles/withStyles';

import renderRoutes from 'utils/renderRoutes';
import { column, flex, relative } from 'constants/Jss';
import Header from 'modules/Header';
import { Footer } from 'modules/Footer/Footer';
import Settings from 'env';
import { ClassStyle, Document } from 'types/types';
import { Tutorial } from 'components';

const styles = () => ({
  Main: {
    display: flex,
    flexDirection: column,
    minHeight: '100vh',
    backgroundColor: '#FFF',
  },
  mainContent: {
    width: '100%',
    flexGrow: 1,
  },
  transitionGroup: {
    position: relative,
  },
  routeSection: {
    width: '100%',
  },
});

class Main extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      Main: ClassStyle,
      mainContent: ClassStyle,
    }),
    routes: PropTypes.array.isRequired,
    tokenExpiresAt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })
    ),
    getAuthorizedRoutes: PropTypes.func,
    termsAndConditions: Document,
    privacyPolicy: Document,
    location: PropTypes.any,
    history: PropTypes.any,
    setIsLoggedIn: PropTypes.func,
  };

  render() {
    const {
      classes,
      routes,
      location,
      history,
      topics,
      getAuthorizedRoutes,
      termsAndConditions,
      privacyPolicy,
      tokenExpiresAt,
      setIsLoggedIn,
    } = this.props;

    setIsLoggedIn(!!(Cookies.get(Settings.API_TOKEN_EXPIRES_AT) || tokenExpiresAt));
    return (
      <div className={classes.Main}>
        <Header pages={routes} history={history} getAuthorizedRoutes={getAuthorizedRoutes} />
        <Tutorial />
        {routes ? (
          <div className={classNames(classes.mainContent)}>
            {renderRoutes(routes, classes, location, { getAuthorizedRoutes })}
          </div>
        ) : null}
        <Footer
          history={history}
          pages={routes}
          topics={topics}
          termsAndConditions={termsAndConditions}
          privacyPolicy={privacyPolicy}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Main);
