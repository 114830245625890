import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as Yup from 'yup';
import TextFieldWrapper from 'components/Wrappers/TextField';
import { Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import Cookies from 'js-cookie';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { auto } from 'constants/Jss';
import { useCommonStyles, useProfileDataFormStyles } from './styles';
import { Button, Switch, Tooltip, OverallErrorMessages } from 'components';
import { Group, Settlement } from 'types/types';
import FileUpload from 'components/File/FileUpload';
import { getUrl } from 'utils/urlFromImagePath';
import { GroupTypes, NotificationTypes } from 'constants/Constants';
import { showNotification } from 'store/common/actions';
import { setIsStayLoggedIn, resetTimer } from 'store/auth/actions';
import { setProfileData, getProfileDetails } from 'store/profile/actions';
import { selectIsStayLoggedIn } from 'store/auth/selectors';
import { selectLoading } from 'store/loader/selectors';
import theme from 'theme';
import { isHiddenOrganization } from 'utils/isHiddenOrganization';
import { getCurrentLanguage } from 'utils/language';
import { setProfileFormErrors } from 'utils/setProfileFormErrors';
import { validateTaxNumber } from 'utils/validateTaxNumber';
import Settings from 'env';

export const ProfileDataForm = ({
  submit,
  groupSearch,
  groups,
  getSettlements,
  settlements,
  name,
  group,
  isKkv,
  settlement,
  email,
  imageUrl,
  isDataPublic,
  fromSocial,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isStayLoggedIn = useSelector(selectIsStayLoggedIn);
  const loading = useSelector(selectLoading);
  const classes = useProfileDataFormStyles();
  const commonClasses = useCommonStyles();

  const [avatar, setAvatar] = useState('');
  const [kkvSwitchDisabled, setKkvSwitchDisabled] = useState(true);
  const [taxNumberDisabled, setTaxNumberDisabled] = useState(!!group.taxNumber);
  const [openDataTooltip, setOpenDataTooltip] = useState(false);
  const [openGroupTooltip, setOpenGroupTooltip] = useState(false);
  const [hiddenWarning, setHiddenWarning] = useState(false);
  const [autoFilled, setAutoFilled] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('form_required'))
      .test('name-length', t('form_name_length'), (value) => !!value && value.length > 2 && value.length < 101),
    email: Yup.string()
      .email(t('form_invalid_email'))
      .required(t('form_required')),
    taxNumber: Yup.string()
      .required(t('form_required'))
      .test('validate-tax-number', t('form_tax_number_format_invalid'), validateTaxNumber),
    organization: Yup.string()
      .required(t('form_required'))
      .test(
        'organization-length',
        t('form_organization_length'),
        (value) => !!value && value.length > 2 && value.length < 256
      )
      .nullable(true),
    kkv: Yup.boolean(),
    settlement: Yup.string()
      .required(t('form_required'))
      .test(
        'only-existing',
        t('form_existing_only'),
        (value) =>
          value === settlement ||
          settlements.some((settlementObj) => !value || settlementObj.name === value) ||
          autoFilled
      )
      .nullable(true),
  });

  const debouncedGroupSearch = debounce((e) => {
    !!e.target.value && groupSearch(e.target.value);
  }, 500);

  const debouncedSettlementSearch = debounce((e) => {
    !!e.target.value && !autoFilled && getSettlements(e.target.value);
  }, 500);

  const handleSubmit = async (formFields, { setFieldError }) => {
    const fields = avatar !== '' ? { ...formFields, avatar } : { ...formFields };
    const { stayLoggedIn, ...submitFields } = fields;
    dispatch(setIsStayLoggedIn(stayLoggedIn));

    if (isStayLoggedIn) {
      dispatch(resetTimer());
    }

    try {
      await submit(submitFields);

      dispatch(setProfileData(submitFields));
      dispatch(showNotification({ translateKey: 'change_profile_success', type: NotificationTypes.success }));

      const token = Cookies.get(Settings.API_TOKEN);
      const language = getCurrentLanguage(window.location.pathname);
      dispatch(getProfileDetails({ language, token }));
      setHiddenWarning(false);
      setTaxNumberDisabled(formFields.taxNumber?.length > 0);
    } catch (err) {
      setProfileFormErrors(err, setFieldError, t);
    }
  };

  const dataTooltipOnClick = () => {
    setOpenDataTooltip(!openDataTooltip);
  };

  const dataTooltipOnClose = () => {
    setOpenDataTooltip(false);
  };

  const groupTooltipOnClick = () => {
    setOpenGroupTooltip(!openGroupTooltip);
  };

  const groupTooltipOnClose = () => {
    setOpenGroupTooltip(false);
  };

  const handleTaxNumberAfterOrganizationChange = (taxNumber, setFieldValue, setFieldTouched) => {
    setFieldValue('taxNumber', taxNumber || '');
    setTaxNumberDisabled(Boolean(taxNumber));
    setFieldTouched('taxNumber');
  };

  const handleTaxNumberReset = (setFieldValue, setFieldTouched) => {
    setFieldValue('taxNumber', '');
    setTaxNumberDisabled(false);
    setFieldTouched('taxNumber');
  };

  return loading ? null : (
    <Formik
      initialValues={{
        name: name || '',
        organization: group.name || '',
        kkv: isKkv,
        settlement: settlement || '',
        email: email || '',
        imageUrl: imageUrl || null,
        stayLoggedIn: isStayLoggedIn,
        isDataPublic,
        taxNumber: group.taxNumber || '',
      }}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, handleSubmit, setFieldTouched, setFieldValue }) => {
        return (
          <form onSubmit={() => {}}>
            <OverallErrorMessages />
            <Box mt={10} className={classes.FormBox}>
              <Grid container>
                <Grid item md={6} sm={12} xs={12} ml={auto} mr={auto}>
                  <Box mr={3} className={classes.GridBox}>
                    <Box mb={3}>
                      <Field
                        component={TextFieldWrapper}
                        name="name"
                        label={t('form_name')}
                        fullWidth
                        autoComplete="name"
                      />
                    </Box>
                    <Box mb={3}>
                      <Field
                        component={TextFieldWrapper}
                        type="email"
                        name="email"
                        label={t('form_email')}
                        fullWidth
                        disabled={fromSocial}
                        autoComplete="email"
                      />
                    </Box>
                    <Box display="flex" mb={hiddenWarning ? 1 : 3}>
                      <Autocomplete
                        id="organization-autocomplete"
                        freeSolo
                        options={groups.filter((group) => group.public).map((group) => group.name)}
                        classes={{
                          clearIndicator: classNames(classes.ClearIndicator, { Filled: !!values.organization }),
                        }}
                        className={classes.GroupAutocomplete}
                        clearText={t('common_clear')}
                        onChange={async (event, newValue) => {
                          if (newValue === null) return;
                          setFieldValue('organization', newValue);
                          setKkvSwitchDisabled(true);
                          const organizations = await groupSearch(newValue);
                          const organization = organizations.find(
                            ({ name }) => name.toLowerCase() === newValue.toLowerCase()
                          );
                          setFieldValue('kkv', organization.type === GroupTypes.KKV);
                          setAutoFilled(true);
                          setFieldValue('settlement', organization.settlement.name);

                          handleTaxNumberAfterOrganizationChange(
                            organization.taxNumber,
                            setFieldValue,
                            setFieldTouched
                          );
                        }}
                        onInputChange={(event, value, reason) => {
                          if (reason.toString() === 'clear') {
                            setFieldValue('organization', '');

                            handleTaxNumberReset(setFieldValue, setFieldTouched);
                          }
                          if (reason.toString() === 'reset') {
                            setFieldValue('organization', value);

                            handleTaxNumberReset(setFieldValue, setFieldTouched);
                          }
                        }}
                        name="organization"
                        renderInput={(params) => {
                          delete params.inputProps['value'];
                          return (
                            <Field
                              {...params}
                              component={TextFieldWrapper}
                              name="organization"
                              label={t('form_organization')}
                              autocomplete="organization"
                              onChange={(e) => {
                                handleChange(e);
                                debouncedGroupSearch(e);
                              }}
                              fullWidth
                              onBlur={async (e) => {
                                const company = e.target?.value;
                                if (company) {
                                  const organizations = await groupSearch(company);
                                  const organization = organizations.find(
                                    (org) => org.name.toLowerCase() === company.toLowerCase()
                                  );
                                  setHiddenWarning(isHiddenOrganization(organizations, company));
                                  if (!organizations.length || !organization) {
                                    setKkvSwitchDisabled(false);
                                    setFieldValue('kkv', true);

                                    handleTaxNumberReset(setFieldValue, setFieldTouched);
                                  } else {
                                    setKkvSwitchDisabled(true);
                                    setFieldValue('kkv', organization.type === GroupTypes.KKV);
                                    setAutoFilled(true);
                                    setFieldValue('settlement', organization.settlement.name);

                                    handleTaxNumberAfterOrganizationChange(
                                      organization.taxNumber,
                                      setFieldValue,
                                      setFieldTouched
                                    );
                                  }
                                } else {
                                  handleTaxNumberReset(setFieldValue, setFieldTouched);
                                }
                                setFieldTouched('organization', true);
                              }}
                            />
                          );
                        }}
                      />
                      {!group.public && (
                        <Tooltip
                          buttonClassName={classNames(classes.Tooltip, classes.GroupTooltip)}
                          open={openGroupTooltip}
                          onClick={groupTooltipOnClick}
                          onClose={groupTooltipOnClose}
                          text={t('profile_group_anonym_tooltip')}
                          warning
                        />
                      )}
                    </Box>
                    {hiddenWarning && (
                      <Box mb={1} color={theme.palette.errorColor}>
                        {t('form_group_warning')}
                      </Box>
                    )}
                    <Box mb={3} className={classes.KKVBox}>
                      <Switch
                        name="kkv"
                        label="KKV"
                        inlineOnLabel={t('yes')}
                        inlineOffLabel={t('no')}
                        mini
                        checked={values.kkv}
                        onChange={() => {
                          setFieldValue('kkv', !values.kkv);
                        }}
                        disabled={kkvSwitchDisabled}
                      />
                    </Box>
                    <Box mb={3}>
                      <Field
                        component={TextFieldWrapper}
                        type="text"
                        name="taxNumber"
                        label={t('form_tax_number')}
                        fullWidth
                        disabled={taxNumberDisabled}
                      />
                    </Box>
                    <Box mb={3}>
                      <Autocomplete
                        id="settlement-autocomplete"
                        freeSolo
                        options={settlements.map((settlement) => settlement.name)}
                        classes={{
                          clearIndicator: classNames(classes.ClearIndicator, { Filled: !!values.settlement }),
                        }}
                        clearText={t('common_clear')}
                        onChange={(event, newValue) => {
                          setAutoFilled(false);
                          setFieldValue('settlement', newValue);
                        }}
                        onInputChange={(event, value, reason) => {
                          setAutoFilled(false);
                          if (reason.toString() === 'clear') {
                            setFieldValue('settlement', '');
                          }
                        }}
                        name="settlement"
                        renderInput={(params) => {
                          delete params.inputProps['value'];
                          return (
                            <Field
                              {...params}
                              component={TextFieldWrapper}
                              name="settlement"
                              label={t('form_settlement')}
                              autocomplete="address-level2"
                              onChange={(e) => {
                                setAutoFilled(false);
                                handleChange(e);
                                debouncedSettlementSearch(e);
                              }}
                              fullWidth
                            />
                          );
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Box ml={3} className={classes.FileUploadBox}>
                    <FileUpload
                      avatar={avatar}
                      setAvatar={setAvatar}
                      imageUrl={imageUrl ? getUrl(imageUrl) : null}
                      deletable
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Switch
                    name="stayLoggedIn"
                    label={t('expiration_confirm')}
                    labelPlacement="end"
                    inlineOnLabel={t('yes')}
                    inlineOffLabel={t('no')}
                    mini
                    checked={values.stayLoggedIn}
                    labelClass={classes.ProfileSwitchLabel}
                    rootClass={classes.StayLoggedInLabelRoot}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Box display="flex" mb={10}>
                    <Switch
                      name="isDataPublic"
                      label={t('profile_anonym')}
                      labelPlacement="end"
                      inlineOnLabel={t('yes')}
                      inlineOffLabel={t('no')}
                      mini
                      checked={values.isDataPublic}
                      labelClass={classNames(classes.ProfileSwitchLabel, classes.AnonymLabel)}
                      rootClass={classes.AnonymLabelRoot}
                      onChange={handleChange}
                    />
                    <Tooltip
                      buttonClassName={classes.Tooltip}
                      open={openDataTooltip}
                      onClick={dataTooltipOnClick}
                      onClose={dataTooltipOnClose}
                      text={t('profile_anonym_tooltip')}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mt={2} mb={4}>
              <Button
                className={commonClasses.SubmitNewPasswordContainer}
                buttonClassName="Pink"
                onClick={(e) => {
                  setKkvSwitchDisabled(true);
                  handleSubmit(e);
                }}
                type="submit"
              >
                {t('common_save')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

ProfileDataForm.propTypes = {
  submit: PropTypes.func.isRequired,
  groupSearch: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(Group),
  getSettlements: PropTypes.func.isRequired,
  settlements: PropTypes.arrayOf(Settlement),
  name: PropTypes.string,
  group: PropTypes.object,
  isKkv: PropTypes.bool,
  settlement: PropTypes.string,
  email: PropTypes.string,
  imageUrl: PropTypes.string,
  avatar: PropTypes.string,
  isDataPublic: PropTypes.bool,
  fromSocial: PropTypes.bool,
};
