import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Dialog } from 'components';
import { useMobileFilterDialogStyles, useTagFilterStyles } from './styles';
import Button from 'components/Button/Button';

const JobsFilterDialog = ({ open, closeDialog, tags, checkedTags, setTags }) => {
  const { t } = useTranslation();
  const classes = useMobileFilterDialogStyles();
  const tagFilterClasses = useTagFilterStyles();

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      title={t('profile_interests')}
      titleClass={classes.Title}
      titleBoxClass={classes.TitleBox}
      contentClass={classes.Content}
      content={
        <div>
          <Box className={classes.Filter}>
            <Box className={classes.MobileJobsTagContainer}>
              {tags.map((tag) => (
                <FormControlLabel
                  key={tag.id}
                  control={
                    <Checkbox
                      className={classNames(tagFilterClasses.Tag, classes.Checkbox, {
                        Checked: checkedTags ? checkedTags.includes(tag.id) : false,
                      })}
                      checked={checkedTags ? checkedTags.includes(tag.id) : false}
                      onChange={() => setTags(tag.id)}
                      role="menuitemcheckbox"
                    />
                  }
                  label={tag.title}
                />
              ))}
            </Box>
          </Box>
          <Box mt={3} mb={3} className={classes.FilterButtonContainer}>
            <Button buttonClassName="Pink" onClick={closeDialog}>
              {t('common_close')}
            </Button>
          </Box>
        </div>
      }
    />
  );
};

JobsFilterDialog.propTypes = {
  open: PropTypes.bool,
  closeDialog: PropTypes.func,
  tags: PropTypes.array,
  checkedTags: PropTypes.array,
  setTags: PropTypes.func,
};

export default JobsFilterDialog;
