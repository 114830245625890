import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import { auto } from 'constants/Jss';
import { Regex } from 'constants/Constants';
import { useCommonStyles, usePasswordChangeFormStyles } from './styles';
import { Button, OverallErrorMessages } from 'components';
import LeftPatch from 'assets/images/profile-left-patch.png';
import RightPatch from 'assets/images/profile-right-patch.png';

const initialValues = {
  password: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

export const PasswordChangeForm = ({ hasPassword, submit }) => {
  const { t } = useTranslation();
  const classes = usePasswordChangeFormStyles();
  const commonClasses = useCommonStyles();

  const validationSchema = {
    newPassword: Yup.string()
      .required(t('form_required'))
      .matches(Regex.Password, t('form_password_strength')),
    newPasswordConfirmation: Yup.string()
      .required(t('form_required'))
      .oneOf([Yup.ref('newPassword'), null], t('form_passwords_must_match')),
  };
  if (hasPassword) {
    validationSchema.password = Yup.string().required(t('form_required'));
  }
  const labelNewPassword = t(hasPassword ? 'form_new_password' : 'form_set_password');
  const labelNewPasswordConfirmation = t(
    hasPassword ? 'form_new_password_confirmation' : 'form_set_password_confirmation'
  );

  const onSubmit = (values, { resetForm }) => {
    resetForm();
    submit(values);
  };

  return (
    <>
      <Box mb={5} className={classes.SectionTitleContainer}>
        <h2 className="Title" tabIndex={0}>
          {t(hasPassword ? 'profile_change_password' : 'profile_set_password')}
        </h2>
        <div className="TitleBorder" />
      </Box>
      <img src={LeftPatch} className={classNames(classes.Patch, classes.LeftPatch)} alt="" />
      <img src={RightPatch} className={classNames(classes.Patch, classes.RightPatch)} alt="" />
      <Formik initialValues={initialValues} validationSchema={Yup.object().shape(validationSchema)} onSubmit={onSubmit}>
        {({ values, errors, touched, handleChange, handleSubmit, setFieldTouched }) => (
          <form onSubmit={() => {}}>
            <OverallErrorMessages />
            <Box className={classes.FormBox} ml={auto} mr={auto}>
              {hasPassword && (
                <Box mb={3} mr={4} className={classes.FullWidth}>
                  <TextField
                    id="password"
                    name="password"
                    type="password"
                    label={t('form_current_password')}
                    variant="outlined"
                    autoComplete="current-password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched('password', true)}
                    error={!!errors.password && touched.password}
                    helperText={touched.password ? errors.password : ''}
                    fullWidth
                    inputProps={{ 'aria-label': t('form_current_password') }}
                  />
                </Box>
              )}
              <Box mb={3} ml={4} mr={4} className={classes.FullWidth}>
                <TextField
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  label={labelNewPassword}
                  variant="outlined"
                  autoComplete="new-password"
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('newPassword', true)}
                  error={!!errors.newPassword && touched.newPassword}
                  helperText={touched.newPassword ? errors.newPassword : ''}
                  fullWidth
                  inputProps={{ 'aria-label': labelNewPassword }}
                />
              </Box>
              <Box mb={3} ml={4} className={classes.FullWidth}>
                <TextField
                  id="newPasswordConfirmation"
                  name="newPasswordConfirmation"
                  type="password"
                  label={labelNewPasswordConfirmation}
                  variant="outlined"
                  autoComplete="new-password"
                  value={values.newPasswordConfirmation}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('newPasswordConfirmation', true)}
                  error={!!errors.newPasswordConfirmation && touched.newPasswordConfirmation}
                  helperText={touched.newPasswordConfirmation ? errors.newPasswordConfirmation : ''}
                  fullWidth
                  inputProps={{ 'aria-label': labelNewPasswordConfirmation }}
                />
              </Box>
            </Box>
            <Box mt={2} mb={4}>
              <Button
                className={commonClasses.SubmitNewPasswordContainer}
                buttonClassName="Pink"
                onClick={handleSubmit}
                type="submit"
              >
                {t('common_save')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

PasswordChangeForm.propTypes = {
  hasPassword: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
};
