import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { isTabEvent } from './eventUtils';

export const useFirstFocus = (firstFocusRef) => {
  const tabPressedRef = useRef(false);

  const history = useHistory();

  useEffect(() => {
    tabPressedRef.current = false;
  }, [history.location.pathname]);

  const onClick = useCallback(() => (tabPressedRef.current = true), []);

  const onKeyDown = useCallback(
    (event) => {
      if (!tabPressedRef.current && isTabEvent(event)) {
        tabPressedRef.current = true;
        if (firstFocusRef.current) {
          event.preventDefault();
          firstFocusRef.current.focus();
        }
      }
    },
    [firstFocusRef]
  );

  return [onClick, onKeyDown];
};
