import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import filter from 'lodash/filter';

import Box from '@material-ui/core/Box';

import SelectedDotIcon from 'assets/images/selected-dot-icon.png';

import { useResultTableStyles } from './styles';

function getAriaLabel(answers, t) {
  const goodAnswers = filter(answers, (answer) => answer.isGood === true)
    .map((answer) => answer.answer)
    .join(` ${t('aria_label_and')} `);
  const selectedAnswers = filter(answers, (answer) => answer.isSelected === true)
    .map((answer) => answer.answer)
    .join(` ${t('aria_label_and')} `);
  const ariaLabel = t('aria_label_result_table_single_and_multiple_select', {
    correctAnswer: goodAnswers,
    selectedAnswer: selectedAnswers ? selectedAnswers : t('aria_label_no_answer'),
  });

  return ariaLabel;
}

function SingleAndMultipleSelectResultTable({ answers, isDesktopLayout }) {
  const { t } = useTranslation();
  const classes = useResultTableStyles();

  return isDesktopLayout ? (
    <Box aria-label={getAriaLabel(answers, t)} className={classes.ResultTableContainer}>
      <table aria-hidden={true}>
        <thead>
          <tr>
            <th scope="col" className="hidden">
              {t('result_table_header_index')}
            </th>
            <th scope="col" className="hidden">
              {t('result_table_header_answer')}
            </th>
            <th scope="col" className="hidden">
              {t('result_table_header_solution')}
            </th>
            <th scope="col" aria-hidden={true} className={classes.AlignCenter}>
              {t('result_table_header_selected_choice')}
            </th>
          </tr>
        </thead>
        <tbody>
          {answers.map((answer, index) => (
            <tr key={answer.id} className={classes.TableRowBorder}>
              <td>{`${index + 1}.`}</td>
              <td>{answer.answer}</td>
              <td className={answer.isGood ? 'success' : 'fail'}>
                {answer.isGood ? t('result_table_data_correct_answer') : t('result_table_data_bad_answer')}
              </td>
              <td className={classes.AlignCenter}>{answer.isSelected ? <img src={SelectedDotIcon} alt="" /> : null}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  ) : (
    <Box className={classes.MobileResultTableContainer}>
      <table className={classes.SecondLayout}>
        {answers.map((answer, index) => {
          return (
            <tr key={answer.id}>
              <td>{`${index + 1}.`}</td>
              <td>
                <p>{answer.answer}</p>
                <div className={classNames(classes.AnswerContainer, classes.SpaceBetween)}>
                  <span className={answer.isGood ? classes.Success : classes.Fail}>
                    {answer.isGood ? t('result_table_data_correct_answer') : t('result_table_data_bad_answer')}
                  </span>
                  {answer.isSelected ? (
                    <div className={classes.AlignRight}>
                      <span className={classes.Selected}>{`${t('result_table_header_selected_choice')} `}</span>
                      <img src={SelectedDotIcon} alt="" />
                    </div>
                  ) : null}
                </div>
              </td>
            </tr>
          );
        })}
      </table>
    </Box>
  );
}

SingleAndMultipleSelectResultTable.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.object),
  isDesktopLayout: PropTypes.bool,
};

export default SingleAndMultipleSelectResultTable;
