import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import {
  Button,
  Dialog,
  SeparatorOr,
  Switch,
  FacebookButton,
  GoogleButton,
  AppleButton,
  OverallErrorMessages,
} from 'components';
import { auto } from 'constants/Jss.js';
import { setIsStayLoggedIn } from 'store/auth/actions';
import { useLoginDialogStyles, usePatches, useSocialStyles } from './styles';
import { History } from '../../types/types';
import { AuthProviderCodes } from 'constants/Constants';
import { selectIsStayLoggedIn } from 'store/auth/selectors';

const LoginDialog = ({ open, closeDialog, submit, history, isVerify }) => {
  const { t } = useTranslation();
  const isStayLoggedIn = useSelector(selectIsStayLoggedIn);
  const dispatch = useDispatch();
  const classes = useLoginDialogStyles();
  const socialSlasses = useSocialStyles();
  const patchClasses = usePatches();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('form_invalid_email'))
      .required(t('form_required')),
    password: Yup.string().required(t('form_required')),
  });

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      title={t('form_title_login')}
      titleBoxClass={patchClasses.YellowPatch}
      aria-modal={true}
      role="dialog"
      aria-labelledby="LoginButton"
      content={
        <>
          <Formik initialValues={{ email: '', password: '' }} validationSchema={validationSchema} onSubmit={submit}>
            {({ values, errors, touched, handleChange, handleSubmit, setFieldTouched }) => (
              <form onSubmit={() => {}}>
                <OverallErrorMessages />
                <Box className={classes.FormBox} ml={auto} mr={auto}>
                  <Box className={socialSlasses.SocialButtonsContainer}>
                    <FacebookButton
                      onClick={() => submit(AuthProviderCodes.FACEBOOK)}
                      type="button"
                      tabIndex={0}
                      aria-label={t('alt_login_facebook_button')}
                      id="FacebookLoginButton"
                    >
                      {t('form_login_facebook_button_text')}
                    </FacebookButton>
                    <SeparatorOr />
                    <AppleButton
                      onClick={() => submit(AuthProviderCodes.APPLE)}
                      type="button"
                      tabIndex={0}
                      aria-label={t('alt_login_apple_button')}
                      id="AppleLoginButton"
                    >
                      {t('form_login_apple_button_text')}
                    </AppleButton>
                    <SeparatorOr />
                    <GoogleButton
                      onClick={() => submit(AuthProviderCodes.GOOGLE)}
                      type="button"
                      tabIndex={0}
                      aria-label={t('alt_login_google_button')}
                      id="GoogleLoginButton"
                    >
                      {t('form_login_google_button_text')}
                    </GoogleButton>
                    <SeparatorOr />
                  </Box>
                  <Box mb={3}>
                    <TextField
                      id="email"
                      type="email"
                      name="email"
                      label={t('form_email')}
                      variant="outlined"
                      autoComplete="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('email', true)}
                      error={!!errors.email && touched.email}
                      helperText={touched.email ? errors.email : ''}
                      fullWidth
                    />
                  </Box>
                  <Box mb={3}>
                    <TextField
                      id="password"
                      name="password"
                      type="password"
                      label={t('form_password')}
                      variant="outlined"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('password', true)}
                      error={!!errors.password && touched.password}
                      helperText={touched.password ? errors.password : ''}
                      fullWidth
                    />
                  </Box>
                  <Box mb={4}>
                    <Button
                      className={classes.LoginButtonContainer}
                      buttonClassName="Pink"
                      onClick={handleSubmit}
                      type="submit"
                      aria-label={t('form_login_button_text')}
                      id="LoginButton"
                    >
                      {t('form_login_button_text')}
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
          <Box className={classes.NewPasswordBox}>
            <Switch
              name="stay_logged_in"
              label={t('expiration_confirm')}
              labelPlacement="end"
              inlineOnLabel={t('yes')}
              inlineOffLabel={t('no')}
              mini
              checked={isStayLoggedIn}
              labelClass={classes.StayLoggedInLabel}
              onChange={() => {
                dispatch(setIsStayLoggedIn(!isStayLoggedIn));
              }}
            />
            <Link
              className={classNames('Bold', classes.NewPasswordLink)}
              to={`${history.location.pathname}?dialog=${t('url_new_password')}`}
              role="button"
              aria-label={t('form_ask_for_new_password')}
            >
              {t('form_ask_for_new_password')}
            </Link>
          </Box>
          {isVerify ? null : (
            <Box className={classes.RegisterBox} mb={8}>
              <Box tabIndex={0}>{t('form_dont_you_have_id')}</Box>
              <Link
                className={classNames('Bold', classes.Link)}
                to={`${history.location.pathname}?dialog=${t('url_register')}`}
                role="button"
                aria-label={t('menu_item_register')}
              >
                {t('menu_item_register')}
              </Link>
            </Box>
          )}
        </>
      }
    />
  );
};

LoginDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  history: History,
  isVerify: PropTypes.bool,
};

export default LoginDialog;
