import {
  GET_CERTIFICATES,
  GET_CERTIFICATES_FAILED,
  GET_CERTIFICATES_SUCCESS,
  GET_CERTIFICATE,
  GET_CERTIFICATE_FAILED,
  GET_CERTIFICATE_SUCCESS,
} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  certificates: {},
  certificate: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CERTIFICATES:
      return {
        ...state,
        loading: true,
      };
    case GET_CERTIFICATES_SUCCESS:
      return {
        ...state,
        loading: false,
        certificates: action.payload || {},
      };
    case GET_CERTIFICATES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_CERTIFICATE:
      return {
        ...state,
        loading: true,
      };
    case GET_CERTIFICATE_SUCCESS:
      return {
        ...state,
        loading: false,
        certificate: action.payload || {},
      };
    case GET_CERTIFICATE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
