import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { useMenuItemStyles } from './styles';

export const MenuItem = forwardRef(
  ({ to, linkName, onClick, onBlur, submenu, submenuClassName, hasIcon, children, mobile }, ref) => {
    const classes = useMenuItemStyles();

    return (
      !!to && (
        <ListItem
          ref={ref}
          component={
            submenu
              ? (props) => (
                  <li>
                    <NavLink {...props} />
                  </li>
                )
              : NavLink
          }
          to={to}
          className={classNames(classes.MenuItem, { [classes.SubmenuItem]: submenu, Mobile: mobile }, submenuClassName)}
          onClick={onClick}
          onBlur={onBlur}
          role="menuitem"
        >
          {hasIcon ? <div className={classes.Icon}>{children}</div> : null}
          <ListItemText className={classNames({ [classes.SubmenuItemText]: submenu })} primary={linkName} />
        </ListItem>
      )
    );
  }
);

MenuItem.propTypes = {
  to: PropTypes.string,
  linkName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  submenu: PropTypes.bool,
  submenuClassName: PropTypes.string,
  icon: PropTypes.node,
  hasIcon: PropTypes.bool,
  children: PropTypes.node,
  mobile: PropTypes.bool,
};
