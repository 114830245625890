import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

import { Button, Dialog } from 'components';
import { useDeleteProfileDialogStyles } from './styles';

const DeleteProfileDialog = ({ open, closeDialog, submit }) => {
  const { t } = useTranslation();
  const classes = useDeleteProfileDialogStyles();

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      title={t('form_warning')}
      titleBoxClass={classes.PinkPatch}
      content={
        <Box className={classes.DialogContent}>
          <Box mb={6} className={classes.Center} tabIndex={0}>{t('form_delete_profile_warning_text')}</Box>
          <Box mb={3} className={classes.ActionContainer}>
            <Box mr={1}>
              <Button buttonClassName={classNames('Pink', 'Secondary')} onClick={closeDialog}>
                {t('common_cancel')}
              </Button>
            </Box>
            <Box ml={1}>
              <Button buttonClassName="Pink" onClick={submit}>
                {t('form_delete_button_text')}
              </Button>
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

DeleteProfileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default DeleteProfileDialog;
