import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import ProfilePage from './ProfilePage';
import { groupSearch } from 'store/groups/actions';
import {
  changePassword,
  deleteProfile,
  getSettlements,
  logout,
  refreshToken,
  setProfileData,
  updateProfileDetails,
} from 'store/profile/actions';
import { addLoader, removeLoader, showNotification } from 'store/common/actions';
import { addCommonLoader, removeCommonLoader } from 'store/loader/actions';

const mapStateToProps = (state) => {
  return {
    profileDetails: state.profile.details,
    groups: state.groups.groups,
    settlements: state.profile.settlements,
    profileTags: state.tags.profileTags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      groupSearch,
      getSettlements,
      updateProfileDetails,
      changePassword,
      showNotification,
      setProfileData,
      deleteProfile,
      logout,
      refreshToken,
      addLoader,
      removeLoader,
      addCommonLoader,
      removeCommonLoader,
    },
    dispatch
  );
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProfilePage);
