import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';

import { useHamburgerStyles } from './styles';

export const Hamburger = ({ onClick, open }) => {
  const classes = useHamburgerStyles();
  const { t } = useTranslation();

  return (
    <IconButton className={classes.Container} onClick={onClick} tabIndex={0} aria-label={t('alt_main_menu')}>
      <span className={classNames(classes.Bar, classes.Bar1, 'Bar', { [classes.Bar1Open]: open })} />
      <span className={classNames(classes.Bar, classes.Bar2, 'Bar', { [classes.Bar2Open]: open })} />
    </IconButton>
  );
};

Hamburger.propTypes = {
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  t: PropTypes.func,
};
