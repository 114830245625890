import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

import { Dialog } from 'components';
import { useNavigationDialogStyles } from './styles';
import { LessonWithCourses, Course } from 'types/types';
import { getCurrentLanguage } from 'utils/language';
import { getTopicsCoursesUrl } from 'utils/getTopicsCoursesUrl';

const NavigationDialog = ({ open, closeDialog, lesson, history, course }) => {
  const { t } = useTranslation();
  const classes = useNavigationDialogStyles();

  const onCourseClick = (courseId) => {
    const topicId = lesson.courses.find((x) => x.id === courseId).modules[0].id;
    history.push(getTopicsCoursesUrl(getCurrentLanguage(window.location.pathname), topicId, courseId, lesson.id));
    closeDialog();
  };

  const onCourseKeyPress = (event, courseId) => {
    if (event.key === 'Enter') {
      onCourseClick(courseId);
    }
  };

  const onTopicClick = (topicId) => {
    history.push(getTopicsCoursesUrl(getCurrentLanguage(window.location.pathname), topicId, course.id));
  };

  const onTopicKeyPress = (event, topicId) => {
    if (event.key === 'Enter') {
      onTopicClick(topicId);
    }
  };

  const dialogRef = useRef(null);
  const firstItemRef = useRef(null);

  const subtitleId = 'nav-dialog-subtitle';

  return (
    <Dialog
      ref={dialogRef}
      open={open}
      onClose={closeDialog}
      title={lesson ? t('search_page_multiple_courses_dialog_title') : t('search_page_multiple_topics_dialog_title')}
      titleBoxClass={classes.YellowPatch}
      ariaDescribedby={subtitleId}
      firstItemRef={firstItemRef}
      content={
        <Box tabIndex={-1} className={classes.DialogContent}>
          <Box
            id={subtitleId}
            mb={6}
            tabIndex={0}
            aria-label={
              lesson
                ? t('search_page_multiple_courses_dialog_subtitle')
                : t('search_page_multiple_topics_dialog_subtitle')
            }
          >
            {lesson
              ? t('search_page_multiple_courses_dialog_subtitle')
              : t('search_page_multiple_topics_dialog_subtitle')}
          </Box>
          <Box
            component="ul"
            className={classes.CourseTitlesContainer}
            mb={6}
            aria-label={
              lesson
                ? t('alt_search_page_multiple_courses_dialog_list')
                : t('alt_search_page_multiple_topics_dialog_list')
            }
          >
            {!!lesson &&
              lesson.courses.map((course, index) => (
                <Box
                  key={course.id}
                  component="li"
                  className="Link"
                  mb={3}
                  tabIndex={0}
                  role="link"
                  ref={!index ? firstItemRef : undefined}
                  aria-label={`${course.title} ${t('common_course')}`}
                  onClick={() => onCourseClick(course.id)}
                  onKeyPress={(event) => onCourseKeyPress(event, course.id)}
                >
                  {course.title}
                </Box>
              ))}
            {!!course &&
              course.modules.map((module, index) => (
                <Box
                  key={module.id}
                  ref={!index ? firstItemRef : undefined}
                  component="li"
                  className="Link"
                  mb={3}
                  tabIndex={0}
                  role="link"
                  aria-label={`${module.title} ${t('common_topic')}`}
                  onClick={() => onTopicClick(module.id)}
                  onKeyPress={(event) => onTopicKeyPress(event, module.id)}
                >
                  {module.title}
                </Box>
              ))}
          </Box>
        </Box>
      }
    />
  );
};

NavigationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  lesson: LessonWithCourses,
  history: PropTypes.object,
  course: Course,
};

export default NavigationDialog;
