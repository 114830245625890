import { auto, bold, center, collapse, flex, flexEnd, hidden, inherit, spaceBetween, start, top } from 'constants/Jss';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useResultTableStyles = makeStyles((theme) => ({
  ResultTableContainer: {
    width: inherit,

    '& table': {
      width: inherit,
      borderCollapse: collapse,
    },

    '& th': {
      textAlign: start,
      paddingBottom: 10,
      fontWeight: bold,
      color: theme.palette.examResultBlue,

      '&.hidden': {
        visibility: hidden,
      },
    },

    '& td': {
      textAlign: start,
      verticalAlign: top,
      fontWeight: bold,
      padding: '20px 10px 20px 0',

      '&.success': {
        color: theme.palette.greenSuccess,
      },

      '&.fail': {
        color: theme.palette.lightPink,
      },

      '&:nth-child(1)': {
        width: '5%',
      },
      '&:nth-child(2)': {
        width: '55%',
        padding: '20px 50px 20px 0',
      },
      '&:nth-child(3)': {
        width: '20%',
      },
      '&:nth-child(4)': {
        width: '20%',
      },
    },
  },
  WordsMatchingLayout: {
    '& td': {
      '&:nth-child(1)': {
        width: '5%',
      },
      '&:nth-child(2)': {
        width: '50%',
        padding: '20px 50px 20px 0',
      },
      '&:nth-child(3)': {
        width: '45%',
      },
    },
  },
  TableRowBorder: {
    borderTop: `1px solid ${theme.palette.examResultBlue}`,
  },
  AlignCenter: {
    textAlign: 'center !important',
  },

  Success: {
    color: theme.palette.greenSuccess,
  },

  Fail: {
    color: theme.palette.lightPink,
  },

  MobileResultTableContainer: {
    width: inherit,
    fontSize: 14,

    '& p': {
      margin: 0,
    },

    '& table': {
      width: inherit,
      borderCollapse: collapse,
    },

    '& tr': {
      borderTop: `1px solid ${theme.palette.examResultBlue}`,

      '&:nth-child(1)': {
        borderTop: 0,
      },
    },

    '& td': {
      textAlign: start,
      verticalAlign: top,
      fontWeight: bold,
      padding: '10px 0 10px',
    },
  },
  FirstLayout: {
    '& td': {
      '&:nth-child(1)': {
        width: '5%',
      },
      '&:nth-child(2)': {
        width: '25%',
      },
      '&:nth-child(3)': {
        width: '25%',
      },
      '&:nth-child(4)': {
        width: '45%',
      },
    },
  },
  SecondLayout: {
    '& td': {
      '&:nth-child(1)': {
        width: '5%',
      },
      '&:nth-child(2)': {
        width: '95%',
      },
    },
  },

  AlignRight: {
    display: flex,
    alignItems: center,
    justifyContent: flexEnd,

    '& span': {
      marginRight: 5,
    },
  },
  Selected: {
    color: theme.palette.examResultBlue,
  },
  SpaceBetween: {
    width: inherit,
    display: flex,
    justifyContent: spaceBetween,
  },
  AnswerContainer: {
    marginTop: 5,
    width: auto,
  },
}));
