import React from 'react';
import PropTypes from 'prop-types';
import { MandatoryLinks } from 'components/MandatoryLinks/MandatoryLinks';
import FormHelperText from '@material-ui/core/FormHelperText';
import { IUrl } from '../../types/types';
import { useAcceptLinks } from './useAcceptLinks';

const AcceptTermsField = ({
  name,
  value,
  onChange,
  setFieldTouched,
  errors,
  touched,
  showNotification,
  termsAndConditions,
  privacyPolicy,
  linkType,
}) => {
  const fieldName = name || 'acceptTerms';
  const error = errors[fieldName] ? errors[fieldName] : '';
  const isTouched = !!(touched || {})[fieldName];
  const parts = useAcceptLinks(termsAndConditions, privacyPolicy, linkType);

  return (
    <>
      <MandatoryLinks
        fieldName={fieldName}
        fieldValue={value}
        onChange={onChange}
        setFieldTouched={setFieldTouched}
        error={error}
        showNotification={showNotification}
        parts={parts}
      />
      {error && isTouched ? <FormHelperText error>{error}</FormHelperText> : ''}
    </>
  );
};

AcceptTermsField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  setFieldTouched: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  showNotification: PropTypes.func,
  termsAndConditions: IUrl,
  privacyPolicy: IUrl,
  linkType: PropTypes.string,
};

export default AcceptTermsField;
