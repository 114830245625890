import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { detectLanguage } from './language';
import Cookies from 'js-cookie';
import Settings from 'env';
import { Location } from '../types/types';

export const withSSRProps = {
  refetch: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
};

let firstLoad = true;

export default function withSSR(Page) {
  class SSR extends React.Component {
    static propTypes = {
      location: Location,
      initialData: PropTypes.object,
      dispatch: PropTypes.func,
      staticContext: PropTypes.any,
      match: PropTypes.any,
      route: PropTypes.shape({
        data: PropTypes.any,
      }),
    };

    static async getInitialData(ctx) {
      const { req } = ctx;
      const pathname = req.url.includes('?') ? req.url.split('?')[0] : req.url;
      const search = req.url.includes('?') ? req.url.split('?')[1] : req.search || '';
      const token = req.cookies ? req.cookies.MTU_TOKEN : Cookies.get(Settings.API_TOKEN);

      // Need to call the wrapped components getInitialData if it exists
      return Page.getInitialData
        ? Page.getInitialData({ ...ctx, firstLoad, pathname, search, token })
        : Promise.resolve(null);
    }

    state = {
      data: this.props.initialData,
      isLoading: this.isLoading(true),
    };

    ignoreLastFetch = false;

    isLoading(loading) {
      const isServer = !!this.props.staticContext;

      return !isServer && loading;
    }

    async componentDidMount() {
      this.mounted = true;
      if (!this.state.data) {
        await this.fetchData();
      }
      firstLoad = false;
    }

    componentWillUnmount() {
      this.ignoreLastFetch = true;
      this.mounted = false;
    }

    fetchData = async (options) => {
      if (!this.ignoreLastFetch) {
        this.setState({ isLoading: this.isLoading(true) });
        try {
          const data = await SSR.getInitialData({
            match: this.props.match,
            location: this.props.location,
            dispatch: this.props.dispatch,
            language: detectLanguage(this.props.location.pathname),
            req: {
              url: this.props.location.pathname,
              search: this.props.location.search,
            },
            options,
          });
          if (this.mounted) {
            this.setState({ data, isLoading: this.isLoading(false) });
          }
        } catch (error) {
          console.error(error);
          if (this.mounted) {
            this.setState(() => ({
              data: { error },
              isLoading: this.isLoading(false),
            }));
          }
        }
      }
    };

    render() {
      const rest = { ...this.props };
      delete rest.initialData;
      const data = { ...this.props.route.data, ...this.state.data };
      const shouldShowLoading = this.state.isLoading && !firstLoad;

      return (
        <Page
          {...rest}
          refetch={this.fetchData}
          isLoading={this.state.isLoading}
          data={data}
          firstLoad={firstLoad}
          shouldShowLoading={shouldShowLoading}
        />
      );
    }
  }

  SSR.displayName = `SSR(${getDisplayName(Page)})`;
  return connect()(SSR);
}

const getDisplayName = (component) => {
  return component.displayName || component.name || 'Component';
};
