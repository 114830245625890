import { auto } from 'constants/Jss';

export const aboutPageStyles = (theme) => ({
  AboutPage: {
    width: '100%',
    maxWidth: 950,
    marginLeft: auto,
    marginRight: auto,
    color: theme.palette.blackTitle,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  First: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  Last: {
    [theme.breakpoints.up('md')]: {
      marginBottom: 410,
    },
  },
  Link: {
    color: theme.palette.cyan,
    '&:hover': {
      color: theme.palette.cyanHover,
    },
  },
});
