import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { Field, Formik } from 'formik';

import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Button, Dialog, OverallErrorMessages } from 'components';
import { auto } from 'constants/Jss';
import { AuthProviderCodes, GroupTypes, Regex } from 'constants/Constants';
import { usePatches, useRegisterDialogStyles } from './styles';
import { Document, Group, SocialToken } from 'types/types';
import AcceptTermsField from 'components/MandatoryLinks/AcceptTermsField';
import MyTooltip from 'components/Tooltip/MyTooltip';
import Switch from 'components/Switch/Switch';
import classNames from 'classnames';
import TextFieldWrapper from 'components/Wrappers/TextField';
import theme from 'theme';
import { isHiddenOrganization } from 'utils/isHiddenOrganization';
import { setProfileFormErrors } from 'utils/setProfileFormErrors';
import { validateTaxNumber } from 'utils/validateTaxNumber';
import { LINK_TYPES } from 'components/MandatoryLinks/useAcceptLinks';

const RegisterDialog = ({
  open,
  socialToken,
  closeDialog,
  submit,
  groupSearch,
  groups,
  settlementSearch,
  settlements,
  showNotification,
  removeNotification,
  termsAndConditions,
  privacyPolicy,
}) => {
  const { t } = useTranslation();
  const classes = useRegisterDialogStyles();
  const patchClasses = usePatches();
  const [hiddenWarning, setHiddenWarning] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(null);
  const [kkvSwitchDisabled, setKkvSwitchDisabled] = useState(false);
  const [taxNumberDisabled, setTaxNumberDisabled] = useState(false);
  const [autoFilled, setAutoFilled] = useState(false);

  const isSocial = !!socialToken && AuthProviderCodes.isSocial(socialToken.provider);

  const validationSchema = {
    name: Yup.string()
      .required(t('form_required'))
      .test('name-length', t('form_name_length'), (value) => !!value && value.length > 2 && value.length < 101),
    email: Yup.string()
      .email(t('form_invalid_email'))
      .required(t('form_required')),
    organization: Yup.string()
      .required(t('form_required'))
      .test(
        'organization-length',
        t('form_organization_length'),
        (value) => !!value && value.length > 2 && value.length < 256
      )
      .nullable(true),
    taxNumber: Yup.string()
      .required(t('form_required'))
      .test('validate-tax-number', t('form_tax_number_format_invalid'), validateTaxNumber),
    kkv: Yup.boolean(),
    acceptTerms: Yup.mixed().oneOf([true], t('form_must_accept')),
    settlement: Yup.string()
      .required(t('form_required'))
      .test(
        'only-existing',
        t('form_existing_only'),
        (value) =>
          settlements.some((settlementObj) => !value || settlementObj.name === value) ||
          (groups.length === 1 && groups[0].settlement && value === groups[0].settlement.name) ||
          autoFilled
      )
      .nullable(true),
  };

  if (!isSocial) {
    validationSchema.password = Yup.string()
      .required(t('form_required'))
      .matches(Regex.Password, t('form_password_strength'));
    validationSchema.passwordConfirmation = Yup.string()
      .required(t('form_required'))
      .oneOf([Yup.ref('password'), null], t('form_passwords_must_match'));
  }

  const debouncedGroupSearch = debounce((e) => {
    !!e.target.value && groupSearch(e.target.value);
  }, 500);

  const debouncedSettlementSearch = debounce((e) => {
    !!e.target.value && !autoFilled && settlementSearch(e.target.value);
  }, 500);

  const tooltips = {
    organization: 'organization',
    kkv: 'kkv',
    settlement: 'settlement',
    taxNumber: 'taxNumber',
  };

  const focusNextField = useCallback(() => {
    document.getElementById('settlement-autocomplete').focus();
  }, []);

  const onClose = (e) => {
    setHiddenWarning(false);
    removeNotification();
    closeDialog(e);
  };

  const handleTaxNumberFieldReset = (setFieldValue) => {
    setFieldValue('taxNumber', '');
    setTaxNumberDisabled(false);
  };

  const handleTaxNumberAfterOrganizationChange = (setFieldValue, taxNumber) => {
    setFieldValue('taxNumber', taxNumber || '');
    setTaxNumberDisabled(Boolean(taxNumber));
  };

  const handleSubmit = async (formValues, { setFieldError }) => {
    try {
      await submit(formValues, socialToken);
    } catch (err) {
      setProfileFormErrors(err, setFieldError, t);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('menu_item_register')}
      titleClass={classes.Title}
      titleBoxClass={patchClasses.YellowPatch}
      contentClass={classes.TooltipDialog}
      aria-modal={true}
      role="dialog"
      aria-labelledby="RegisterButton"
      noFocus
      content={
        <Formik
          initialValues={{
            name: isSocial ? socialToken.name : '',
            organization: '',
            kkv: true,
            settlement: '',
            taxNumber: '',
            email: isSocial ? socialToken.email : '',
            password: '',
            passwordConfirmation: '',
            acceptTerms: false,
          }}
          validationSchema={Yup.object().shape(validationSchema)}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleSubmit, setFieldTouched, setFieldValue }) => (
            <form onSubmit={() => {}}>
              <OverallErrorMessages />
              <Box className={classes.FormBox} ml={auto} mr={auto}>
                <Box mb={3}>
                  <TextField
                    id="name"
                    name="name"
                    label={t('form_name')}
                    variant="outlined"
                    autoComplete="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched('name', true)}
                    error={!!errors.name && touched.name}
                    helperText={touched.name ? errors.name : ''}
                    fullWidth
                    autoFocus={true}
                  />
                </Box>
                <Box mb={hiddenWarning ? 1 : 3} className={classes.FieldBox}>
                  <Autocomplete
                    id="group-autocomplete"
                    freeSolo
                    options={groups.filter((group) => group.public).map((group) => group.name)}
                    classes={{
                      clearIndicator: classNames(classes.ClearIndicator, { Filled: !!values.organization }),
                    }}
                    clearText={t('common_clear')}
                    onChange={async (event, newValue) => {
                      if (newValue === null) {
                        return;
                      }

                      setFieldValue('organization', newValue);
                      setKkvSwitchDisabled(true);
                      const organizations = await groupSearch(newValue);
                      const organization = organizations.find(
                        (org) => org.name.toLowerCase() === newValue.toLowerCase()
                      );
                      setFieldValue('kkv', organization.type === GroupTypes.KKV);
                      setAutoFilled(true);
                      await settlementSearch(organization.settlement.name);
                      setFieldValue('settlement', organization.settlement.name);
                      handleTaxNumberAfterOrganizationChange(setFieldValue, organization.taxNumber);
                      focusNextField();
                    }}
                    onInputChange={(event, value, reason) => {
                      if (reason.toString() === 'clear') {
                        setFieldValue('organization', '');
                        handleTaxNumberFieldReset(setFieldValue);
                      }
                      if (reason.toString() === 'reset') {
                        setFieldValue('organization', value);
                        handleTaxNumberFieldReset(setFieldValue);
                      }
                    }}
                    name="organization"
                    renderInput={(params) => {
                      delete params.inputProps['value'];
                      return (
                        <Field
                          {...params}
                          component={TextFieldWrapper}
                          name="organization"
                          label={t('form_organization')}
                          autocomplete="organization"
                          onChange={(e) => {
                            handleChange(e);
                            debouncedGroupSearch(e);
                            handleTaxNumberFieldReset(setFieldValue);
                          }}
                          onBlur={async (e) => {
                            if (e.target && e.target.value) {
                              const company = e.target.value;
                              const organizations = await groupSearch(company);
                              const organization = organizations.find(
                                (org) => org.name.toLowerCase() === company.toLowerCase()
                              );
                              setHiddenWarning(isHiddenOrganization(organizations, company));
                              if (!organizations.length || !organization) {
                                setKkvSwitchDisabled(false);
                                setFieldValue('kkv', true);
                              } else {
                                setKkvSwitchDisabled(true);
                                setFieldValue('kkv', organization.type === GroupTypes.KKV);
                                setAutoFilled(true);
                                await settlementSearch(organization.settlement.name);
                                setFieldValue('settlement', organization.settlement.name);
                                handleTaxNumberAfterOrganizationChange(setFieldValue, organization.taxNumber);
                                focusNextField();
                              }
                            }
                            setFieldTouched('organization', true);
                          }}
                          fullWidth
                        />
                      );
                    }}
                  />
                  <MyTooltip
                    open={openTooltip === tooltips.organization}
                    onClick={() => setOpenTooltip(openTooltip === tooltips.organization ? null : tooltips.organization)}
                    onClose={() => {
                      openTooltip === tooltips.organization && setOpenTooltip(null);
                    }}
                    text={t('form_register_tooltip_organization')}
                    preLine
                  />
                </Box>
                {hiddenWarning && (
                  <Box mb={1} color={theme.palette.errorColor}>
                    {t('form_group_warning')}
                  </Box>
                )}
                <Box mb={3} className={classNames(classes.FieldBox, classes.FlexFieldBox)}>
                  <Switch
                    name="kkv"
                    label="KKV"
                    inlineOnLabel={t('yes')}
                    inlineOffLabel={t('no')}
                    mini
                    checked={values.kkv}
                    onChange={() => {
                      setFieldValue('kkv', !values.kkv);
                    }}
                    disabled={kkvSwitchDisabled}
                  />
                  <MyTooltip
                    open={openTooltip === tooltips.kkv}
                    onClick={() => setOpenTooltip(openTooltip === tooltips.kkv ? null : tooltips.kkv)}
                    onClose={() => {
                      openTooltip === tooltips.kkv && setOpenTooltip(null);
                    }}
                    text={t('form_register_tooltip_kkv')}
                    top={-4}
                  />
                </Box>
                <Box mb={3} className={classes.FieldBox}>
                  <TextField
                    type="text"
                    id="tax-number-field"
                    name="taxNumber"
                    label={t('form_tax_number')}
                    variant="outlined"
                    value={values.taxNumber}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched('taxNumber', true)}
                    error={!!errors.taxNumber && touched.taxNumber}
                    helperText={touched.taxNumber ? errors.taxNumber : ''}
                    fullWidth
                    disabled={taxNumberDisabled}
                  />

                  <MyTooltip
                    open={openTooltip === tooltips.taxNumber}
                    onClick={() => setOpenTooltip(openTooltip === tooltips.taxNumber ? null : tooltips.taxNumber)}
                    onClose={() => {
                      openTooltip === tooltips.taxNumber && setOpenTooltip(null);
                    }}
                    text={t('form_tax_number_tooltip')}
                  />
                </Box>
                <Box mb={3} className={classes.FieldBox}>
                  <Autocomplete
                    id="settlement-autocomplete"
                    freeSolo
                    options={settlements.map((settlement) => settlement.name)}
                    classes={{
                      clearIndicator: classNames(classes.ClearIndicator, { Filled: !!values.settlement }),
                    }}
                    clearText={t('common_clear')}
                    onChange={(event, newValue) => {
                      setAutoFilled(false);
                      setFieldValue('settlement', newValue);
                    }}
                    onInputChange={(event, value, reason) => {
                      setAutoFilled(false);
                      if (reason.toString() === 'clear') {
                        setFieldValue('settlement', '');
                      }
                    }}
                    name="settlement"
                    renderInput={(params) => {
                      delete params.inputProps['value'];
                      return (
                        <Field
                          {...params}
                          component={TextFieldWrapper}
                          name="settlement"
                          label={t('form_settlement')}
                          autocomplete="address-level2"
                          onChange={(e) => {
                            setAutoFilled(false);
                            handleChange(e);
                            debouncedSettlementSearch(e);
                          }}
                          fullWidth
                        />
                      );
                    }}
                  />
                  <MyTooltip
                    open={openTooltip === tooltips.settlement}
                    onClick={() => setOpenTooltip(openTooltip === tooltips.settlement ? null : tooltips.settlement)}
                    onClose={() => {
                      openTooltip === tooltips.settlement && setOpenTooltip(null);
                    }}
                    text={t('form_register_tooltip_settlement')}
                  />
                </Box>
                <Box mb={3}>
                  <TextField
                    type="email"
                    id="email-field"
                    name="email"
                    label={t('form_email')}
                    variant="outlined"
                    autoComplete="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched('email', true)}
                    error={!!errors.email && touched.email}
                    helperText={touched.email ? errors.email : ''}
                    fullWidth
                    disabled={isSocial}
                  />
                </Box>
                {!isSocial && (
                  <>
                    <Box mb={3}>
                      <TextField
                        id="password"
                        name="password"
                        type="password"
                        label={t('form_password')}
                        variant="outlined"
                        autoComplete="new-password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={() => setFieldTouched('password', true)}
                        error={!!errors.password && touched.password}
                        helperText={touched.password ? errors.password : ''}
                        fullWidth
                        inputProps={{ 'aria-label': t('form_password') }}
                      />
                    </Box>
                    <Box mb={3}>
                      <TextField
                        id="passwordConfirmation"
                        name="passwordConfirmation"
                        type="password"
                        label={t('form_confirm_password')}
                        variant="outlined"
                        autoComplete="new-password"
                        value={values.passwordConfirmation}
                        onChange={handleChange}
                        onBlur={() => setFieldTouched('passwordConfirmation', true)}
                        error={!!errors.passwordConfirmation && touched.passwordConfirmation}
                        helperText={touched.passwordConfirmation ? errors.passwordConfirmation : ''}
                        fullWidth
                        inputProps={{ 'aria-label': t('form_confirm_password') }}
                      />
                    </Box>
                  </>
                )}
                <Box mb={3}>
                  <AcceptTermsField
                    name="acceptTerms"
                    value={values.acceptTerms}
                    onChange={handleChange}
                    setFieldTouched={setFieldTouched}
                    errors={errors}
                    touched={touched}
                    showNotification={showNotification}
                    termsAndConditions={termsAndConditions}
                    privacyPolicy={privacyPolicy}
                    linkType={LINK_TYPES.REGISTRATION_LINKS}
                  />
                </Box>
                <Box mb={4}>
                  <Button
                    className={classes.RegisterButtonContainer}
                    buttonClassName="DarkBlue"
                    onClick={handleSubmit}
                    type="submit"
                    id="RegisterButton"
                    aria-label={t('form_register_button_text')}
                  >
                    {t('form_register_button_text')}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      }
    />
  );
};

RegisterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  socialToken: SocialToken,
  closeDialog: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  groupSearch: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(Group),
  settlementSearch: PropTypes.func,
  settlements: PropTypes.array,
  showNotification: PropTypes.func,
  removeNotification: PropTypes.func,
  termsAndConditions: Document,
  privacyPolicy: Document,
};

export default RegisterDialog;
