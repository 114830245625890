import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logout } from 'store/profile/actions';
import App from './App';
import { bindActionCreators } from 'redux';
import { removeNotification } from 'store/common/actions';
import { setIsStayLoggedIn } from 'store/auth/actions';
import { selectLoading } from 'store/loader/selectors';

const mapStateToProps = (state) => {
  return {
    loading: selectLoading(state),
    notification: state.common.notification,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ logout, removeNotification, setIsStayLoggedIn }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
