import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import { absolute, bold, center, grid, start, end } from 'constants/Jss';

import { Button, Dialog } from 'components';

import MobileDialogPatch from 'assets/images/result-dialog-patch-mobile.png';
import DialogPatch from 'assets/images/result-dialog-patch.png';

import ResultCard from './ResultCard';

const useStyles = makeStyles((theme) => {
  return {
    DialogPatch: {
      position: absolute,
      top: 0,
      left: 0,
    },
    ExamResultSummary: {
      fontWeight: bold,
      textAlign: center,
      marginBottom: 80,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 0,
      },
    },
    ButtonContainer: {
      display: grid,
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateAreas: `
      "col1 col2 col3"
      `,
      margin: '80px 0 150px',
      padding: '0 120px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 50px',
        margin: '16px 0 150px',
      },

      '&.MobileButtonContainer': {
        padding: 0,
      },
    },
    CloseDialogButton: {
      gridArea: 'col2',
      textAlign: center,
      [theme.breakpoints.down('sm')]: {
        gridArea: 'col1',
        justifySelf: start,
      },
    },
    UpButton: {
      gridArea: 'col3',
      justifySelf: end,
    },
  };
});

const ExamResultDialog = ({ isOpen, resultExplain, handleDialog }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isDesktopLayout = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const results = resultExplain.explain || [];

  const resultDialogRef = useRef(null);
  const scrollToTop = () => resultDialogRef.current.scrollIntoView({ behavior: 'smooth' });

  return (
    <Dialog
      innerRef={resultDialogRef}
      open={isOpen}
      fullWidth={true}
      maxWidth={'md'}
      onClose={() => handleDialog(false)}
      title={t('exam_results_page_check_exams_dialog_title')}
      content={
        <Box>
          <img className={classes.DialogPatch} src={isDesktopLayout ? DialogPatch : MobileDialogPatch} alt="" />
          <div tabIndex={0} className={classes.ExamResultSummary}>
            {`${resultExplain.questionCounter} ${t('result_card_question')} - ${resultExplain.correctAnswerCounter} ${t(
              'exam_result_summary_correct_solution'
            )}`}
          </div>
          {results.map((result, index) => (
            <ResultCard
              key={result.id}
              result={result}
              index={index}
              count={results.length}
              isDesktopLayout={isDesktopLayout}
            />
          ))}
          <div
            className={
              isDesktopLayout ? classes.ButtonContainer : classNames(classes.ButtonContainer, 'MobileButtonContainer')
            }
          >
            <Button buttonClassName="Pink" className={classes.CloseDialogButton} onClick={() => handleDialog(false)}>
              {t('common_close')}
            </Button>
            <Button buttonClassName="Blue" className={classes.UpButton} onClick={scrollToTop}>
              {t('common_up')}
            </Button>
          </div>
        </Box>
      }
    />
  );
};

ExamResultDialog.propTypes = {
  isOpen: PropTypes.bool,
  resultExplain: PropTypes.object,
  handleDialog: PropTypes.func,
};

export default ExamResultDialog;
