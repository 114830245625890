import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import LessonDetailPage from './LessonDetailPage';
import { showNotification } from 'store/common/actions';
import { getTest } from 'store/tests/actions';
import { addCommonLoader, removeCommonLoader } from 'store/loader/actions';
import { selectLoading } from 'store/loader/selectors';

const mapStateToProps = (state) => {
  return {
    lesson: state.lessons.lesson,
    course: state.courses.course,
    loading: selectLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getTest, showNotification, addCommonLoader, removeCommonLoader }, dispatch);
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LessonDetailPage);
