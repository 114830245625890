import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import Truncate from 'react-truncate';

import { useTopListCardsStyles } from './styles';
import NoImage from 'assets/images/no-image-3.png';
import { getUrl } from 'utils/urlFromImagePath';
import { User } from 'types/types';
import { TopListTypes, TopCardHeight } from 'constants/Constants';

export const TopListCard = ({ user, group, activeTab, myId, myCardRef, isMyCardSelected, podium }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const classes = useTopListCardsStyles();
  const { t } = useTranslation();
  const myCard = user ? myId === user.id && isMyCardSelected : myId === group.id && isMyCardSelected;

  const onClick = () => {
    setIsFlipped(!isFlipped);
  };

  useEffect(() => {
    setIsFlipped(false);
  }, [user, group]);

  return (
    <Box onClick={onClick} role="presentation" className={classes.TopListCardContainer}>
      <Box
        className={classNames(classes.TopListCardFlipper, {
          [classes.TopListCardFlipperFlipped]: isFlipped,
        })}
      >
        <Box
          className={classNames(classes.TopListCard, classes.TopListCardFront, {
            [classes.First]: user ? user.rank === 1 : group.rank === 1,
            [classes.Second]: user ? user.rank === 2 : group.rank === 2,
            [classes.Third]: user ? user.rank === 3 : group.rank === 3,
            myCard,
          })}
          tabIndex={0}
          ref={myCard ? myCardRef : null}
          aria-label={`${activeTab === TopListTypes.User ? user.rank : group.rank}. ${t('alt_place')}: ${
            activeTab === TopListTypes.User ? user.name : group.name
          }, ${activeTab === TopListTypes.User ? user.group : group.membersCount + ' ' + t('common_user_plural')}, ${
            user ? user.score : group.score
          } ${t('common_xp')}`}
          height={TopCardHeight}
        >
          <Box>
            {activeTab === TopListTypes.User ? (
              <Box className={classes.TopListCardIconContainer}>
                <Box
                  style={{ backgroundImage: user.avatar ? `url(${getUrl(user.avatar)}` : `url(${NoImage})` }}
                  className={classNames(classes.TopListCardIcon, { [classes.TopListCardNoIcon]: !user.avatar })}
                  aria-hidden={true}
                />
              </Box>
            ) : null}
            <Box className={classes.TopListBox}>
              <Box className={classes.NameAndOrganization} style={{ marginTop: user ? 52 : 24 }}>
                <Box height={user ? 42 : 63} aria-hidden={true} className={classes.Name}>
                  <Truncate lines={user ? 2 : 3} width={226}>
                    {user ? user.name : group.name}
                  </Truncate>
                </Box>
                {user ? (
                  <Box mt={0.5} className={classes.Organization} aria-hidden={true}>
                    <Truncate lines={2} width={226}>
                      {user.group}
                    </Truncate>
                  </Box>
                ) : (
                  <Box className={classes.ValueAndText}>
                    <Box className={classes.Value} aria-hidden={true}>
                      {group.membersCount}
                    </Box>
                    <Box className={classes.Text} aria-hidden={true}>
                      {t('common_user_plural')}
                    </Box>
                  </Box>
                )}
              </Box>
              <Box className={classNames(classes.XPAndRank, { podium, myCard })}>
                <Box className={classes.ValueAndText}>
                  <Box className={classes.Value} aria-hidden={true}>
                    {user ? user.score : group.score}
                  </Box>
                  <Box className={classes.Text} aria-hidden={true}>
                    {t('common_xp')}
                  </Box>
                </Box>
                <Box className={classes.Rank} aria-hidden={true}>{`#${user ? user.rank : group.rank}`}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className={classNames(classes.TopListCard, classes.TopListCardBack, {
            [classes.First]: user ? user.rank === 1 : group.rank === 1,
            [classes.Second]: user ? user.rank === 2 : group.rank === 2,
            [classes.Third]: user ? user.rank === 3 : group.rank === 3,
            myCard,
            [classes.TopListGroupCardBack]: !user,
          })}
          aria-hidden={true}
        >
          <Box>
            <Box className={classes.TopListBox}>
              <Box className={classes.NameAndOrganization} style={{ marginTop: 24 }}>
                <Box
                  aria-hidden={true}
                  className={classNames(
                    { [classes.UserNameBackCard]: !!user, [classes.OrganizationNameBackCard]: !user },
                    classes.WordWrap
                  )}
                >
                  {user ? user.name : group.name}
                </Box>
                {!!user && (
                  <Box mt={1} className={classNames(classes.Organization, classes.WordWrap)} aria-hidden={true}>
                    {user.group}
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              className={classNames(classes.Back, { podium, myCard, [classes.BackGroup]: !user })}
              textAlign="center"
              aria-hidden={true}
            >
              {t('common_back')}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

TopListCard.propTypes = {
  user: User,
  group: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
    membersCount: PropTypes.number,
    rank: PropTypes.number,
    score: PropTypes.number,
  }),
  activeTab: PropTypes.oneOf([TopListTypes.Group, TopListTypes.User]),
  myId: PropTypes.any,
  myCardRef: PropTypes.any,
  isMyCardSelected: PropTypes.bool,
  podium: PropTypes.bool,
};
