import { name } from 'platform';

export const Browsers = {
  CHROME: 'Chrome',
  CHROME_MOBILE: 'Chrome Mobile',
  FIREFOX: 'Firefox',
  EDGE: 'Microsoft Edge',
};

export const browser = name;
