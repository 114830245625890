import createStyles from '@material-ui/core/styles/createStyles';
import { none, noRepeat } from 'constants/Jss';
import Anett from 'assets/images/anett.png';
import PrizePatchFlipped from 'assets/images/prize-patch-flipped.png';
import SnowmanPatchFlipped from 'assets/images/snowman-patch-flipped.png';
import Temple from 'assets/images/temple.png';
import AboutLeftPatch from 'assets/images/about-left-patch.png';
import AboutRightPatch from 'assets/images/about-right-patch.png';
import PlayPatch from 'assets/images/play-patch.png';
import CertificateBottomRight from 'assets/images/certificate-bottom-right.png';
import CertificateBottomLeft from 'assets/images/certificate-bottom-left.png';

//TODO Refactor the background images if there is time for it
export const pageStyles = (theme) =>
  createStyles({
    MainContainer: {
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        '& > div > div': {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          maxWidth: '100%',
        },
      },
    },
    HomePage: {
      backgroundImage:
        `url(${Anett}), ` +
        `linear-gradient(${theme.palette.headerBlue}, ${theme.palette.headerBlue}), ` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 188 180'><path fill='rgb(97,190,235)' d='M188,93H0c0-.075,0-.15,0-.225v-.1A91.41,91.41,0,0,1,16.054,40.861a94.155,94.155,0,0,1,59-38.979,96.241,96.241,0,0,1,37.888,0,94.05,94.05,0,0,1,59,38.979A92.285,92.285,0,0,1,180.613,56.6,91.269,91.269,0,0,1,188,92.68c0,.106,0,.214,0,.32Z'/><ellipse fill='rgb(97,190,235)' cx='49' cy='48' rx='49' ry='48' transform='translate(0, 46)'/></svg>"), ` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 188 180'><path fill='rgb(255,210,153)' d='M90,180h-.323A90.028,90.028,0,0,1,71.6,178.171a89.014,89.014,0,0,1-32.066-13.542A90.127,90.127,0,0,1,54.77,7.073,88.989,88.989,0,0,1,71.6,1.828,90.027,90.027,0,0,1,89.677,0H90V180Z'/></svg>"), ` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 195.908 294'><path transform='translate(-840.063 -385.255)' fill='rgb(252,202,131)' d='M1016.388,579.6A49.016,49.016,0,0,0,987,569.887a48.783,48.783,0,0,1-48.975-48.593,48.789,48.789,0,0,0-48.988-48.581,48.513,48.513,0,0,1-29.38-87.459,98.08,98.08,0,0,0,78.368,38.86c54.092,0,97.951,43.513,97.951,97.18a96.29,96.29,0,0,1-19.583,58.3'/><path fill='rgb(51,183,183)' d='M987,511a48.765,48.765,0,0,1-48.957-48.593A48.974,48.974,0,0,0,840.1,462.4c0-.227-.03-.444-.034-.674V529h.006l7.115,7.122v84.253a97.96,97.96,0,0,1,90.833-60.791,98.068,98.068,0,0,0,78.371-38.869A49.013,49.013,0,0,0,987,511' transform='translate(-840.063 -326.6)'/></svg>")`,
      backgroundPosition: '100% 0px, 0px 0px, 100% 1860px, calc(100% + 98px) 1860px, -51px 1160px',
      backgroundSize: '750px 451px, 100% 451px, 188px 180px, 188px 180px, 195.908px 294px',
      backgroundRepeat: `${noRepeat}, ${noRepeat}, ${noRepeat}, ${noRepeat}, ${noRepeat}`,
      [theme.breakpoints.down(theme.breakpoints.values.hiddenImages)]: {
        backgroundImage:
          `url(${Anett}), ` + `linear-gradient(${theme.palette.headerBlue}, ${theme.palette.headerBlue})`,
      },
      [theme.breakpoints.down('sm')]: {
        backgroundImage: none,
        '& > div > div': {
          paddingTop: 0,
        },
      },
    },
    TopicsPage: {
      backgroundImage:
        `url(${Temple}),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(185,217,136)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(156,219,248)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(124,81,160)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
      backgroundPosition:
        '-220px max(100%, 984px), -281px -250px, calc(100% + 221px) 136px, -281px 518px, calc(100% + 221px) 950px',
      backgroundSize: '440px 440px, 500px 445.066px, 500px 445.066px, 500px 445.066px, 500px 445.066px',
      backgroundRepeat: `${noRepeat}, ${noRepeat}, ${noRepeat}, ${noRepeat}, ${noRepeat}`,
      [theme.breakpoints.down(theme.breakpoints.values.hiddenImages)]: {
        backgroundImage:
          `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 360 365'><path fill='rgb(242,125,131)' d='M204.2,0c0,0,18,89.5-23,115s-81.6,2.2-111,61c-25.6,51.2-25,87.3-70,94.7C-0.2,163.6,0.1,0,0.1,0H204.2z'/></svg>"), ` +
          `url(${Temple})`,
        backgroundPosition: `-57px -95px, -120px calc(100% - 125px)`,
        backgroundSize: '360px 365px, 240px 240px',
        backgroundRepeat: `${noRepeat}, ${noRepeat}`,
      },
    },
    CourseDetailPage: {
      backgroundImage:
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(185,217,136)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(156,219,248)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
      backgroundPosition: '-281px -250px, calc(100% + 221px) 166px, -281px 548px',
      backgroundSize: '500px 445.066px, 500px 445.066px, 500px 445.066px',
      backgroundRepeat: `${noRepeat}, ${noRepeat}, ${noRepeat}`,
      [theme.breakpoints.down(theme.breakpoints.values.hiddenImages)]: {
        backgroundImage: none,
        backgroundPosition: '-281px -300px',
        backgroundSize: '500px 445.066px',
        backgroundRepeat: noRepeat,
      },
      [theme.breakpoints.down('sm')]: {
        '& > div > div': {
          paddingTop: theme.spacing(10),
          '&.loggedIn': {
            paddingTop: 0,
          },
        },
      },
      [theme.breakpoints.down('xs')]: {
        backgroundPosition: '-300px -300px',
      },
    },
    ProfilePage: {
      backgroundImage:
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(185,217,136)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url(${SnowmanPatchFlipped})`,
      backgroundPosition: '-281px -250px, calc(100% + 221px) 136px, -221px 400px',
      backgroundSize: '500px 445.066px, 500px 445.066px, 440px 445px',
      backgroundRepeat: `${noRepeat}, ${noRepeat}, ${noRepeat}`,
      [theme.breakpoints.down(theme.breakpoints.values.hiddenImages)]: {
        backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
        backgroundPosition: '-281px -300px',
        backgroundSize: '500px 445.066px',
        backgroundRepeat: noRepeat,
      },
    },
    BadgesPage: {
      backgroundImage:
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url(${PrizePatchFlipped})`,
      backgroundPosition: '-281px -250px, calc(100% + 241px) calc(100% - 120px)',
      backgroundSize: '500px 445.066px, 500px 445.066px',
      backgroundRepeat: `${noRepeat}, ${noRepeat}`,
      [theme.breakpoints.down(theme.breakpoints.values.hiddenImages)]: {
        backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
        backgroundPosition: '-281px -300px',
        backgroundSize: '500px 445.066px',
        backgroundRepeat: noRepeat,
      },
    },
    CertificatesPage: {
      backgroundImage:
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url(${CertificateBottomLeft}),` +
        `url(${CertificateBottomRight})`,
      backgroundPosition: '-281px -250px, -92px calc(100% - 200px), calc(100% + 93px) calc(100% - 120px)',
      backgroundSize: '500px 445.066px, 184px 92px, 243.65px 213.18px',
      backgroundRepeat: `${noRepeat}, ${noRepeat}, ${noRepeat}`,
      [theme.breakpoints.down(theme.breakpoints.values.hiddenImages)]: {
        backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
        backgroundPosition: '-281px -300px',
        backgroundSize: '500px 445.066px',
        backgroundRepeat: noRepeat,
      },
    },
    LessonDetailPage: {
      backgroundImage:
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(185,217,136)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(156,219,248)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
      backgroundPosition: '-281px -250px, calc(100% + 221px) 136px, -281px 518px',
      backgroundSize: '500px 445.066px, 500px 445.066px, 500px 445.066px',
      backgroundRepeat: `${noRepeat}, ${noRepeat}, ${noRepeat}`,
      [theme.breakpoints.down(theme.breakpoints.values.hiddenImages)]: {
        backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
        backgroundPosition: '-281px -300px',
        backgroundSize: '500px 445.066px',
        backgroundRepeat: noRepeat,
      },
    },
    TopListPage: {
      backgroundImage:
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(185,217,136)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(156,219,248)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(85,185,154)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
      backgroundPosition: '-281px -250px, calc(100% + 221px) 136px, -281px 518px, calc(100% + 221px) 950px',
      backgroundSize: '500px 445.066px, 500px 445.066px, 500px 445.066px, 500px 445.066px',
      backgroundRepeat: `${noRepeat}, ${noRepeat}, ${noRepeat}, ${noRepeat}`,
      [theme.breakpoints.down(theme.breakpoints.values.hiddenImages)]: {
        backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 360 365'><path fill='rgb(242,125,131)' d='M204.2,0c0,0,18,89.5-23,115s-81.6,2.2-111,61c-25.6,51.2-25,87.3-70,94.7C-0.2,163.6,0.1,0,0.1,0H204.2z'/></svg>")`,
        backgroundPosition: '-57px -95px',
        backgroundSize: '360px 365px',
        backgroundRepeat: noRepeat,
        '& .PageTitleContainer': {
          marginBottom: theme.spacing(3),
        },
      },
    },
    NotFoundPage: {
      backgroundImage:
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(252, 196, 128)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
      backgroundPosition: '-281px -250px, calc(100% + 221px) 136px',
      backgroundSize: '500px 445.066px, 500px 445.066px',
      backgroundRepeat: `${noRepeat}, ${noRepeat}`,
      [theme.breakpoints.down(theme.breakpoints.values.hiddenImages)]: {
        backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 360 365'><path fill='rgb(242,125,131)' d='M204.2,0c0,0,18,89.5-23,115s-81.6,2.2-111,61c-25.6,51.2-25,87.3-70,94.7C-0.2,163.6,0.1,0,0.1,0H204.2z'/></svg>")`,
        backgroundPosition: '-57px -95px',
        backgroundSize: '360px 365px',
        backgroundRepeat: noRepeat,
      },
    },
    ExamsPage: {
      backgroundImage:
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(252,196,128)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 471.667 353.777'><g fill='rgb(135,206,220)' transform='translate(-708.661 -417.54)'><path d='M751.159,615.694A117.483,117.483,0,0,1,810.1,631.477,117.333,117.333,0,0,1,869.119,615.7,117.927,117.927,0,0,0,987.043,497.764a58.98,58.98,0,0,0-117.96,0,58.992,58.992,0,0,1-59,58.967,58.932,58.932,0,0,0-58.929,58.962' transform='translate(75.365 37.691)'/><path d='M772.413,631.464a117.333,117.333,0,0,1,59.014-15.778A117.924,117.924,0,0,0,949.351,497.757a58.961,58.961,0,0,1,117.921.006A117.928,117.928,0,0,1,949.351,615.69,117.919,117.919,0,0,0,831.428,733.613a117.856,117.856,0,0,0-59.014-102.149' transform='translate(113.056 37.704)'/></g></svg>")`,
      backgroundPosition: '-281px -250px, calc(100% + 221px) 136px, -236px 458px',
      backgroundSize: '500px 445.066px, 500px 445.066px, 471.667px 353.777px',
      backgroundRepeat: `${noRepeat}, ${noRepeat}, ${noRepeat}`,
      [theme.breakpoints.down(theme.breakpoints.values.hiddenImages)]: {
        backgroundImage: 'none',
      },
    },
    CoursesPage: {
      backgroundImage:
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(252,196,128)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 471.667 353.777'><g fill='rgb(135,206,220)' transform='translate(-708.661 -417.54)'><path d='M751.159,615.694A117.483,117.483,0,0,1,810.1,631.477,117.333,117.333,0,0,1,869.119,615.7,117.927,117.927,0,0,0,987.043,497.764a58.98,58.98,0,0,0-117.96,0,58.992,58.992,0,0,1-59,58.967,58.932,58.932,0,0,0-58.929,58.962' transform='translate(75.365 37.691)'/><path d='M772.413,631.464a117.333,117.333,0,0,1,59.014-15.778A117.924,117.924,0,0,0,949.351,497.757a58.961,58.961,0,0,1,117.921.006A117.928,117.928,0,0,1,949.351,615.69,117.919,117.919,0,0,0,831.428,733.613a117.856,117.856,0,0,0-59.014-102.149' transform='translate(113.056 37.704)'/></g></svg>")`,
      backgroundPosition: '-281px -250px, calc(100% + 221px) 136px, -236px 458px',
      backgroundSize: '500px 445.066px, 500px 445.066px, 471.667px 353.777px',
      backgroundRepeat: `${noRepeat}, ${noRepeat}, ${noRepeat}`,
      [theme.breakpoints.down(theme.breakpoints.values.hiddenImages)]: {
        backgroundImage: 'none',
      },
    },
    KnowledgeStorePage: {
      backgroundImage:
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(252,196,128)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(185,217,136)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url(${PlayPatch})`,
      backgroundPosition: '-281px -250px, calc(100% + 221px) 136px, calc(100% + 221px) calc(100% - 20px), -221px 100%',
      backgroundSize: '500px 445.066px, 500px 445.066px, 500px 445.066px, 442px 442px',
      backgroundRepeat: `${noRepeat}, ${noRepeat}, ${noRepeat}, ${noRepeat}`,
      [theme.breakpoints.down(theme.breakpoints.values.hiddenImages)]: {
        backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 360 365'><path fill='rgb(242,125,131)' d='M204.2,0c0,0,18,89.5-23,115s-81.6,2.2-111,61c-25.6,51.2-25,87.3-70,94.7C-0.2,163.6,0.1,0,0.1,0H204.2z'/></svg>")`,
        backgroundPosition: '-57px -95px',
        backgroundSize: '360px 365px',
        backgroundRepeat: noRepeat,
      },
    },
    AboutPage: {
      backgroundImage:
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(252,196,128)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url(${AboutLeftPatch}),` +
        `url(${AboutRightPatch})`,
      backgroundPosition: '-281px -250px, calc(100% + 221px) 136px, -220px 1320px, calc(100% + 221px) 1375px',
      backgroundSize: '500px 445.066px, 500px 445.066px, 440px 440px, 440px 385px',
      backgroundRepeat: `${noRepeat}, ${noRepeat}, ${noRepeat}, ${noRepeat}`,
      [theme.breakpoints.down(theme.breakpoints.values.hiddenImages)]: {
        backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 360 365'><path fill='rgb(242,125,131)' d='M204.2,0c0,0,18,89.5-23,115s-81.6,2.2-111,61c-25.6,51.2-25,87.3-70,94.7C-0.2,163.6,0.1,0,0.1,0H204.2z'/></svg>")`,
        backgroundPosition: '-57px -95px',
        backgroundSize: '360px 365px',
        backgroundRepeat: noRepeat,
      },
    },
  });
