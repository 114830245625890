import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  absolute,
  auto,
  baseline,
  block,
  bold,
  capitalize,
  center,
  cover,
  flex,
  flexEnd,
  flexStart,
  grid,
  hidden,
  none,
  noRepeat,
  normal,
  pointer,
  relative,
  spaceBetween,
  transparent,
  unset,
} from 'constants/Jss';
import { TopCardHeight } from 'constants/Constants';

const topBoxShadow = '0px 5px 35px';

export const topListPageStyles = (theme) => ({
  TopListContainer: {
    width: '100%',
    position: relative,
  },
  MyPositionButtonContainer: {
    display: flex,
    alignItems: center,
    justifyContent: center,
  },
  SearchDescription: {
    color: theme.palette.blackTitle,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  TabContainer: {
    display: flex,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: theme.spacing(4),
    },
  },
  Tab: {
    fontSize: 21,
    fontWeight: bold,
    color: theme.palette.blackTitle,
    textTransform: capitalize,
    '&.Active': {
      borderBottom: `8px solid ${theme.palette.cyan}`,
    },
    '&:not(.Active)': {
      cursor: pointer,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      width: '50%',
      textAlign: center,
      margin: 0,
      whiteSpace: normal,
      paddingBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: `calc(25% - 45px)`,
      paddingRight: `calc(25% - 45px)`,
    },
  },
});

export const useTopListCardsStyles = makeStyles((theme) => ({
  TopListCardContainer: {
    width: 264,
    height: 237,
    display: flex,
    alignItems: flexEnd,
    perspective: 1000,
    '&:hover': {
      cursor: pointer,
    },
  },
  TopListCardFlipper: {
    transition: '0.6s',
    transformStyle: 'preserve-3d',
    position: relative,
    width: '100%',
  },
  TopListCardFlipperFlipped: {
    transform: 'rotateY(180deg)',
  },
  TopListCard: {
    width: '100%',
    backgroundColor: theme.palette.greyF9,
    borderRadius: 18,
    color: theme.palette.blackTitle,
    position: relative,
    backfaceVisibility: hidden,
    border: `2px solid ${theme.palette.cyan}`,
    '&.myCard': {
      border: `4px solid ${theme.palette.lightBlue}`,
    },
  },
  TopListCardFront: {
    zIndex: 2,
    transform: 'rotateY(0deg)',
  },
  TopListCardBack: {
    height: 237,
    transform: 'rotateY(180deg)',
    position: absolute,
    bottom: 0,
  },
  TopListGroupCardBack: {
    height: TopCardHeight,
  },
  TopListCardIconContainer: {
    position: absolute,
    left: `calc(50% - ${theme.mixins.topList.cardIconSize / 2}px)`,
    top: theme.mixins.topList.cardIconSize / -2,
    background: theme.palette.white,
    borderRadius: '50%',
    width: theme.mixins.topList.cardIconSize,
    height: theme.mixins.topList.cardIconSize,
    display: flex,
    alignItems: center,
    justifyContent: center,
    opacity: 1,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.complex,
    }),
  },
  TopListCardIcon: {
    width: theme.mixins.topList.cardIconSize - theme.mixins.topList.cardIconPadding * 2,
    height: theme.mixins.topList.cardIconSize - theme.mixins.topList.cardIconPadding * 2,
    borderRadius: '50%',
    backgroundSize: cover,
    backgroundPosition: center,
    backgroundRepeat: noRepeat,
  },
  TopListCardNoIcon: {
    backgroundColor: theme.palette.avatarGrey,
    backgroundSize: '50%',
  },
  TopListBox: {
    display: flex,
    alignItems: flexStart,
    justifyContent: center,
    height: 198,
  },
  NameAndOrganization: {
    textAlign: center,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  Name: {
    fontSize: 18,
    lineHeight: '18px',
    fontWeight: bold,
  },
  UserNameBackCard: {
    fontSize: 16,
    lineHeight: '16px',
    fontWeight: bold,
  },
  OrganizationNameBackCard: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: bold,
  },
  Organization: {
    fontSize: 14,
    lineHeight: '14px',
  },
  XPAndRank: {
    position: absolute,
    bottom: 0,
    left: 0,
    width: '100%',
    display: flex,
    justifyContent: spaceBetween,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.cyan,
    '&.podium, &.podium.myCard': {
      color: theme.palette.white,
    },
    '&.myCard': {
      color: theme.palette.darkBlue,
    },
  },
  ValueAndText: {
    display: flex,
    alignItems: baseline,
    justifyContent: center,
  },
  Value: {
    fontSize: 18,
    fontWeight: bold,
  },
  Text: {
    fontSize: 12,
    marginLeft: theme.spacing(0.25),
  },
  Rank: {
    fontSize: 21,
    fontFamily: 'Montserrat',
    fontWeight: bold,
  },
  First: {
    border: none,
    color: theme.palette.white,
    backgroundColor: theme.palette.lightPink,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
    '&.myCard': {
      color: theme.palette.white,
      border: none,
      boxShadow: `${topBoxShadow} ${theme.palette.lightPink}`,
    },
  },
  Second: {
    border: none,
    color: theme.palette.white,
    backgroundColor: theme.palette.greenSuccess,
    '&.myCard': {
      color: theme.palette.white,
      border: none,
      boxShadow: `${topBoxShadow} ${theme.palette.greenSuccess}`,
    },
  },
  Third: {
    border: none,
    color: theme.palette.white,
    backgroundColor: theme.palette.lightBlue,
    '&.myCard': {
      color: theme.palette.white,
      border: none,
      boxShadow: `${topBoxShadow} ${theme.palette.lightBlue}`,
    },
  },
  Back: {
    position: relative,
    top: 8,
    fontSize: 18,
    fontWeight: bold,
    color: theme.palette.cyan,
    '&.podium, &.podium.myCard': {
      color: theme.palette.white,
    },
    '&.myCard': {
      color: theme.palette.darkBlue,
    },
  },
  BackGroup: {
    top: unset,
    bottom: 28,
  },
  WordWrap: {
    wordWrap: 'anywhere',
  },
}));

export const usePodiumStyles = makeStyles((theme) => ({
  PodiumContainer: {
    display: flex,
    justifyContent: spaceBetween,
    [theme.breakpoints.down('sm')]: {
      display: block,
      marginTop: theme.spacing(8),
      '& > div': {
        display: flex,
        justifyContent: center,
      },
    },
  },
  Card: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
    },
  },
  First: {
    [theme.breakpoints.down('sm')]: {
      '&.Mobile': {
        marginTop: 0,
        '& > div:first-child': {
          marginTop: 0,
        },
        marginLeft: 0,
        marginRight: 0,
      },
    },
    [theme.breakpoints.up('md')]: {
      '&.Mobile': {
        display: none,
      },
    },
  },
}));

export const useOutOfPodiumStyles = makeStyles((theme) => ({
  OutOfPodiumContainer: {
    display: grid,
    'grid-template-columns': 'repeat(auto-fill, minmax(260px, 1fr))',
    '& > div': {
      marginLeft: auto,
      marginRight: auto,
    },
    [theme.breakpoints.down('xs')]: {
      display: block,
      '& > div': {
        display: flex,
        justifyContent: center,
      },
    },
  },
}));

export const useTopListSearchStyles = makeStyles((theme) => ({
  SearchContainer: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  Form: {
    height: theme.mixins.topList.searchHeight,
    position: relative,
    display: flex,
    alignItems: center,
    flexGrow: 1,
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey70}`,
    '&.TooShort:focus-within': {
      borderColor: theme.palette.errorColor,
    },
  },
  SearchIconContainer: {
    cursor: pointer,
    width: theme.mixins.header.contentSize,
    height: theme.mixins.header.contentSize,
    '&:hover': {
      backgroundColor: theme.palette.whiteHover,
    },
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    '& svg': {
      fill: theme.palette.grey70,
    },
  },
  SearchField: {
    fontSize: 16,
    color: theme.palette.grey70,
    flexGrow: 1,
  },
  InputField: {
    paddingRight: theme.spacing(5),
    minWidth: 235,
    [theme.breakpoints.down('xs')]: {
      minWidth: 156,
    },
    '&::-webkit-search-cancel-button': {
      display: none,
    },
  },
  CloseSearch: {
    cursor: pointer,
    padding: 0,
    '&:hover': {
      backgroundColor: transparent,
    },
    '& svg': {
      width: 20,
      height: 20,
      fill: theme.palette.grey70,
    },
  },
  CloseSearchSeparator: {
    width: 1,
    backgroundColor: theme.palette.black,
    height: '50%',
    margin: `auto 0 auto ${theme.spacing(1)}px`,
  },
  CloseSearchIcon: {
    width: 20,
    height: 20,
  },
  SearchIcon: {
    color: theme.palette.grey70,
  },
  SearchIconAdornment: {
    fill: '#61BEEB !important',
    opacity: 1,
  },
  SearchIconAdornmentButton: {
    cursor: pointer,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-disabled svg': {
      opacity: 0.5,
      fill: `${theme.palette.text.primary} !important`,
    },
  },
}));
