import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import hoistStatics from 'hoist-non-react-statics';

import { Image } from 'components';
import { badgesPageStyles } from './styles';
import { getBadges, getProfileDetails } from 'store/profile/actions';
import { Badge, ClassStyle } from 'types/types';
import { getUrl } from 'utils/urlFromImagePath';
import { titleFocus } from 'utils/titleFocus';

class BadgesPage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      BadgesPageContainer: ClassStyle,
      BadgeContainer: ClassStyle,
      BadgeBox: ClassStyle,
      Badge: ClassStyle,
      BadgeImageBox: ClassStyle,
      BadgeImage: ClassStyle,
      BadgeTitle: ClassStyle,
      BadgeDescription: ClassStyle,
    }),
    t: PropTypes.func.isRequired,
    badges: PropTypes.arrayOf(Badge),
    addCommonLoader: PropTypes.func,
  };

  static pageTitleKey = 'profile_badges';

  LockedBadgeUrl = '/storage/badges/lockedBadge.png';

  constructor(props) {
    super(props);

    this.props.addCommonLoader();
  }

  static async getInitialData({ dispatch, language, token }) {
    try {
      if (token) {
        await Promise.all([dispatch(getBadges({ language, token })), dispatch(getProfileDetails({ language, token }))]);
      }
      titleFocus(dispatch);
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    const { classes, t, badges } = this.props;

    return (
      <div className={classes.BadgesPageContainer}>
        <Box mb={51} className={classes.BadgeContainer}>
          <Box className={classes.BadgeBox}>
            {badges.map((badge) => (
              <Box
                mb={5}
                className={classes.Badge}
                key={badges.indexOf(badge)}
                tabIndex={0}
                aria-label={
                  (badge.image === this.LockedBadgeUrl ? t('alt_locked_badge') : t('alt_unlocked_badge')) +
                  badge.name +
                  ', ' +
                  badge.description
                }
              >
                <Box className={classes.BadgeImageBox}>
                  <Image src={getUrl(badge.image)} className={classes.BadgeImage} aria-hidden={true} />
                </Box>
                <Box mt={4} mb={2} className={classes.BadgeTitle} aria-hidden={true}>
                  {badge.name}
                </Box>
                <Box className={classes.BadgeDescription} aria-hidden={true}>
                  {badge.description}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </div>
    );
  }
}

export default hoistStatics(withTranslation()(withStyles(badgesPageStyles)(BadgesPage)), BadgesPage);
