import { bold, center, contain, hidden, inlineBlock, middle, none, noRepeat, pointer } from 'constants/Jss';

export const paginationStyles = (theme) => ({
  Invisible: {
    visibility: hidden,
  },
  PaginationContainer: {
    textAlign: center,
    '& > div': {
      display: inlineBlock,
      margin: 3,
      backgroundRepeat: noRepeat,
      backgroundPosition: center,
      backgroundSize: contain,

      width: 32,
      height: 32,
      fontSize: 20,
      textAlign: center,
      textDecoration: none,
      lineHeight: '32px',
      color: theme.palette.grey70,
      verticalAlign: middle,

      '-webkit-user-select': none,
      '-moz-user-select': none,
      '-ms-user-select': none,
      'user-select': none,
      '&.Prev, &.Next': {
        width: 24,
        height: 24,
      },
      '& svg': {
        width: 24,
        height: 24,
        fill: theme.palette.cyan,
      },
      '&.Active': {
        color: theme.palette.cyan,
        cursor: 'default',
        fontWeight: bold,
      },
      '&:hover': {
        cursor: pointer,
      },
    },
  },
});
