import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { useSearchFieldStyles } from '../../styles';
import { IconButtonBase } from './IconButtonBase';
import { closeIcon } from 'utils/icons';

export const CloseButton = memo((props) => {
  const { Button, CloseIcon } = useSearchFieldStyles();
  return <IconButtonBase iconFactory={closeIcon} buttonClass={Button} iconClass={CloseIcon} {...props} />;
});

CloseButton.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
