export const GET_TOP_LIST = 'GET_TOP_LIST';
export const GET_TOP_LIST_SUCCESS = 'GET_TOP_LIST_SUCCESS';
export const GET_TOP_LIST_FAILED = 'GET_TOP_LIST_FAILED';

export const GET_TOP_LIST_OF_GROUPS = 'GET_TOP_LIST_OF_GROUPS';
export const GET_TOP_LIST_OF_GROUPS_SUCCESS = 'GET_TOP_LIST_OF_GROUPS_SUCCESS';
export const GET_TOP_LIST_OF_GROUPS_FAILED = 'GET_TOP_LIST_OF_GROUPS_FAILED';

export const SET_PAGE = 'SET_PAGE';

export const SET_PAGE_OF_GROUPS = 'SET_PAGE_OF_GROUPS';
