import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import MDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ClearIcon from '@material-ui/icons/Clear';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { isFirefox } from 'utils/isFirefox';
import { absolute, auto, bold, center, pointer } from 'constants/Jss';

const useStyles = makeStyles((theme) => ({
  CloseButton: {
    position: absolute,
    top: theme.spacing(3),
    right: theme.spacing(3),
    color: theme.palette.grey70,
    cursor: pointer,
    [theme.breakpoints.down('sm')]: {
      top: 26,
      right: 26,
      '& svg': {
        width: 20,
        height: 20,
      },
    },
  },
  DialogHeader: {
    margin: 0,
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(5),
    textAlign: center,
  },
  DialogTitle: {
    fontFamily: 'Montserrat',
    fontWeight: bold,
    fontSize: 32,
    paddingBottom: theme.spacing(1),
  },
}));

const Dialog = React.forwardRef(
  (
    {
      open,
      onClose,
      actions,
      title,
      content,
      contentClass,
      titleClass,
      titleBoxClass,
      maxWidth,
      alwaysFullScreen,
      noClose,
      innerRef,
      firstItemRef,
      ariaDescribedby,
      isExamOrTest = false,
      noFocus = false,
    },
    ref
  ) => {
    const theme = useTheme();
    const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
    const fullScreen = alwaysFullScreen || mediaQuery;
    const classes = useStyles();
    const { t } = useTranslation();

    const titleRef = useRef(null);

    const onEntered = () => {
      if (ref && ref.current) {
        // TODO Change this, if there is a better solution
        if (!isFirefox() && !isExamOrTest) {
          ref.current.attributes.role.value = 'dialog';
        }
      }
      if (isFirefox() && titleRef.current && !firstItemRef && !isExamOrTest && !noFocus) {
        titleRef.current.focus();
      }
      if (firstItemRef && firstItemRef.current) {
        firstItemRef.current.focus();
      }
    };

    const getAriaRole = () => {
      if (isFirefox()) {
        return;
      }
      return isExamOrTest ? 'presentation' : 'dialog';
    };

    return (
      <MDialog
        ref={ref}
        fullScreen={fullScreen}
        open={!!open}
        onClose={onClose}
        maxWidth={maxWidth || 'md'}
        fullWidth
        onEntered={onEntered}
        PaperProps={{ role: getAriaRole(), 'aria-modal': 'true' }}
        aria-labelledby="titleId"
        aria-describedby={ariaDescribedby}
        TransitionProps={{ role: undefined }}
      >
        {title ? (
          <Box
            ref={innerRef}
            marginTop={10}
            marginBottom={5}
            marginLeft={auto}
            marginRight={auto}
            className={classNames(titleBoxClass, classes.DialogHeader)}
          >
            <h1 id="titleId" ref={titleRef} className={classNames(classes.DialogTitle, titleClass)} tabIndex={0}>
              {title}
            </h1>
            <div className="TitleBorder" />
          </Box>
        ) : null}
        {!actions && !noClose ? (
          <div
            className={classes.CloseButton}
            onClick={onClose}
            tabIndex={0}
            aria-label={t('alt_close_dialog')}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onClose(e);
              }
            }}
          >
            <ClearIcon color={'inherit'} />
          </div>
        ) : null}
        <DialogContent className={contentClass}>{content}</DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </MDialog>
    );
  }
);

Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  actions: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  contentClass: PropTypes.string,
  titleClass: PropTypes.string,
  titleBoxClass: PropTypes.string,
  maxWidth: PropTypes.any,
  alwaysFullScreen: PropTypes.bool,
  noClose: PropTypes.bool,
  innerRef: PropTypes.any,
  ariaDescribedby: PropTypes.string,
  firstItemRef: PropTypes.any,
  isExamOrTest: PropTypes.bool,
  noFocus: PropTypes.bool,
};

export default Dialog;
