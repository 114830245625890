import { ApiErrorMessages } from 'constants/Constants';

export const setProfileFormErrors = (err, setFieldError, t) => {
  if ('response' in err) {
    const errorKeys = err.response.data?.errors || [];

    if (errorKeys.length > 0) {
      const errorMap = new Map();
      errorMap.set(ApiErrorMessages.TAX_NUMBER_UNIQUE, {
        controlName: 'taxNumber',
        errorMessage: t('error_unique_tax_number'),
      });
      errorMap.set(ApiErrorMessages.TAX_NUMBER_INVALID_FORMAT, {
        controlName: 'taxNumber',
        errorMessage: t('error_invalid_tax_number'),
      });
      errorMap.set(ApiErrorMessages.EMAIL_UNIQUE, {
        controlName: 'email',
        errorMessage: t('error_unique_email_address'),
      });

      errorKeys.forEach((key) => {
        if (errorMap.has(key)) {
          const { controlName, errorMessage } = errorMap.get(key);
          setFieldError(controlName, errorMessage);
        }
      });
    }
  }
};
