import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { useSearchFieldStyles } from '../../styles';
import { IconButtonBase } from './IconButtonBase';
import { clearIcon } from 'utils/icons';

export const ClearButton = memo((props) => {
  const { Button, ClearIcon } = useSearchFieldStyles();
  return <IconButtonBase iconFactory={clearIcon} buttonClass={Button} iconClass={ClearIcon} {...props} />;
});

ClearButton.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
