import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';

import Button from 'components/Button/Button';
import JobsFilterDialog from 'components/Filter/JobsFilterDialog';
import { useTagsDialogStyles } from './styles';

export const MobileProfileTags = ({ tags, checkedTags, setTags }) => {
  const tagsDialogClasses = useTagsDialogStyles();
  const { t } = useTranslation();

  const [openModal, setOpenModal] = React.useState(false);

  return (
    <div className={tagsDialogClasses.TagsDialogContainer}>
      <Box className={tagsDialogClasses.AddTagContainer}>
        <Box className={tagsDialogClasses.AddTagLabel}>{t('profile_interests')}</Box>
        <Button
          buttonClassName={classNames(tagsDialogClasses.AddTagButton, 'Pink')}
          onClick={() => setOpenModal(true)}
          aria-label={t('alt_add_tag')}
        >
          <AddIcon />
        </Button>
      </Box>
      <Box mt={2} className={tagsDialogClasses.ProfileTagsContainer}>
        {tags
          .filter((tag) => checkedTags.includes(tag.id))
          .map((tag, index) => (
            <Box mr={2.5} mb={1.5} className={tagsDialogClasses.ProfileTag} key={index}>
              <Box tabIndex={0} aria-label={tag.title + ' ' + t('alt_tag')}>
                <div aria-hidden={true}>{tag.title}</div>
              </Box>
              <IconButton
                className={tagsDialogClasses.ClearTag}
                onClick={() => setTags(tag.id)}
                tabIndex={0}
                aria-label={tag.title + ' ' + t('alt_remove_tag')}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ))}
      </Box>
      <JobsFilterDialog
        open={openModal}
        closeDialog={() => setOpenModal(false)}
        tags={tags}
        checkedTags={checkedTags}
        setTags={setTags}
      />
    </div>
  );
};

MobileProfileTags.propTypes = {
  tags: PropTypes.array,
  checkedTags: PropTypes.array,
  setTags: PropTypes.func,
};
