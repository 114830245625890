import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import CoursesPage from './CoursesPage';
import {
  getBookmarkedCourses,
  loadMoreBookmarkedCourses,
  getOngoingCourses,
  loadMoreOngoingCourses,
  resetBookmarkedCourses,
  resetOngoingCourses,
} from 'store/courses/actions';
import { addCommonLoader, removeCommonLoader } from 'store/loader/actions';

const mapStateToProps = (state) => {
  return {
    ongoingCourses: state.courses.ongoingCourses.data,
    bookmarkedCourses: state.courses.bookmarkedCourses.data,
    numberOfOngoingCourses: state.courses.ongoingCourses.count,
    numberOfBookmarkedCourses: state.courses.bookmarkedCourses.count,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOngoingCourses,
      loadMoreOngoingCourses,
      getBookmarkedCourses,
      loadMoreBookmarkedCourses,
      addCommonLoader,
      removeCommonLoader,
      resetBookmarkedCourses,
      resetOngoingCourses,
    },
    dispatch
  );
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CoursesPage);
