import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { withWindowWidth } from 'components';
import TestPage from './TestPage';
import { validateTest } from 'store/tests/actions';
import { showNotification } from 'store/common/actions';
import { removeCommonLoader } from 'store/loader/actions';
import { getTest } from 'store/tests/actions';

const mapStateToProps = (state) => {
  return {
    test: state.tests.test,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      validateTest,
      showNotification,
      removeCommonLoader,
      getTest,
    },
    dispatch
  );
};

export default compose(withRouter, withWindowWidth, connect(mapStateToProps, mapDispatchToProps))(TestPage);
