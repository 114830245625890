import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';

import { TopListTypes } from 'constants/Constants';
import { TopListCard } from './TopListCard';
import { usePodiumStyles } from './styles';

export const Podium = ({ activeTab, first, second, third, myId, isMyCardSelected, myCardRef }) => {
  const classes = usePodiumStyles();

  const amIRanked = (ranked) => {
    if (ranked.id === myId) {
      return myCardRef;
    }
    return null;
  };

  return (
    <Box my={8} className={classes.PodiumContainer}>
      <Box className={classNames(classes.PodiumOrder, classes.First)}>
        {first ? (
          <TopListCard
            user={activeTab === TopListTypes.User ? first : null}
            group={activeTab === TopListTypes.User ? null : first}
            activeTab={activeTab}
            myId={myId}
            isMyCardSelected={isMyCardSelected}
            podium
            myCardRef={amIRanked(first)}
          />
        ) : null}
      </Box>
      <Box className={classNames(classes.Card)}>
        {second ? (
          <TopListCard
            user={activeTab === TopListTypes.User ? second : null}
            group={activeTab === TopListTypes.User ? null : second}
            activeTab={activeTab}
            myId={myId}
            isMyCardSelected={isMyCardSelected}
            podium
            myCardRef={amIRanked(second)}
          />
        ) : null}
      </Box>
      <Box className={classNames(classes.Card)}>
        {third ? (
          <TopListCard
            user={activeTab === TopListTypes.User ? third : null}
            group={activeTab === TopListTypes.User ? null : third}
            activeTab={activeTab}
            myId={myId}
            isMyCardSelected={isMyCardSelected}
            podium
            myCardRef={amIRanked(third)}
          />
        ) : null}
      </Box>
    </Box>
  );
};

Podium.propTypes = {
  activeTab: PropTypes.oneOf([TopListTypes.Group, TopListTypes.User]),
  first: PropTypes.any,
  second: PropTypes.any,
  third: PropTypes.any,
  myId: PropTypes.number,
  isMyCardSelected: PropTypes.bool,
  myCardRef: PropTypes.any,
};
