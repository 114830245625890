import { ADD_LOADER, ADD_NOTIFICATION, REMOVE_LOADER, REMOVE_NOTIFICATION, SET_FILTER_PARAMS } from './types';

const INITIAL_STATE = {
  loading: false,
  notification: null,
  filterParams: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notification: {
          translateKey: action.payload.translateKey,
          content: action.payload.content,
          type: action.payload.type,
        },
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notification: null,
      };
    case SET_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload,
      };
    case ADD_LOADER:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_LOADER:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
