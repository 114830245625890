export function easeInOutSin(time) {
  return (1 + Math.sin(Math.PI * time - Math.PI / 2)) / 2;
}

export function animate(property, element, to, options = {}) {
  const { ease = easeInOutSin, duration = 300 } = options;

  let start = null;
  const from = parseInt(element[property]);
  let cancelled = false;

  const cancel = () => {
    cancelled = true;
  };

  const step = (timestamp) => {
    if (cancelled) {
      return;
    }

    if (start === null) {
      start = timestamp;
    }
    const time = Math.min(1, (timestamp - start) / duration);

    element[property] = ease(time) * (to - from) + from;

    if (time >= 1) {
      return;
    }

    requestAnimationFrame(step);
  };

  if (from !== to) {
    requestAnimationFrame(step);
  }

  return cancel;
}
