import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import replace from 'lodash/replace';

import logo from 'assets/images/mtuSmall.png';

import CloseBtn from './CloseBtn';

export const Tooltip = ({
  backProps,
  closeProps,
  continuous,
  index,
  isLastStep,
  primaryProps,
  size,
  skipProps,
  step,
  tooltipProps,
}) => {
  const { content, hideBackButton, hideCloseButton, hideFooter, showProgress, showSkipButton, title, styles } = step;
  const { back, close, last, next, skip } = step.locale;
  const { t } = useTranslation();
  const output = { primary: close };
  const nextButton = [next, t('tutorial_button_next_step_2'), next, t('tutorial_button_next_step_4')];
  const ariaLabel = replace(content, /<.*?>/gm, '');

  if (continuous) {
    output.primary = isLastStep ? last : nextButton[index];

    if (showProgress) {
      output.primary = (
        <span>
          {output.primary} ({index + 1}/{size})
        </span>
      );
    }
  }

  if (showSkipButton && !isLastStep) {
    output.skip = (
      <button
        style={styles.buttonSkip}
        type="button"
        data-test-id="button-skip"
        aria-live="off"
        {...skipProps}
        aria-label={undefined}
        title={undefined}
      >
        {skip}
      </button>
    );
  }

  if (!hideBackButton && index > 0) {
    output.back = (
      <button
        style={styles.buttonBack}
        type="button"
        data-test-id="button-back"
        {...backProps}
        aria-label={undefined}
        title={undefined}
      >
        {back}
      </button>
    );
  }

  output.close = !hideCloseButton && (
    <CloseBtn styles={styles.buttonClose} data-test-id="button-close" {...closeProps} />
  );

  return (
    <div
      ref={tooltipProps.ref}
      style={styles.tooltip}
      {...tooltipProps}
      role="application"
      aria-modal={undefined}
      aria-label={ariaLabel}
    >
      <div style={styles.tooltipContainer}>
        {title && (
          <h4 style={styles.tooltipTitle} aria-label={title}>
            {title}
          </h4>
        )}
        <object
          aria-label={ariaLabel}
          role="text"
          tabIndex={0}
          style={styles.tooltipContent}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      {!hideFooter && (
        <div style={styles.tooltipFooter}>
          <img src={logo} alt="" />
          <div style={styles.tooltipFooterSpacer}>{output.skip}</div>
          {output.back}
          <button
            style={styles.buttonNext}
            type="button"
            data-test-id="button-primary"
            {...primaryProps}
            aria-label={undefined}
            title={undefined}
          >
            {output.primary}
          </button>
        </div>
      )}
      {output.close}
    </div>
  );
};

Tooltip.propTypes = {
  backProps: PropTypes.object.isRequired,
  closeProps: PropTypes.object.isRequired,
  continuous: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  primaryProps: PropTypes.object.isRequired,
  size: PropTypes.number.isRequired,
  skipProps: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
  tooltipProps: PropTypes.object.isRequired,
};
