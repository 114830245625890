export const preventDefault = (event) => event.preventDefault();

export const stopPropagation = (event) => event.stopPropagation();

export const isSpaceEvent = ({ key }) => key === ' ';

export const isEnterEvent = ({ key }) => key === 'Enter';

export const isEscapeEvent = ({ key }) => key === 'Escape';

export const isTabEvent = ({ key }) => key === 'Tab';

export const isSpaceOrEnterEvent = (event) => isSpaceEvent(event) || isEnterEvent(event);

export const stopEnterPropagation = (event) => {
  if (isEnterEvent(event)) {
    event.stopPropagation();
  }
};

export const createEnterHandler = (handler) => (event) => {
  if (isEnterEvent(event)) {
    handler();
  }
};

export const createSpaceOrEnterHandler = (handler) => (event) => {
  if (isSpaceOrEnterEvent(event)) {
    handler();
  }
};
