import { auto, left, normal } from 'constants/Jss';
import { lessonDetailPageStyles } from '../LessonDetailPage/styles';

export const knowledgeStorePageStyles = (theme) => ({
  ...lessonDetailPageStyles(theme),
  KnowledgeStorePage: {
    width: '100%',
    maxWidth: 950,
    marginLeft: auto,
    marginRight: auto,
    lineHeight: '30px',
    color: theme.palette.blackTitle,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  Subtitle: {
    '&.Title': {
      fontSize: 18,
      lineHeight: '30px',
      margin: 0,
      fontFamily: 'WonderUnit',
      '&.Regular': {
        fontWeight: normal,
      },
      color: theme.palette.blackTitle,
      textAlign: left,
      padding: 0,
    },
  },
  List: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
  MarginBottom: {
    marginBottom: theme.spacing(50),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
});
