import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation } from 'react-i18next';

import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';

import { getProfileDetails } from 'store/profile/actions';
import { titleFocus } from 'utils/titleFocus';
import { ClassStyle } from 'types/types';

import { knowledgeStorePageStyles } from './styles';

class KnowledgeStorePage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      KnowledgeStorePage: ClassStyle,
      Subtitle: ClassStyle,
      List: ClassStyle,
      MarginBottom: ClassStyle,
    }),
    t: PropTypes.func,
  };

  static pageTitleKey = 'page_title_knowledge_store';

  static async getInitialData({ dispatch, language, token }) {
    try {
      if (token) {
        await dispatch(getProfileDetails({ language, token }));
      }
      titleFocus(dispatch);
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    const { classes, t } = this.props;
    return (
      <Box mt={6} className={classes.KnowledgeStorePage}>
        <Box mb={3} tabIndex={0}>
          <h2 className={classNames('Title', classes.Subtitle)}>{t('knowledge_store_page_subtitle1')}</h2>
          <div>
            <span>{t('knowledge_store_page_paragraph1_text1')}</span>
            <span className="Bold">{t('knowledge_store_page_paragraph1_text2')}</span>
            <span>{t('knowledge_store_page_paragraph1_text3')}</span>
            <span className="Bold">{t('knowledge_store_page_paragraph1_text4')}</span>
            <span>{t('knowledge_store_page_paragraph1_text5')}</span>
            <span className="Bold">{t('knowledge_store_page_paragraph1_text6')}</span>
            <span>{t('knowledge_store_page_paragraph1_text7')}</span>
            <span className="Bold">{t('knowledge_store_page_paragraph1_text8')}</span>
          </div>
          <a href="https://mtu.gov.hu/cikkek/strategia" className="Link" target="_blank">
            {t('knowledge_store_page_paragraph1_link')}
          </a>
        </Box>
        <Box mb={3} tabIndex={0}>
          <h2 className={classNames('Title', classes.Subtitle)}>{t('knowledge_store_page_subtitle2')}</h2>
          <div>{t('knowledge_store_page_paragraph2_text')}</div>
          <a href="https://mtu.gov.hu/cikkek/kutatasok-elemzesek" className="Link" target="_blank">
            {t('knowledge_store_page_paragraph2_link')}
          </a>
        </Box>
        <Box mb={3} tabIndex={0}>
          <h2 className={classNames('Title', classes.Subtitle)}>{t('knowledge_store_page_subtitle3')}</h2>
          <div>{t('knowledge_store_page_paragraph3_text')}</div>
          <a href="https://ujturizmus.turizmusakademia.hu/tudastar" className="Link" target="_blank">
            {t('knowledge_store_page_paragraph3_link')}
          </a>
        </Box>
        <Box mb={3} tabIndex={0}>
          <h2 className={classNames('Title', classes.Subtitle)}>{t('knowledge_store_page_subtitle4')}</h2>
          <div>{t('knowledge_store_page_paragraph4_text')}</div>
          <a href="https://mtu.gov.hu/cikkek/timea-projekt-92" className="Link" target="_blank">
            {t('knowledge_store_page_paragraph4_link')}
          </a>
        </Box>
        <Box mb={3} tabIndex={0}>
          <h2 className={classNames('Title', classes.Subtitle)}>{t('knowledge_store_page_subtitle5')}</h2>
          <div>
            <span>{t('knowledge_store_page_paragraph5_text1')}</span>
            <span className="Bold">{t('knowledge_store_page_paragraph5_text2')}</span>
            <span>{t('knowledge_store_page_paragraph5_text3')}</span>
            <span className="Bold">{t('knowledge_store_page_paragraph5_text4')}</span>
            <span>{t('knowledge_store_page_paragraph5_text5')}</span>
            <span className="Bold">{t('knowledge_store_page_paragraph5_text6')}</span>
            <span>{t('knowledge_store_page_paragraph5_text7')}</span>
            <span className="Bold">{t('knowledge_store_page_paragraph5_text8')}</span>
          </div>
        </Box>
        <Box className={classes.MarginBottom} tabIndex={0}>
          <h2 className={classNames('Title', classes.Subtitle, 'Regular')}>{t('knowledge_store_page_subtitle6')}</h2>
          <ul className={classes.List}>
            <li>
              <a
                className="Link"
                href="https://timearendezveny.turizmusakademia.hu/presentations/TA_1_eloadas_honlapra_0302.pdf"
                target="_blank"
              >
                {t('knowledge_store_page_paragraph6_link1')}
              </a>
            </li>
            <li>
              <a
                className="Link"
                href="https://timearendezveny.turizmusakademia.hu/presentations/TA_2_eloadas_honlapra_0302.pdf"
                target="_blank"
              >
                {t('knowledge_store_page_paragraph6_link2')}
              </a>
            </li>
            <li>
              <a
                className="Link"
                href="https://timearendezveny.turizmusakademia.hu/presentations/TA_3_eloadas_honlapra_0302.pdf"
                target="_blank"
              >
                {t('knowledge_store_page_paragraph6_link3')}
              </a>
            </li>
            <li>
              <a
                className="Link"
                href="https://timearendezveny.turizmusakademia.hu/presentations/TA_4_eloadas_honlapra_0302.pdf"
                target="_blank"
              >
                {t('knowledge_store_page_paragraph6_link4')}
              </a>
            </li>
          </ul>
        </Box>
        {/* ########## Temporarily hide Knowledge Store - video section ##########*/}
        {/*<Box mb={10} tabIndex={0}>*/}
        {/*  <h2 className={classNames('Title', 'H1Style')}>*/}
        {/*    {t('knowledge_store_page_subtitle_educational_movies_title')}*/}
        {/*  </h2>*/}
        {/*  <div className="TitleBorder" />*/}
        {/*</Box>*/}
        {/*<KnowledgeStoreVideoSection />*/}
      </Box>
    );
  }
}

export default hoistStatics(
  withTranslation()(withStyles(knowledgeStorePageStyles)(KnowledgeStorePage)),
  KnowledgeStorePage
);
