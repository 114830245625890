import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import ExamsPage from './ExamsPage';
import { getCoursesWithExams, loadMoreCoursesWithExams, resetCoursesWithExams } from 'store/courses/actions';
import { addCommonLoader, removeCommonLoader } from 'store/loader/actions';

const mapStateToProps = (state) => {
  return {
    coursesWithExams: state.courses.coursesWithExams.data,
    numberOfCoursesWithExams: state.courses.coursesWithExams.count,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getCoursesWithExams, loadMoreCoursesWithExams, addCommonLoader, removeCommonLoader, resetCoursesWithExams },
    dispatch
  );
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ExamsPage);
