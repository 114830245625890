import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { paginationStyles } from './styles';
import { ClassStyle } from '../../types/types';

class Pagination extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      PaginationContainer: ClassStyle,
      Invisible: ClassStyle,
    }),
    t: PropTypes.func.isRequired,
    itemCount: PropTypes.number,
    itemPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    visiblePageLinks: PropTypes.number,
    setCurrentPage: PropTypes.func,
  };

  activePageLink = (page) => {
    const { currentPage } = this.props;
    return currentPage === page ? 'Active' : '';
  };

  invisiblePageLink = (page, maxPage) => {
    const { currentPage, visiblePageLinks } = this.props;
    return currentPage > page + Math.max((visiblePageLinks - 1) / 2, visiblePageLinks - 1 - maxPage + currentPage) ||
      currentPage < page - Math.max((visiblePageLinks - 1) / 2, visiblePageLinks - currentPage)
      ? 'Hidden'
      : '';
  };

  render() {
    const { t, itemCount, itemPerPage, currentPage, setCurrentPage, classes } = this.props;
    const pages = Array.from({ length: Math.ceil(itemCount / itemPerPage) }, (v, k) => k + 1);
    const maxPage = pages.length;
    return pages.length > 1 ? (
      <Box component="nav" mt={2} className={classes.PaginationContainer}>
        <Box
          className={classNames('Prev', { [classes.Invisible]: currentPage === 1 })}
          onClick={() => setCurrentPage(currentPage - 1)}
          tabIndex={0}
          role="button"
          aria-label={t('alt_pagination_prev')}
        >
          <ArrowBackIcon />
        </Box>

        {pages.map((page, index) => (
          <Box
            tabIndex={0}
            className={classNames('Page', this.activePageLink(page), this.invisiblePageLink(page, maxPage))}
            onClick={() => setCurrentPage(page)}
            key={index}
            role="button"
            aria-label={t('alt_pagination_page', { page })}
          >
            <span>{page}</span>
          </Box>
        ))}

        <Box
          className={classNames('Next', { [classes.Invisible]: currentPage === maxPage })}
          onClick={() => setCurrentPage(currentPage + 1)}
          role="button"
          aria-label={t('alt_pagination_next')}
          tabIndex={0}
        >
          <ArrowForwardIcon />
        </Box>
      </Box>
    ) : null;
  }
}

export default withTranslation()(withStyles(paginationStyles)(Pagination));
