export default {
  API_BASE_URL: 'https://mtu-api-dev.attrecto-dev.com/api',
  STORAGE_BASE_URL: 'https://mtu-api-dev.attrecto-dev.com',
  AUTH_TOKEN_ID: 'id',
  API_TOKEN: 'MTU_TOKEN',
  API_TOKEN_STAY_IN: 'MTU_TOKEN_STAY_IN',
  API_TOKEN_EXPIRES_AT: 'MTU_TOKEN_EXPIRES_AT',
  INTERVAL_IN_MS: 1000,
  EXPIRATION_MODAL_TIME_IN_MS: 60000,
  REFRESH_TOKEN_IN_PROGRESS: 'refreshTokenInProgress',
  REFRESH_TOKEN_RESOURCE: '/auth/login/refresh',
  LOGIN_RESOURCE: '/auth/login/email',
  SOCIAL_LOGIN_RESOURCE: '/auth/login/social',
  QUERY_FORMAT: { arrayFormat: 'bracket' },
  ITEMS_PER_PAGE: 20,
  CERTIFICATES_PER_PAGE: 9,
  I18NEXT: 'i18nextMTU',
  SORT_BY: {
    EMAIL: 'email',
  },
  HOME_PAGE_TUTORIAL_READY: 'homePageTutorialReady',
  TOPICS_PAGE_TUTORIAL_READY: 'topicsPageTutorialReady',
  COURSE_PAGE_TUTORIAL_READY: 'coursePageTutorialReady',
  IS_BROWSER: process && !process.release,
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyA1G5L8Wm5Kt4gBR9P0fZq9AyTMPnLxO_Y',
    authDomain: 'mtu-attrecto.firebaseapp.com',
    databaseURL: 'https://mtu-attrecto.firebaseio.com',
    projectId: 'mtu-attrecto',
    storageBucket: 'mtu-attrecto.appspot.com',
    messagingSenderId: '692263203129',
    appId: '1:692263203129:web:d9fbd1e50ff7e355feb8e8',
    measurementId: 'G-D9DSCH44Z5',
  },
};
