import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const AppSvgIcon = ({ children, role, ...props }) => (
  <SvgIcon role={role} {...props}>
    {children}
  </SvgIcon>
);

AppSvgIcon.propTypes = {
  children: PropTypes.node,
  role: PropTypes.string,
};

export default AppSvgIcon;
