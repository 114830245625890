import * as CoursesService from 'services/courses';
import {
  BOOKMARK_COURSE,
  BOOKMARK_COURSE_FAILED,
  BOOKMARK_COURSE_SUCCESS,
  GET_BOOKMARKED_COURSES,
  GET_BOOKMARKED_COURSES_FAILED,
  GET_BOOKMARKED_COURSES_SUCCESS,
  GET_COURSE_BY_ID,
  GET_COURSE_BY_ID_FAILED,
  GET_COURSE_BY_ID_SUCCESS,
  GET_COURSES,
  GET_COURSES_FAILED,
  GET_COURSES_FOR_TOPIC,
  GET_COURSES_FOR_TOPIC_FAILED,
  GET_COURSES_FOR_TOPIC_SUCCESS,
  GET_COURSES_SUCCESS,
  GET_COURSES_WITH_EXAMS,
  GET_COURSES_WITH_EXAMS_SUCCESS,
  GET_ONGOING_COURSES,
  GET_ONGOING_COURSES_FAILED,
  GET_ONGOING_COURSES_SUCCESS,
  LOAD_MORE_BOOKMARKED_COURSES,
  LOAD_MORE_BOOKMARKED_COURSES_FAILED,
  LOAD_MORE_BOOKMARKED_COURSES_SUCCESS,
  LOAD_MORE_COURSES,
  LOAD_MORE_COURSES_FAILED,
  LOAD_MORE_COURSES_SUCCESS,
  LOAD_MORE_COURSES_WITH_EXAMS,
  LOAD_MORE_COURSES_WITH_EXAMS_FAILED,
  LOAD_MORE_COURSES_WITH_EXAMS_SUCCESS,
  LOAD_MORE_ONGOING_COURSES,
  LOAD_MORE_ONGOING_COURSES_FAILED,
  LOAD_MORE_ONGOING_COURSES_SUCCESS,
  RESET_BOOKMARKED_COURSES,
  RESET_COURSES,
  RESET_COURSES_WITH_EXAMS,
  RESET_ONGOING_COURSES,
  SET_FILTERS,
  SET_SORT_BY,
} from './types';
import { GET_TOP_LIST_OF_GROUPS_FAILED } from '../topList/types';

export const getCourses = (params, language) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COURSES });

      const courses = await CoursesService.getCourses({ language, ...params });

      dispatch({
        type: GET_COURSES_SUCCESS,
        payload: courses,
      });

      return courses;
    } catch (error) {
      dispatch({ type: GET_COURSES_FAILED });
      throw error;
    }
  };
};

export const resetCourses = () => {
  return { type: RESET_COURSES };
};

export const getCoursesForTopic = (params, language) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COURSES_FOR_TOPIC });

      const courses = await CoursesService.getCoursesForTopic({ language, ...params });

      dispatch({
        type: GET_COURSES_FOR_TOPIC_SUCCESS,
        payload: courses,
      });

      return courses;
    } catch (error) {
      dispatch({ type: GET_COURSES_FOR_TOPIC_FAILED });
      throw error;
    }
  };
};

export const loadMoreCoursesForTopic = (params, language) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_MORE_COURSES });

      const courses = await CoursesService.getCoursesForTopic({ language, ...params });

      dispatch({
        type: LOAD_MORE_COURSES_SUCCESS,
        payload: courses,
      });

      return courses;
    } catch (error) {
      dispatch({ type: LOAD_MORE_COURSES_FAILED });
      throw error;
    }
  };
};

export const loadMoreCourses = (params) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_MORE_COURSES });

      const courses = await CoursesService.getCourses(params);

      dispatch({
        type: LOAD_MORE_COURSES_SUCCESS,
        payload: courses,
      });

      return courses;
    } catch (error) {
      dispatch({ type: LOAD_MORE_COURSES_FAILED });
      throw error;
    }
  };
};

export const setFilters = ({ tags, duration, examDone, searchString }) => {
  return async (dispatch) => {
    dispatch({
      type: SET_FILTERS,
      payload: {
        tags,
        duration,
        examDone,
        searchString,
      },
    });
  };
};

export const setSortBy = (sortBy) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SORT_BY,
      payload: { sortBy },
    });
  };
};

export const getCourseById = (id, language) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COURSE_BY_ID });

      const course = await CoursesService.getCourseById(id, language);

      dispatch({
        type: GET_COURSE_BY_ID_SUCCESS,
        payload: course,
      });
      return course;
    } catch (error) {
      dispatch({ type: GET_COURSE_BY_ID_FAILED });
      throw error;
    }
  };
};

export const getCoursesWithExams = ({ page, language, token }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COURSES_WITH_EXAMS });

      const courses = await CoursesService.getCoursesWithExams(page, language, token);

      dispatch({
        type: GET_COURSES_WITH_EXAMS_SUCCESS,
        payload: courses,
      });
    } catch (error) {
      dispatch({ type: GET_TOP_LIST_OF_GROUPS_FAILED });

      throw error;
    }
  };
};

export const resetCoursesWithExams = () => {
  return { type: RESET_COURSES_WITH_EXAMS };
};

export const loadMoreCoursesWithExams = ({ page }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_MORE_COURSES_WITH_EXAMS });

      const courses = await CoursesService.getCoursesWithExams(page);

      dispatch({
        type: LOAD_MORE_COURSES_WITH_EXAMS_SUCCESS,
        payload: courses,
      });
    } catch (error) {
      dispatch({ type: LOAD_MORE_COURSES_WITH_EXAMS_FAILED });

      throw error;
    }
  };
};

export const getOngoingCourses = ({ page, language, token }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ONGOING_COURSES });

      const courses = await CoursesService.getOngoingCourses(page, language, token);

      dispatch({
        type: GET_ONGOING_COURSES_SUCCESS,
        payload: courses,
      });
    } catch (error) {
      dispatch({ type: GET_ONGOING_COURSES_FAILED });
      throw error;
    }
  };
};

export const resetOngoingCourses = () => {
  return { type: RESET_ONGOING_COURSES };
};

export const loadMoreOngoingCourses = ({ page }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_MORE_ONGOING_COURSES });

      const courses = await CoursesService.getOngoingCourses(page);

      dispatch({
        type: LOAD_MORE_ONGOING_COURSES_SUCCESS,
        payload: courses,
      });
    } catch (error) {
      dispatch({ type: LOAD_MORE_ONGOING_COURSES_FAILED });
      throw error;
    }
  };
};

export const getBookmarkedCourses = ({ page, language, token }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_BOOKMARKED_COURSES });

      const courses = await CoursesService.getBookmarkedCourses(page, language, token);

      dispatch({
        type: GET_BOOKMARKED_COURSES_SUCCESS,
        payload: courses,
      });
    } catch (error) {
      dispatch({ type: GET_BOOKMARKED_COURSES_FAILED });
      throw error;
    }
  };
};

export const resetBookmarkedCourses = () => {
  return { type: RESET_BOOKMARKED_COURSES };
};

export const loadMoreBookmarkedCourses = ({ page }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_MORE_BOOKMARKED_COURSES });

      const courses = await CoursesService.getBookmarkedCourses(page);

      dispatch({
        type: LOAD_MORE_BOOKMARKED_COURSES_SUCCESS,
        payload: courses,
      });
    } catch (error) {
      dispatch({ type: LOAD_MORE_BOOKMARKED_COURSES_FAILED });
      throw error;
    }
  };
};

export const bookmarkCourse = ({ id, isBookmarked }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BOOKMARK_COURSE });

      await CoursesService.bookmarkCourse(id, isBookmarked);

      dispatch({
        type: BOOKMARK_COURSE_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: BOOKMARK_COURSE_FAILED });
      throw error;
    }
  };
};
