import Page from 'components/Page/Page';
import TopicsPage from 'modules/TopicsPage';
import withSSR from './withSSR';

export const getTopicsRoute = (topic) => ({
  path: `/hu/temak/${topic.id}`,
  component: withSSR(Page(TopicsPage, 'TopicsPage', topic.title)),
  linkName: topic.title,
  exact: true,
});

export const getTopicsRoutes = (topics) => {
  return topics.map((topic) => getTopicsRoute(topic));
};
