import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  absolute,
  auto,
  block,
  bold,
  breakSpaces,
  center,
  column,
  flex,
  flexStart,
  inlineBlock,
  none,
  normal,
  pointer,
  relative,
  spaceBetween,
  transparent,
  underline,
  unset,
  uppercase,
  wrap,
} from 'constants/Jss';

const cloudBorder = (color) => `1px solid ${color}`;

export const useRemainingTimeStyles = makeStyles((theme) => ({
  TimeStrip: {
    fontFamily: 'Montserrat',
    fontWeight: bold,
    fontSize: 21,
    textTransform: uppercase,
    width: '100%',
    display: flex,
    alignItems: center,
    justifyContent: center,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  TimeNumbers: {
    width: 70,
    display: flex,
    justifyContent: flexStart,
  },
}));

export const useProgressBarStyles = makeStyles((theme) => ({
  ProgressBarContainer: {
    width: 665,
    height: 30,
    borderRadius: 15,
    backgroundColor: theme.palette.greyF2,
    position: relative,
    [theme.breakpoints.down('sm')]: {
      height: 16,
      borderRadius: 8,
      width: '100%',
    },
  },
  NumberIndicator: {
    position: absolute,
    top: 0,
    left: -80,
    height: 30,
    display: flex,
    alignItems: center,
    fontFamily: 'Montserrat',
    fontWeight: bold,
    fontSize: 21,
    color: theme.palette.grey64,
    [theme.breakpoints.down('sm')]: {
      top: -35,
      left: 0,
    },
  },
  ProgressStrip: {
    height: 30,
    borderRadius: 15,
    backgroundColor: theme.palette.yellow,
    [theme.breakpoints.down('sm')]: {
      height: 16,
      borderRadius: 8,
    },
  },
}));

export const useFormStyles = makeStyles((theme) => ({
  FormBox: {
    maxWidth: 950,
    marginLeft: auto,
    marginRight: auto,
  },
  Checkbox: {
    alignSelf: flexStart,
    marginRight: theme.spacing(3),
    '& svg': {
      fill: theme.palette.checkboxBorder,
    },
    '&:hover': {
      backgroundColor: theme.palette.whiteHover,
    },
    '&.Mui-checked': {
      color: theme.palette.whiteHover,
      '&:hover': {
        backgroundColor: `${theme.palette.whiteHover} !important`,
      },
      '& svg': {
        fill: theme.palette.lightBlue,
      },
    },
  },
  AnswerBox: {
    display: flex,
    border: `1px solid ${theme.palette.answerBorder}`,
    borderRadius: 3,
    padding: theme.spacing(2.5),
    backgroundColor: theme.palette.white,
    position: relative,
    '&:hover': {
      boxShadow: '0px 5px 20px #00000041',
    },
    '&.Checked': {
      border: `2px solid ${theme.palette.lightBlue}`,
    },
    '&.Correct': {
      border: `2px solid ${theme.palette.greenSuccess}`,
      '& svg': {
        fill: theme.palette.greenSuccess,
      },
    },
    '&.Wrong': {
      border: `2px solid ${theme.palette.lightPink}`,
      '& svg': {
        fill: theme.palette.lightPink,
      },
    },
    '&.TrueOrFalse': {
      border: 0,
      backgroundColor: `${theme.palette.white} !important`,
      boxShadow: none,
    },
    '&:not(.TrueOrFalse)': {
      cursor: pointer,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      '& svg': {
        width: 20,
        height: 20,
      },
    },
  },
  Answer: {
    color: theme.palette.blackTitle,
    fontSize: 18,
    lineHeight: '21px',
    '& .MuiRadio-root, & .MuiCheckbox-root': {
      margin: '-9px 24px -9px 0',
      alignSelf: center,
    },
    '& .MuiFormControlLabel-label': {
      alignSelf: center,
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: theme.palette.blackTitle,
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 !important',
      '& .MuiFormControlLabel-label': {
        fontSize: 14,
        lineHeight: '18px',
      },
      '& > span': {
        padding: 0,
        margin: 0,
        marginRight: theme.spacing(1),
      },
    },
  },
  Flex: {
    display: flex,
    flexDirection: 'row !important',
    flexWrap: 'nowrap !important',
    justifyContent: center,
  },
  SelectList: {
    padding: theme.spacing(5),
    background: `${theme.palette.white} 0% 0% no-repeat padding-box`,
    boxShadow: `0px 16px 32px #00000029`,
    borderRadius: 13,
    opacity: 1,
  },
  SelectListPaper: {
    borderRadius: 13,
    boxShadow: none,
    border: `1px solid ${theme.palette.grey70}`,
  },
  WordCloud: {
    background: `${theme.palette.greyF9} 0% 0% no-repeat padding-box`,
    border: cloudBorder(theme.palette.grey70),
    borderRadius: 5,
    opacity: 1,
  },
  WordCloudPadding: {
    paddingLeft: theme.spacing(0.75),
  },
  WordsContainer: {
    flexWrap: wrap,
  },
  CloudContainer: {
    display: flex,
    alignItems: center,
    flexWrap: wrap,
  },
  Cloud: {
    width: auto,
    height: 26,
    background: `${theme.palette.greyE3} 0% 0% no-repeat padding-box`,
    border: cloudBorder(theme.palette.grey70),
    borderRadius: 5,
    opacity: 1,
    marginLeft: 0,
    marginRight: 10,
    minWidth: 56,
    position: relative,
    '&:hover': {
      cursor: pointer,
    },
  },
  WordCloudAnswered: {
    display: flex,
    justifyContent: center,
    alignItems: center,
    minWidth: 115,
    margin: `0 ${theme.spacing(1)}px !important`,
    padding: theme.spacing(0.75),
  },
  WordCloudItem: {
    whiteSpace: normal,
    borderBottom: `1px solid ${theme.palette.blackTitle}`,
    padding: theme.spacing(2),
    fontSize: 18,
    '&:last-child, &:first-child': {
      border: none,
    },
  },
  WordCloudItemPre: {
    margin: 0,
    padding: 0,
    fontFamily: 'WonderUnit, sans-serif',
    whiteSpace: breakSpaces,
    fontSize: 18,
  },
  WordCloudItemFilled: {
    color: theme.palette.darkBlue,
    fontWeight: bold,
    textDecoration: underline,
  },
  WordCloudFilled: {
    background: `${theme.palette.white} 0% 0% no-repeat padding-box`,
    border: cloudBorder(theme.palette.darkBlue),
    color: theme.palette.darkBlue,
    '& path': {
      fill: theme.palette.darkBlue,
    },
  },
  WordCloudSuccess: {
    marginLeft: 13,
    marginRight: 21,
    background: `${theme.palette.white} 0% 0% no-repeat padding-box`,
    border: cloudBorder(theme.palette.greenSuccess),
    color: theme.palette.greenSuccess,
    padding: theme.spacing(0.75),
  },
  CloudFilled: {
    backgroundColor: theme.palette.white,
    border: cloudBorder(theme.palette.darkBlue),
    color: theme.palette.darkBlue,
    display: flex,
    justifyContent: center,
    alignItems: center,
    fontSize: 18,
    width: 'auto',
    whiteSpace: breakSpaces,
    height: auto,
    padding: theme.spacing(0.75),
    [theme.breakpoints.down('sm')]: {
      whiteSpace: unset,
    },
  },
  BubbleFilled: {
    width: '100%',
    height: '100%',
  },
  BubbleCurrent: {
    background: `${theme.palette.currentBubble} 0% 0% no-repeat padding-box`,
    border: cloudBorder(theme.palette.examResultBlue),
  },
  CloudSuccess: {
    background: `${theme.palette.white} 0% 0% no-repeat padding-box`,
    border: cloudBorder(theme.palette.greenSuccess),
    color: theme.palette.greenSuccess,
  },
  WordCloudFailure: {
    background: `${theme.palette.white} 0% 0% no-repeat padding-box`,
    border: cloudBorder(theme.palette.lightPink),
    color: theme.palette.lightPink,
    padding: theme.spacing(0.75),
  },
  SubstitutionWordSpacing: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  SubstitutionFormControl: {
    background: transparent,
  },
  SelectMenu: {
    whiteSpace: normal,
    textAlign: center,
    paddingLeft: theme.spacing(0.75),
    fontSize: 18,
  },
  CloudSpacing: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  SubstitutionSentencePart: {
    display: inlineBlock,
    whiteSpace: breakSpaces,
    margin: 0,
  },
  SubstitutionSelect: {
    marginRight: 14,
  },
  SubstitutionAnsweredContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: column,
    },
  },
  SubstitutionAnswered: {
    paddingRight: theme.spacing(4),
    height: 36,
  },
  AccessibleSubstitutionAnswered: {
    [theme.breakpoints.down('sm')]: {
      marginTop: `${theme.spacing(1)}px !important`,
    },
  },
  Divider: {
    width: '100%',
    height: 3,
    backgroundColor: theme.palette.lightBlue,
    margin: `${theme.spacing(4)}px 0`,
  },
  PairingMainContainer: {
    display: flex,
    justifyContent: spaceBetween,
    [theme.breakpoints.down('sm')]: {
      flexDirection: column,
    },
  },
  PairingSubContainer: {
    width: '48%',
    display: flex,
    justifyContent: center,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: block,
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
    },
  },
  PairingContainer: {
    width: '100%',
    background: `${theme.palette.white} 0% 0% no-repeat padding-box`,
    border: cloudBorder(theme.palette.pairingBorder),
    borderRadius: 23,
    display: flex,
    flexDirection: column,
    alignItems: center,
    position: relative,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  PairCloudEmpty: {
    opacity: 0.2,
    color: transparent,
  },
  PairCloudSelected: {
    background: `${theme.palette.white} 0% 0% no-repeat padding-box`,
    border: cloudBorder(theme.palette.lightBlueHover),
    color: theme.palette.lightBlueHover,
    fontWeight: bold,
    textDecoration: underline,
  },
  ClickableWord: {
    '&:hover': {
      cursor: pointer,
    },
  },
  PairGoodWordsTitle: {
    color: theme.palette.greenSuccess,
    borderBottom: `2px solid ${theme.palette.pairingBorder}`,
    [theme.breakpoints.down('sm')]: {
      textAlign: center,
    },
  },
  PairGoodWordsAnswer: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      textAlign: center,
    },
  },
  PairingBottomContainer: {
    display: flex,
    [theme.breakpoints.down('sm')]: {
      flexDirection: column,
    },
  },
  BottomContainer: {
    width: '49%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  AnsweredIconContainer: {
    position: absolute,
    top: 'calc(50% - 12px)',
  },
  AnsweredSize: {
    width: `${auto} !important`,
    height: `${36}px !important`,
    minWidth: unset,
  },
}));

export const useExamTestOpeningFooterStyles = makeStyles((theme) => ({
  ExamTestFooter: {
    width: '100%',
    height: theme.mixins.testPage.footerHeight,
    backgroundColor: theme.palette.greyF2,
    border: 0,
    borderTop: `4px solid ${theme.palette.bluishGrey}`,
    display: flex,
    alignItems: center,
    justifyContent: center,
    animation: none,
    borderRadius: 0,
    margin: '0 !important',
    [theme.breakpoints.down('sm')]: {
      height: theme.mixins.testPage.mobileFooterHeight,
      minHeight: theme.mixins.testPage.mobileFooterHeight,
      position: 'sticky',
      bottom: 0,
      '&.IOs': {
        position: '-webkit-sticky',
      },
    },
  },
  ExamTestOpeningFooter: {
    margin: '0 auto',
    maxWidth: 750,
    width: 750,
    display: flex,
    justifyContent: center,
    position: relative,
    [theme.breakpoints.down('sm')]: {
      justifyContent: spaceBetween,
      padding: `0 ${theme.spacing(3)}px`,
    },
  },
  AccessibilityContainer: {
    position: absolute,
    left: 0,
    top: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'static',
    },
  },
  AccessibilityLabel: {
    maxWidth: 135,
    marginLeft: `${theme.spacing(4)}px !important`,
    fontWeight: `${bold} !important`,
    fontSize: '1rem !important',
  },
  AccessibilityCheckbox: {
    width: 30,
    height: 30,
    border: `1px solid ${theme.palette.grey70}`,
    display: 'flex',
    borderRadius: 6,
    justifyContent: center,
  },
  AccessibilityCheckboxRoot: {
    backgroundColor: 'transparent',
    width: 30,
    height: 30,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& > .MuiIconButton-label': {
      width: 30,
    },
  },
}));

export const useQuestionImageStyles = makeStyles((theme) => ({
  ImageContainer: {
    display: flex,
    justifyContent: center,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down(768)]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}));
