import request from 'utils/request';
import { Methods } from 'utils/http';

const BASE_URL = '/modules';

export const getTopics = async (language) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}`,
    language,
  });
};