import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation } from 'react-i18next';

import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';

import { coursesPageStyles } from './styles';
import { ClassStyle, Course } from 'types/types';
import { getBookmarkedCourses, getOngoingCourses } from 'store/courses/actions';
import { getProfileDetails } from 'store/profile/actions';
import { CardContainer } from 'components/CardContainer/CardContainer';
import { CourseCardList } from 'components';
import { titleFocus } from 'utils/titleFocus';
import getArraySortComparer from 'utils/getArraySortComparer';
import { createSpaceOrEnterHandler } from 'utils/eventUtils';
import { CardsPerPage } from 'constants/Constants';

class CoursesPage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      CoursesContainer: ClassStyle,
      TabContainer: ClassStyle,
      Tab: ClassStyle,
      CardBox: ClassStyle,
      ResultContainer: ClassStyle,
      ResultBox: ClassStyle,
    }),
    t: PropTypes.func,
    ongoingCourses: PropTypes.arrayOf(Course),
    bookmarkedCourses: PropTypes.arrayOf(Course),
    numberOfOngoingCourses: PropTypes.number,
    numberOfBookmarkedCourses: PropTypes.number,
    getOngoingCourses: PropTypes.func,
    getBookmarkedCourses: PropTypes.func,
    loadMoreOngoingCourses: PropTypes.func,
    loadMoreBookmarkedCourses: PropTypes.func,
    addCommonLoader: PropTypes.func,
    removeCommonLoader: PropTypes.func,
    resetOngoingCourses: PropTypes.func,
    resetBookmarkedCourses: PropTypes.func,
    isLoading: PropTypes.bool,
    shouldShowLoading: PropTypes.bool,
  };

  static pageTitleKey = 'page_title_courses';

  static async getInitialData({ dispatch, language, token }) {
    try {
      if (token) {
        await Promise.all([
          dispatch(getOngoingCourses({ page: 1, language, token })),
          dispatch(getBookmarkedCourses({ page: 1, language, token })),
          dispatch(getProfileDetails({ language, token })),
        ]);
      }
      titleFocus(dispatch);
    } catch (err) {
      console.error(err);
    }
  }

  componentWillUnmount() {
    this.props.resetOngoingCourses();
    this.props.resetBookmarkedCourses();
  }

  componentDidUpdate() {
    const { isLoading, bookmarkedCourses, numberOfBookmarkedCourses, loadMoreBookmarkedCourses } = this.props;
    if (
      !isLoading &&
      bookmarkedCourses.length % CardsPerPage !== 0 &&
      numberOfBookmarkedCourses > bookmarkedCourses.length
    ) {
      loadMoreBookmarkedCourses({ page: this.state.bookmarkedPage });
    }
  }

  tabs = {
    ongoing: 'ongoing',
    bookmarked: 'bookmarked',
  };

  state = {
    activeTab: this.tabs.ongoing,
    onGoingPage: 1,
    bookmarkedPage: 1,
    inProp: true,
  };

  setActiveTab = (activeTab) => {
    this.setState({ inProp: false });
    setTimeout(() => {
      this.setState({ activeTab, inProp: true });
    }, 100);
  };

  loadMoreOngoing = async () => {
    const { onGoingPage } = this.state;
    const { loadMoreOngoingCourses } = this.props;
    const newPage = onGoingPage + 1;
    this.setState({ onGoingPage: newPage });
    await loadMoreOngoingCourses({ page: newPage });
  };

  loadMoreBookmarked = async () => {
    const { bookmarkedPage } = this.state;
    const { loadMoreBookmarkedCourses } = this.props;
    const newPage = bookmarkedPage + 1;
    this.setState({ bookmarkedPage: newPage });
    await loadMoreBookmarkedCourses({ page: newPage });
  };

  render() {
    const {
      classes,
      t,
      ongoingCourses,
      bookmarkedCourses,
      numberOfOngoingCourses,
      numberOfBookmarkedCourses,
      isLoading,
      shouldShowLoading,
    } = this.props;
    const { activeTab, inProp } = this.state;

    return (
      <div className={classes.CoursesContainer}>
        <Box className={classes.TabContainer} role="tablist">
          <Box
            mr={6}
            className={classNames(classes.Tab, { Active: activeTab === this.tabs.ongoing })}
            onClick={() => this.setActiveTab(this.tabs.ongoing)}
            role="tab"
            tabIndex={0}
            aria-selected={activeTab === this.tabs.ongoing}
            onKeyPress={() => createSpaceOrEnterHandler(this.setActiveTab(this.tabs.ongoing))}
          >
            {t('courses_page_ongoing')}
          </Box>
          <Box
            className={classNames(classes.Tab, { Active: activeTab === this.tabs.bookmarked })}
            onClick={() => this.setActiveTab(this.tabs.bookmarked)}
            role="tab"
            tabIndex={0}
            aria-selected={activeTab === this.tabs.bookmarked}
            onKeyPress={() => createSpaceOrEnterHandler(this.setActiveTab(this.tabs.bookmarked))}
          >
            {t('courses_page_bookmarked')}
          </Box>
        </Box>
        <Fade in={inProp} timeout={200}>
          <Box aria-live="polite" mt={4} mb={5} className={classes.CardBox}>
            {activeTab === this.tabs.ongoing && ongoingCourses ? (
              <CardContainer
                items={ongoingCourses}
                numberOfItems={numberOfOngoingCourses}
                loadMoreItems={this.loadMoreOngoing}
                resultClass={classNames(classes.ResultContainer, 'Course', 'page-animation-exit-hidden')}
                isLoading={isLoading}
                skeleton={shouldShowLoading}
              >
                <CourseCardList
                  courses={ongoingCourses}
                  skeleton={shouldShowLoading}
                  itemContainerClass={classes.ResultBox}
                />
              </CardContainer>
            ) : null}
            {activeTab === this.tabs.bookmarked && bookmarkedCourses ? (
              <CardContainer
                items={bookmarkedCourses}
                numberOfItems={numberOfBookmarkedCourses}
                loadMoreItems={this.loadMoreBookmarked}
                resultClass={classNames(classes.ResultContainer, 'Course', 'page-animation-exit-hidden')}
                isLoading={isLoading}
                skeleton={shouldShowLoading}
              >
                <CourseCardList
                  courses={bookmarkedCourses.sort(getArraySortComparer(bookmarkedCourses, 'title'))}
                  skeleton={shouldShowLoading}
                  itemContainerClass={classes.ResultBox}
                />
              </CardContainer>
            ) : null}
          </Box>
        </Fade>
      </div>
    );
  }
}

export default hoistStatics(withTranslation()(withStyles(coursesPageStyles)(CoursesPage)), CoursesPage);
