import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import find from 'lodash/find';

import Box from '@material-ui/core/Box';

import SelectedDotIcon from 'assets/images/selected-dot-icon.png';

import { useResultTableStyles } from './styles';

function getAriaLabel(answers, t) {
  const goodAnswer = find(answers, (answer) => answer.isGood === true);
  const selectedAnswer = find(answers, (answer) => answer.isSelected === true);
  const noAnswer = t('aria_label_no_answer');
  const ariaLabel = t('aria_label_result_table_true_or_false', {
    correctAnswer: goodAnswer?.answer,
    selectedAnswer: selectedAnswer ? selectedAnswer.answer : noAnswer,
  });

  return ariaLabel;
}

function TrueOrFalseResultTable({ answers, isDesktopLayout }) {
  const { t } = useTranslation();
  const classes = useResultTableStyles();

  return isDesktopLayout ? (
    <Box aria-label={getAriaLabel(answers, t)} className={classes.ResultTableContainer}>
      <table aria-hidden={true}>
        <thead>
          <tr>
            <th scope="col" className="hidden">
              {t('result_table_header_index')}
            </th>
            <th scope="col" className="hidden">
              {t('result_table_header_answer')}
            </th>
            <th scope="col" className="hidden">
              {t('result_table_header_solution')}
            </th>
            <th scope="col" className={classes.AlignCenter}>
              {t('result_table_header_selected_mark')}
            </th>
          </tr>
        </thead>
        <tbody>
          {answers.map((answer, index) => (
            <tr key={answer.id} className={classes.TableRowBorder}>
              <td>{`${index + 1}.`}</td>
              <td>{answer.answer}</td>
              <td className={answer.isGood ? 'success' : 'fail'}>
                {answer.isGood ? t('result_table_data_correct_answer') : t('result_table_data_bad_answer')}
              </td>
              <td className={classes.AlignCenter}>{answer.isSelected ? <img src={SelectedDotIcon} alt="" /> : null}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  ) : (
    <Box className={classes.MobileResultTableContainer}>
      <table className={classes.FirstLayout}>
        {answers.map((answer, index) => (
          <tr key={answer.id}>
            <td>{`${index + 1}.`}</td>
            <td>{answer.answer}</td>
            <td className={answer.isGood ? classes.Success : classes.Fail}>
              {answer.isGood ? t('result_table_data_correct_answer') : t('result_table_data_bad_answer')}
            </td>
            <td>
              {answer.isSelected ? (
                <div className={classes.AlignRight}>
                  <span className={classes.Selected}>{`${t('result_table_header_selected_mark')} `}</span>
                  <img src={SelectedDotIcon} alt="" />
                </div>
              ) : null}
            </td>
          </tr>
        ))}
      </table>
    </Box>
  );
}

TrueOrFalseResultTable.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.object),
  isDesktopLayout: PropTypes.bool,
};

export default TrueOrFalseResultTable;
