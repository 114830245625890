import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { Trans, withTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { fade } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import FilePreview, { FileTypes } from './FilePreview';
import { absolute, block, center, column, cover, flex, none, noRepeat, nowrap, pointer, relative } from 'constants/Jss';
import Button from 'components/Button/Button';
import { ClassStyle } from '../../types/types';
import noImage from 'assets/images/no-image-3.png';

const styles = (theme) => ({
  FileUpload: {
    height: '100%',
    position: relative,
    '& input:[type="file"]': {
      display: none,
    },
    '&.has-errors': {
      '& .dropzone': {
        borderColor: theme.palette.error.main,
      },
    },
    '& .video-loading': {
      position: absolute,
      width: '100%',
      height: '100%',
      justifyContent: center,
    },
  },
  Container: {
    position: relative,
    '& .delete': {
      position: absolute,
      right: 0,
      top: 0,
    },
    display: flex,
    height: '100%',
    flexDirection: column,
    flexWrap: nowrap,
    alignItems: center,
    [theme.breakpoints.down('sm')]: {
      minHeight: 260,
    },
  },
  DeleteIcon: {
    position: absolute,
    top: 0,
    right: 'calc(50% - 118px)',
    '& svg': {
      fill: theme.palette.grey70,
    },
    '&:hover': {
      backgroundColor: theme.palette.greyF2,
    },
    [theme.breakpoints.down('sm')]: {
      right: 'calc(50% - 100px)',
    },
  },
  DropzoneBox: {
    width: '100%',
    height: 60,
    backgroundColor: theme.palette.white,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
    },
  },
  DropzoneGrid: {
    height: '100%',
  },
  Dropzone: {
    display: flex,
    alignItems: center,
    justifyContent: center,
    border: `1px dashed ${fade(theme.palette.primary.main, 0.2)}`,
    transition: theme.transitions.create(['border', 'background-color'], {
      duration: theme.transitions.duration.complex,
    }),
    width: '100%',
    height: '100%',
    cursor: pointer,
    textAlign: center,
    '&:hover, &.active': {
      borderColor: theme.palette.primary.main,
    },
    '&.active.accept': {
      backgroundColor: theme.palette.greyE5,
      '& .drop': {
        display: block,
      },
    },
    '&.active.reject': {
      borderColor: theme.palette.error.main,
      '& .reject': {
        display: block,
      },
    },
    '&:focus': {
      outline: none,
    },
    '& .info': {
      display: none,
    },
  },
  PreviewGridPlaceholder: {
    display: flex,
    alignItems: center,
    justifyContent: center,
    [theme.breakpoints.down('sm')]: {
      minHeight: 200,
    },
  },
  PositionRelative: {
    position: relative,
  },
  Placeholder: {
    width: theme.mixins.filePreview.size,
    height: theme.mixins.filePreview.size,
    [theme.breakpoints.down('sm')]: {
      width: theme.mixins.filePreview.mobileSize,
      height: theme.mixins.filePreview.mobileSize,
    },
    backgroundColor: theme.palette.avatarGrey,
    backgroundSize: cover,
    backgroundPosition: center,
    backgroundRepeat: noRepeat,
    borderRadius: '50%',
    '&.NoAvatar': {
      backgroundSize: '60%',
    },
  },
  DropzoneContent: {
    display: flex,
    alignItems: center,
    justifyContent: center,
    color: theme.palette.grey70,
  },
});

class FileUpload extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      FileUpload: ClassStyle,
      Container: ClassStyle,
      PositionRelative: ClassStyle,
      DeleteIcon: ClassStyle,
      PreviewGridPlaceholder: ClassStyle,
      Placeholder: ClassStyle,
      DropzoneBox: ClassStyle,
      DropzoneGrid: ClassStyle,
      Dropzone: ClassStyle,
      DropzoneContent: ClassStyle,
    }),
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    errorMessage: PropTypes.node,
    className: PropTypes.string,
    onChange: PropTypes.func,
    maxSizeInMb: PropTypes.number,
    deletable: PropTypes.bool,
    type: PropTypes.string,
    avatar: PropTypes.string,
    setAvatar: PropTypes.func,
    imageUrl: PropTypes.string,
    t: PropTypes.func,
    file: PropTypes.any,
    required: PropTypes.any,
    validate: PropTypes.func,
    resetValidation: PropTypes.func,
    name: PropTypes.any,
  };

  static defaultProps = {
    type: FileTypes.image,
    accept: ['image/*'],
    maxSizeInMb: 10,
    deletable: false,
  };

  state = {
    file: '',
    errorMessage: '',
  };

  id = uniqueId('file-upload-');

  checkValidity = () => {
    const file = this.state.file || this.props.file;
    if (this.props.required) {
      this.input.current.validity = {
        valueMissing: !file,
      };
      return !!file;
    }
    return true;
  };

  input = {
    current: {
      validity: {},
      checkValidity: this.checkValidity,
    },
  };

  validate = () => {
    return this.props.validate();
  };

  resetValidation = () => {
    this.setState({ errorMessage: '' });
    this.props.resetValidation();
  };

  getMaxSize = () => {
    return this.props.maxSizeInMb * 1024 * 1024;
  };

  getValue = () => {
    return this.state.file;
  };

  onDrop = (acceptedFiles) => {
    const { onChange, name, setAvatar } = this.props;
    const file = acceptedFiles[0];
    if (file) {
      this.setState({ file });

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        file.preview = reader.result;
        this.setState({ file, errorMessage: '' });

        setAvatar && setAvatar(file.preview);

        if (onChange) {
          onChange({ target: { value: file, name } });
        }
      };
    }
  };

  onRejected = (files) => {
    if (files[0].size > this.getMaxSize()) {
      this.setState({
        errorMessage: <Trans i18nKey="errors_file_size" values={{ size: this.props.maxSizeInMb }} />,
      });
    } else {
      this.setState({
        errorMessage: <Trans i18nKey="errors_unsupported_file_type" />,
      });
    }
  };

  deleteFile = () => {
    const { onChange, name, setAvatar } = this.props;
    this.setState({ file: '' });
    setAvatar && setAvatar(null);
    if (onChange) {
      onChange({ target: { value: null, name } });
    }
  };

  render() {
    const { file } = this.state;
    const { accept, className, type, classes, errorMessage, t, deletable, imageUrl, avatar } = this.props;

    return (
      <div
        className={classNames('FileUpload', className, classes.FileUpload, {
          'has-errors': !!errorMessage || !!this.state.errorMessage,
        })}
      >
        <Grid container className={classes.Container}>
          {file || this.props.file ? (
            <Grid item md={12} sm={12} xs={12} className={classes.PositionRelative}>
              <FilePreview file={file || this.props.file} type={type} />
              {deletable && (
                <IconButton className={classes.DeleteIcon} onClick={this.deleteFile}>
                  <CloseIcon />
                </IconButton>
              )}
            </Grid>
          ) : imageUrl && avatar !== null ? (
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              className={classNames(classes.PositionRelative, classes.PreviewGridPlaceholder)}
            >
              <div className={classes.Placeholder} style={{ backgroundImage: `url(${imageUrl})` }} />
              {deletable && (
                <IconButton
                  className={classes.DeleteIcon}
                  onClick={this.deleteFile}
                  tabIndex={0}
                  aria-label={t('alt_delete_profile_picture')}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Grid>
          ) : (
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              className={classNames(classes.PositionRelative, classes.PreviewGridPlaceholder)}
            >
              <div
                className={classNames(classes.Placeholder, 'NoAvatar')}
                style={{ backgroundImage: `url("${noImage}")` }}
              />
            </Grid>
          )}
          <Box className={classes.DropzoneBox}>
            <Grid item md={12} sm={12} xs={12} className={classes.DropzoneGrid}>
              <Dropzone
                id={this.id}
                accept={accept}
                onDrop={this.onDrop}
                onDropRejected={this.onRejected}
                maxSize={this.getMaxSize()}
                multiple={false}
              >
                {({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <div
                      {...getRootProps()}
                      className={classNames('dropzone', classes.Dropzone, {
                        active: isDragActive,
                      })}
                    >
                      <input {...getInputProps()} />
                      <div className={classes.DropzoneContent}>
                        <Button aria-labeledby="profile-img-upload" buttonClassName="Pink" tabIndex={-1}>
                          <span id="profile-img-upload">{t('profile_upload_picture')}</span>
                        </Button>
                      </div>
                    </div>
                  );
                }}
              </Dropzone>
            </Grid>
          </Box>
        </Grid>
        {(errorMessage || this.state.errorMessage) && (
          <FormHelperText role="alert" error>
            {errorMessage ? errorMessage : this.state.errorMessage}
          </FormHelperText>
        )}
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(FileUpload));
