import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Container from '@material-ui/core/Container';

import { useFooterStyles } from './styles';
import { getPath } from 'utils/getPath';
import useScrollListener from 'utils/useScrollListener';
import { FooterLinks, ScrollToTopVisible, SocialLinks } from 'constants/Constants';
import { facebookIcon, instagramIcon, linkedInIcon, mtuLogo } from 'utils/icons';
import { Document } from 'types/types';
import { getUrl } from 'utils/urlFromImagePath';

export const Footer = ({ pages, topics, termsAndConditions, privacyPolicy }) => {
  const classes = useFooterStyles();
  const { t } = useTranslation();
  const [scrollToTopVisible, setScrollToTopVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const handleScrollToTop = () => {
    setScrollToTopVisible(
      (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) > ScrollToTopVisible
    );
  };

  useScrollListener(handleScrollToTop);

  return (
    !loading && (
      <footer className={classes.Footer}>
        <Box className={classNames(classes.ScrollToTop, { Visible: scrollToTopVisible })} onClick={scrollToTop}>
          <ExpandLessIcon role={undefined} />
        </Box>
        <Box className={classes.FooterBox}>
          <Box className={classes.LogoWrapper}>
            <Container className={classes.LogoContainer}>
              <Box mb={5} className={classes.LogoAndSocialBox}>
                <a href={FooterLinks.csodasMagyarorszag} target="_blank" aria-label={t('alt_wow_hungary')}>
                  <Box className={classes.LogoBox} />
                </a>
                <Box className={classes.SocialBox}>
                  <a
                    href={SocialLinks.facebook}
                    className={classes.Logo}
                    target="_blank"
                    aria-label={t('alt_mtu_facebook')}
                  >
                    {facebookIcon()}
                  </a>
                  <a
                    href={SocialLinks.linkedIn}
                    className={classes.Logo}
                    target="_blank"
                    aria-label={t('alt_mtu_linkedin')}
                  >
                    {linkedInIcon()}
                  </a>
                  <a
                    href={SocialLinks.instagram}
                    className={classes.Logo}
                    target="_blank"
                    aria-label={t('alt_wow_hungary_instagram')}
                  >
                    {instagramIcon()}
                  </a>
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>
        <Container className={classes.MainContainer}>
          <Box className={classes.MTULogo}>
            <a href={FooterLinks.mtu} target="_blank" aria-label={t('alt_mtu_web_page')}>
              {mtuLogo()}
            </a>
          </Box>
          <Grid container className={classes.GridContainer}>
            <Grid item md={3} lg={3} sm={3} xs={5} className={classes.Column}>
              <Box className={classNames(classes.FooterTitle)} mb={2}>
                {t('footer_menu')}
              </Box>
              <Box className={classNames(classes.FooterText)} mb={0.5}>
                <Link to={getPath(pages, t('menu_item_home'))}>{t('menu_item_home')}</Link>
              </Box>
              <Box className={classes.FooterText} mb={0.5}>
                <Link to={getPath(pages, t('menu_item_about'))}>{t('menu_item_about')}</Link>
              </Box>
              <Box className={classes.FooterText} mb={0.5}>
                <Link to={getPath(pages, t('menu_item_knowledge_store'))}>{t('menu_item_knowledge_store')}</Link>
              </Box>
            </Grid>
            <Grid item md={6} lg={6} sm={6} xs={7} className={classNames(classes.Column)}>
              <Box className={classes.FooterTitle} mb={2}>
                {t('footer_topics')}
              </Box>
              {topics
                ? topics.map((topic) => (
                    <Box className={classes.FooterText} mb={0.5} key={topic.id}>
                      <Link to={getPath(pages, topic.title)}>{topic.title}</Link>
                    </Box>
                  ))
                : null}
            </Grid>
            <Grid item md={3} lg={3} sm={3} xs={12}>
              <Box className={classes.FooterTitle} mb={2}>
                {t('footer_contact')}
              </Box>
              <Box className={classNames(classes.FooterText, classes.PreFormatted)} mb={0.5}>
                <a href={FooterLinks.addressMapLink} target="_blank" aria-label={t('alt_mtu_address')}>
                  1027 Budapest{'\n'}Kacsa u. 15-23.
                </a>
              </Box>
              <Box className={classes.FooterText} mb={0.5} tabIndex={0}>
                {t('footer_contact_line3')}
              </Box>
              <Box className={classes.FooterText} mb={0.5}>
                <a href="tel:+3614888700" aria-label={t('alt_call_mtu')}>{`+36 1 4888 700`}</a>
              </Box>
              <Box className={classNames(classes.FooterText, classes.PreFormatted)} mb={0.5}>
                <a href="mailto:info@hozzaferheto.turizmusakademia.hu" aria-label={t('alt_mail_to_mtu')}>
                  info@hozzaferheto.{'\n'}turizmusakademia.hu
                </a>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Container className={classes.CopyRightContainer}>
          <Box mt={2} className={classes.CopyRight}>
            <span>{`${t('footer_copyright')}`}</span>
            <Box ml={1} mr={1}>
              |
            </Box>
            <a href={getUrl(termsAndConditions.url)} target="_blank" aria-label={t('footer_terms_of_use')}>
              {`${t('footer_terms_of_use')}`}
            </a>
            <Box ml={1} mr={1}>
              |
            </Box>
            <a href={getUrl(privacyPolicy.url)} target="_blank" aria-label={t('footer_privacy_policy')}>
              {t('footer_privacy_policy')}
            </a>
          </Box>
        </Container>
      </footer>
    )
  );
};

Footer.propTypes = {
  history: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  termsAndConditions: Document,
  privacyPolicy: Document,
};
