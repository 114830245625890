import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';

import Box from '@material-ui/core/Box';

import { Lesson } from 'types/types';
import { useLessonBoxStyles } from './styles';
import Image from 'components/Image/Image';
import LessonTouched from 'assets/images/lesson-touched.png';
import LessonUntouched from 'assets/images/lesson-untouched.png';
import LessonFailedIcon from 'assets/images/lesson-failed-icon.png';
import LessonSucceededIcon from 'assets/images/lesson-succeeded-icon.png';
import { simplifiedTimeIndicator, featherArrowSvg } from 'utils/icons';
import { NotificationTypes } from 'constants/Constants';
import { createEnterHandler } from 'utils/eventUtils';
import { redirectTo } from 'utils/redirectTo';
import { selectIsLoggedIn } from 'store/auth/selectors';
import theme from 'theme';

export const LessonCard = forwardRef(
  ({ history, lesson, showNotification, containerClass, onClick, onFocus, index, isOnLessonPage = false }, ref) => {
    const { t } = useTranslation();
    const classes = useLessonBoxStyles();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const { id, title, minutesToComplete, testResult, testSuccess } = lesson;
    const hasResult = lesson.testResult !== null;

    const redirectToLessonDetails = () => {
      if (isLoggedIn) {
        if (!isOnLessonPage) {
          history.push(window.location.pathname + '/' + t('url_lessons') + '/' + id);
        } else {
          redirectTo(id);
        }
      } else if (showNotification) {
        showNotification({
          content: (
            <Box textAlign="center">
              <Trans
                i18nKey="course_details_login_to_lesson_access"
                components={[
                  <Link
                    id="lesson-notification-link"
                    className={classes.NotificationLink}
                    key="course_details_login_to_lesson_access"
                    aria-label={t('alt_course_details_login_to_lesson_access')}
                    to={`${history.location.pathname}?dialog=${t('url_login')}`}
                  />,
                ]}
              />
            </Box>
          ),
          type: NotificationTypes.warning,
        });
      }
    };

    const onCardClick = () => {
      onClick ? onClick() : redirectToLessonDetails();
    };

    return (
      <Box
        mt={8}
        className={classNames(classes.LessonContainer, containerClass, {
          [classes.LessonContainerTouched]: lesson.lessonStarted,
          TutorialCourse1: index === 0,
        })}
        onClick={onCardClick}
        onFocus={onFocus}
        onKeyPress={createEnterHandler(onCardClick)}
        aria-label={t('alt_lesson_card', { index: index + 1, title })}
        tabIndex={0}
        role="application"
        ref={ref}
      >
        <Box className={classes.LessonBoxIconContainer}>
          <Image
            src={lesson.lessonStarted ? LessonTouched : LessonUntouched}
            className={classes.LessonBoxIcon}
            aria-hidden={true}
          />
        </Box>
        <Box className={classNames(classes.IndicatorContainer, { HasResult: hasResult })}>
          {!hasResult ? null : (
            <Box className={classes.IconizedIndicator}>
              <Image src={testSuccess ? LessonSucceededIcon : LessonFailedIcon} />
              <div>{`${testResult}%`}</div>
            </Box>
          )}
          <Box className={classes.IconizedIndicator}>
            {simplifiedTimeIndicator(minutesToComplete, t, theme.palette.blackTitle)}
          </Box>
        </Box>
        <Box mt={2} className={classes.LessonDescription}>
          <Truncate lines={3}>{title}</Truncate>
        </Box>
        <Box
          className={classNames(classes.LessonCardArrow, {
            [classes.LessonCardArrowTouched]: lesson.lessonStarted,
          })}
        >
          {featherArrowSvg()}
        </Box>
      </Box>
    );
  }
);

LessonCard.propTypes = {
  history: PropTypes.object,
  lesson: Lesson.isRequired,
  showNotification: PropTypes.func,
  containerClass: PropTypes.string,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  index: PropTypes.number,
  isOnLessonPage: PropTypes.bool,
};
