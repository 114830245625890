import queryString from 'query-string';

import request from 'utils/request';
import { Methods } from 'utils/http';

const BASE_URL = '/courses';

export const getCourses = async ({
  language,
  page = 1,
  searchText = null,
  tags = null,
  examDone = null,
  minutesMin = null,
  minutesMax = null,
}) => {
  const params = {
    page,
    sortBy: 'title',
    order: 'asc',
    searchText,
    examDone,
    minutesMin,
    minutesMax,
  };
  for (let p in params) {
    if (!params[p] && params[p] !== false) {
      delete params[p];
    }
  }
  let query = queryString.stringify(params);
  if (tags) {
    for (let tag of tags) {
      query += `&tags[]=${tag}`;
    }
  }

  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/search?${query}`,
    language,
  });
};

export const getCoursesForTopic = async ({
  language,
  page = 1,
  searchText = null,
  moduleId,
  tags = null,
  examDone = null,
  minutesMin = null,
  minutesMax = null,
}) => {
  const params = {
    page,
    searchText,
    moduleId,
    examDone,
    minutesMin,
    minutesMax,
  };
  for (let p in params) {
    if (!params[p] && params[p] !== false) {
      delete params[p];
    }
  }
  let query = queryString.stringify(params);
  if (tags) {
    for (let tag of tags) {
      query += `&tags[]=${tag}`;
    }
  }

  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}?${query}`,
    language,
  });
};

export const getCourseById = async (id, language) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/${id}`,
    language,
  });
};

export const getCoursesWithExams = async (page, language, token) => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/user-exams?page=${page}`,
    language,
  };
  if (token) {
    requestData.headers = { Authorization: token };
  }
  return await request(requestData);
};

export const getOngoingCourses = async (page, language, token) => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/user-courses?page=${page}`,
    language,
  };
  if (token) {
    requestData.headers = { Authorization: token };
  }
  return await request(requestData);
};

export const getBookmarkedCourses = async (page, language, token) => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/bookmarked?page=${page}`,
    language,
  };
  if (token) {
    requestData.headers = { Authorization: token };
  }
  return await request(requestData);
};

export const bookmarkCourse = async (id, isBookmarked) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}/${id}/bookmark`,
    data: {
      bookmarkCourse: isBookmarked,
    },
  });
};
