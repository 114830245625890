import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import hoistStatics from 'hoist-non-react-statics';
import isEqual from 'lodash/isEqual';

import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import withStyles from '@material-ui/core/styles/withStyles';

import { getProfileTags, getTags } from 'store/tags/actions';
import { getCourses, setFilters } from 'store/courses/actions';
import { getLessons } from 'store/lessons/actions';
import { getProfileDetails } from 'store/profile/actions';
import { CourseCardList, NavigationDialog } from 'components';
import { searchPageStyles } from './styles';
import { ClassStyle, Course, FiltersType, LessonWithCourses, Tag } from 'types/types';
import { LessonCard } from 'components/LessonCard/LessonCard';
import { CardContainer } from 'components/CardContainer/CardContainer';
import { ItemTypes, NotificationTypes } from 'constants/Constants';
import { Filters } from 'components/Filter/Filters';
import { MobileFilter } from 'components/Filter/MobileFilter';
import { getNumberOfActiveFilters } from 'utils/filters';
import { getCurrentLanguage } from 'utils/language';
import { titleFocus } from 'utils/titleFocus';
import { isSpaceOrEnterEvent } from 'utils/eventUtils';
import { getTopicsCoursesUrl } from 'utils/getTopicsCoursesUrl';

class SearchPage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      SearchPageContainer: ClassStyle,
      SearchControlContainer: ClassStyle,
      TabContainer: ClassStyle,
      Tab: ClassStyle,
      FilterContainer: ClassStyle,
      FiltersContainer: ClassStyle,
      FilterBoxContainer: ClassStyle,
      ResultContainer: ClassStyle,
      ResultBox: ClassStyle,
      LessonContainer: ClassStyle,
      NotificationLink: ClassStyle,
    }),
    t: PropTypes.func,
    courses: PropTypes.arrayOf(Course),
    lessons: PropTypes.arrayOf(LessonWithCourses),
    numberOfCourses: PropTypes.number,
    numberOfLessons: PropTypes.number,
    filters: FiltersType,
    setFilters: PropTypes.func.isRequired,
    getCourses: PropTypes.func.isRequired,
    getLessons: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    showNotification: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(Tag),
    loadMoreCourses: PropTypes.func,
    loadMoreLessons: PropTypes.func,
    profileTags: PropTypes.arrayOf(Tag),
    history: PropTypes.object,
    addCommonLoader: PropTypes.func,
    removeCommonLoader: PropTypes.func,
    resetCourses: PropTypes.func,
    isLoading: PropTypes.bool,
    shouldShowLoading: PropTypes.bool,
  };

  state = {
    activeTab: ItemTypes.Course,
    openDialog: false,
    lesson: null,
    coursePage: 1,
    lessonPage: 1,
    lessonIndex: 0,
    inProp: true,
  };

  static pageTitleKey = 'page_title_search';

  static async getInitialData({ dispatch, language, token, search }) {
    const params = queryString.parse(search);
    const searchString = language.toUpperCase() === 'HU' ? params['keresoszo'] : params['searchterm'];
    await dispatch(setFilters({ searchString }));

    try {
      await Promise.all([
        dispatch(getTags(language)),
        dispatch(getCourses({ searchText: searchString }, language)),
        dispatch(getLessons({ searchText: searchString }, language)),
        token && dispatch(getProfileDetails({ language, token })),
        token && dispatch(getProfileTags({ language, token })),
      ]);

      titleFocus(dispatch);
    } catch (err) {
      console.error(err);
    }
  }

  lessonRefs = this.props.lessons ? this.props.lessons.map(() => createRef()) : null;

  componentDidMount() {
    const { setFilters } = this.props;
    setFilters({
      tags: [],
      examDone: null,
      duration: null,
    });
  }

  async componentDidUpdate(prevProps) {
    const { filters, getCourses, getLessons, history, t, setFilters, isLoggedIn } = this.props;
    const params = queryString.parse(history.location.search);
    const searchString = params[t('url_search_term')];
    if (searchString && searchString !== filters.searchString) {
      await setFilters({ searchString });
    }
    if (!isEqual(filters, prevProps.filters) || prevProps.isLoggedIn !== isLoggedIn) {
      this.setState({ coursePage: 1, lessonPage: 1 });

      await Promise.all([
        getCourses({
          searchText: filters.searchString && filters.searchString.length ? filters.searchString : null,
          tags: filters.tags.length ? filters.tags : null,
          minutesMin: (filters.duration && filters.duration.minutesMin) || null,
          minutesMax: (filters.duration && filters.duration.minutesMax) || null,
          examDone: filters.examDone === true || filters.examDone === false ? filters.examDone : null,
          page: 1,
        }),
        getLessons({
          searchText: filters.searchString && filters.searchString.length ? filters.searchString : null,
          tags: filters.tags.length ? filters.tags : null,
          minutesMin: (filters.duration && filters.duration.minutesMin) || null,
          minutesMax: (filters.duration && filters.duration.minutesMax) || null,
          page: 1,
        }),
      ]);
    }
  }

  componentWillUnmount() {
    this.props.resetCourses();
  }

  loadMoreCourses = async (page) => {
    const { filters, loadMoreCourses } = this.props;
    this.setState({ coursePage: page });
    await loadMoreCourses({
      searchText: filters.searchString && filters.searchString.length ? filters.searchString : null,
      tags: filters.tags.length ? filters.tags : null,
      minutesMin: (filters.duration && filters.duration.minutesMin) || null,
      minutesMax: (filters.duration && filters.duration.minutesMax) || null,
      examDone: filters.examDone === true || filters.examDone === false ? filters.examDone : null,
      page,
    });
  };

  loadMoreLessons = async (page) => {
    const { filters, loadMoreLessons } = this.props;
    this.setState({ lessonPage: page });
    await loadMoreLessons({
      searchText: filters.searchString && filters.searchString.length ? filters.searchString : null,
      tags: filters.tags.length ? filters.tags : null,
      minutesMin: (filters.duration && filters.duration.minutesMin) || null,
      minutesMax: (filters.duration && filters.duration.minutesMax) || null,
      page,
    });
  };

  setActiveTab = (activeTab) => {
    this.setState({ inProp: false });
    setTimeout(() => {
      this.setState({ activeTab, inProp: true });
    }, 200);
  };

  onTabKeyPress(event, activeTab) {
    if (isSpaceOrEnterEvent(event)) {
      event.preventDefault();
      this.setActiveTab(activeTab);
    }
  }

  setCloseExamFilter = (closeExamFilter) => {
    this.closeExamFilter = closeExamFilter;
  };

  openLessonDialog = (lesson) => {
    this.setState({ openDialog: true, lesson });
  };

  closeDialog = () => {
    const { lessonIndex } = this.state;
    this.setState({ openDialog: false, lesson: null });
    if (!!this.lessonRefs && !!this.lessonRefs[lessonIndex]) {
      this.lessonRefs[lessonIndex].current.focus();
    }
  };

  onLessonCardClick = (lesson, index) => {
    const { isLoggedIn, history, showNotification, classes, t } = this.props;
    if (isLoggedIn) {
      if (lesson.courses.length > 1) {
        this.setState({ lessonIndex: index });
        this.openLessonDialog(lesson);
      } else {
        history.push(
          getTopicsCoursesUrl(
            getCurrentLanguage(window.location.pathname),
            lesson.courses[0].modules[0].id,
            lesson.courses[0].id,
            lesson.id
          )
        );
      }
    } else {
      showNotification({
        content: (
          <Box textAlign="center">
            <Trans
              i18nKey="course_details_login_to_lesson_access"
              components={[
                <Link
                  id="lesson-notification-link"
                  className={classes.NotificationLink}
                  key="course_details_login_to_lesson_access"
                  aria-label={t('alt_course_details_login_to_lesson_access')}
                  to={`${history.location.pathname}${
                    history.location.search ? history.location.search + '&' : '?'
                  }dialog=${t('url_login')}`}
                />,
              ]}
            />
          </Box>
        ),
        type: NotificationTypes.warning,
      });
    }
  };

  render() {
    const {
      classes,
      t,
      history,
      courses,
      numberOfCourses,
      lessons,
      numberOfLessons,
      isLoggedIn,
      showNotification,
      filters,
      setFilters,
      tags,
      profileTags,
      isLoading,
      shouldShowLoading,
    } = this.props;
    const { activeTab, lesson, openDialog, coursePage, lessonPage, inProp } = this.state;

    return (
      <div className={classes.SearchPageContainer}>
        <Box ml={1.5} mb={2} className={classes.SearchControlContainer}>
          <Box role="tablist" mt={2} className={classes.TabContainer}>
            <Box
              mr={3}
              className={classNames(classes.Tab, { Active: activeTab === ItemTypes.Course })}
              onClick={() => this.setActiveTab(ItemTypes.Course)}
              role="tab"
              tabIndex={0}
              aria-selected={activeTab === ItemTypes.Course}
              onKeyPress={(event) => this.onTabKeyPress(event, ItemTypes.Course)}
            >
              {t('common_course')}
            </Box>
            <Box
              mr={3}
              className={classNames(classes.Tab, { Active: activeTab === ItemTypes.Lesson })}
              onClick={() => {
                this.closeExamFilter && this.closeExamFilter();
                this.setActiveTab(ItemTypes.Lesson);
              }}
              role="tab"
              tabIndex={0}
              aria-selected={activeTab === ItemTypes.Lesson}
              onKeyPress={(event) => this.onTabKeyPress(event, ItemTypes.Lesson)}
            >
              {t('course_details_lesson')}
            </Box>
          </Box>
          {activeTab === ItemTypes.Course ? (
            <Box className={classes.FilterContainer}>
              <Filters
                tags={tags}
                filters={filters}
                setFilters={setFilters}
                filterClass={classes.FiltersContainer}
                filterBoxClass={classes.FilterBoxContainer}
                profileTags={profileTags}
                itemType={ItemTypes.Course}
                setCloseExamFilter={this.setCloseExamFilter}
              />
              <MobileFilter
                tags={tags}
                filters={filters}
                setFilters={setFilters}
                numberOfItems={numberOfCourses}
                alignVertical
                profileTags={profileTags}
                itemType={ItemTypes.Course}
              />
            </Box>
          ) : (
            <Box className={classes.FilterContainer}>
              <Filters
                tags={tags}
                filters={filters}
                setFilters={setFilters}
                filterClass={classes.FiltersContainer}
                filterBoxClass={classes.FilterBoxContainer}
                profileTags={profileTags}
                itemType={ItemTypes.Lesson}
              />
              <MobileFilter
                tags={tags}
                filters={filters}
                setFilters={setFilters}
                numberOfItems={numberOfLessons}
                alignVertical
                profileTags={profileTags}
                itemType={ItemTypes.Lesson}
              />
            </Box>
          )}
        </Box>
        <Fade in={inProp} timeout={100}>
          <Box aria-live="polite" mt={2} mb={5}>
            {activeTab === ItemTypes.Course ? (
              <CardContainer
                items={courses}
                numberOfItems={numberOfCourses}
                loadMoreItems={this.loadMoreCourses}
                itemType={ItemTypes.Course}
                resultClass={classNames(classes.ResultContainer, 'Course')}
                numberOfActiveFilters={getNumberOfActiveFilters(filters)}
                isLoading={isLoading}
                skeleton={shouldShowLoading}
                page={coursePage}
              >
                <CourseCardList courses={courses} skeleton={shouldShowLoading} itemContainerClass={classes.ResultBox} />
              </CardContainer>
            ) : null}
            {activeTab === ItemTypes.Lesson && lessons ? (
              <CardContainer
                items={lessons}
                numberOfItems={numberOfLessons}
                loadMoreItems={this.loadMoreLessons}
                itemType={ItemTypes.Lesson}
                resultClass={classNames(classes.ResultContainer, 'Lesson')}
                numberOfActiveFilters={getNumberOfActiveFilters(filters)}
                isLoading={isLoading}
                skeleton={shouldShowLoading}
                page={lessonPage}
              >
                {lessons.map((lesson, index) => (
                  <Box mb={6} ml={1.5} mr={1.5} key={lesson.id} className={classes.ResultBox}>
                    <LessonCard
                      ref={this.lessonRefs && this.lessonRefs[index] ? this.lessonRefs[index] : undefined}
                      history={history}
                      lesson={lesson}
                      showNotification={showNotification}
                      containerClass={classes.LessonContainer}
                      onClick={() => this.onLessonCardClick(lesson, index)}
                      index={lessons.indexOf(lesson)}
                    />
                  </Box>
                ))}
              </CardContainer>
            ) : null}
          </Box>
        </Fade>

        {isLoggedIn && !!lesson && (
          <NavigationDialog open={openDialog} closeDialog={this.closeDialog} lesson={lesson} history={history} />
        )}
      </div>
    );
  }
}

export default hoistStatics(withTranslation()(withStyles(searchPageStyles)(SearchPage)), SearchPage);
