import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  absolute,
  auto,
  bold,
  breakWord,
  center,
  columnReverse,
  ellipsis,
  flex,
  flexEnd,
  grid,
  hidden,
  none,
  normal,
  nowrap,
  pointer,
  relative,
  spaceBetween,
  transparent,
  underline,
  uppercase,
  vertical,
  visible,
} from 'constants/Jss';
import { TagFilterSize } from 'constants/Constants';

export const useFiltersStyles = makeStyles((theme) => ({
  Filters: {
    display: flex,
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  Search: {
    border: `1px solid ${theme.palette.grey70}`,
    display: flex,
    flexGrow: 1,
    alignItems: center,
    backgroundColor: theme.palette.white,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    '&.TooShort:focus-within': {
      borderColor: theme.palette.errorColor,
    },
  },
  Form: {
    height: theme.mixins.header.contentSize,
    position: relative,
    display: flex,
    flexGrow: 1,
  },
  SearchIconContainer: {
    cursor: pointer,
    width: theme.mixins.header.contentSize,
    height: theme.mixins.header.contentSize,
    '&:hover': {
      backgroundColor: theme.palette.whiteHover,
    },
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '& svg': {
      fill: theme.palette.grey70,
    },
  },
  SearchField: {
    fontSize: 16,
    color: theme.palette.grey70,
    flexGrow: 1,
  },
  InputField: {
    paddingRight: theme.spacing(5),
    minWidth: 235,
    '&::-webkit-search-cancel-button': {
      display: none,
    },
  },
  CloseSearch: {
    position: absolute,
    top: 12,
    right: 14,
    cursor: pointer,
    padding: 0,
    color: transparent,
    '&:hover': {
      backgroundColor: transparent,
    },
    '&:focus': {
      backgroundColor: transparent,
    },
    '& svg': {
      width: 20,
      height: 20,
      fill: theme.palette.grey70,
    },
  },
  FilterBoxContainer: {
    border: `1px solid ${theme.palette.grey70}`,
    borderLeftWidth: 0,
    display: flex,
    alignItems: center,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.white,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  TagIconA: {
    fill: none,
  },
  TagIconB: {
    fill: none,
    stroke: theme.palette.white,
  },
  TagIconC: {
    stroke: none,
  },
  ArrowIcon: {
    color: theme.palette.white,
  },
}));

export const useFilterStyles = makeStyles((theme) => ({
  FilterButton: {
    display: flex,
    alignItems: center,
    height: 34,
    padding: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.white,
    textTransform: uppercase,
    fontSize: 16,
    backgroundColor: theme.palette.lightPink,
    '&:hover': {
      backgroundColor: theme.palette.lightPinkHover,
    },
  },
  FilterIcon: {
    display: flex,
    alignItems: center,
    cursor: pointer,
    '& svg': {
      width: 20,
      height: 18,
    },
  },
  FilterDialogContainer: {
    position: relative,
    '& ul': {
      paddingTop: theme.spacing(4),
      borderRadius: 4,
      color: theme.palette.white,
      backgroundColor: theme.palette.lightPink,
      marginTop: theme.spacing(1.25),
      position: relative,
      maxWidth: TagFilterSize.Narrow,
      outline: none,
    },
    '&.Wide ul': {
      maxWidth: TagFilterSize.Wide,
    },
    '& ul.Top': {
      marginTop: 0,
      marginBottom: theme.spacing(1.25),
    },
    '&.Small': {
      '& ul': {
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
      },
    },
    '&:not(.Numbered) > div:nth-child(2)': {
      zIndex: 10,
    },
    '&.Numbered > div:nth-child(3)': {
      zIndex: 10,
    },
  },
  ActiveFiltersBox: {
    position: absolute,
    top: theme.spacing(-1.5),
    right: theme.spacing(-1),
    backgroundColor: theme.palette.activeFiltersBackground,
    borderRadius: 13,
    display: flex,
    alignItems: center,
    justifyContent: center,
    zIndex: 1,
    minWidth: 20,
    '&.Mobile': {
      minWidth: 18,
    },
  },
  ActiveFilters: {
    color: theme.palette.white,
    fontSize: 14,
    fontWeight: bold,
    lineHeight: 1,
    padding: '4px 6px 2px',
    '&.Mobile': {
      fontSize: 12,
    },
  },
  FilterContent: {
    position: absolute,
    bottom: 0,
  },
  CloseButtonContainer: {
    display: flex,
    alignItems: center,
    justifyContent: center,
    width: '100%',
  },
  CloseButton: {
    color: theme.palette.lightPink,
    backgroundColor: theme.palette.white,
    fontSize: 16,
    '&:hover': {
      backgroundColor: theme.palette.greyF2,
    },
  },
  CloseIconContainer: {
    position: absolute,
    top: 0,
    right: 0,
  },
  CloseIconButton: {
    padding: 0,
    color: transparent,
    backgroundColor: transparent,
    '&:hover': {
      color: transparent,
      backgroundColor: transparent,
    },
    '&:focus': {
      color: transparent,
      backgroundColor: transparent,
    },
  },
  CloseIcon: {
    color: theme.palette.white,
    width: 20,
    height: 20,
  },
  Triangle: {
    position: absolute,
    left: 'calc(50% - 3px)',
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderBottom: `10px solid ${theme.palette.lightPink}`,
    '&:not(.Top)': {
      top: -10,
    },
    '&.Top': {
      bottom: -10,
      transform: 'rotate(180deg)',
    },
  },
}));

export const useMobileFiltersStyles = makeStyles((theme) => ({
  MobileFilters: {
    [theme.breakpoints.up('md')]: {
      display: none,
    },
  },
  MobileSearch: {
    border: `1px solid ${theme.palette.grey70}`,
    borderRadius: 4,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.white,
  },
  MobileForm: {
    width: '100%',
    height: theme.mixins.header.contentSize,
    position: relative,
    display: flex,
    flexGrow: 1,
  },
  MobileSearchIconContainer: {
    cursor: pointer,
    width: theme.mixins.header.contentSize,
    height: theme.mixins.header.contentSize,
    '&:hover': {
      backgroundColor: theme.palette.whiteHover,
    },
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '& svg': {
      fill: theme.palette.grey70,
    },
  },
  MobileSearchField: {
    display: flex,
    flexGrow: 1,
    alignItems: center,
    '& > input::-webkit-search-cancel-button': {
      display: none,
    },
  },
  MobileCloseSearch: {
    top: 12,
    color: theme.palette.grey70,
    right: 14,
    width: 20,
    cursor: pointer,
    height: 20,
    position: absolute,
  },
  FilterContainer: {
    display: flex,
    alignItems: center,
    justifyContent: flexEnd,
    [theme.breakpoints.down('sm')]: {
      justifyContent: spaceBetween,
      '&.Vertical': {
        flexDirection: columnReverse,
      },
    },
  },
  MobileNumberOfCourses: {
    color: theme.palette.grey70,
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      display: none,
    },
    '&.Vertical': {
      marginTop: theme.spacing(2),
    },
  },
  MobileFilterIcon: {
    cursor: pointer,
    position: relative,
  },
  FilterStrip: {
    height: 3,
    borderRadius: 2,
    backgroundColor: theme.palette.cyan,
  },
  TopStrip: {
    width: 21,
  },
  MiddleStrip: {
    width: 15,
  },
  BottomStrip: {
    width: 5,
  },
}));

export const useCheckboxFilterStyles = makeStyles(() => ({
  CheckboxFilterContainer: {
    '& .MuiFormControlLabel-label': {
      fontSize: 16,
      whiteSpace: nowrap,
    },
  },
}));

export const useTagFilterStyles = makeStyles((theme) => ({
  TagFilterContainer: {
    display: grid,
    maxHeight: 300,
    overflowY: auto,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    'grid-template-columns': 'repeat(auto-fill, minmax(183px, 1fr))',
    '& .MuiFormControlLabel-label': {
      maxWidth: 160,
      fontSize: 16,
      wordBreak: breakWord,
      overflow: hidden,
      textOverflow: ellipsis,
      display: '-webkit-box',
      '-webkit-box-orient': vertical,
      '-webkit-line-clamp': 2,
    },
  },
  TagFilterWrapper: {
    position: relative,
    overflowY: visible,
  },
  QuickFilterContainer: {
    position: absolute,
    top: theme.spacing(-5),
    left: theme.spacing(2),
    display: flex,
    '& > div': {
      display: flex,
      alignItems: center,
    },
  },
  ClearBox: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(3),
  },
  InterestBox: {
    borderLeft: `1px solid ${theme.palette.white}`,
    paddingLeft: theme.spacing(3),
  },
  ActionText: {
    cursor: pointer,
    color: theme.palette.white,
    textDecoration: underline,
    fontSize: 16,
    lineHeight: 1,
  },
}));

export const useMobileFilterDialogStyles = makeStyles((theme) => ({
  Title: {
    fontSize: 24,
    fontWeight: normal,
    color: theme.palette.grey70,
    padding: 0,
    lineHeight: `${theme.spacing(5)}px`,
  },
  TitleBox: {
    margin: 0,
  },
  Content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  Filter: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey70}`,
      paddingBottom: theme.spacing(2),
    },
    '&:not(:first-child)': {
      paddingTop: theme.spacing(2),
    },
  },
  Header: {
    display: flex,
    alignItems: center,
    justifyContent: spaceBetween,
    cursor: pointer,
    '&.Open': {
      marginBottom: theme.spacing(2),
    },
  },
  Body: {
    maxHeight: 0,
    overflow: hidden,
    transition: 'max-height ease-in-out 0.3s',
    '&.Open': {
      maxHeight: 500,
      overflow: auto,
      paddingLeft: theme.spacing(1.5),
    },
  },
  HeaderTitle: {
    color: theme.palette.grey70,
  },
  ArrowIcon: {
    color: theme.palette.grey70,
    '&.Open': {
      transform: 'rotate(180deg)',
    },
  },
  Checkbox: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    '& svg': {
      fill: theme.palette.grey70,
    },
    '&.Checked svg': {
      fill: theme.palette.lightPink,
    },
  },
  FilterButtonContainer: {
    display: flex,
    alignItems: center,
    justifyContent: center,
  },
  MobileInterestContainer: {
    display: flex,
    alignItems: center,
    justifyContent: center,
  },
  MobileTagsContainer: {
    maxHeight: 300,
    overflowY: auto,
    display: grid,
    'grid-template-columns': 'repeat(auto-fill, minmax(183px, 1fr))',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& .MuiFormControlLabel-label': {
      maxWidth: 160,
      fontSize: 14,
      wordBreak: breakWord,
      overflow: hidden,
      textOverflow: ellipsis,
      display: '-webkit-box',
      '-webkit-box-orient': vertical,
      '-webkit-line-clamp': 2,
    },
  },
  MobileJobsTagContainer: {
    display: grid,
    'grid-template-columns': 'repeat(auto-fill, minmax(183px, 1fr))',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& .MuiFormControlLabel-label': {
      maxWidth: 160,
      fontSize: 14,
      wordBreak: breakWord,
      overflow: hidden,
      textOverflow: ellipsis,
      display: '-webkit-box',
      '-webkit-box-orient': vertical,
      '-webkit-line-clamp': 2,
    },
  },
  MobileRadioFilter: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
    },
  },
  MobileFilterLabel: {
    paddingRight: theme.spacing(1.5),
  },
  ClearBox: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(3),
  },
  InterestBox: {
    borderLeft: `1px solid ${theme.palette.grey64}`,
    paddingLeft: theme.spacing(3),
    display: flex,
    alignItems: center,
  },
  ActionText: {
    cursor: pointer,
    color: theme.palette.grey64,
    textDecoration: underline,
    fontSize: 14,
    lineHeight: 1,
  },
}));

export const useSortingDialogStyles = makeStyles((theme) => ({
  SortingContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    cursor: pointer,
    '&:not(.Mobile)': {
      textTransform: uppercase,
      '&:hover': {
        backgroundColor: theme.palette.whiteHover,
      },
    },
    '& .SortingBox': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    '&:not(:last-child) .SortingBox': {
      borderBottom: `2px solid ${theme.palette.whiteHover}`,
      '&.Mobile': {
        borderWidth: 1,
        borderColor: theme.palette.grey70,
      },
    },
  },
  SortingButton: {
    color: theme.palette.grey70,
    '&:hover': {
      color: theme.palette.black,
    },
  },
}));
