import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { Helmet } from 'react-helmet';

import { Certificate, History } from 'types/types';
import { getPublicCertificate } from 'store/certificates/actions';
import { getCurrentLanguage } from 'utils/language';
import { getUrl } from 'utils/urlFromImagePath';
import { titleFocus } from 'utils/titleFocus';
import certificateImage from 'assets/images/about-left-patch.png';

class CertificatePage extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    addCommonLoader: PropTypes.func,
    certificate: Certificate,
    getCertificate: PropTypes.func,
    history: History,
  };

  constructor(props) {
    super(props);

    this.props.addCommonLoader();
  }

  static async getInitialData({ dispatch, req }) {
    const reqParams = req.url.split('/');
    const uuid = reqParams[reqParams.length - 1];
    try {
      await dispatch(getPublicCertificate({ uuid }));
      titleFocus(dispatch);
    } catch (err) {
      console.error(err);
    }
  }

  componentDidMount() {
    this.props.history.push('/' + getCurrentLanguage(this.props.history.location.pathname));
  }

  render() {
    const { certificate, t } = this.props;

    return certificate ? (
      <div>
        <Helmet>
          <meta property="og:locale" content="hu_HU" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={t('certificate_title', { topic: certificate.moduleTitle })} />
          <meta
            property="og:image"
            content={certificate.certificate ? getUrl(certificate.certificate) : certificateImage}
          />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:site_name" content="MTÜ" />
          <meta property="og:description" content="" />
        </Helmet>
      </div>
    ) : null;
  }
}

export default hoistStatics(withTranslation()(CertificatePage), CertificatePage);
