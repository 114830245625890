import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Radio from '@material-ui/core/Radio';

import { useRadioButtonStyles } from './styles';

export const RadioButton = ({ color, coloredBorder, ...props }) => {
  const { Border, Button } = useRadioButtonStyles();
  const borderClass = classNames(Border, { coloredBorder: !!coloredBorder }, color);
  return (
    <Radio
      icon={<div className={borderClass}></div>}
      checkedIcon={
        <div className={borderClass}>
          <div className={classNames(Button, color)}></div>
        </div>
      }
      {...props}
    />
  );
};

RadioButton.propTypes = {
  color: PropTypes.oneOf(['blue', 'green', 'red']).isRequired,
  coloredBorder: PropTypes.bool,
};
