import { GET_EXAM, GET_EXAM_SUCCESS, GET_EXAM_FAILED } from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  exam: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_EXAM:
      return {
        ...state,
        loading: true,
      };
    case GET_EXAM_SUCCESS:
      return {
        ...state,
        loading: false,
        exam: action.payload,
      };
    case GET_EXAM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
