import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import { absolute, preLine, transparent } from 'constants/Jss';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import { useTranslation } from 'react-i18next';

const useTooltipStyles = makeStyles((theme) => ({
  Arrow: {
    color: theme.palette.headerLightBlue,
  },
  WarningArrow: {
    color: theme.palette.lightPink,
  },
  Tooltip: {
    backgroundColor: theme.palette.headerLightBlue,
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(2),
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 200,
    },
  },
  WarningTooltip: {
    backgroundColor: theme.palette.lightPink,
  },
  PreLineTooltip: {
    whiteSpace: preLine,
  },
}));

const useStyles = makeStyles((theme) => ({
  IconButton: {
    position: absolute,
    top: 14,
    right: -theme.spacing(6),
    padding: 0,
    color: transparent,
    '& svg': {
      fill: theme.palette.headerLightBlue,
    },
    '&:hover': {
      backgroundColor: transparent,
    },
  },
  WarningIconButton: {
    '& svg': {
      fill: theme.palette.lightPink,
    },
  },
  Icon: {
    width: 32,
    height: 32,
  },
}));

const MyTooltip = ({ open, onClick, onClose, text, top, buttonClassName, warning, preLine }) => {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const { t } = useTranslation();

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Tooltip
        open={open}
        title={text}
        arrow
        classes={{
          arrow: warning ? tooltipClasses.WarningArrow : tooltipClasses.Arrow,
          tooltip: classNames(tooltipClasses.Tooltip, {
            [tooltipClasses.WarningTooltip]: warning,
            [tooltipClasses.PreLineTooltip]: preLine,
          }),
        }}
        placement="left-start"
      >
        <IconButton
          style={{ top }}
          className={classNames(
            classes.IconButton,
            {
              [classes.WarningIconButton]: warning,
            },
            buttonClassName
          )}
          onClick={onClick}
          tabIndex={0}
          aria-label={t('alt_more_info')}
          alt={t('alt_more_info')}
        >
          {warning ? (
            <ErrorIcon role={undefined} className={classes.Icon} />
          ) : (
            <InfoIcon role={undefined} className={classes.Icon} />
          )}
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

MyTooltip.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  text: PropTypes.string,
  top: PropTypes.number,
  buttonClassName: PropTypes.string,
  warning: PropTypes.bool,
  preLine: PropTypes.bool,
};

export default MyTooltip;
