import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import { Image } from 'components';
import Settings from 'env';
import { useQuestionImageStyles } from './styles';

export const QuestionImage = ({ questions, questionIndex }) => {
  const classes = useQuestionImageStyles();
  const image = questions[questionIndex] ? questions[questionIndex].image : null;
  const imageAlt = questions[questionIndex] ? questions[questionIndex].imageAlt : null;

  return (
    !!image && (
      <Box mb={6} className={classes.ImageContainer}>
        <Image
          tabIndex={imageAlt ? 0 : -1}
          alt={imageAlt}
          className={classes.Image}
          src={Settings.STORAGE_BASE_URL + image}
        />
      </Box>
    )
  );
};

QuestionImage.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionIndex: PropTypes.number.isRequired,
};
