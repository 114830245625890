import React, { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { useSearchFieldStyles } from '../../styles';
import { IconButtonBase } from './IconButtonBase';
import { searchIcon } from 'utils/icons';

export const SearchButton = memo(
  forwardRef(({ iconClass, ...props }, ref) => {
    const { Button, SearchIcon } = useSearchFieldStyles();
    return (
      <IconButtonBase
        ref={ref}
        iconFactory={searchIcon}
        buttonClass={Button}
        iconClass={iconClass || SearchIcon}
        {...props}
      />
    );
  })
);

SearchButton.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  iconClass: PropTypes.string,
};
