import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { testCorrect, testIncorrect } from 'utils/icons';
import { useFormStyles } from './styles';
import { Answer } from 'types/types';

export const CheckboxAnswer = ({ answer, answered, checked, questionElementId, toggleAnswer }) => {
  const classes = useFormStyles();

  const ref = useRef();

  const onAnswerChange = () => {
    if (!answered) {
      toggleAnswer();
    }
  };

  const onAnswerKeyUp = ({ key }) => {
    if (key === ' ' || key === 'Enter') {
      onAnswerChange();
    }
  };

  const onInsideAnswerClick = (event) => {
    event.preventDefault();
    ref.current.focus();
  };

  const labelId = `answer${answer.id}`;

  const Correct = answered && answer.isGood;
  const Wrong = answered && checked && !answer.isGood;

  return (
    <Box
      ref={ref}
      mb={3}
      className={classNames(classes.AnswerBox, {
        Checked: checked,
        Correct: answered && answer.isGood,
        Wrong: answered && checked && !answer.isGood,
      })}
      tabIndex={0}
      aria-describedby={
        answered && answer.isGood ? 'correct' : answered && checked && !answer.isGood ? 'incorrect' : undefined
      }
      role="checkbox"
      aria-checked={checked}
      aria-labelledby={`${labelId} ${questionElementId}`}
      onClick={onAnswerChange}
      onKeyUp={onAnswerKeyUp}
    >
      <FormControlLabel
        className={classes.Answer}
        label={<div id={labelId}>{answer.answer}</div>}
        control={
          <Checkbox
            className={classes.Checkbox}
            disabled={answered}
            checked={checked}
            tabIndex={-1}
            aria-hidden={true}
            onClick={onInsideAnswerClick}
          />
        }
        tabIndex={-1}
        aria-hidden={true}
        onClick={onInsideAnswerClick}
      />
      {!!answered && (
        <Box className={classes.AnsweredIconContainer} right={10}>
          {!!Correct && testCorrect()}
          {!!Wrong && testIncorrect()}
        </Box>
      )}
    </Box>
  );
};

CheckboxAnswer.propTypes = {
  answer: Answer.isRequired,
  answered: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  questionElementId: PropTypes.string.isRequired,
  toggleAnswer: PropTypes.func.isRequired,
};
