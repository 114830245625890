import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getUrl } from 'utils/urlFromImagePath';

export const LINK_TYPES = {
  REGISTRATION_LINKS: 'REGISTER_LINKS',
  NEW_TERMS_LINKS: 'NEW_TERMS_LINKS',
};

export const useAcceptLinks = (termsAndConditions, privacyPolicy, type) => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        text:
          type === LINK_TYPES.REGISTRATION_LINKS
            ? t('form_accept_terms_part1_register')
            : t('form_accept_terms_part1_new_terms'),
      },
      { text: t('form_accept_terms_part2'), link: getUrl(termsAndConditions.url) },
      { text: t('form_accept_terms_part3') },
      { text: t('form_accept_terms_part4'), link: getUrl(privacyPolicy.url) },
      { text: '.' },
    ],
    [t, termsAndConditions.url, privacyPolicy.url, type]
  );
};
