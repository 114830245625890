import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import Box from '@material-ui/core/Box';

import { examsPageStyles } from './styles';
import { ClassStyle, Course } from 'types/types';
import { getCoursesWithExams } from 'store/courses/actions';
import { getProfileDetails } from 'store/profile/actions';
import { CardContainer } from 'components/CardContainer/CardContainer';
import { CourseCardList } from 'components';
import { titleFocus } from 'utils/titleFocus';

class ExamsPage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      CardBox: ClassStyle,
      ResultContainer: ClassStyle,
      ResultBox: ClassStyle,
    }),
    coursesWithExams: PropTypes.arrayOf(Course),
    numberOfCoursesWithExams: PropTypes.number,
    getCoursesWithExams: PropTypes.func,
    loadMoreCoursesWithExams: PropTypes.func,
    addCommonLoader: PropTypes.func,
    removeCommonLoader: PropTypes.func,
    resetCoursesWithExams: PropTypes.func,
    isLoading: PropTypes.bool,
    shouldShowLoading: PropTypes.bool,
  };

  static pageTitleKey = 'page_title_exams';

  static async getInitialData({ dispatch, language, token }) {
    try {
      if (token) {
        await Promise.all([
          dispatch(getCoursesWithExams({ page: 1, language, token })),
          dispatch(getProfileDetails({ language, token })),
        ]);
      }
      titleFocus(dispatch);
    } catch (err) {
      console.error(err);
    }
  }

  state = {
    page: 1,
  };

  componentWillUnmount() {
    this.props.resetCoursesWithExams();
  }

  loadMore = async () => {
    const { page } = this.state;
    const { loadMoreCoursesWithExams } = this.props;
    const newPage = page + 1;
    this.setState({ page: newPage });
    await loadMoreCoursesWithExams({ page: newPage });
  };

  render() {
    const { classes, coursesWithExams, numberOfCoursesWithExams, isLoading, shouldShowLoading } = this.props;

    return (
      <Box aria-live="polite" mt={4} mb={5} className={classes.CardBox}>
        <CardContainer
          items={coursesWithExams}
          numberOfItems={numberOfCoursesWithExams}
          loadMoreItems={this.loadMore}
          resultClass={classNames(classes.ResultContainer, 'Course', 'page-animation-exit-hidden')}
          isLoading={isLoading}
          skeleton={shouldShowLoading}
        >
          <CourseCardList
            courses={coursesWithExams}
            skeleton={shouldShowLoading}
            itemContainerClass={classes.ResultBox}
          />
        </CardContainer>
      </Box>
    );
  }
}

export default hoistStatics(withTranslation()(withStyles(examsPageStyles)(ExamsPage)), ExamsPage);
