import {
  absolute,
  bold,
  center,
  column,
  contain,
  flex,
  justify,
  none,
  noRepeat,
  pointer,
  relative,
  transparent,
} from 'constants/Jss';
import Tablet from 'assets/images/tablet.png';
import Anett from 'assets/images/anett.png';

export const homePageStyles = (theme) => ({
  BeAnettContainer: {
    width: '40%',
    color: theme.palette.white,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 0,
      marginBottom: 0,
      paddingTop: theme.spacing(4),
      paddingBottom: 174,
      backgroundImage: `url('${Anett}')`,
      backgroundSize: '264px 159px',
      backgroundPosition: '100% 100%',
      backgroundRepeat: noRepeat,
      backgroundColor: theme.palette.headerBlue,
    },
  },
  BeAnettDescription: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
    color: theme.palette.white,
    fontSize: 16,
  },
  Form: {
    height: theme.mixins.homeSearch.height,
    position: relative,
    display: flex,
    width: '100%',
    backgroundColor: theme.palette.white,
    borderRadius: 4,
    border: `1px solid ${theme.palette.white}`,
    '&.TooShort:focus-within': {
      borderColor: theme.palette.errorColor,
    },
  },
  Search: {
    display: flex,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  SearchIconContainer: {
    cursor: pointer,
    width: theme.mixins.header.contentSize,
    height: theme.mixins.header.contentSize,
    backgroundColor: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.whiteHover,
    },
    '&:disabled': {
      backgroundColor: theme.palette.white,
    },
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    '& svg': {
      fill: theme.palette.grey70,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  SearchField: {
    fontSize: 16,
    color: theme.palette.grey70,
    flexGrow: 1,
  },
  InputField: {
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(5),
    },
    '&::-webkit-search-cancel-button': {
      display: none,
    },
    '&::placeholder': {
      color: theme.palette.grey6E,
      opacity: 1,
    },
  },
  CloseSearch: {
    width: 20,
    height: 20,
    cursor: pointer,
    '& svg': {
      fill: theme.palette.grey70,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  CloseSearchSeparator: {
    width: 1,
    backgroundColor: theme.palette.black,
    height: '50%',
    margin: 'auto 0',
  },
  CloseSearchIcon: {
    width: 20,
    height: 20,
  },
  MainTopicContainer: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(8),
      marginBottom: 0,
      minHeight: 467,
    },
  },
  TopicsTitle: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(8),
    },
  },
  TopicSelectContainer: {
    width: 285,
    flexShrink: 0,
  },
  TopicSelectBox: {
    padding: theme.spacing(2.5),
    paddingRight: theme.spacing(4.5),
    '&.active': {
      backgroundColor: theme.palette.cyan,
      borderTopRightRadius: 50,
      borderBottomRightRadius: 50,
    },
  },
  TopicSelect: {
    fontSize: 21,
    fontWeight: bold,
    textDecorationLine: none,
    textDecorationColor: transparent,
    lineHeight: `${theme.spacing(3)}px`,
    color: theme.palette.black,
    '&.active': {
      color: theme.palette.white,
    },
    '&:not(.active)': {
      cursor: pointer,
    },
  },
  TopicTutorial: {
    padding: 5,
  },
  TopicDescriptionContainer: {
    color: theme.palette.blackTitle,
    width: '100%',
    fontSize: 18,
    textAlign: justify,
  },
  TopicsTutorialContainer: {
    padding: 5,
  },
  TopicContainer: {
    display: flex,
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  MobileTopicContainer: {
    [theme.breakpoints.up('md')]: {
      display: none,
    },
    display: flex,
    flexDirection: column,
    alignItems: center,
    [theme.breakpoints.down(480)]: {
      width: '100%',
    },
  },
  MobileTopic: {
    textDecorationLine: none,
    textDecorationColor: theme.palette.black,
    color: theme.palette.white,
    marginBottom: theme.spacing(4),
    '&:hover': {
      textDecorationLine: none,
      color: theme.palette.white,
    },
    [theme.breakpoints.down(480)]: {
      fontSize: 16,
      minWidth: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.up(480)]: {
      width: 380,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  MobileTopicBox: {
    backgroundColor: theme.palette.cyan,
    borderRadius: 44,
    lineHeight: '28px',
    padding: theme.spacing(2),
    cursor: pointer,
    fontSize: 21,
    textAlign: center,
    '&:hover': {
      backgroundColor: theme.palette.cyanHover,
      color: theme.palette.white,
    },
  },
  BestCourses: {
    flexGrow: 1,
  },
  BestCourse: {
    maxWidth: 222,
    height: 383,
    '& .CourseImage': {
      height: 133,
    },
    '& .ImageContainer': {
      height: 133,
    },
    '& .CourseDonePatch': {
      height: 136,
      backgroundPositionY: 4,
    },
  },
  ViewAllTopicsContainer: {
    display: flex,
    justifyContent: center,
    alignItems: center,
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  SpecialCourseContainer: {
    display: flex,
  },
  TabletPhotoContainer: {
    backgroundImage: `url('${Tablet}')`,
    backgroundSize: contain,
    backgroundRepeat: noRepeat,
  },
  DesktopTabletPhotoContainer: {
    minHeight: 497,
    marginTop: theme.spacing(10),
    backgroundPositionX: center,
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  MobileTabletPhotoContainer: {
    minHeight: 330,
    backgroundPosition: 'center top',
    [theme.breakpoints.up('md')]: {
      display: none,
    },
  },
  SpecialBox: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(6),
    },
  },
  SpecialBoxContainer: {
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingLeft: 0,
    },
  },
  SpecialBoxDescription: {
    fontSize: 18,
    color: theme.palette.blackTitle,
    lineHeight: '30px',
    textAlign: justify,
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  MobileSpecialBoxDescription: {
    textAlign: justify,
    [theme.breakpoints.up('md')]: {
      display: none,
    },
  },
  AboutUsContainer: {
    width: '100%',
    position: relative,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  MobileAboutUsContainer: {
    [theme.breakpoints.up('md')]: {
      display: none,
    },
    backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 1281.4 808.1'><path fill='rgb(250,250,250)' d='M1281.4,0c-138.4,4.1-172.9,144.1-494,143.7c-334.1-0.5-631.4-3.4-737.7,167.8s-27.4,299.8,98.6,308s365.1-96,602.5,62s345.8,169.7,476.4,26.7c19.5-21.3,36.9-41.6,52.6-61.1C1279.9,431.4,1281.4,190.7,1281.4,0z'/></svg>")`,
    backgroundSize: '100% 808px',
    backgroundPosition: '100% -100px',
    backgroundRepeat: noRepeat,
    paddingBottom: theme.spacing(10),
  },
  IndicatorContainer: {
    display: flex,
    justifyContent: center,
  },
  Indicator: {
    border: `1px solid ${theme.palette.green}`,
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.white,
    cursor: pointer,
    '&.active': {
      backgroundColor: theme.palette.green,
    },
    '&:not(:last-child)': {
      marginRight: theme.spacing(2.5),
    },
  },
  Paginate: {
    backgroundColor: theme.palette.white,
    width: theme.mixins.pagination.size,
    height: theme.mixins.pagination.size,
    display: flex,
    alignItems: center,
    justifyContent: center,
    position: absolute,
    borderRadius: '50%',
    top: `calc(50% - ${theme.mixins.pagination.size / 2}px)`,
    cursor: pointer,
    boxShadow: '0px 5px 20px #00000019',
    '&:hover': {
      boxShadow: '0px 5px 20px #00000041',
    },
    '&.Left': {
      left: 0,
      '& svg': {
        transform: 'rotate(90deg) translate(-3.586px, 5.894px)',
      },
    },
    '&.Right': {
      right: 0,
      '& svg': {
        transform: 'rotate(-90deg) translate(-2.586px, 6.894px)',
      },
    },
    '&.Hidden': {
      display: none,
    },
    '& svg': {
      fill: none,
      stroke: theme.palette.black,
      strokeWidth: 2,
      width: 24.617,
      height: 19.309,
    },
  },
  Link: {
    color: theme.palette.cyan,
    '&:hover': {
      color: theme.palette.cyanHover,
    },
  },
  SearchIcon: {
    color: theme.palette.grey70,
  },
  SearchIconAdornment: {
    fill: '#61BEEB !important',
    opacity: 1,
  },
  SearchIconAdornmentButton: {
    cursor: pointer,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-disabled svg': {
      opacity: 0.5,
      fill: `${theme.palette.text.primary} !important`,
    },
  },
});
