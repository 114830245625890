import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { Skeleton } from 'components';

import { center, flex, flexEnd, none } from 'constants/Jss';

const useStyles = makeStyles((theme) => ({
  NumberOfItems: {
    display: flex,
    justifyContent: flexEnd,
    alignItems: center,
    color: theme.palette.grey70,
    fontSize: 14,
    width: '100%',
    paddingRight: theme.spacing(3),

    '&.MobileHidden': {
      [theme.breakpoints.down('sm')]: {
        display: none,
      },
    },
  },
}));

const SearchResultNumber = ({ numberOfItems, resultText, mobileHiddenClass }) => {
  const classes = useStyles();

  return (
    <Box
      mt={2}
      mb={5}
      mr={3}
      className={classNames(classes.NumberOfItems, 'page-animation-exit-hidden', mobileHiddenClass)}
      tabIndex={0}
      role="status"
    >
      {numberOfItems || numberOfItems === 0 ? (
        <span>{`${numberOfItems} ${resultText}`}</span>
      ) : (
        <Skeleton height={20} width={60} />
      )}
    </Box>
  );
};

SearchResultNumber.propTypes = {
  numberOfItems: PropTypes.number,
  resultText: PropTypes.string,
  mobileHiddenClass: PropTypes.string,
};

export default SearchResultNumber;
