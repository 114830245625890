import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  absolute,
  auto,
  block,
  center,
  column,
  fixed,
  flex,
  flexEnd,
  flexStart,
  inherit,
  inlineBlock,
  none,
  noRepeat,
  normal,
  pointer,
  relative,
  spaceAround,
  spaceBetween,
  table,
  transparent,
  unset,
  visible,
} from 'constants/Jss';

export const useDesktopHeaderStyles = makeStyles((theme) => ({
  Header: {
    height: `${theme.mixins.header.height}px !important`,
    boxShadow: '0px 5px 20px #00000019',
    '&:hover': {
      boxShadow: '0px 5px 20px #00000041',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none !important',
    },
    backgroundColor: theme.palette.headerBlue,
    position: relative,
    display: 'unset !important',
    flexGrow: 'unset !important',
    padding: 'unset !important',
    cursor: 'unset !important',
  },
  HeaderBg: {
    display: 'inline-block !important',
    position: absolute,
    top: 0,
    left: 0,
    width: '100% !important',
    height: '60px !important',
    backgroundColor: theme.palette.headerBlue,
    padding: '0 94px',
  },
  HeaderGrid: {
    display: flex,
    alignItems: center,
    height: '100%',
    position: 'unset !important',
    paddingRight: 'unset !important',
  },
  LogoContainer: {
    display: 'flex  !important',
    justifyContent: 'unset !important',
    padding: '0 !important',
    marginRight: `${theme.spacing(4)} !important`,
  },
  Logo: {
    cursor: pointer,
    '&.MuiAvatar-root': {
      width: 180,
      height: auto,
      borderRadius: 0,
    },
  },
  SearchContainer: {
    display: flex,
    alignItems: center,
    height: theme.mixins.header.contentSize,
    marginLeft: 'unset !important',
    marginRight: 'unset !important',
  },
  SearchIcon: {
    fill: `${theme.palette.white} !important`,
  },
  SearchInput: {
    flex: 1,
    position: relative,
    color: theme.palette.white,
    fontSize: 14,
    cursor: 'text',
  },
  MenuContainer: {
    display: 'flex !important',
    flexGrow: '1 !important',
    alignItems: center,
    justifyContent: flexEnd,
    height: `${theme.mixins.header.contentSize}px !important`,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',

    '&.TopicsDropdownContainer': {
      display: 'inline-block !important',
      marginRight: '0px !important',
    },
    '&.AboutDropdownContainer': {
      top: 'unset !important',
      zIndex: unset,
    },
  },

  LoginButtonContainer: {
    padding: 'unset !important',
    maxWidth: 'unset !important',

    '& div': {
      backgroundColor: 'unset !important',
    },
  },
  RegistrationButtonContainer: {
    '& div': {
      top: 'unset !important',
      right: 'unset !important',
    },
  },
  MenuContainerHidden: {
    display: `${none} !important`,
  },
  SubMenu: {
    paddingRight: theme.spacing(7.75),
    position: relative,
    '&:not(:last-child):after': {
      content: `''`,
      position: absolute,
      bottom: 0,
      width: '86%',
      left: '7%',
      height: 2,
      backgroundColor: theme.palette.whiteHover,
    },
    '&:not(:last-child) > div': {
      borderBottom: `${none} !important`,
    },
  },
  List: {
    padding: '0px !important',
    display: flex,
    height: 'unset !important',
  },
}));

export const useMobileHeaderStyles = makeStyles((theme) => ({
  MobileHeader: {
    [theme.breakpoints.up('md')]: {
      display: none,
    },
    display: flex,
    height: theme.mixins.mobileHeader.height,
    backgroundColor: theme.palette.headerBlue,
    alignItems: center,
    padding: theme.spacing(2),
    position: fixed,
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    '&.Tutorial': {
      position: absolute,
    },
  },
  MobileHeaderBg: {
    position: absolute,
    top: 0,
    left: 0,
    width: '100%',
    height: 60,
    backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 1280 60'><g transform='translate(-935 587)'><g transform='translate(196.673 121.794)'><path fill='rgb(20,56,127)' d='M382.327,378.569c0-61.993,49.637-112.26,110.813-112.26s110.786-50.248,110.786-112.247c0,62,49.6,112.247,110.793,112.247S825.5,316.577,825.5,378.563h-.106l.106.007c0,61.993-49.637,112.26-110.82,112.26S603.9,541.071,603.9,603.077c0-62.006-49.61-112.247-110.793-112.247s-110.78-50.267-110.78-112.254l.113-.007Z' transform='translate(890.828 -1258.609'/><path fill='rgb(20,56,127)' d='M382.327,378.569c0-61.993,49.637-112.26,110.813-112.26s110.786-50.248,110.786-112.247c0,62,49.6,112.247,110.793,112.247S825.5,316.577,825.5,378.563h-.106l.106.007c0,61.993-49.637,112.26-110.82,112.26S603.9,541.071,603.9,603.077c0-62.006-49.61-112.247-110.793-112.247s-110.78-50.267-110.78-112.254l.113-.007Z' transform='translate(669 -1034.179)'/></g></g></svg>")`,
    backgroundSize: '1280px 60px',
    backgroundPosition: '-246px 0px',
    backgroundRepeat: noRepeat,
    zIndex: -1,
  },
  MobileLogoContainer: {
    border: 0,
    display: flex,
    justifyContent: center,
  },
  MobileLogo: {
    cursor: pointer,
    '&.MuiAvatar-root': {
      width: 180,
      height: auto,
      borderRadius: 0,
    },
  },
  MobileActionContainer: {
    display: flex,
    justifyContent: flexEnd,
    flexGrow: 1,
    color: theme.palette.white,
  },
  MobileMenuButton: {
    color: inherit,
    width: 25,
    height: 25,
    cursor: pointer,
  },
  MobileSearchButton: {
    width: 25,
    height: 25,
    cursor: pointer,
  },
  MobileSearchIconButton: {
    padding: 0,
    '&:hover': {
      color: transparent,
      backgroundColor: transparent,
      '& svg': {
        fill: theme.palette.blue50,
      },
    },
    '&:focus': {
      color: transparent,
      backgroundColor: transparent,
      '& svg': {
        fill: theme.palette.blue50,
      },
    },
  },
  DrawerContainer: {
    backgroundColor: theme.palette.headerBlue,
    boxShadow: none,
    position: `${relative} !important`,
    top: '0 !important',
    '&:focus': {
      outline: none,
    },
    '& ul': {
      padding: 0,
    },
  },
  MobileAvatarContainer: {
    display: flex,
    justifyContent: 'center !important',
    backgroundColor: theme.palette.headerBlue,
  },
  MobileAvatar: {
    backgroundColor: theme.palette.avatarGrey,
    width: '90px !important',
    height: '90px !important',
    '&.NoAvatar > .MuiAvatar-img': {
      width: '60%',
      height: '60%',
    },
  },
  MobileTopicsButton: {
    color: theme.palette.white,
    padding: `${theme.spacing(2)}px !important`,
  },
  MobileDivider: {
    backgroundColor: `${theme.palette.white} !important`,
  },
  Collapse: {
    padding: 0,
  },
  MobileAuthContainer: {
    display: flex,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  SearchIcon: {
    color: theme.palette.white,
  },
}));

export const usePatches = makeStyles((theme) => ({
  YellowPatch: {
    backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(252,196,128)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
    backgroundPosition: '-286px -250px',
    backgroundSize: '500px 445.066px',
    backgroundRepeat: `${noRepeat}`,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(17),
    },
  },
}));

export const useMenuItemStyles = makeStyles((theme) => ({
  MenuItem: {
    color: theme.palette.white,
    backgroundColor: 'unset !important',
    '&:hover': {
      color: theme.palette.white,
    },
    '&:focus': {
      color: theme.palette.white,
    },
    '&.Mobile': {
      color: theme.palette.white,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  SubmenuItem: {
    color: theme.palette.grey70,
    '&:hover': {
      color: theme.palette.grey70,
      backgroundColor: theme.palette.whiteHover,
    },
    '&:focus': {
      color: theme.palette.grey70,
      backgroundColor: theme.palette.whiteHover,
    },
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    '&:not(:last-child) > div': {
      borderBottom: `2px solid ${theme.palette.whiteHover}`,
    },
    '&.Mobile': {
      color: theme.palette.white,
      backgroundColor: theme.palette.blackOpacity,
      paddingTop: 0,
      paddingBottom: 0,
      '&:not(:last-child) > div': {
        border: 0,
      },
    },
  },
  SubmenuItemText: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  Icon: {
    width: 35,
    height: 59,
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 8,
  },
}));

export const useSearchStyles = makeStyles((theme) => ({
  Search: {
    display: flex,
    '&:focus-within': {
      backgroundColor: theme.palette.searchBg,
    },
  },
  MobileSearch: {
    borderRadius: 0,
    backgroundColor: theme.palette.whiteHover,
    '&:focus-within': {
      backgroundColor: theme.palette.whiteHover,
    },
  },
  SearchWithBg: {
    backgroundColor: theme.palette.searchBg,
  },
  Form: {
    height: theme.mixins.header.contentSize,
    position: relative,
    backgroundColor: transparent,
    display: flex,
    alignItems: center,
    width: '100%',
    border: `1px solid transparent`,
    '& input::-webkit-input-placeholder': {
      color: theme.palette.white,
    },
    '& input::-moz-placeholder': {
      color: theme.palette.white,
    },
    '& input::placeholder': {
      color: theme.palette.white,
    },
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  },
  FormOpen: {
    backgroundColor: theme.palette.white,
    borderColor: theme.palette.white,
    paddingLeft: theme.spacing(1),
    '& input::-webkit-input-placeholder': {
      color: theme.palette.grey6E,
      opacity: 1,
    },
    '& input::-moz-placeholder': {
      color: theme.palette.grey6E,
      opacity: 1,
    },
    '& input::placeholder': {
      color: theme.palette.grey6E,
      opacity: 1,
    },
    '&.TooShort': {
      borderColor: theme.palette.errorColor,
    },
  },
  MobileForm: {
    width: '100%',
    height: theme.spacing(7),
    display: flex,
    alignItems: center,
  },
  SearchIconContainer: {
    cursor: pointer,
    width: theme.mixins.header.contentSize - 2,
    height: theme.mixins.header.contentSize - 2,
    backgroundColor: transparent,
    '&:hover': {
      backgroundColor: theme.palette.whiteHover,
    },
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    '&.Mui-disabled svg': {
      opacity: 0.6,
    },
  },
  MobileSearchIconContainer: {
    backgroundColor: transparent,
    '&:hover': {
      backgroundColor: transparent,
    },
    '& svg': {
      width: '18.5px !important',
      height: '19px !important',
    },
    marginLeft: theme.spacing(1),
  },
  SearchField: {
    fontSize: 15,
    color: theme.palette.grey70,
    width: '100%',
  },
  MobileSearchField: {
    color: theme.palette.white,
    flexGrow: 1,
  },
  InputField: {
    paddingRight: theme.spacing(5),
    minWidth: 230,
    [theme.breakpoints.down(1140)]: {
      minWidth: 215,
      color: theme.palette.white,
    },
    '&::placeholder': {
      opacity: 1,
    },
    '&::-webkit-search-cancel-button': {
      display: none,
    },
  },
  CloseSearch: {
    padding: 0,
    marginRight: 4,
    '& svg': {
      width: 27,
      height: 27,
      fill: theme.palette.grey70,
    },
    '&:hover': {
      backgroundColor: transparent,
    },
  },
  CancelSearch: {
    cursor: pointer,
    padding: 0,
    '&:hover': {
      backgroundColor: transparent,
    },
    '& svg': {
      width: 21,
      height: 21,
      fill: theme.palette.grey70,
    },
  },
  DesktopCancelSearchSeparator: {
    width: 1,
    backgroundColor: theme.palette.black,
    height: '50%',
    marginLeft: theme.spacing(1),
  },
  MobileCancelSearch: {
    position: absolute,
    top: 14,
    right: 14,
    '& svg': {
      width: 18,
      height: 18,
      fill: theme.palette.white,
    },
  },
  SearchIcon: {
    fill: theme.palette.white,
  },
  SearchIconAdornmentButton: {
    cursor: pointer,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-disabled svg': {
      opacity: 0.5,
      fill: `${theme.palette.text.primary} !important`,
    },
  },
  SearchIconAdornment: {
    fill: `${theme.palette.lightBlue} !important`,
    opacity: 1,
  },
}));

export const useHamburgerStyles = makeStyles((theme) => ({
  Container: {
    display: inlineBlock,
    cursor: pointer,
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    '&:hover': {
      color: transparent,
      backgroundColor: transparent,
      '& .Bar': {
        backgroundColor: theme.palette.blue50,
      },
    },
    '&:focus': {
      color: transparent,
      backgroundColor: transparent,
      '& .Bar': {
        backgroundColor: theme.palette.blue50,
      },
    },
    '& .MuiIconButton-label': {
      display: block,
    },
  },
  Bar: {
    display: block,
    height: 5,
    backgroundColor: theme.palette.white,
    borderRadius: 3,
    marginLeft: 0,
    marginRight: 0,
    transition: '0.4s',
  },
  Bar1: {
    width: 24,
    marginBottom: 4,
  },
  Bar2: {
    width: 18,
  },
  Bar1Open: {
    width: 25,
    '-webkit-transform': 'rotate(-45deg) translate(-4px, 0)',
    transform: 'rotate(-45deg) translate(-4px, 0)',
    marginBottom: 0,
  },
  Bar2Open: {
    width: 25,
    '-webkit-transform': 'rotate(45deg) translate(-3px, 0)',
    transform: 'rotate(45deg) translate(-3px, 0)',
  },
}));

export const useLoginDialogStyles = makeStyles((theme) => ({
  FormBox: {
    maxWidth: theme.mixins.loginForm.width,
  },
  Description: {
    textAlign: center,
  },
  LoginButtonContainer: {
    display: flex,
    justifyContent: center,
  },
  ForgotPasswordButtonContainer: {
    display: flex,
    alignItems: center,
    justifyContent: spaceAround,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
      display: table,
      width: '100%',
      textAlign: center,
    },
  },
  ResetPasswordButtonContainer: {
    display: flex,
    alignItems: center,
    justifyContent: center,
  },
  Or: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  Link: {
    color: theme.palette.lightBlue,
    '&:hover': {
      color: theme.palette.lightBlueHover,
    },
  },
  NewPasswordBox: {
    maxWidth: theme.mixins.loginForm.width,
    marginLeft: auto,
    marginRight: auto,
    display: flex,
    justifyContent: spaceBetween,
    alignItems: flexStart,
    '&.center': {
      justifyContent: center,
    },
    '& > .MuiFormControlLabel-root': {
      marginLeft: 0,
    },
  },
  NewPasswordLink: {
    display: flex,
    justifyContent: center,
    paddingBottom: theme.spacing(4),
    fontSize: 16,
    color: theme.palette.cyan,
    '&:hover': {
      color: theme.palette.cyanHover,
    },
  },
  StayLoggedInLabel: {
    color: theme.palette.grey70,
    fontSize: 12,
    textAlign: center,
    maxWidth: 100,
    fontWeight: 700,
    marginRight: theme.spacing(1),
  },
  RegisterBox: {
    display: flex,
    justifyContent: spaceBetween,
    maxWidth: 380,
    marginLeft: auto,
    marginRight: auto,
    fontSize: 16,
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.greyLoginLine}`,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexDirection: column,
      alignItems: center,
    },
  },
}));

export const useRegisterDialogStyles = makeStyles((theme) => ({
  FormBox: {
    maxWidth: theme.mixins.registerForm.width,
  },
  FieldBox: {
    position: relative,
  },
  FlexFieldBox: {
    display: flex,
    alignItems: center,
    color: theme.palette.greyB3,
    fontSize: 16,
    fontWeight: normal,
  },
  ClearIndicator: {
    visibility: visible,
    '&:hover': {
      backgroundColor: theme.palette.white,
    },
    '&.Filled': {
      visibility: visible,
    },
    '& svg': {
      fill: theme.palette.grey70,
    },
  },
  RegisterButtonContainer: {
    display: flex,
    justifyContent: center,
  },
  Title: {
    borderColor: theme.palette.green,
  },
  Checkbox: {
    marginRight: theme.spacing(3),
    color: `${theme.palette.whiteHover} !important`,
    '& svg': {
      fill: theme.palette.checkboxBorder,
    },
    '&:hover': {
      backgroundColor: theme.palette.whiteHover,
    },
    '&.Mui-checked': {
      '& svg': {
        fill: `${theme.palette.lightBlue} !important`,
      },
      '&:hover': {
        backgroundColor: `${theme.palette.whiteHover} !important`,
      },
    },
  },
  CheckboxText: {
    fontSize: 12,
  },
  ErrorText: {
    color: theme.palette.errorColor,
    fontSize: '0.8571428571428571rem',
    margin: '8px 14px 0',
    lineHeight: '1em',
  },
  TooltipDialog: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(8),
      fontSize: 16,
      '& label': {
        fontSize: 16,
        lineHeight: '18px',
      },
    },
  },
}));

export const useSocialStyles = makeStyles(() => {
  return {
    SocialButtonsContainer: {
      maxWidth: 350,
      marginLeft: auto,
      marginRight: auto,
    },
  };
});
