import React, { Component, createRef } from 'react';
import { isTabEvent } from 'utils/eventUtils';

export const withFirstFocus = (WrappedComponent) => {
  class WithFirstFocus extends Component {
    tabPressedRef = createRef(false);
    firstFocusRef = createRef();

    onClick = () => {
      this.tabPressedRef.current = true;
    };

    onKeyDown = (event) => {
      if (!this.tabPressedRef.current && isTabEvent(event)) {
        this.tabPressedRef.current = true;
        if (this.firstFocusRef.current) {
          event.preventDefault();
          this.firstFocusRef.current.focus();
        }
      }
    };

    render() {
      return (
        <WrappedComponent
          onClick={this.onClick}
          onKeyDown={this.onKeyDown}
          firstFocusRef={this.firstFocusRef}
          {...this.props}
        />
      );
    }
  }

  return WithFirstFocus;
};
