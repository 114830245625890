import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';

import logo from 'assets/images/mtu.png';
import noImage from 'assets/images/no-image-3.png';
import { MenuItem } from './MenuItem';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useMobileHeaderStyles } from './styles';
import { Hamburger } from './Hamburger';
import { Profile } from 'types/types';
import { getUrl } from 'utils/urlFromImagePath';
import { searchIcon } from 'utils/icons';
import { MinDesktopWidth } from 'constants/Constants';
import { isBrowser } from 'utils/browser';
import { MobileHeaderSearchField } from 'components';
import { useFirstFocus } from 'utils/useFirstFocus';
import { RunStates } from 'components/Tutorial/Tutorial';

export const MobileHeader = ({
  onLogoClick,
  getPath,
  search,
  history,
  topics,
  profile,
  logout: logoutAction,
  isLoggedIn,
}) => {
  const classes = useMobileHeaderStyles();
  const { t } = useTranslation();
  const { runState } = useSelector(({ tutorial }) => tutorial);

  const Menus = {
    Topics: 'topics',
    About: 'about',
    Profile: 'profile',
  };

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(null);

  const logoutMenuRef = useRef(null);
  const firstFocusRef = React.useRef(null);

  const handleResize = () => {
    setDrawerOpen(false);
    setOpenMenu('');
    if (window.innerWidth > MinDesktopWidth) {
      setSearchOpen(false);
    }
  };

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (isBrowser()) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setSearchOpen(false);
    setOpenMenu('');
    setDrawerOpen(open);
  };

  const toggleSearch = (open) => () => {
    setDrawerOpen(false);
    setOpenMenu('');
    setSearchOpen(open);
  };

  const toggleTopics = () => {
    setOpenMenu(openMenu === Menus.Topics ? '' : Menus.Topics);
  };

  const toggleAbout = () => {
    setOpenMenu(openMenu === Menus.About ? '' : Menus.About);
  };

  const toggleProfile = () => {
    setOpenMenu(openMenu === Menus.Profile ? '' : Menus.Profile);
    setTimeout(() => {
      if (openMenu !== Menus.Profile) {
        logoutMenuRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
  };

  const logout = () => {
    logoutAction();
    setDrawerOpen(false);
    setSearchOpen(false);
    setOpenMenu('');
  };

  const onSearchSubmit = useCallback(
    (searchString) => {
      setSearchOpen(false);
      search({ searchString });
    },
    [search]
  );

  const closeSearch = useCallback(() => {
    setSearchOpen(false);
  }, []);

  const [onClick, onKeyDown] = useFirstFocus(firstFocusRef);

  return (
    <Box
      className={classNames(classes.MobileHeader, { Tutorial: runState === RunStates.RUNNING })}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      <div className={classes.MobileHeaderBg} />
      <Box className={classes.MobileLogoContainer}>
        <Avatar
          ref={firstFocusRef}
          className={classes.MobileLogo}
          src={logo}
          onClick={() => {
            setDrawerOpen(false);
            setSearchOpen(false);
            onLogoClick();
          }}
          tabIndex={0}
          role="link"
          alt={t('alt_link_to_home_page')}
          aria-label={t('alt_link_to_home_page')}
          id="page-logo-mobile"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onLogoClick();
            }
          }}
        />
      </Box>
      <Box className={classes.MobileActionContainer}>
        <Box mt={1} className={classes.MobileSearchButton}>
          <IconButton
            className={classes.MobileSearchIconButton}
            onClick={toggleSearch(!searchOpen)}
            tabIndex={0}
            aria-label={t('common_search')}
          >
            {searchIcon({ iconClass: classes.SearchIcon, width: 21.13, height: 22.4 })}
          </IconButton>
        </Box>
        <Box ml={1}>
          <Hamburger onClick={toggleDrawer(!drawerOpen)} open={drawerOpen} />
        </Box>
        <Box>
          <Drawer
            anchor="top"
            open={searchOpen}
            onClose={toggleSearch(false)}
            PaperProps={{ className: classes.DrawerContainer }}
          >
            {searchOpen && (
              <MobileHeaderSearchField
                ariaLabel={t('common_search_placeholder')}
                placeholder={t('common_search_placeholder')}
                submit={onSearchSubmit}
                cancel={closeSearch}
              />
            )}
          </Drawer>
          <Drawer
            anchor="top"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{ className: classes.DrawerContainer }}
          >
            <div role="presentation">
              <List>
                {isLoggedIn ? (
                  <ListItem className={classes.MobileAvatarContainer}>
                    <Box mt={1} mb={2}>
                      <Avatar
                        className={classNames(classes.MobileAvatar, { NoAvatar: !profile.avatar })}
                        src={profile.avatar ? getUrl(profile.avatar) : noImage}
                        alt={t('alt_profile_picture')}
                      />
                    </Box>
                  </ListItem>
                ) : null}
                <MenuItem
                  mobile
                  linkName={t('menu_item_home')}
                  to={getPath(t('menu_item_home'))}
                  onClick={toggleDrawer(false)}
                />
                <Divider variant="middle" className={classes.MobileDivider} />
                <ListItem className={classes.MobileTopicsButton} button onClick={toggleTopics}>
                  <ListItemText primary={t('menu_item_topics')} />
                  {openMenu === Menus.Topics ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse className={classes.Collapse} in={openMenu === Menus.Topics} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {topics
                      ? topics.map((topic) => (
                          <div key={topic.id}>
                            <Divider variant="middle" className={classes.MobileDivider} />
                            <MenuItem
                              mobile
                              onClick={toggleDrawer(false)}
                              linkName={topic.title}
                              to={getPath(topic.title)}
                              submenu
                            />
                          </div>
                        ))
                      : null}
                  </List>
                </Collapse>
                <Divider variant="middle" className={classes.MobileDivider} />
                {isLoggedIn ? (
                  <>
                    <MenuItem
                      mobile
                      linkName={t('menu_item_courses')}
                      to={getPath(t('menu_item_courses'))}
                      onClick={toggleDrawer(false)}
                    />
                    <Divider variant="middle" className={classes.MobileDivider} />
                    <MenuItem
                      mobile
                      linkName={t('menu_item_exams')}
                      to={getPath(t('menu_item_exams'))}
                      onClick={toggleDrawer(false)}
                    />
                    <Divider variant="middle" className={classes.MobileDivider} />
                    <MenuItem
                      mobile
                      linkName={t('menu_item_top_list')}
                      to={getPath(t('menu_item_top_list'))}
                      onClick={toggleDrawer(false)}
                    />
                    <Divider variant="middle" className={classes.MobileDivider} />
                  </>
                ) : null}

                <ListItem className={classes.MobileTopicsButton} button onClick={toggleAbout}>
                  <ListItemText primary={t('menu_item_about')} />
                  {openMenu === Menus.About ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse className={classes.Collapse} in={openMenu === Menus.About} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Divider variant="middle" className={classes.MobileDivider} />
                    <MenuItem
                      mobile
                      linkName={t('menu_item_about')}
                      to={getPath(t('menu_item_about'))}
                      onClick={toggleDrawer(false)}
                      submenu
                    />
                    <Divider variant="middle" className={classes.MobileDivider} />
                    <MenuItem
                      mobile
                      linkName={t('menu_item_knowledge_store')}
                      to={getPath(t('menu_item_knowledge_store'))}
                      onClick={toggleDrawer(false)}
                      submenu
                    />
                  </List>
                </Collapse>
                {isLoggedIn ? (
                  <>
                    <Divider variant="middle" className={classes.MobileDivider} />
                    <ListItem className={classes.MobileTopicsButton} button onClick={toggleProfile}>
                      <ListItemText primary={t('menu_item_profile')} />
                      {openMenu === Menus.Profile ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse className={classes.Collapse} in={openMenu === Menus.Profile} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <Divider variant="middle" className={classes.MobileDivider} />
                        <MenuItem
                          mobile
                          linkName={t('menu_item_profile')}
                          to={getPath(t('menu_item_profile'))}
                          onClick={toggleDrawer(false)}
                          submenu
                        />
                        <Divider variant="middle" className={classes.MobileDivider} />
                        {!!profile.isShowCertificates && (
                          <>
                            <MenuItem
                              mobile
                              linkName={t('menu_item_certificates')}
                              to={getPath(t('menu_item_certificates'))}
                              onClick={toggleDrawer(false)}
                              submenu
                            />
                            <Divider variant="middle" className={classes.MobileDivider} />
                          </>
                        )}
                        <MenuItem
                          mobile
                          linkName={t('menu_item_badges')}
                          to={getPath(t('menu_item_badges'))}
                          onClick={toggleDrawer(false)}
                          submenu
                        />
                      </List>
                    </Collapse>
                    <Divider variant="middle" className={classes.MobileDivider} />
                    <MenuItem
                      mobile
                      linkName={t('menu_item_logout')}
                      to={getPath(t('menu_item_home'))}
                      onClick={logout}
                      ref={logoutMenuRef}
                    />
                  </>
                ) : (
                  <>
                    <Divider variant="middle" className={classes.MobileDivider} />
                    <div className={classes.MobileAuthContainer}>
                      <Box marginLeft={2} marginRight={2}>
                        <Link
                          to={`${history.location.pathname}${
                            history.location.search ? history.location.search + '&' : '?'
                          }dialog=${t('url_login')}`}
                          onClick={toggleDrawer(false)}
                          role="button"
                          aria-label={t('menu_item_login')}
                        >
                          <Button disableTouchRipple buttonClassName="Header Secondary" tabIndex={-1}>
                            {t('menu_item_login')}
                          </Button>
                        </Link>
                      </Box>
                      <Box marginRight={2}>
                        <Link
                          to={`${history.location.pathname}?dialog=${t('url_register')}`}
                          onClick={toggleDrawer(false)}
                          role="button"
                          aria-label={t('menu_item_register')}
                        >
                          <Button disableTouchRipple buttonClassName="Header" tabIndex={-1}>
                            {t('menu_item_register')}
                          </Button>
                        </Link>
                      </Box>
                    </div>
                  </>
                )}
              </List>
            </div>
          </Drawer>
        </Box>
      </Box>
    </Box>
  );
};

MobileHeader.propTypes = {
  onLogoClick: PropTypes.func.isRequired,
  getPath: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  profile: Profile,
  logout: PropTypes.func,
  isLoggedIn: PropTypes.bool,
};
