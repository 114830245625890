import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import hoistStatics from 'hoist-non-react-statics';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Cookies from 'js-cookie';

import { profilePageStyles } from './styles';
import { getProfileDetails } from 'store/profile/actions';
import { getProfileTags } from 'store/tags/actions';
import { ClassStyle, Group, ProfileDetails, Settlement, Tag } from 'types/types';
import { getUrl } from 'utils/urlFromImagePath';
import noImage from 'assets/images/no-image-3.png';
import { PasswordChangeForm } from './PasswordChangeForm';
import { ProfileDataForm } from './ProfileDataForm';
import { ApiErrorMessages, GroupTypes, NotificationTypes } from 'constants/Constants';
import Image from 'components/Image/Image';
import { ProfileTags } from './ProfileTags';
import LeftPatch from 'assets/images/profile-left-patch.png';
import RightPatch from 'assets/images/profile-right-patch.png';
import Calendar from 'assets/images/calendar.png';
import { getDateString } from 'utils/date';
import DeleteProfileDialog from './DeleteProfileDialog';
import { getCurrentLanguage } from 'utils/language';
import Settings from 'env';
import { parseJwt } from 'utils/jwt';
import { MobileProfileTags } from './MobileProfileTags';
import { removeToken } from 'utils/refreshToken';
import queryString from 'query-string';
import { getFirstLoginDialogShown, setFirstLoginDialogShown } from 'utils/firstLogin';
import FirstLoginDialog from './FirstLoginDialog';
import { titleFocus } from 'utils/titleFocus';
import { containsErrorMessage } from 'utils/error';

class ProfilePage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      ProfilePageContainer: ClassStyle,
      BeginningOfLearningContainer: ClassStyle,
      BeginningOfLearningData: ClassStyle,
      BeginningOfLearningLabel: ClassStyle,
      BeginningOfLearningDate: ClassStyle,
      CalendarIcon: ClassStyle,
      ProfileDetailsContainer: ClassStyle,
      AvatarContainer: ClassStyle,
      Avatar: ClassStyle,
      ProfileDataContainer: ClassStyle,
      NameContainer: ClassStyle,
      GroupContainer: ClassStyle,
      EmailContainer: ClassStyle,
      XPBox: ClassStyle,
      XPBubble: ClassStyle,
      XPLabel: ClassStyle,
      XPValueContainer: ClassStyle,
      XPValue: ClassStyle,
      XPText: ClassStyle,
      SectionTitleContainer: ClassStyle,
      PersonalDataContainer: ClassStyle,
      ProfileTagsBox: ClassStyle,
      MobileProfileTagsBox: ClassStyle,
      ChangePasswordContainer: ClassStyle,
      DeleteProfileContainer: ClassStyle,
      DeleteActionContainer: ClassStyle,
      DeleteProfileText: ClassStyle,
      DeleteProfileIcon: ClassStyle,
      MobilePatchContainer: ClassStyle,
    }),
    profileDetails: ProfileDetails,
    t: PropTypes.func.isRequired,
    history: PropTypes.object,
    pages: PropTypes.array.isRequired,
    groupSearch: PropTypes.func.isRequired,
    groups: PropTypes.arrayOf(Group),
    getSettlements: PropTypes.func.isRequired,
    settlements: PropTypes.arrayOf(Settlement),
    updateProfileDetails: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    profileTags: PropTypes.arrayOf(Tag),
    setProfileData: PropTypes.func.isRequired,
    deleteProfile: PropTypes.func,
    logout: PropTypes.func,
    getAuthorizedRoutes: PropTypes.func,
    refreshToken: PropTypes.func,
    addLoader: PropTypes.func,
    removeLoader: PropTypes.func,
    addCommonLoader: PropTypes.func,
    removeCommonLoader: PropTypes.func,
  };

  static pageTitleKey = 'page_title_profile';

  Dialogs = {
    DeleteProfile: 'delete-profile',
    FirstLogin: 'first-login',
  };

  constructor(props) {
    super(props);

    this.state = {
      openDialog: '',
    };

    this.props.addCommonLoader();
  }

  static async getInitialData({ dispatch, language, token }) {
    try {
      if (token) {
        await Promise.all([
          dispatch(getProfileDetails({ language, token })),
          dispatch(getProfileTags({ language, token })),
        ]);
      }
      titleFocus(dispatch);
    } catch (err) {
      console.error(err);
    }
  }

  componentDidUpdate() {
    const {
      history,
      t,
      profileDetails: { id },
    } = this.props;
    const { dialog } = queryString.parse(history.location.search);
    if (dialog && dialog === t('url_first_login') && !getFirstLoginDialogShown(id)) {
      setFirstLoginDialogShown(id);
      this.setState({ openDialog: this.Dialogs.FirstLogin });
    }
  }

  setUserTags = async (id) => {
    const { profileDetails, updateProfileDetails, setProfileData, profileTags, addLoader, removeLoader } = this.props;
    const {
      id: profileId,
      tags,
      name,
      email,
      group,
      settlement: { id: settlementId },
      avatar,
      firstLoginDate,
      isDataPublic,
    } = profileDetails;
    let newTags = null;
    const tagIds = tags.map((tag) => tag.id);
    if (tagIds.includes(id)) {
      newTags = tagIds.filter((tagId) => tagId !== id);
    } else {
      newTags = [...tagIds, id];
    }
    addLoader();
    updateProfileDetails({
      name,
      email,
      groupId: group.id,
      groupType: group.type,
      tags: newTags,
      settlementId,
      isDataPublic,
    });
    setProfileData({
      tags: profileTags.filter((tag) => newTags.includes(tag.id)),
      name,
      email,
      avatar,
      firstLoginDate,
      id: profileId,
      isDataPublic,
    });
    removeLoader();
  };

  submitProfileData = async ({ name, organization, email, avatar, settlement, kkv, isDataPublic, taxNumber }) => {
    const {
      updateProfileDetails,
      groups,
      profileDetails,
      getSettlements,
      refreshToken,
      setProfileData,
      addLoader,
      removeLoader,
      showNotification,
    } = this.props;

    const groupObj = groups.find((group) => group.name === organization);
    const groupId = groupObj
      ? groupObj.id
      : organization === profileDetails.group.name
      ? profileDetails.group.id
      : null;
    const settlementObjects = await getSettlements(settlement);
    const settlementObject = settlementObjects.find((settlementObj) => settlementObj.name === settlement);
    const settlementId = settlementObject ? settlementObject.id : profileDetails.settlement.id;
    const data = {
      name,
      email,
      groupId,
      groupName: groupId ? null : organization,
      settlementId,
      tags: profileDetails.tags.map((tag) => tag.id),
      groupType: kkv ? 1 : 0,
      isDataPublic,
      taxNumber,
    };

    if (avatar || avatar === null) {
      data.avatar = avatar ? avatar.split('base64,')[1] : null;
    }

    addLoader();

    try {
      await updateProfileDetails(data);
      await refreshToken(Cookies.get(Settings.API_TOKEN));
      const { name: newName, email: newEmail, avatar: newAvatar, sub: id, firstLoginDate } = parseJwt(
        Cookies.get(Settings.API_TOKEN)
      );
      setProfileData({
        id,
        name: newName,
        email: newEmail,
        avatar: newAvatar,
        firstLoginDate,
        tags: profileDetails.tags,
        isDataPublic,
        taxNumber,
      });
      removeLoader();
    } catch (err) {
      showNotification({ translateKey: 'profile_save_error_message', type: NotificationTypes.error });
      throw err;
    }
  };

  submitNewPassword = async ({ password, newPassword }) => {
    const { changePassword, showNotification } = this.props;
    try {
      await changePassword(password || null, newPassword);
      showNotification({
        translateKey: password ? 'change_password_success' : 'set_password_success',
        type: NotificationTypes.success,
      });
    } catch (error) {
      if (containsErrorMessage(error, ApiErrorMessages.OLD_PASSWORD_IS_INCORRECT)) {
        showNotification({ translateKey: 'old_password_is_incorrect', type: NotificationTypes.error });
      } else if (containsErrorMessage(error, ApiErrorMessages.NEW_PASSWORD_IS_INCORRECT)) {
        showNotification({ translateKey: 'new_password_is_incorrect', type: NotificationTypes.error });
      } else if (!error.resolved) {
        throw error;
      }
    }
  };

  openDeleteProfileDialog = () => {
    this.setState({ openDialog: this.Dialogs.DeleteProfile });
  };

  closeDialog = () => {
    const { history } = this.props;

    if (this.state.openDialog === this.Dialogs.FirstLogin) {
      history.push(history.location.pathname);
      const profileDataElement = document.getElementById('personal-data');
      profileDataElement && profileDataElement.scrollIntoView();
    }
    this.setState({ openDialog: '' });
  };

  deleteProfile = async () => {
    const { deleteProfile, history, showNotification } = this.props;
    this.closeDialog();
    const success = await deleteProfile();
    if (success) {
      removeToken();
      history.push('/' + getCurrentLanguage(history.location.pathname));
      showNotification({ translateKey: 'profile_successful_profile_deletion', type: NotificationTypes.success });
    }
  };

  render() {
    const { classes, profileDetails, t, groups, groupSearch, settlements, getSettlements, profileTags } = this.props;
    const {
      score,
      avatar,
      avatarFull,
      email,
      name,
      group,
      settlement,
      tags,
      firstLoginDate,
      isDataPublic,
      hasPassword,
      fromSocial,
    } = profileDetails;
    return !tags ? null : (
      <div className={classes.ProfilePageContainer}>
        <Box className={classes.BeginningOfLearningContainer}>
          <Box mr={2} className={classes.BeginningOfLearningData}>
            <Box className={classes.BeginningOfLearningLabel}>{t('profile_beginning_of_learning')}</Box>
            <Box
              className={classes.BeginningOfLearningDate}
              tabIndex={0}
              aria-label={t('profile_beginning_of_learning') + ': ' + getDateString(firstLoginDate)}
            >
              <div aria-hidden={true}>{getDateString(firstLoginDate)}</div>
            </Box>
          </Box>
          <Image src={Calendar} className={classes.CalendarIcon} aria-hidden={true} />
        </Box>
        <Box mb={16} className={classes.ProfileDetailsContainer}>
          <Box mr={3} className={classes.AvatarContainer}>
            <Avatar
              className={classNames(classes.Avatar, { NoAvatar: !avatar })}
              src={avatar ? getUrl(avatar) : noImage}
              aria-hidden={true}
              alt={t('alt_profile_picture')}
            />
          </Box>
          <Box className={classNames(classes.ProfileDataContainer, 'Mobile')}>
            <Box className={classes.NameContainer} tabIndex={0}>
              {name}
            </Box>
            <Box className={classes.GroupContainer} tabIndex={0}>
              {group ? group.name : ''}
            </Box>
            <Box className={classes.EmailContainer} tabIndex={0}>
              {email}
            </Box>
          </Box>
          <Box order={3} className={classes.ProfileDataContainer}>
            <Box className={classes.NameContainer} tabIndex={0}>
              {name}
            </Box>
            <Box className={classes.GroupContainer} tabIndex={0}>
              {group ? group.name : ''}
            </Box>
            <Box className={classes.EmailContainer} tabIndex={0}>
              {email}
            </Box>
          </Box>
          <Box className={classes.XPBox}>
            <Box className={classes.XPBubble}>
              <Box>
                {score || score === 0 ? (
                  <div tabIndex={0} aria-label={`${t('profile_xp')}: ${score} ${t('common_xp')}`}>
                    <Box className={classes.XPLabel} aria-hidden={true}>
                      {t('profile_xp')}
                    </Box>
                    <Box className={classes.XPValueContainer} aria-hidden={true}>
                      <div className={classes.XPValue}>{score}</div>
                      <div className={classes.XPText}>{t('common_xp')}</div>
                    </Box>
                  </div>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mb={8} className={classes.PersonalDataContainer} id="personal-data">
          <Box mb={19} className={classes.SectionTitleContainer}>
            <h2 className="Title" tabIndex={0}>
              {t('profile_personal_data')}
            </h2>
            <div className="TitleBorder" />
          </Box>
          <ProfileDataForm
            submit={this.submitProfileData}
            groups={groups}
            groupSearch={groupSearch}
            settlements={settlements}
            getSettlements={getSettlements}
            name={name}
            group={group ? group : ''}
            isKkv={group.type === GroupTypes.KKV}
            settlement={settlement ? settlement.name : ''}
            email={email}
            imageUrl={avatarFull}
            isDataPublic={isDataPublic}
            fromSocial={fromSocial}
          />
        </Box>
        <Box mb={3} className={classes.SectionTitleContainer}>
          <Box className="Title" tabIndex={0}>
            {t('profile_interests')}
          </Box>
          <div className="TitleBorder" />
        </Box>
        <Box mb={5} tabIndex={0}>
          {t('profile_interests_description')}
        </Box>
        <Box mb={12} className={classes.ProfileTagsBox}>
          <ProfileTags tags={profileTags} checkedTags={tags.map((tag) => tag.id)} setTags={this.setUserTags} />
        </Box>
        <Box mb={8} className={classes.MobileProfileTagsBox}>
          <MobileProfileTags tags={profileTags} checkedTags={tags.map((tag) => tag.id)} setTags={this.setUserTags} />
        </Box>
        <Box mb={31} className={classes.ChangePasswordContainer}>
          <PasswordChangeForm hasPassword={hasPassword} submit={this.submitNewPassword} />
        </Box>
        <Box mb={10} className={classes.DeleteProfileContainer}>
          <Box
            className={classes.DeleteActionContainer}
            onClick={this.openDeleteProfileDialog}
            tabIndex={0}
            role="button"
            aria-label={t('profile_delete_profile')}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.openDeleteProfileDialog();
              }
            }}
          >
            <Box mr={1} className={classes.DeleteProfileText} aria-hidden={true}>
              {t('profile_delete_profile')}
            </Box>
            <DeleteIcon className={classes.DeleteProfileIcon} aria-hidden={true} />
          </Box>
        </Box>
        <Box mb={8} className={classes.MobilePatchContainer}>
          <img src={LeftPatch} className={classNames('Patch', 'LeftPatch')} aria-hidden={true} alt="" />
          <img src={RightPatch} className={classNames('Patch', 'RightPatch')} aria-hidden={true} alt="" />
        </Box>
        <DeleteProfileDialog
          closeDialog={this.closeDialog}
          submit={this.deleteProfile}
          open={this.state.openDialog === this.Dialogs.DeleteProfile}
        />
        <FirstLoginDialog
          closeDialog={this.closeDialog}
          submit={this.closeDialog}
          open={this.state.openDialog === this.Dialogs.FirstLogin}
        />
      </div>
    );
  }
}

export default hoistStatics(withTranslation()(withStyles(profilePageStyles)(ProfilePage)), ProfilePage);
