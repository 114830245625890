import React, { Component, createRef } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { FacebookShareButton, LinkedinShareButton } from 'react-share';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import hoistStatics from 'hoist-non-react-statics';
import Cookies from 'js-cookie';
import classNames from 'classnames';

import { Pagination } from 'components';
import { certificatesPageStyles } from './styles';
import { getCertificates } from 'store/certificates/actions';
import { getProfileDetails } from 'store/profile/actions';
import { Certificate, ClassStyle, History, ProfileDetails } from 'types/types';
import { getUrl } from 'utils/urlFromImagePath';
import { titleFocus } from 'utils/titleFocus';
import { downloadIcon, facebookShareIcon, linkedinShareIcon } from 'utils/icons';
import { isSpaceOrEnterEvent } from 'utils/eventUtils';
import { getCurrentLanguage } from 'utils/language';
import { downloadFile } from 'utils/downloadFile';
import { NotificationTypes } from 'constants/Constants';
import Settings from 'env';

import CertificatePlaceholder from 'assets/images/certificate-placeholder.png';

import theme from 'theme';

const CERTIFICATE_REFETCH_INTERVAL = 15000;

class CertificatesPage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      CertificatesPageContainer: ClassStyle,
      CertificateImageBox: ClassStyle,
      CertificateShownImageBox: ClassStyle,
      CertificateImage: ClassStyle,
      ModuleTitle: ClassStyle,
      CertificateContainer: ClassStyle,
      CertificateFooter: ClassStyle,
      DownloadBox: ClassStyle,
    }),
    history: History,
    t: PropTypes.func.isRequired,
    addCommonLoader: PropTypes.func,
    certificates: PropTypes.shape({
      count: PropTypes.number,
      data: PropTypes.arrayOf(Certificate),
    }),
    profileDetails: ProfileDetails,
    getCertificates: PropTypes.func,
    windowWidth: PropTypes.number,
    isLoggedIn: PropTypes.bool,
    showNotification: PropTypes.func.isRequired,
  };

  static pageTitleKey = 'profile_certificates';

  certificatesRef = createRef();

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      origin: '',
    };

    this.timer = null;
    this.props.addCommonLoader();
  }

  static async getInitialData({ dispatch, language, token }) {
    try {
      if (token) {
        await Promise.all([
          dispatch(getCertificates({ language, page: 1, token })),
          dispatch(getProfileDetails({ language, token })),
        ]);
      }
      titleFocus(dispatch);
    } catch (err) {
      console.error(err);
    }
  }

  paginate = async (to) => {
    const { getCertificates, windowWidth } = this.props;
    try {
      this.setState({ page: to });
      await getCertificates({ page: to });
      const yOffset = -60;
      let y = this.certificatesRef.current.getBoundingClientRect().top + window.pageYOffset;
      if (windowWidth < theme.breakpoints.values.md) {
        y += yOffset;
      }
      window.scrollTo({ top: y, behavior: 'smooth' });
    } catch (err) {
      console.error(err);
    }
  };

  getMissingCertificate = () => {
    const { certificates, history, getCertificates } = this.props;
    const isMissingCertificate = certificates.data?.map((cert) => !!cert.certificateThumb).includes(false);
    const language = getCurrentLanguage(history.location.pathname);
    const token = Cookies.get(Settings.API_TOKEN);

    if (isMissingCertificate) {
      getCertificates({ language, page: 1, token });
      this.timer = setTimeout(() => this.getMissingCertificate(), CERTIFICATE_REFETCH_INTERVAL);
    }
  };

  componentDidMount() {
    this.setState({ origin: window.location.origin });
    setTimeout(() => this.getMissingCertificate(), CERTIFICATE_REFETCH_INTERVAL);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  certificateDownload = (id) => {
    const token = Cookies.get(Settings.API_TOKEN);
    const linkSource = `${Settings.STORAGE_BASE_URL}/certificates/${id}/download?token=${token}`;

    downloadFile(linkSource);
  };

  failedShareNotification = (certificate) => {
    const { showNotification } = this.props;
    return new Promise((resolve) => {
      if (certificate.certificate) {
        resolve();
      } else {
        showNotification({
          translateKey: 'certificate_share_failed',
          type: NotificationTypes.warning,
        });
      }
    }).catch((error) => error);
  };

  render() {
    const { classes, certificates, history, t, profileDetails, isLoggedIn } = this.props;
    const { page, origin } = this.state;

    if (!profileDetails.isShowCertificates && isLoggedIn) {
      return <Redirect to={`/${getCurrentLanguage(history.location.pathname)}/404`} />;
    }

    return (
      <div className={classes.CertificatesPageContainer}>
        <Box tabIndex={certificates?.count ? -1 : 0}>
          {certificates?.count ? (
            <>
              <Grid ref={this.certificatesRef} justify="center" spacing={2} container>
                {certificates?.data?.map((certificate) => {
                  return (
                    <Grid className={classes.CertificateContainer} item xs={12} sm={6} md={4} key={certificate.id}>
                      <Box
                        className={classNames(classes.CertificateImageBox, {
                          [classes.CertificateShownImageBox]: certificate.certificate,
                        })}
                        onClick={() => {
                          this.certificateDownload(certificate.id);
                        }}
                      >
                        <img
                          className={classes.CertificateImage}
                          src={certificate.certificate ? getUrl(certificate.certificate) : CertificatePlaceholder}
                          tabIndex={0}
                          alt={
                            certificate.certificate && certificate.certificateAlt
                              ? certificate.certificateAlt
                              : t('alt_certificate_image')
                          }
                        />
                      </Box>
                      <Box className={classes.ModuleTitle}>{certificate.moduleTitle}</Box>
                      <Box className={classes.CertificateFooter}>
                        <Box
                          role="button"
                          onClick={() => {
                            this.certificateDownload(certificate.id);
                          }}
                          onKeyPress={(e) => {
                            if (isSpaceOrEnterEvent(e)) {
                              e.preventDefault();
                              this.certificateDownload(certificate.id);
                            }
                          }}
                          tabIndex={0}
                          className={classes.DownloadBox}
                          aria-label={t('certificate_download', { topic: certificate.moduleTitle })}
                        >
                          {downloadIcon()}
                        </Box>
                        <Box display="flex">
                          <Box>
                            <FacebookShareButton
                              aria-label={t('certificate_facebook', { topic: certificate.moduleTitle })}
                              url={`${origin}${history.location.pathname}/${certificate.uuid}`}
                              beforeOnClick={() => this.failedShareNotification(certificate)}
                            >
                              {facebookShareIcon()}
                            </FacebookShareButton>
                          </Box>
                          <Box ml={1}>
                            <LinkedinShareButton
                              aria-label={t('certificate_linkedin', { topic: certificate.moduleTitle })}
                              url={`${origin}${history.location.pathname}/${certificate.uuid}`}
                              title={t('certificate_title', { topic: certificate.moduleTitle })}
                              beforeOnClick={() => this.failedShareNotification(certificate)}
                            >
                              {linkedinShareIcon()}
                            </LinkedinShareButton>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
              <Pagination
                itemCount={certificates.count}
                itemPerPage={Settings.CERTIFICATES_PER_PAGE}
                currentPage={page}
                setCurrentPage={this.paginate}
              />
            </>
          ) : (
            t('certificates_none')
          )}
        </Box>
      </div>
    );
  }
}

export default hoistStatics(withTranslation()(withStyles(certificatesPageStyles)(CertificatesPage)), CertificatesPage);
