import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { ProfileTagsDialog } from './ProfileTagsDialog';
import { ProfileTagsDialogContent } from './ProfileTagsDialogContent';
import Button from 'components/Button/Button';
import { isBrowser } from 'utils/browser';
import { useTagsDialogStyles } from './styles';

export const ProfileTags = ({ tags, checkedTags, setTags }) => {
  const classes = useTagsDialogStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const tagAnchorRef = React.useRef(null);
  const addTagButtonRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((open) => !open);
  };

  const handleClose = () => {
    setOpen(false);
    addTagButtonRef.current.focus();
  };

  const handleResize = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (isBrowser()) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  return (
    <div className={classes.TagsDialogContainer}>
      <Box className={classes.AddTagContainer} ref={tagAnchorRef}>
        <Box className={classes.AddTagLabel}>{t('profile_interests')}</Box>
        <Button
          buttonClassName={classNames(classes.AddTagButton, 'Pink')}
          onClick={handleToggle}
          aria-label={t('alt_add_tag')}
          buttonRef={addTagButtonRef}
          role="button"
        >
          <AddIcon />
        </Button>
      </Box>
      <Box mt={2} className={classes.ProfileTagsContainer}>
        {tags
          .filter((tag) => checkedTags.includes(tag.id))
          .map((tag, index) => (
            <Box mr={2.5} mb={1.5} className={classes.ProfileTag} key={index}>
              <Box tabIndex={0} aria-label={tag.title + ' ' + t('alt_tag')}>
                <div aria-hidden={true}>{tag.title}</div>
              </Box>
              <IconButton
                className={classes.ClearTag}
                onClick={() => setTags(tag.id)}
                tabIndex={0}
                aria-label={tag.title + ' ' + t('alt_remove_tag')}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ))}
      </Box>
      <ProfileTagsDialog
        menuId="profileTags"
        anchorRef={tagAnchorRef}
        content={<ProfileTagsDialogContent tags={tags} checkedTags={checkedTags} setTags={setTags} />}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
};

ProfileTags.propTypes = {
  tags: PropTypes.array,
  checkedTags: PropTypes.array,
  setTags: PropTypes.func,
};
