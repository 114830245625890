import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { center, flex } from 'constants/Jss';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => {
  return {
    Separator: {
      alignItems: center,
      display: flex,
      justifyContent: center,
      height: 50,

      '&::before, &::after': {
        content: 'close-quote',
        flex: '1 1',
        borderBottom: `1px solid ${theme.palette.grey70}`,
        opacity: 0.5,
      },
      '&::before': {
        marginLeft: -13,
        marginRight: 27,
      },
      '&::after': {
        marginLeft: 27,
        marginRight: -13,
      },
    },
  };
});

export const Separator = ({ ...props }) => <Box className={useStyles().Separator} {...props} />;
