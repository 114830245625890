import { useLayoutEffect } from 'react';
import throttle from 'lodash/throttle';
import { isBrowser } from 'utils/browser';

function useScrollListener(effect, wait = 250) {
  const handleScroll = throttle(effect, wait);

  useLayoutEffect(() => {
    if (isBrowser()) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (isBrowser()) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);
}

export default useScrollListener;
