import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { useSearchFieldStyles } from '../../styles';
import { IconButtonBase } from './IconButtonBase';
import { voiceIcon } from 'utils/icons';

export const VoiceButton = memo((props) => {
  const { Button, VoiceIcon } = useSearchFieldStyles();
  return <IconButtonBase iconFactory={voiceIcon} buttonClass={Button} iconClass={VoiceIcon} {...props} />;
});

VoiceButton.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
