import React from 'react';
import PropTypes from 'prop-types';
// import { connect } from 'react-redux';

import { Roles } from 'constants/Constants';

const AccessController = ({ authorizedRoles, role = Roles.User, children, rootComponent: Component, ...props }) => {
  // TODO remove the first part of the condition when the role is injected
  if (role && authorizedRoles && !authorizedRoles.includes(role)) {
    return null;
  }
  if (Component) {
    return <Component {...props} />;
  }
  return children;
};

// TODO inject role from  redux state
export const filterRoutes = ({ routes, role }) =>
  routes.filter((route) => !route.authorizedRoles || route.authorizedRoles.includes(role));

AccessController.propTypes = {
  authorizedRoles: PropTypes.arrayOf(PropTypes.string),
  role: PropTypes.string,
  rootComponent: PropTypes.func,
  children: PropTypes.node,
};

export default AccessController;

// RECOMMENDED TODO: replace export with the code below and correct the reference to the role in the redux state
// const mapStateToProps = (state) => {
//   return {
//     role: state.authReducer.data ? state.authReducer.data.role : '',
//   };
// };
//
// export default connect(mapStateToProps)(AccessController);
