import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';

import { useFiltersStyles } from './styles';
import { FilterDialog } from './FilterDialog';
import { TagFilter } from './TagFilter';
import { FiltersType, Tag } from 'types/types';
import { DurationFilter } from './DurationFilter';
import { arrowSvg, tagSvg } from 'utils/icons';
import { ExamsFilter } from './ExamsFilter';
import { ItemTypes } from 'constants/Constants';
import { selectIsLoggedIn } from 'store/auth/selectors';
import { FilterSearchField } from 'components/SearchFields';

export const Filters = ({
  search,
  tags,
  filters,
  setFilters,
  filterClass,
  filterBoxClass,
  profileTags,
  itemType,
  setCloseExamFilter,
}) => {
  const classes = useFiltersStyles();
  const { t } = useTranslation();
  const [openFilter, setOpenFilter] = React.useState(null);
  const [filterValues, setFilterValues] = React.useState(filters);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  setCloseExamFilter && setCloseExamFilter(() => setOpenFilter(null));

  const tagFilter = useMemo(() => t('filter_tag'), [t]);
  const durationFilter = useMemo(() => t('filter_duration'), [t]);
  const examsFilter = useMemo(() => t('filter_exams'), [t]);

  const tagRef = React.useRef(null);
  const durationRef = React.useRef(null);
  const examsRef = React.useRef(null);

  const filterAnchorRef = useMemo(() => {
    switch (openFilter) {
      case tagFilter:
        return tagRef;
      case durationFilter:
        return durationRef;
      case examsFilter:
        return examsRef;
      default:
        return undefined;
    }
  }, [openFilter, tagFilter, durationFilter, examsFilter]);

  const handleFilterToggle = (label) => {
    setOpenFilter((prevOpenFilter) => (prevOpenFilter === label ? null : label));
  };

  const handleFilterClose = (event) => {
    if (filterAnchorRef && filterAnchorRef.current && filterAnchorRef.current.contains(event.target)) {
      return;
    }
    setOpenFilter(null);
    setFilterValues(filters);
  };

  const changeFilterValues = (filter) => {
    const nextFilter = { ...filterValues, ...filter };
    setFilterValues({ ...filterValues, ...filter });
    return nextFilter;
  };

  const triggerFilter = (filter = filterValues) => {
    setFilters(filter);
  };

  const changeFilterValuesAndTrigger = (filter) => {
    const nextFilter = changeFilterValues(filter);
    triggerFilter(nextFilter);
  };

  const onFilterButtonClick = () => {
    triggerFilter();
    setOpenFilter(null);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpenFilter = React.useRef(openFilter);
  React.useEffect(() => {
    if (!!prevOpenFilter.current && !openFilter) {
      filterAnchorRef && filterAnchorRef.current && filterAnchorRef.current.focus();
    }

    prevOpenFilter.current = openFilter;
  }, [openFilter, filterAnchorRef]);

  useEffect(() => {
    setFilterValues(filters);
  }, [filters]);

  const onSearchSubmit = useCallback((searchString) => search({ searchString }), [search]);

  return (
    <Box className={classNames(classes.Filters, filterClass)}>
      {!!search && (
        <FilterSearchField
          ariaLabel={t('common_search_placeholder')}
          placeholder={t('common_search_placeholder')}
          submit={onSearchSubmit}
          searchOnEmpty={true}
        />
      )}
      <Box className={classNames(classes.FilterBoxContainer, filterBoxClass, 'FilterBoxContainer')}>
        <Box ml={4} mr={2}>
          <FilterDialog
            menuId={tagFilter}
            anchorRef={tagRef}
            icon={tagSvg(classes.TagIconA, classes.TagIconB, classes.TagIconC)}
            label={tagFilter}
            content={
              <TagFilter
                tags={tags}
                filter={filterValues.tags}
                setFilters={changeFilterValues}
                profileTags={profileTags}
                itemType={itemType}
              />
            }
            open={openFilter === tagFilter}
            handleToggle={handleFilterToggle}
            handleClose={handleFilterClose}
            onFilterButtonClick={onFilterButtonClick}
            activeFilters={filters.tags.length}
            tKey="tag"
            itemType={itemType}
          />
        </Box>
        <Box ml={2} mr={isLoggedIn && itemType === ItemTypes.Course ? 2 : 4}>
          <FilterDialog
            menuId={durationFilter}
            anchorRef={durationRef}
            icon={arrowSvg('white')}
            label={durationFilter}
            content={<DurationFilter filter={filterValues.duration} setFilters={changeFilterValuesAndTrigger} />}
            open={openFilter === durationFilter}
            handleToggle={handleFilterToggle}
            handleClose={handleFilterClose}
            activeFilters={filterValues.duration ? 1 : 0}
            small
            tKey="duration"
          />
        </Box>
        {!isLoggedIn || itemType !== ItemTypes.Course ? null : (
          <Box ml={2} mr={4}>
            <FilterDialog
              menuId={examsFilter}
              anchorRef={examsRef}
              icon={arrowSvg('white')}
              label={examsFilter}
              content={<ExamsFilter filter={filterValues.examDone} setFilters={changeFilterValuesAndTrigger} />}
              open={openFilter === examsFilter}
              handleToggle={handleFilterToggle}
              handleClose={handleFilterClose}
              activeFilters={filterValues.examDone !== null ? 1 : 0}
              small
              tKey="exams"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

Filters.propTypes = {
  search: PropTypes.func,
  tags: PropTypes.arrayOf(Tag),
  filters: FiltersType,
  setFilters: PropTypes.func,
  filterClass: PropTypes.any,
  filterBoxClass: PropTypes.string,
  profileTags: PropTypes.arrayOf(Tag),
  itemType: PropTypes.oneOf([ItemTypes.Course, ItemTypes.Lesson]).isRequired,
  setCloseExamFilter: PropTypes.func,
};
