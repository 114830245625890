import development from './development';
import staging from './staging';
import uat from './uat';
import test from './test';
import production from './production';
import general from './general';

const ENV = process.env.RAZZLE_ENV || 'development';

const ENV_CONSTANTS = {
  development,
  staging,
  uat,
  production,
  test,
};

export default {
  ...general,
  ...ENV_CONSTANTS[ENV],
};
