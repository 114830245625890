import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useMobileFiltersStyles, useFilterStyles } from './styles';
import { auto } from 'constants/Jss';
import { MobileFilterDialog } from './MobileFilterDialog';
import { getNumberOfActiveFilters } from 'utils/filters';
import { FiltersType, Tag } from 'types/types';
import { ItemTypes } from 'constants/Constants';
import { Skeleton } from 'components';
import { MobileFilterSearchField } from 'components/SearchFields';

export const MobileFilter = ({
  search,
  tags,
  filters,
  setFilters,
  numberOfItems,
  alignVertical,
  profileTags,
  itemType,
  isDataLoading,
}) => {
  const classes = useMobileFiltersStyles();
  const filterIconClasses = useFilterStyles();
  const { t } = useTranslation();
  const [openFilter, setOpenFilter] = React.useState(false);

  const onSearchSubmit = useCallback((searchString) => search({ searchString }), [search]);

  return (
    <Box className={classes.MobileFilters}>
      {!!search && (
        <Box mb={4}>
          <MobileFilterSearchField
            ariaLabel={t('common_search_placeholder')}
            placeholder={t('common_search_placeholder')}
            submit={onSearchSubmit}
            searchOnEmpty={true}
          />
        </Box>
      )}
      <Box mr={1} mb={2} className={classNames(classes.FilterContainer, { Vertical: alignVertical })}>
        <Box className={classNames(classes.MobileNumberOfCourses, { Vertical: alignVertical })} tabIndex={0}>
          {!isDataLoading ? (
            <span>
              {numberOfItems} {itemType === ItemTypes.Course ? t('common_course') : t('course_details_lesson')}
            </span>
          ) : (
            <Skeleton height={20} width={60} />
          )}
        </Box>
        <Box
          className={classes.MobileFilterIcon}
          onClick={() => setOpenFilter(true)}
          role="button"
          tabIndex={0}
          aria-label={
            getNumberOfActiveFilters(filters) > 0
              ? t('alt_open_filter_dialog_with_active_filters', { numberOfFilters: getNumberOfActiveFilters(filters) })
              : t('alt_open_filter_dialog')
          }
          aria-haspopup={true}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setOpenFilter(true);
            }
          }}
        >
          {getNumberOfActiveFilters(filters) > 0 ? (
            <div className={classNames(filterIconClasses.ActiveFiltersBox, 'Mobile')}>
              <Box className={classNames(filterIconClasses.ActiveFilters, 'Mobile')}>
                {getNumberOfActiveFilters(filters)}
              </Box>
            </div>
          ) : null}
          <Box mb={0.375} ml={auto} mr={auto} className={classNames(classes.FilterStrip, classes.TopStrip)} />
          <Box mb={0.375} ml={auto} mr={auto} className={classNames(classes.FilterStrip, classes.MiddleStrip)} />
          <Box ml={auto} mr={auto} className={classNames(classes.FilterStrip, classes.BottomStrip)} />
        </Box>
      </Box>
      <MobileFilterDialog
        open={openFilter}
        closeDialog={() => setOpenFilter(false)}
        tags={tags}
        filters={filters}
        setFilters={setFilters}
        profileTags={profileTags}
        itemType={itemType}
      />
    </Box>
  );
};

MobileFilter.propTypes = {
  search: PropTypes.func,
  tags: PropTypes.arrayOf(Tag),
  filters: FiltersType,
  setFilters: PropTypes.func,
  filterBoxClass: PropTypes.string,
  profileTags: PropTypes.arrayOf(Tag),
  numberOfItems: PropTypes.number,
  isDataLoading: PropTypes.bool,
  alignVertical: PropTypes.bool,
  itemType: PropTypes.oneOf([ItemTypes.Course, ItemTypes.Lesson]).isRequired,
};
