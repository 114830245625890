import request from 'utils/request';
import { Methods } from 'utils/http';
import { getAuthorizedRoutesForExport as getAuthorizedRoutes, store } from 'client';
import { logout } from 'store/profile/actions';

const BASE_URL = '/users';

export const getProfileDetails = async (language, token) => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/me`,
    language,
  };
  if (token) {
    requestData.headers = { Authorization: token };
  }
  const { data } = await request(requestData);
  if (data) {
    data.isDataPublic = Boolean(data.isDataPublic); // TODO remove this convert when BE updated
    data.group.public = Boolean(data.group.public); // TODO remove this convert when BE updated
    return data;
  }
  store.dispatch(logout(getAuthorizedRoutes));
};

export const updateProfileDetails = async ({
  name,
  groupId,
  groupName,
  groupType,
  email,
  avatar,
  settlementId,
  tags,
  isDataPublic,
  taxNumber,
}) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}`,
    data: {
      name,
      groupId,
      groupName,
      groupType,
      email,
      avatar,
      settlementId,
      tags,
      isDataPublic,
      taxNumber,
    },
  });
};

export const changePassword = async ({ oldPassword, newPassword }) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/change-password`,
    data: {
      oldPassword,
      newPassword,
    },
  });
};

export const refreshToken = async (token) => {
  return await request({
    method: Methods.POST,
    resource: '/auth/login/refresh',
    data: {
      token,
      isStayLoggedIn: store.getState().auth.isStayLoggedIn,
    },
    skipReset: true,
  });
};

export const getSettlements = async (searchText) => {
  return await request({
    method: Methods.GET,
    resource: `/settlement/search/${searchText}`,
  });
};

export const deleteProfile = async () => {
  return await request({
    method: Methods.DELETE,
    resource: BASE_URL,
  });
};

export const getBadges = async (language, token) => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/user-badges`,
    language,
  };
  if (token) {
    requestData.headers = { Authorization: token };
  }
  return await request(requestData);
};
