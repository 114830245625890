import * as TagService from 'services/tag';
import {
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILED,
  GET_PROFILE_TAGS_FAILED,
  GET_PROFILE_TAGS,
  GET_PROFILE_TAGS_SUCCESS,
} from './types';

export const getTags = (language) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_TAGS });

      const tags = await TagService.getTags(language);

      dispatch({
        type: GET_TAGS_SUCCESS,
        payload: tags,
      });

      return tags;
    } catch (error) {
      dispatch({ type: GET_TAGS_FAILED });
      throw error;
    }
  };
};

export const getProfileTags = ({ language, token }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PROFILE_TAGS });

      const tags = await TagService.getProfileTags(language, token);

      dispatch({
        type: GET_PROFILE_TAGS_SUCCESS,
        payload: tags,
      });

      return tags;
    } catch (error) {
      dispatch({ type: GET_PROFILE_TAGS_FAILED });
      throw error;
    }
  };
};
