import * as ProfileService from 'services/profile';
import * as AuthService from 'services/auth';
import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  DELETE_PROFILE,
  DELETE_PROFILE_FAILED,
  DELETE_PROFILE_SUCCESS,
  GET_BADGES,
  GET_BADGES_FAILED,
  GET_BADGES_SUCCESS,
  GET_PROFILE_DETAILS,
  GET_PROFILE_DETAILS_FAILED,
  GET_PROFILE_DETAILS_SUCCESS,
  GET_SETTLEMENTS,
  GET_SETTLEMENTS_FAILED,
  GET_SETTLEMENTS_SUCCESS,
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_PROFILE_DATA,
  UPDATE_PROFILE_DETAILS,
  UPDATE_PROFILE_DETAILS_FAILED,
  UPDATE_PROFILE_DETAILS_SUCCESS,
} from './types';
import { history } from 'store/store';
import { getDashboard } from 'store/dashboard/actions';
import { showNotification } from 'store/common/actions';
import { setTokenExpiration } from 'store/auth/actions';
import { removeToken, setToken } from 'utils/refreshToken';
import { NotificationTypes } from 'constants/Constants';
import { getCurrentLanguage } from 'utils/language';

export const setProfileData = ({ id, name, email, avatar, tags, firstLoginDate, isDataPublic }) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PROFILE_DATA,
      payload: {
        id,
        name,
        email,
        avatar,
        tags,
        firstLoginDate,
        isDataPublic,
      },
    });
  };
};

export const logout = (getAuthorizedRoutes) => {
  return async (dispatch) => {
    AuthService.logout();

    removeToken();

    const language = getCurrentLanguage(history?.location.pathname);

    if (`/${language}` === history?.location.pathname) {
      dispatch(getDashboard(language));
    }

    getAuthorizedRoutes?.();

    history?.push(`/${language}`);

    dispatch(showNotification({ translateKey: 'logout_success', type: NotificationTypes.success }));

    await dispatch({
      type: LOGOUT,
    });
  };
};

export const refreshToken = (token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN });

      const { access_token, expires_in } = await ProfileService.refreshToken(token);

      setToken({ token: access_token, expiresAt: expires_in });

      dispatch(setTokenExpiration(parseInt(expires_in)));

      dispatch({
        type: LOGIN_SUCCESS,
      });
    } catch (error) {
      removeToken();
      if (window) {
        window.location.href = window.location.origin + '/' + getCurrentLanguage(window.location.pathname);
      }
      dispatch({ type: LOGIN_FAILED });
      throw error;
    }
  };
};

export const getProfileDetails = ({ language, token }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PROFILE_DETAILS });

      const data = await ProfileService.getProfileDetails(language, token);
      dispatch({
        type: GET_PROFILE_DETAILS_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({ type: GET_PROFILE_DETAILS_FAILED });
      throw error;
    }
  };
};

export const updateProfileDetails = ({
  name,
  groupId,
  groupName,
  groupType,
  email,
  avatar,
  settlementId,
  tags,
  isDataPublic,
  taxNumber,
}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PROFILE_DETAILS });

      const data = await ProfileService.updateProfileDetails({
        name,
        groupId,
        groupName,
        groupType,
        email,
        avatar,
        settlementId,
        tags,
        isDataPublic,
        taxNumber,
      });

      dispatch({
        type: UPDATE_PROFILE_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({ type: UPDATE_PROFILE_DETAILS_FAILED });
      throw error;
    }
  };
};

export const changePassword = (oldPassword, newPassword) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CHANGE_PASSWORD });

      const { success } = await ProfileService.changePassword({ oldPassword, newPassword });

      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
      });

      return success;
    } catch (error) {
      dispatch({ type: CHANGE_PASSWORD_FAILED });
      throw error;
    }
  };
};

export const getSettlements = (searchText) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SETTLEMENTS });

      const data = await ProfileService.getSettlements(searchText);

      dispatch({
        type: GET_SETTLEMENTS_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({ type: GET_SETTLEMENTS_FAILED });
      throw error;
    }
  };
};

export const deleteProfile = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_PROFILE });

      await ProfileService.deleteProfile();

      dispatch({
        type: DELETE_PROFILE_SUCCESS,
      });

      return true;
    } catch (error) {
      dispatch({ type: DELETE_PROFILE_FAILED });
      throw error;
    }
  };
};

export const getBadges = ({ language, token }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_BADGES });

      const { data } = await ProfileService.getBadges(language, token);
      dispatch({
        type: GET_BADGES_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({ type: GET_BADGES_FAILED });
      throw error;
    }
  };
};
