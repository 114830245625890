import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { TopListTypes } from 'constants/Constants';
import { TopListCard } from './TopListCard';
import { useOutOfPodiumStyles } from './styles';

export const OutOfPodium = ({ entities, activeTab, myId, myCardRef, isMyCardSelected }) => {
  const classes = useOutOfPodiumStyles();

  const isInTop = (entity) => entity.rank <= 3;

  return (
    <Box className={classes.OutOfPodiumContainer} mb={6}>
      {entities.map((entity, index) => (
        <Box mt={activeTab === TopListTypes.User ? 12 : 9} key={index}>
          <TopListCard
            user={activeTab === TopListTypes.User ? entity : null}
            group={activeTab === TopListTypes.User ? null : entity}
            activeTab={activeTab}
            myId={myId}
            myCardRef={myCardRef}
            isMyCardSelected={isMyCardSelected}
            podium={isInTop(entity)}
          />
        </Box>
      ))}
    </Box>
  );
};

OutOfPodium.propTypes = {
  entities: PropTypes.array,
  activeTab: PropTypes.oneOf([TopListTypes.Group, TopListTypes.User]),
  myId: PropTypes.any,
  myCardRef: PropTypes.any,
  isMyCardSelected: PropTypes.bool,
};
