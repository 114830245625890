import React from 'react';
import PropTypes from 'prop-types';

import FormHelperText from '@material-ui/core/FormHelperText';

import get from 'lodash/get';

import { Field, Form } from '../../types/types';

const ErrorMessage = ({ variant, form, field, className }) => {
  const error = get(form.errors, field.name) || '';
  const touched = get(form.touched, field.name);

  return touched && error ? (
    <FormHelperText id={`${field.name}-error`} error variant={variant} className={className}>
      {error}
    </FormHelperText>
  ) : null;
};

ErrorMessage.propTypes = {
  variant: PropTypes.string,
  form: Form,
  field: Field,
  className: PropTypes.string,
};

export default ErrorMessage;
