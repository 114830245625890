import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import AcceptTermsField from 'components/MandatoryLinks/AcceptTermsField';
import { Button, Dialog, OverallErrorMessages } from 'components';
import { auto } from 'constants/Jss.js';
import { useLoginDialogStyles, usePatches } from './styles';
import { Regex } from 'constants/Constants';
import { Document } from 'types/types';

const ResetPasswordDialog = ({
  open,
  closeDialog,
  submit,
  t,
  isNewUser,
  token,
  termsAndConditions,
  privacyPolicy,
  showNotification,
}) => {
  const schema = {
    password: Yup.string()
      .required(t('form_required'))
      .matches(Regex.Password, t('form_password_strength')),
    passwordConfirmation: Yup.string()
      .required(t('form_required'))
      .oneOf([Yup.ref('password'), null], t('form_passwords_must_match')),
  };

  if (isNewUser) {
    schema.acceptTerms = Yup.mixed().oneOf([true], t('form_must_accept'));
  }

  const classes = useLoginDialogStyles();
  const patchClasses = usePatches();

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      title={t('form_title_reset_password')}
      titleBoxClass={patchClasses.YellowPatch}
      content={
        <Formik
          initialValues={{ password: '', passwordConfirmation: '', acceptTerms: false }}
          validationSchema={Yup.object().shape(schema)}
          onSubmit={(values) => submit({ password: values.password, token, isNewUser })}
        >
          {({ values, errors, touched, handleChange, handleSubmit, setFieldTouched }) => (
            <form onSubmit={() => {}}>
              <OverallErrorMessages />
              <Box className={classes.FormBox} ml={auto} mr={auto}>
                <Box mb={3}>
                  <TextField
                    id="password"
                    name="password"
                    type="password"
                    label={t('form_password')}
                    variant="outlined"
                    autoComplete="new-password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched('password', true)}
                    error={!!errors.password && touched.password}
                    helperText={touched.password ? errors.password : ''}
                    fullWidth
                    inputProps={{ 'aria-label': t('form_password') }}
                  />
                </Box>
                <Box mb={3}>
                  <TextField
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    type="password"
                    label={t('form_confirm_password')}
                    variant="outlined"
                    autoComplete="new-password"
                    value={values.passwordConfirmation}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched('passwordConfirmation', true)}
                    error={!!errors.passwordConfirmation && touched.passwordConfirmation}
                    helperText={touched.passwordConfirmation ? errors.passwordConfirmation : ''}
                    fullWidth
                    inputProps={{ 'aria-label': t('form_confirm_password') }}
                  />
                </Box>
                {isNewUser ? (
                  <Box mb={3}>
                    <AcceptTermsField
                      name="acceptTerms"
                      value={values.acceptTerms}
                      onChange={handleChange}
                      setFieldTouched={setFieldTouched}
                      errors={errors}
                      touched={touched}
                      showNotification={showNotification}
                      termsAndConditions={termsAndConditions}
                      privacyPolicy={privacyPolicy}
                    />
                  </Box>
                ) : null}
                <Box mb={4} className={classes.ResetPasswordButtonContainer}>
                  <Button buttonClassName="Pink" onClick={handleSubmit} type="submit" aria-label={t('common_post')}>
                    {t('common_post')}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      }
    />
  );
};

ResetPasswordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  isNewUser: PropTypes.bool,
  termsAndConditions: Document,
  privacyPolicy: Document,
  showNotification: PropTypes.func.isRequired,
};

export default withTranslation()(ResetPasswordDialog);
