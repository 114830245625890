import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Button, Dialog, Switch, AppSvgIcon } from 'components';
import { FiltersType, Tag } from 'types/types';
import { ItemTypes } from 'constants/Constants';
import { selectIsLoggedIn } from 'store/auth/selectors';

import { useMobileFilterDialogStyles, useTagFilterStyles } from './styles';

import { DURATION_VALUES, getDurationLabel, getDurationAriaLabel } from './DurationFilter';

export const MobileFilterDialog = ({ open, closeDialog, tags, filters, setFilters, profileTags, itemType }) => {
  const { t } = useTranslation();
  const classes = useMobileFilterDialogStyles();
  const tagFilterClasses = useTagFilterStyles();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const [openFilter, setOpenFilter] = React.useState('');
  const [filterValues, setFilterValues] = React.useState(filters);
  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    setFilterValues(filters);
  }, [filters]);

  const handleFilterChange = (filters) => {
    setFilterValues({ ...filterValues, ...filters });
  };

  const onTagsChange = (id) => {
    const checkedTagIds = [...filterValues.tags];
    handleFilterChange({
      tags: checkedTagIds.includes(id) ? checkedTagIds.filter((checkedId) => checkedId !== id) : [...checkedTagIds, id],
    });
    setChecked(false);
  };

  const clearAllTags = () => {
    handleFilterChange({ tags: [] });
    setChecked(false);
  };

  const showMyInterest = () => {
    handleFilterChange({ tags: !checked ? profileTags.map((tag) => tag.id) : [] });
    setChecked(!checked);
  };

  const onFilter = () => {
    setFilters(filterValues);
    closeDialog();
  };

  const onClose = () => {
    setFilterValues(filters);
    closeDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('common_filter')}
      titleClass={classes.Title}
      titleBoxClass={classes.TitleBox}
      contentClass={classes.Content}
      content={
        <div>
          <div>
            <Box className={classes.Filter}>
              <Box
                className={classNames(classes.Header, { Open: openFilter === 'tag' })}
                onClick={() => (openFilter === 'tag' ? setOpenFilter('') : setOpenFilter('tag'))}
              >
                <div
                  className={classes.HeaderTitle}
                  tabIndex={0}
                  role="button"
                  aria-label={t('alt_tag_filter')}
                  aria-haspopup={true}
                >
                  {t('filter_tag')}
                </div>
                <AppSvgIcon
                  className={classNames(classes.ArrowIcon, { Open: openFilter === 'tag' })}
                  viewBox="0 0 24 24"
                >
                  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                </AppSvgIcon>
              </Box>
              <Box className={classNames(classes.Body, { Open: openFilter === 'tag' })}>
                <Box mb={1} className={classes.MobileInterestContainer}>
                  <Box className={classes.ClearBox}>
                    <div
                      className={classes.ActionText}
                      onClick={clearAllTags}
                      tabIndex={openFilter === 'tag' ? 0 : -1}
                      role="button"
                    >
                      {t('topics_page_clear_filter')}
                    </div>
                  </Box>
                  {isLoggedIn && profileTags && profileTags.length ? (
                    <Box className={classes.InterestBox}>
                      <div className={classes.ActionText} onClick={showMyInterest}>
                        {t('topics_page_my_interest')}
                      </div>
                      <Box ml={2}>
                        <Switch
                          mini
                          switchClassName="filterSwitch"
                          checked={checked}
                          onClick={showMyInterest}
                          tabIndex={openFilter === 'tag' ? 0 : -1}
                          aria-label={t('alt_show_my_interest')}
                        />
                      </Box>
                    </Box>
                  ) : null}
                </Box>
                <Box className={classes.MobileTagsContainer}>
                  {tags.map((tag) => (
                    <FormControlLabel
                      key={tag.id}
                      control={
                        <Checkbox
                          className={classNames(tagFilterClasses.Tag, classes.Checkbox, {
                            Checked: filterValues.tags ? filterValues.tags.includes(tag.id) : false,
                          })}
                          checked={filterValues.tags ? filterValues.tags.includes(tag.id) : false}
                          onChange={() => onTagsChange(tag.id)}
                          tabIndex={-1}
                          aria-hidden={true}
                        />
                      }
                      label={tag.title}
                      aria-label={tag.title}
                      role="checkbox"
                      tabIndex={openFilter === 'tag' ? 0 : -1}
                      aria-checked={filterValues.tags ? filterValues.tags.includes(tag.id) : false}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <Box className={classes.Filter}>
              <Box
                className={classNames(classes.Header, { Open: openFilter === 'duration' })}
                onClick={() => (openFilter === 'duration' ? setOpenFilter('') : setOpenFilter('duration'))}
              >
                <div
                  className={classes.HeaderTitle}
                  tabIndex={0}
                  role="button"
                  aria-label={t('alt_duration_filter')}
                  aria-haspopup={true}
                >
                  {t('filter_duration')}
                </div>
                <AppSvgIcon
                  className={classNames(classes.ArrowIcon, { Open: openFilter === 'duration' })}
                  viewBox="0 0 24 24"
                >
                  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                </AppSvgIcon>
              </Box>
              <Box
                className={classNames(classes.Body, {
                  Open: openFilter === 'duration',
                })}
              >
                {Object.keys(DURATION_VALUES).map((key) => (
                  <div key={key} className={classes.MobileRadioFilter}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classNames(classes.Checkbox, {
                            Checked: filterValues.duration === DURATION_VALUES[key],
                          })}
                          checked={isEqual(filterValues.duration, DURATION_VALUES[key])}
                          onChange={() =>
                            handleFilterChange({
                              duration: isEqual(filterValues.duration, DURATION_VALUES[key])
                                ? null
                                : DURATION_VALUES[key],
                            })
                          }
                          tabIndex={-1}
                          aria-hidden={true}
                        />
                      }
                      classes={{ root: classes.MobileFilterLabel }}
                      label={getDurationLabel(key)}
                      aria-label={getDurationAriaLabel(key)}
                      role="checkbox"
                      tabIndex={openFilter === 'duration' ? 0 : -1}
                      aria-checked={isEqual(filterValues.duration, DURATION_VALUES[key])}
                    />
                  </div>
                ))}
              </Box>
            </Box>
            {!isLoggedIn || itemType !== ItemTypes.Course ? null : (
              <Box className={classes.Filter}>
                <Box
                  className={classNames(classes.Header, { Open: openFilter === 'examDone' })}
                  onClick={() => (openFilter === 'examDone' ? setOpenFilter('') : setOpenFilter('examDone'))}
                >
                  <div
                    className={classes.HeaderTitle}
                    tabIndex={0}
                    role="button"
                    aria-label={t('alt_exams_filter')}
                    aria-haspopup={true}
                  >
                    {t('filter_exams')}
                  </div>
                  <AppSvgIcon
                    className={classNames(classes.ArrowIcon, { Open: openFilter === 'examDone' })}
                    viewBox="0 0 24 24"
                  >
                    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                  </AppSvgIcon>
                </Box>
                <Box
                  className={classNames(classes.Body, {
                    Open: openFilter === 'examDone',
                  })}
                >
                  <div className={classes.MobileRadioFilter}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classNames(classes.Checkbox, { Checked: filterValues.examDone === true })}
                          checked={filterValues.examDone === true}
                          onChange={() =>
                            handleFilterChange({ examDone: filterValues.examDone === true ? null : true })
                          }
                          tabIndex={-1}
                          aria-hidden={true}
                        />
                      }
                      label={t('topics_page_exam_done')}
                      aria-label={t('topics_page_exam_done')}
                      role="checkbox"
                      tabIndex={openFilter === 'examDone' ? 0 : -1}
                      aria-checked={filterValues.examDone === true}
                    />
                  </div>
                  <div className={classes.MobileRadioFilter}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classNames(classes.Checkbox, { Checked: filterValues.examDone === false })}
                          checked={filterValues.examDone === false}
                          onChange={() =>
                            handleFilterChange({ examDone: filterValues.examDone === false ? null : false })
                          }
                          tabIndex={-1}
                          aria-hidden={true}
                        />
                      }
                      label={t('topics_page_exam_to_be_done')}
                      aria-label={t('topics_page_exam_to_be_done')}
                      role="checkbox"
                      tabIndex={openFilter === 'examDone' ? 0 : -1}
                      aria-checked={filterValues.examDone === false}
                    />
                  </div>
                </Box>
              </Box>
            )}
          </div>
          <Box mt={3} className={classes.FilterButtonContainer}>
            <Button buttonClassName="Pink" onClick={onFilter}>
              {t('common_filtering')}
            </Button>
          </Box>
        </div>
      }
    />
  );
};

MobileFilterDialog.propTypes = {
  open: PropTypes.bool,
  closeDialog: PropTypes.func,
  tags: PropTypes.arrayOf(Tag),
  filters: FiltersType,
  setFilters: PropTypes.func,
  profileTags: PropTypes.arrayOf(Tag),
  itemType: PropTypes.oneOf([ItemTypes.Course, ItemTypes.Lesson]).isRequired,
};
