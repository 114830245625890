import request from 'utils/request';
import { Methods } from 'utils/http';
import { AuthProviderNames } from 'constants/Constants';

const BASE_URL = '/auth';

export const login = async ({ email, password, isStayLoggedIn, provider, accessToken }) => {
  const isSocial = !!accessToken;
  const path = isSocial ? `social/${AuthProviderNames[provider].toLowerCase()}` : 'email';
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/login/${path}`,
    data: isSocial ? { accessToken, isStayLoggedIn } : { email, password, isStayLoggedIn },
  });
};

export const register = async (registerInfo) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/registration`,
    data: registerInfo,
  });
};

export const verifyEmail = async ({ language, token }) => {
  return await request({
    headers: {
      'Accept-Language': language,
    },
    method: Methods.POST,
    resource: `${BASE_URL}/verify`,
    data: { token },
  });
};

export const forgotPassword = async (email) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/forgotten-password-email`,
    headers: {
      platform: 'web',
    },
    data: {
      email,
    },
  });
};

export const resetPassword = async ({ token, password }) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/password/reset-by-token`,
    data: {
      token,
      password,
    },
  });
};

export const logout = async () => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/logout`,
  });
};
