import { searchPageStyles } from '../SearchPage/styles';

export const coursesPageStyles = (theme) => ({
  ...searchPageStyles(theme),
  CoursesContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(7),
    },
  },
  CardBox: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
    },
  },
});
