import firebase from 'firebase/app';
import 'firebase/auth';

import Settings from 'env';
import { AuthProviderCodes, FirebasePopupClosedCode } from 'constants/Constants';
import { downloadFileAsBlob } from './file';
import { readBlobAsDataURL } from 'utils/readBlobAsDataURL';

export class SocialService {
  static get defaultFirebase() {
    if (!SocialService._defaultFirebase) {
      firebase.initializeApp(Settings.FIREBASE_CONFIG);
      SocialService._defaultFirebase = firebase;
    }
    return SocialService._defaultFirebase;
  }

  static get facebookProvider() {
    if (!SocialService._facebookProvider) {
      SocialService._facebookProvider = new SocialService.defaultFirebase.auth.FacebookAuthProvider();
      SocialService._facebookProvider.addScope('public_profile');
      SocialService._facebookProvider.addScope('email');
    }
    return SocialService._facebookProvider;
  }

  static get googleProvider() {
    if (!SocialService._googleProvider) {
      SocialService._googleProvider = new SocialService.defaultFirebase.auth.GoogleAuthProvider();
      SocialService._googleProvider.setCustomParameters({ prompt: 'select_account' });
      SocialService._googleProvider.addScope('email');
    }
    return SocialService._googleProvider;
  }

  static get appleProvider() {
    if (!SocialService._appleProvider) {
      SocialService._appleProvider = new SocialService.defaultFirebase.auth.OAuthProvider('apple.com');
      SocialService._appleProvider.addScope('email');
      SocialService._appleProvider.addScope('name');
    }
    return SocialService._appleProvider;
  }

  constructor() {
    throw new Error(`${SocialService.prototype.constructor.name} class is static`);
  }

  static getProviderByCode(providerCode) {
    switch (providerCode) {
      case AuthProviderCodes.FACEBOOK:
        return SocialService.facebookProvider;
      case AuthProviderCodes.GOOGLE:
        return SocialService.googleProvider;
      case AuthProviderCodes.APPLE:
        return SocialService.appleProvider;
      default:
        throw new Error(`Invalid provider code: ${providerCode}`);
    }
  }

  static async loginWithProvider(providerCode) {
    try {
      const result = await SocialService.defaultFirebase
        .auth()
        .signInWithPopup(SocialService.getProviderByCode(providerCode));
      const { id, sub, email, name, picture } = result.additionalUserInfo.profile;
      const { accessToken, idToken } = result.credential;
      const { user } = result;
      return {
        email: email || user.email,
        name: name || user.displayName,
        provider: providerCode,
        socialId: (id || sub).toString(),
        avatar: (picture && (picture.data ? picture.data.url : picture)) || null,
        accessToken: providerCode === AuthProviderCodes.APPLE ? idToken : accessToken,
      };
    } catch (error) {
      if (error && error.code === FirebasePopupClosedCode) {
        return;
      }
      throw error;
    }
  }

  static async registerWithProvider(providerCode) {
    const socialToken = await SocialService.loginWithProvider(providerCode);
    if (!socialToken) {
      return;
    }
    let avatar = null;
    if (socialToken.avatar) {
      try {
        const blob = await downloadFileAsBlob(socialToken.avatar);
        avatar = (await readBlobAsDataURL(blob)).split('base64,')[1];
      } catch (error) {
        console.warn(`Failed to download profile picture for registration: ${socialToken.avatar}`, error);
      }
    }
    return { ...socialToken, avatar };
  }

  static async getRegisterToken(providerCode) {
    switch (providerCode) {
      case AuthProviderCodes.EMAIL:
        return {
          email: '',
          name: '',
          provider: providerCode,
          socialId: '',
          avatar: null,
          accessToken: '',
        };
      case AuthProviderCodes.FACEBOOK:
      case AuthProviderCodes.GOOGLE:
      case AuthProviderCodes.APPLE:
        return await SocialService.registerWithProvider(providerCode);
      default:
        throw new Error(`Unknown AuthProviderCode for register method: ${providerCode}`);
    }
  }
}
