export const mapAlternate = (array, fn1, fn2, thisArg) => {
  let fn = fn1;
  const output = [];
  for (let i = 0; i < array.length; i++) {
    output[i] = fn.call(thisArg, array[i], i, array);

    fn = fn === fn1 ? fn2 : fn1;
  }
  return output;
};
