import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

const OverallErrorMessages = () => {
  const { errors, submitCount } = useFormikContext();
  const { t } = useTranslation();

  return (
    !!errors &&
    submitCount > 0 && (
      <Box position="fixed" tabIndex={-1} top={-9999999} role="alert">
        {Object.keys(errors).map(
          (key, index) =>
            `${t(`form_${key}`)} ${key === 'acceptTerms' ? t('common_checkbox') : t('common_field')}: ${
              Object.values(errors)[index]
            }`
        )}
      </Box>
    )
  );
};

export default OverallErrorMessages;
