import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import hoistStatics from 'hoist-non-react-statics';

import { homePageStyles } from './styles';
import { Button, CourseCard, DefaultSearchField } from 'components';
import * as Constants from 'constants/Constants';
import { ClassStyle, Course, Tag } from 'types/types';
import { getDashboard } from 'store/dashboard/actions';
import { getPath } from 'utils/getPath';
import { isBrowser } from 'utils/browser';
import { removeCommonLoader } from 'store/loader/actions';
import { getProfileDetails } from 'store/profile/actions';
import { RunStates } from 'components/Tutorial/Tutorial';
import { getHomePageTutorialReady } from 'utils/localStorage';
import { StepIndexes } from 'components/Tutorial/Tutorial';
import { isInViewport } from 'utils/isInViewport';

class HomePage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      BeAnettContainer: ClassStyle,
      BeAnettDescription: ClassStyle,
      Search: ClassStyle,
      Form: ClassStyle,
      SearchIconContainer: ClassStyle,
      SearchIcon: ClassStyle,
      SearchIconAdornment: ClassStyle,
      SearchIconAdornmentButton: ClassStyle,
      SearchIconAdornmentButtonAbsolute: ClassStyle,
      SearchField: ClassStyle,
      InputField: ClassStyle,
      CloseSearch: ClassStyle,
      CloseSearchSeparator: ClassStyle,
      CloseSearchIcon: ClassStyle,
      SpecialBox: ClassStyle,
      SpecialCourseContainer: ClassStyle,
      TabletPhotoContainer: ClassStyle,
      DesktopTabletPhotoContainer: ClassStyle,
      SpecialBoxContainer: ClassStyle,
      SpecialBoxDescription: ClassStyle,
      Link: ClassStyle,
      MobileSpecialBoxDescription: ClassStyle,
      MobileTabletPhotoContainer: ClassStyle,
      MainTopicContainer: ClassStyle,
      TopicsTitle: ClassStyle,
      TopicTutorial: ClassStyle,
      TopicDescriptionContainer: ClassStyle,
      TopicsTutorialContainer: ClassStyle,
      TopicContainer: ClassStyle,
      TopicSelectContainer: ClassStyle,
      TopicSelectBox: ClassStyle,
      TopicSelect: ClassStyle,
      BestCourses: ClassStyle,
      BestCourse: ClassStyle,
      ViewAllTopicsContainer: ClassStyle,
      MobileTopicContainer: ClassStyle,
      MobileTopic: ClassStyle,
      MobileTopicBox: ClassStyle,
      HomePageTitle: ClassStyle,
    }),
    t: PropTypes.func,
    pages: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    dashboard: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        languageId: PropTypes.string.isRequired,
        courses: PropTypes.arrayOf(Course),
      })
    ),
    isLoggedIn: PropTypes.bool.isRequired,
    getProfileDetails: PropTypes.func.isRequired,
    profileTags: PropTypes.arrayOf(Tag),
    id: PropTypes.number,
    addCommonLoader: PropTypes.func,
    removeCommonLoader: PropTypes.func,
    logout: PropTypes.func,
    tutorial: PropTypes.object.isRequired,
    setTutorialState: PropTypes.func.isRequired,
  };

  static pageTitleKey = 'page_title_home';

  state = {
    searchString: '',
    activeTopicId: this.props.dashboard && this.props.dashboard.length ? this.props.dashboard[0].id : 0,
    scrolledToTopics: false,
    selectedTopic: 0,
  };
  navigating = false;

  topicsRef = createRef();

  topicListRefs = this.props.dashboard
    ? this.props.dashboard.filter((topic, index) => index < Constants.MaxTopics).map(() => createRef())
    : [];

  static async getInitialData({ dispatch, language, token }) {
    if (token) {
      try {
        await dispatch(getProfileDetails({ token }));
      } catch (err) {
        console.error(err);
      }
    }
    await dispatch(getDashboard(language));

    if (isBrowser()) {
      dispatch(removeCommonLoader());
    }
  }

  componentDidMount() {
    this.props.addCommonLoader();

    const { runState } = this.props.tutorial;
    switch (runState) {
      case RunStates.NOT_RUNNIG:
        if (!getHomePageTutorialReady()) {
          this.props.setTutorialState({ runState: RunStates.WAITING, stepIndex: StepIndexes.TutorialHome1 });
          this.subscribeToScroll();
        }
        break;
      default:
        break;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dashboard, id, getProfileDetails } = this.props;
    const { activeTopicId } = prevState;
    if (prevProps.dashboard && prevProps.dashboard !== dashboard) {
      const activeTopic = dashboard.find((topic) => topic.id === activeTopicId);
      this.setState({ activeTopicId: activeTopic ? activeTopic.id : dashboard[0].id });
    }
    if (!prevProps.id && id) {
      getProfileDetails({});
    }
    const { runState, stepIndex } = this.props.tutorial;
    switch (runState) {
      case RunStates.WAITING:
        switch (stepIndex) {
          case StepIndexes.TutorialHome1:
            if (this.state.scrolledToTopics && this.isLoaded()) {
              this.props.setTutorialState({ runState: RunStates.RUNNING });
            }
            break;
          case StepIndexes.TutorialHome2:
            if (this.isLoaded()) {
              this.props.setTutorialState({ runState: RunStates.RUNNING });
            }
            break;
          case StepIndexes.TutorialTopics1:
            if (!this.navigating && prevProps.tutorial.stepIndex === StepIndexes.TutorialHome2) {
              this.viewTopics({ showAll: true });
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  componentWillUnmount() {
    this.unSubscribeToScroll();
  }

  subscribeToScroll() {
    document.addEventListener('scroll', this.onScroll, { passive: true });
  }

  unSubscribeToScroll() {
    document.removeEventListener('scroll', this.onScroll, { passive: true });
  }

  onScroll = () => {
    if (!this.state.scrolledToTopics && this.topicsRef.current && isInViewport(this.topicsRef.current)) {
      this.setState({ scrolledToTopics: true });
      this.unSubscribeToScroll();
    }
  };

  isLoaded() {
    const { loading, dashboard } = this.props;
    return !loading && !!dashboard.length;
  }

  onSearch = (searchText) => {
    const { history, t, pages } = this.props;
    history.push({
      pathname: getPath(pages, t('common_search')),
      search: `?${t('url_search_term')}=${encodeURIComponent(searchText)}`,
    });
  };

  onChange = (searchString) => {
    this.setState({ searchString });
  };

  setActiveTopic = (activeTopicId, index) => {
    this.setState({ activeTopicId, selectedTopic: index });
  };

  viewTopics = ({ showAll }) => {
    const { activeTopicId } = this.state;
    const { dashboard, history, pages } = this.props;
    this.navigating = true;
    history.push(
      getPath(pages, dashboard.find((topic) => topic.id === activeTopicId).title) +
        `${showAll ? '' : '?filter=interest'}`
    );
  };

  topicsKeyDown = (e, index) => {
    const { selectedTopic } = this.state;
    const { dashboard } = this.props;
    const filteredDashboard = dashboard.filter((topic, index) => index < Constants.MaxTopics);
    const keyCode = e.which || e.keyCode;
    if (keyCode === Constants.ArrowKeys.DOWN) {
      e.preventDefault();
      if (selectedTopic === filteredDashboard.length - 1) {
        this.setState({ selectedTopic: 0, activeTopicId: filteredDashboard[0].id });
        this.topicListRefs[0].current.focus();
      } else {
        this.setState({ selectedTopic: selectedTopic + 1, activeTopicId: filteredDashboard[index + 1].id });
        this.topicListRefs[index + 1].current.focus();
      }
    } else if (keyCode === Constants.ArrowKeys.UP) {
      e.preventDefault();
      if (selectedTopic === 0) {
        this.setState({
          selectedTopic: filteredDashboard.length - 1,
          activeTopicId: filteredDashboard[filteredDashboard.length - 1].id,
        });
        this.topicListRefs[filteredDashboard.length - 1].current.focus();
      } else {
        this.setState({ selectedTopic: selectedTopic - 1, activeTopicId: dashboard[index - 1].id });
        this.topicListRefs[index - 1].current.focus();
      }
    }
  };

  render() {
    const { activeTopicId, selectedTopic } = this.state;
    const { classes, t, dashboard, pages, history, isLoggedIn, profileTags } = this.props;

    return (
      <>
        <Box mt={3} mb={16} className={classes.BeAnettContainer}>
          <Box mb={2}>
            <h1 tabIndex={0}>{t('home_page_be_anett_title')}</h1>
          </Box>
          <Box tabIndex={0} mb={2.5} className={classNames(classes.BeAnettDescription, 'Description')}>
            <div>{t('home_page_be_anett_description1')}</div>
            <div>{t('home_page_be_anett_description2')}</div>
          </Box>
          <div className={classes.Search}>
            <DefaultSearchField
              ariaLabel={t('common_search_placeholder')}
              placeholder={t('common_search_placeholder')}
              submit={this.onSearch}
            />
          </div>
        </Box>
        <Box mt={8} mb={10} className={classes.SpecialBox}>
          <h2 className="Title HomePageTitle" tabIndex={0}>
            {t('home_page_special_box_title')}
          </h2>
          <div className={'TitleBorder'} />
          <Grid container className={classes.SpecialCourseContainer}>
            <Grid
              item
              md={6}
              className={classNames(classes.TabletPhotoContainer, classes.DesktopTabletPhotoContainer)}
            />
            <Grid item md={6} className={classes.SpecialBoxContainer}>
              <Box className={classes.SpecialBoxDescription}>
                <Box mb={3} tabIndex={0}>
                  {t('home_page_special_box_description_1')}
                </Box>
                <Box mb={3} tabIndex={0}>
                  <span>{t('home_page_special_box_description_2a')}</span>
                  <Link
                    to={getPath(pages, t('menu_item_knowledge_store'))}
                    className={classNames(classes.Link, 'Bold')}
                  >
                    {t('home_page_special_box_description_2b')}
                  </Link>
                  <span>{t('home_page_special_box_description_2c')}</span>
                  <span className="Bold">{t('home_page_special_box_description_2d')}</span>
                  <span>{t('home_page_special_box_description_2e')}</span>
                </Box>
                <Box tabIndex={0}>
                  <span>{t('home_page_special_box_description_3a')}</span>
                  <span className="Bold">{t('home_page_special_box_description_3b')}</span>
                  <span>{t('home_page_special_box_description_3c')}</span>
                  <span className="Bold">{t('home_page_special_box_description_3d')}</span>
                </Box>
              </Box>
              <Box mt={5} className={classes.MobileSpecialBoxDescription}>
                <Box mb={10}>
                  <Box mb={3} tabIndex={0}>
                    {t('home_page_special_box_description_1')}
                  </Box>
                  <Box mb={3} tabIndex={0}>
                    <span>{t('home_page_special_box_description_2a')}</span>
                    <Link
                      to={getPath(pages, t('menu_item_knowledge_store'))}
                      className={classNames(classes.Link, 'Bold')}
                    >
                      {t('home_page_special_box_description_2b')}
                    </Link>
                    <span>{t('home_page_special_box_description_2c')}</span>
                    <span className="Bold">{t('home_page_special_box_description_2d')}</span>
                    <span>{t('home_page_special_box_description_2e')}</span>
                  </Box>
                  <Box tabIndex={0}>
                    <span>{t('home_page_special_box_description_3a')}</span>
                    <span className="Bold">{t('home_page_special_box_description_3b')}</span>
                    <span>{t('home_page_special_box_description_3c')}</span>
                    <span className="Bold">{t('home_page_special_box_description_3d')}</span>
                  </Box>
                </Box>
                <Box
                  className={classNames(classes.TabletPhotoContainer, classes.MobileTabletPhotoContainer)}
                  aria-hidden="true"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mb={16} className={classes.MainTopicContainer}>
          <Box mb={4} className={classNames(classes.TopicTutorial, 'TutorialHome1')} ref={this.topicsRef}>
            <Box className="Title" tabIndex={0}>
              {t('home_page_topics')}
            </Box>
            <Box mb={10} className={classNames('TitleBorder', 'Cyan', classes.TopicsTitle)} />
            <Box className={classes.TopicDescriptionContainer} tabIndex={0}>
              {t('home_page_topic_description')}
            </Box>
          </Box>
          <div className={classNames(classes.TopicsTutorialContainer, 'TutorialHome2')}>
            <Box className={classes.TopicContainer}>
              <Box mr={10} aria-label={t('home_page_topics')} role="tablist" className={classes.TopicSelectContainer}>
                {dashboard
                  .filter((topic, index) => index < Constants.MaxTopics)
                  .map((topic, index) => (
                    <Box
                      key={topic.id}
                      className={classNames(classes.TopicSelectBox, { active: topic.id === activeTopicId })}
                      role="tab"
                      aria-selected={index === selectedTopic ? 'true' : 'false'}
                      tabIndex={index === selectedTopic ? 0 : -1}
                      onKeyDown={(e) => this.topicsKeyDown(e, index)}
                      ref={this.topicListRefs[index]}
                    >
                      <div
                        onClick={() => this.setActiveTopic(topic.id, index)}
                        className={classNames(classes.TopicSelect, { active: topic.id === activeTopicId })}
                      >
                        {topic.title}
                      </div>
                    </Box>
                  ))}
              </Box>
              <Grid container spacing={2} className={classes.BestCourses}>
                {dashboard.find((topic) => topic.id === activeTopicId) &&
                dashboard.find((topic) => topic.id === activeTopicId).courses
                  ? dashboard
                      .find((topic) => topic.id === activeTopicId)
                      .courses.map((course) => (
                        <Grid md={4} item key={course.id}>
                          <CourseCard
                            course={course}
                            className={classes.BestCourse}
                            history={history}
                            topicId={activeTopicId}
                          />
                        </Grid>
                      ))
                  : null}
                <Grid item xs={12} sm={12} md={12}>
                  <Box mt={7} className={classes.ViewAllTopicsContainer}>
                    {isLoggedIn && profileTags && profileTags.length ? (
                      <Box ml={2}>
                        <Button buttonClassName={'Pink'} onClick={this.viewTopics}>
                          {t('home_page_view_personalized')}
                        </Button>
                      </Box>
                    ) : null}
                    <Box ml={isLoggedIn && profileTags && profileTags.length ? 2 : 0}>
                      <Button
                        buttonClassName={classNames('Pink', {
                          Secondary: !!(isLoggedIn && profileTags && profileTags.length),
                        })}
                        onClick={() => this.viewTopics({ showAll: true })}
                      >
                        {t('home_page_view_all')}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.MobileTopicContainer}>
              {dashboard
                .filter((topic, index) => index < Constants.MaxTopics)
                .map((topic) => (
                  <Link to={getPath(pages, topic.title)} className={classes.MobileTopic} key={topic.id}>
                    <Box className={classes.MobileTopicBox}>{topic.title}</Box>
                  </Link>
                ))}
            </Box>
          </div>
        </Box>
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withStyles(homePageStyles)(HomePage)), HomePage);
