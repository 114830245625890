import React from 'react';
import qs from 'query-string';
import PropTypes from 'prop-types';

export default ({ tabs = [] }) => (WrappedComponent) => {
  return class TabQueryParams extends React.Component {
    static propTypes = {
      location: PropTypes.object,
      history: PropTypes.object,
      match: PropTypes.object,
    };

    state = {
      tabIndex: this.getActiveTabIndex(),
    };

    componentDidMount() {
      this.setTab(this.state.tabIndex);
    }

    getActiveTabIndex() {
      const { tab } = qs.parse(this.props.location.search);
      const defaultTabIndex = tabs.findIndex(({ defaultTab }) => defaultTab);
      const activeTabIndex = tabs.findIndex((data) => data.urlParam === tab);

      return activeTabIndex > -1 ? activeTabIndex : defaultTabIndex;
    }

    setTab = (tabIndex) => {
      this.setState({ tabIndex });
      this.props.history.replace({
        path: this.props.match.path,
        search: qs.stringify({
          ...qs.parse(this.props.location.search),
          tab: tabs[tabIndex].urlParam,
        }),
      });
    };

    render() {
      return <WrappedComponent tabIndex={this.state.tabIndex} setTab={this.setTab} {...this.props} />;
    }
  };
};
