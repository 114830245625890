import React, { forwardRef, useRef } from 'react';
import ReactSlider from 'react-slick';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Arrow } from './Arrow';

import { useSliderStyles } from './styles';

export const Slider = forwardRef(
  ({ children, initialSlide, centerMode, dots, slidesToShow, centerPadding, infinite = false }, ref) => {
    const classes = useSliderStyles();

    const sliderRef = useRef();

    return (
      <ReactSlider
        ref={ref ? ref : sliderRef}
        prevArrow={<Arrow ref={ref ? ref : sliderRef} direction="left" infinite={infinite} />}
        nextArrow={<Arrow ref={ref ? ref : sliderRef} direction="right" infinite={infinite} />}
        dots={dots}
        centerMode={centerMode}
        initialSlide={initialSlide}
        slidesToShow={slidesToShow}
        centerPadding={centerPadding}
        className={classes.Slider}
        infinite={infinite}
        customPaging={() => <button aria-hidden={true} tabIndex={-1} />}
      >
        {children}
      </ReactSlider>
    );
  }
);

Slider.propTypes = {
  children: PropTypes.node,
  initialSlide: PropTypes.number,
  centerMode: PropTypes.bool,
  dots: PropTypes.bool,
  slidesToShow: PropTypes.number,
  centerPadding: PropTypes.string,
  infinite: PropTypes.bool,
};
