import makeStyles from '@material-ui/core/styles/makeStyles';
import { center, left } from 'constants/Jss';

export const useAcceptNewTermsDialogStyles = makeStyles((theme) => ({
  PinkPatch: {
    '& .TitleBorder': {
      backgroundColor: theme.palette.lightBlue,
    },
    [theme.breakpoints.down('xs')]: {
      '& > h1': {
        fontSize: 24,
      },
    },
  },
  Checkbox: {
    color: `${theme.palette.whiteHover} !important`,
    '& svg': {
      fill: theme.palette.checkboxBorder,
    },
    '&:hover': {
      backgroundColor: theme.palette.whiteHover,
    },
    '&.Mui-checked': {
      '& svg': {
        fill: `${theme.palette.lightBlue} !important`,
      },
      '&:hover': {
        backgroundColor: `${theme.palette.whiteHover} !important`,
      },
    },
  },
  ErrorText: {
    color: theme.palette.errorColor,
    fontSize: '0.8571428571428571rem',
    margin: '8px 14px 0',
    lineHeight: '1em',
  },
  Description: {
    maxWidth: theme.mixins.registerForm.width,
    textAlign: center,
  },
  Links: {
    maxWidth: theme.mixins.registerForm.width,
    textAlign: left,
  },
}));
