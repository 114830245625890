import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import CertificatePage from './CertificatePage';
import { getCertificate } from 'store/certificates/actions';
import { selectCertificate } from 'store/certificates/selectors';
import { addCommonLoader } from 'store/loader/actions';

const mapStateToProps = (state) => {
  return {
    certificate: selectCertificate(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCertificate,
      addCommonLoader,
    },
    dispatch
  );
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CertificatePage);
