import React, { forwardRef, useImperativeHandle, useState, useRef, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import { MaxSkeletonCard } from 'constants/Constants';
import { CourseCard, NavigationDialog } from 'components';
import { getCurrentLanguage } from 'utils/language';
import { getTopicsCoursesUrl } from 'utils/getTopicsCoursesUrl';

export const CourseCardList = forwardRef(({ courses, skeleton, itemContainerClass, onTopicsPage }, ref) => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const [course, setCourse] = useState(null);
  const [courseIndex, setCourseIndex] = useState(0);

  const courseRefs = useRef(courses.map(() => createRef()));

  const openCourseDialog = (course, index) => {
    setOpenDialog(true);
    setCourse(course);
    setCourseIndex(index);
  };

  const closeDialog = () => {
    setOpenDialog(false);
    setCourse(null);
    if (courseRefs.current[courseIndex]) {
      courseRefs.current[courseIndex].current.focus();
    }
  };

  const onClick = (course, index, useFirstModule) => {
    if (course.modules.length > 1 && !useFirstModule) {
      openCourseDialog(course, index);
    } else {
      history.push(getTopicsCoursesUrl(getCurrentLanguage(window.location.pathname), course.modules[0].id, course.id));
    }
  };

  const courseList = courses || [];
  const tutorialIndex = Math.min(courseList.length - 1, 2);

  useImperativeHandle(ref, () => ({
    clickTutorialCard: () => {
      if (
        courseRefs &&
        courseRefs.current &&
        courseRefs.current[tutorialIndex] &&
        courseRefs.current[tutorialIndex].current &&
        courseRefs.current[tutorialIndex].current.firstChild
      ) {
        courseRefs.current[tutorialIndex].current.firstChild.click();
      } else {
        onClick(courseList[tutorialIndex], true);
      }
    },
  }));

  return (
    <>
      {!skeleton
        ? courseList.map((course, index) => (
            <Box
              mb={6}
              ml={1.5}
              mr={1.5}
              key={course.id}
              className={classNames(itemContainerClass, { TutorialTopics2: index === tutorialIndex })}
            >
              <CourseCard
                onClick={
                  !onTopicsPage
                    ? () => {
                        onClick(course, index);
                      }
                    : undefined
                }
                course={course}
                history={history}
                ref={courseRefs.current[index]}
              />
            </Box>
          ))
        : null}
      {skeleton
        ? Array.from({ length: MaxSkeletonCard }).map((_, i) => (
            <Box mb={6} ml={1.5} mr={1.5} key={`skeleton_${i}`}>
              <CourseCard skeleton />
            </Box>
          ))
        : null}
      {!!course && <NavigationDialog open={openDialog} closeDialog={closeDialog} course={course} history={history} />}
    </>
  );
});

CourseCardList.propTypes = {
  courses: PropTypes.array.isRequired,
  itemContainerClass: PropTypes.string,
  skeleton: PropTypes.bool,
  onTopicsPage: PropTypes.bool,
};
