import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { verifyEmail } from 'store/auth/actions';
import { showNotification } from 'store/common/actions';
import { detectLanguage } from 'utils/language';
import { getPath } from 'utils/getPath';
import { withTranslation } from 'react-i18next';
import { NotificationTypes } from 'constants/Constants';
import { History } from '../../types/types';

class VerifyEmail extends Component {
  static propTypes = {
    history: History,
    verifyEmail: PropTypes.func,
    pages: PropTypes.array,
    t: PropTypes.func,
    showNotification: PropTypes.func,
  };

  async componentDidMount() {
    const { history, verifyEmail, pages, t, showNotification } = this.props;
    const { token } = queryString.parse(history.location.search);
    const result = await verifyEmail({ language: detectLanguage(history.location.pathname), token });
    if (result) {
      showNotification({ translateKey: 'registration_confirm_success', type: NotificationTypes.success });
      history.push(`${getPath(pages, t('menu_item_home'))}?dialog=${t('url_login')}&verify=true`);
    } else {
      history.push(getPath(pages, t('menu_item_home')));
    }
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showNotification, verifyEmail }, dispatch);
};

export default compose(withRouter, connect(null, mapDispatchToProps))(withTranslation()(VerifyEmail));
