import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

import { usePrevious } from 'utils/usePrevious';
import { useProgressBarStyles } from './styles';

const ProgressBar = forwardRef(({ currentQuestion, numberOfQuestions }, ref) => {
  const classes = useProgressBarStyles();
  const { t } = useTranslation();
  const prevQuestion = usePrevious(currentQuestion);

  useEffect(() => {
    if (prevQuestion !== currentQuestion && ref) {
      setTimeout(() => ref.current.focus({ preventScroll: true }), 100);
    }
  }, [ref, currentQuestion, prevQuestion]);

  return (
    <Box
      ref={ref}
      className={classes.ProgressBarContainer}
      tabIndex={0}
      aria-label={`${currentQuestion}. ${t('alt_question')}, ${t('alt_sum')} ${numberOfQuestions}`}
    >
      <Box className={classes.NumberIndicator} aria-hidden={true}>{`${currentQuestion} / ${numberOfQuestions}`}</Box>
      <Box
        className={classes.ProgressStrip}
        style={{ width: `${(currentQuestion / numberOfQuestions) * 100}%` }}
        aria-hidden={true}
      />
    </Box>
  );
});

ProgressBar.propTypes = {
  currentQuestion: PropTypes.number,
  numberOfQuestions: PropTypes.number,
};

export default ProgressBar;
