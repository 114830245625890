import makeStyles from '@material-ui/core/styles/makeStyles';
import { center, flex, inherit, none, pointer, transparent, unset } from 'constants/Jss';

export const useSearchFieldStyles = makeStyles((theme) => ({
  Container: {
    display: flex,
    alignItems: center,
    width: '100%',
    backgroundColor: theme.palette.white,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    paddingLeft: theme.spacing(1),
    '&.invalid:focus-within': {
      borderColor: theme.palette.errorColor,
    },
  },
  InputContainer: {
    flex: 1,
    height: '80%',
  },
  Input: {
    width: '100%',
    height: '100%',
    backgroundColor: inherit,
    border: none,
    padding: '0 10px',
    fontFamily: 'WonderUnit',
    '&::-webkit-search-cancel-button': {
      display: none,
    },
  },
  ButtonContainer: {
    height: '100%',
  },
  Button: {
    cursor: pointer,
    height: '100%',
    padding: '0 10px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-disabled svg': {
      opacity: 0.5,
      fill: `${theme.palette.text.primary} !important`,
    },
    '&.isListening svg': {
      fill: `${theme.palette.lightPink} !important`,
    },
  },
  ClearIcon: {
    stroke: `${theme.palette.grey2C} !important`,
  },
  VoiceIcon: {
    fill: `${theme.palette.lightBlue} !important`,
  },
  SearchIcon: {
    fill: `${theme.palette.lightBlue} !important`,
  },
  CloseIcon: {
    stroke: `${theme.palette.grey2C} !important`,
  },
  SeparatorConatiner: {
    display: flex,
    alignItems: center,
    height: '100%',
  },
  Separator: {
    width: 2,
    height: 24,
    backgroundColor: theme.palette.grey2C,
  },
}));

export const useDefaultStyles = makeStyles((theme) => ({
  Container: {
    height: theme.mixins.homeSearch.height,
    borderColor: theme.palette.grey80,
  },
  Input: {
    fontSize: 17,
  },
}));

export const useDesktopHeaderStyles = makeStyles((theme) => ({
  Container: {
    height: theme.mixins.header.contentSize,
    borderColor: transparent,
  },
  Input: {
    fontSize: 17,
  },
}));

export const useMobileHeaderStyles = makeStyles((theme) => ({
  Container: {
    height: theme.spacing(7),
    borderColor: transparent,
    borderRadius: unset,
  },
  Input: {
    fontSize: 15,
  },
}));

export const useFilterStyles = makeStyles((theme) => ({
  Container: {
    height: 68,
    borderColor: theme.palette.grey70,
    borderTopRightRadius: unset,
    borderBottomRightRadius: unset,
  },
  Input: {
    fontSize: 17,
  },
}));

export const useMobileFilterStyles = makeStyles((theme) => ({
  Container: {
    height: 62,
    borderColor: theme.palette.grey70,
  },
  Input: {
    fontSize: 15,
  },
}));
