import { RunStates } from 'components/Tutorial/Tutorial';
import { ActionType } from './actions';

const INITIAL_STATE = {
  runState: RunStates.NOT_RUNNIG,
  stepIndex: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionType.SET_TUTORIAL_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
