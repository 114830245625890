import { ADD_COMMON_LOADER, REMOVE_COMMON_LOADER } from './types';

const INITIAL_STATE = {
  loading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_COMMON_LOADER:
      return {
        loading: true,
      };
    case REMOVE_COMMON_LOADER:
      return {
        loading: false,
      };
    default:
      return state;
  }
};

