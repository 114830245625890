export const inlineBlock = 'inline-block';
export const flex = 'flex';
export const inlineFlex = 'inline-flex';
export const none = 'none';
export const block = 'block';
export const relative = 'relative';
export const absolute = 'absolute';
export const fixed = 'fixed';
export const left = 'left';
export const right = 'right';
export const center = 'center';
export const flexStart = 'flex-start';
export const flexEnd = 'flex-end';
export const bold = 'bold';
export const column = 'column';
export const row = 'row';
export const ellipsis = 'ellipsis';
export const nowrap = 'nowrap';
export const wrap = 'wrap';
export const hidden = 'hidden';
export const spaceBetween = 'space-between';
export const spaceAround = 'space-around';
export const spaceEvenly = 'space-evenly';
export const auto = 'auto';
export const cover = 'cover';
export const contain = 'contain';
export const noRepeat = 'no-repeat';
export const pointer = 'pointer';
export const transparent = 'transparent';
export const columnReverse = 'column-reverse';
export const rowReverse = 'row-reverse';
export const uppercase = 'uppercase';
export const visible = 'visible';
export const inherit = 'inherit';
export const normal = 'normal';
export const underline = 'underline';
export const vertical = 'vertical';
export const baseline = 'baseline';
export const both = 'both';
export const unset = 'unset';
export const breakWord = 'break-word';
export const breakSpaces = 'break-spaces';
export const grid = 'grid';
export const top = 'top';
export const table = 'table';
export const middle = 'middle';
export const capitalize = 'capitalize';
export const sticky = 'sticky';
export const justify = 'justify';
export const collapse = 'collapse';
export const start = 'start';
export const end = 'end';
export const preLine = 'pre-line';
