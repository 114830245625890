import { GET_TOPICS, GET_TOPICS_SUCCESS, GET_TOPICS_FAILED } from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  topics: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TOPICS:
      return {
        ...state,
        loading: true,
      };
    case GET_TOPICS_SUCCESS:
      return {
        ...state,
        loading: false,
        topics: action.payload || [],
      };
    case GET_TOPICS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
