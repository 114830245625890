import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Box from '@material-ui/core/Box';

import { navigationArrow } from 'utils/icons';
import { useArrowStyles } from './styles';

export const Arrow = forwardRef(({ direction = 'left', currentSlide, slideCount, infinite }, ref) => {
  const classes = useArrowStyles();
  const isLeft = direction === 'left';
  const isRight = direction === 'right';
  const isFirstSlide = currentSlide === 0;
  const isLastSlide = currentSlide === slideCount - 1;

  const onArrowClick = () => {
    const movement = direction === 'left' ? -1 : 1;
    ref.current.slickGoTo(currentSlide + movement);
  };

  return infinite || (isLeft && !isFirstSlide) || (isRight && !isLastSlide) ? (
    <Box
      width={74}
      height={74}
      onClick={onArrowClick}
      className={classNames(classes.SliderArrow, { [classes.SliderArrowRight]: direction === 'right' })}
    >
      {navigationArrow(
        classNames(classes.ArrowIcon, {
          [classes.ArrowIconLeft]: direction === 'left',
          [classes.ArrowIconRight]: direction === 'right',
        })
      )}
    </Box>
  ) : null;
});

Arrow.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']),
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
  infinite: PropTypes.bool,
};
