import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useTranslation } from 'react-i18next';

import { auto } from 'constants/Jss';
import { useFormStyles } from './styles';
import { RadioButton } from 'components/RadioButton/RadioButton';
import { usePrevious } from 'utils/usePrevious';
import { testCorrect, testIncorrect } from 'utils/icons';

export const TrueOrFalseForm = ({ answers, setAnswer, answered }) => {
  const classes = useFormStyles();
  const { t } = useTranslation();

  const [value, setValue] = React.useState('');
  const prevAnswers = usePrevious(answers);

  useEffect(() => {
    if (prevAnswers !== answers) {
      setValue('');
      setAnswer([]);
    }
  }, [answers, prevAnswers, setAnswer]);

  const handleChange = (event) => {
    setAnswer([+event.target.value]);
    setValue(event.target.value);
  };

  return (
    <Box className={classes.FormBox} ml={auto} mr={auto}>
      <RadioGroup
        className={classes.Flex}
        aria-label={t('alt_answers')}
        name="answers"
        value={value}
        onChange={(e) => e.preventDefault()}
        role="radiogroup"
      >
        {answers.map((answer, i) => {
          const Checked = value !== '' && i === +value;
          const Correct = answered && answer.isGood;
          const Wrong = answered && !answer.isGood && Checked;
          const color = Correct ? 'green' : Wrong ? 'red' : 'blue';
          return (
            <Box
              mb={3}
              key={i}
              onClick={() => !answered && handleChange({ target: { value: i } })}
              className={classNames(classes.AnswerBox, 'TrueOrFalse', { Checked, Correct, Wrong })}
            >
              <FormControlLabel
                className={classes.Answer}
                label={answer.answer}
                value={`${i}`}
                control={
                  <RadioButton
                    color={color}
                    coloredBorder={Correct && !Checked}
                    checked={Checked}
                    disabled={answered}
                    aria-hidden={true}
                    tabIndex={-1}
                  />
                }
                tabIndex={0}
                role="radio"
                aria-label={answer.answer}
                aria-checked={Checked}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    !answered && handleChange({ target: { value: i } });
                  }
                }}
              />
              {!!answered && !!Correct && testCorrect()}
              {!!answered && !!Wrong && testIncorrect()}
            </Box>
          );
        })}
      </RadioGroup>
    </Box>
  );
};

TrueOrFalseForm.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string,
      isGood: PropTypes.bool,
    })
  ),
  setAnswer: PropTypes.func,
  answered: PropTypes.bool,
};
