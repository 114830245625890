import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import zipObject from 'lodash/zipObject';
import isArray from 'lodash/isArray';

import { testPageStyles } from './styles';
import OpeningPatch from 'assets/images/test-opening-patch-center.png';
import ClosingPatchSuccess from 'assets/images/test-closing-patch-success.png';
import ClosingPatchFailed from 'assets/images/test-closing-patch-failed.png';
import {
  Button,
  Dialog,
  Image,
  ProgressBar,
  RemainingTime,
  ExamTestOpeningFooter,
  QuestionImage,
  SingleChoiceForm,
  MultipleChoiceForm,
  TrueOrFalseForm,
  AccessibleSubstitutionForm,
  SubstitutionForm,
  PairingForm,
} from 'components';
import theme from 'theme';
import { closeButton, correctAnswerIcon, incorrectAnswerIcon } from 'utils/icons';
import {
  ExamStates,
  NotificationTypes,
  QuestionElementId,
  QuestionTypes,
  ScrollToTopVisible,
} from 'constants/Constants';
import { getProfileDetails } from 'store/profile/actions';
import { decimals } from 'utils/decimals';
import { ClassStyle, ExamState, History, Test } from 'types/types';
import { isIOS } from 'utils/browser';
import { getCurrentLanguage } from 'utils/language';
import { titleFocus } from 'utils/titleFocus';
import { isSmallWidth } from 'utils/isSmallWidth';
import { animate } from 'utils/animate';

class TestPage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      CloseButton: ClassStyle,
      InnerContent: ClassStyle,
      OpeningContent: ClassStyle,
      ImageContainer: ClassStyle,
      OpeningImage: ClassStyle,
      IntroText: ClassStyle,
      TestFooter: ClassStyle,
      ClosingContent: ClassStyle,
      PatchBox: ClassStyle,
      PatchResult: ClassStyle,
      EndTestTitle: ClassStyle,
      ColoredResult: ClassStyle,
      ResultDescription: ClassStyle,
      QuestionContent: ClassStyle,
      ProgressBarContainer: ClassStyle,
      StickyTimeContainer: ClassStyle,
      StickyTimeVisible: ClassStyle,
      StickyTimeContainerIos: ClassStyle,
      Image: ClassStyle,
      QuestionWithAnswersContainer: ClassStyle,
      Question: ClassStyle,
      QuestionLabel: ClassStyle,
      DoubleButtonContainer: ClassStyle,
      ResultBox: ClassStyle,
      Answer: ClassStyle,
      Content: ClassStyle,
      AppLoader: ClassStyle,
      CircularLoaderContainer: ClassStyle,
      CircularLoader: ClassStyle,
      NoPadding: ClassStyle,
    }),
    history: History,
    t: PropTypes.func,
    examState: ExamState,
    test: Test,
    validateTest: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    removeCommonLoader: PropTypes.func,
    getTest: PropTypes.func,
    windowWidth: PropTypes.number,
  };

  static pageTitleKey = 'page_title_test';

  static async getInitialData({ dispatch, language, token }) {
    try {
      if (token) {
        await dispatch(getProfileDetails({ language, token }));
      }
      titleFocus(dispatch);
    } catch (err) {
      console.error(err);
    }
  }

  state = {
    examState: ExamStates.OPEN,
    questionIndex: null,
    answer: [],
    answered: false,
    correct: false,
    stickyTimeVisible: false,
    isLoading: false,
    testResult: null,
    isAccessible: false,
    lessonId: null,
  };

  endExamTimeout = null;
  forceCloseTimout = null;
  questions = [];

  innerContent = createRef();
  staticTimer = createRef();
  progressBar = createRef();

  async componentDidMount() {
    const { history, test, getTest } = this.props;

    const params = history.location.pathname.split('/');
    const lessonId = params[7];
    this.setState({ lessonId });
    if (isEmpty(test)) {
      await getTest(lessonId);
    }
    this.props.removeCommonLoader();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.examState === ExamStates.QUESTION &&
      this.innerContent.current &&
      prevState.questionIndex !== this.state.questionIndex &&
      this.props.windowWidth >= theme.breakpoints.values.sm
    ) {
      setTimeout(() => {
        if (this.innerContent.current) {
          animate('scrollTop', this.innerContent.current, document.body.scrollHeight);
        }
      }, ScrollToTopVisible);
    }
    if (
      prevState.examState !== this.props.examState &&
      this.innerContent.current &&
      this.staticTimer.current &&
      this.props.test.minutesToCompleteTest
    ) {
      this.innerContent.current.onscroll = () => {
        if (this.state.examState === ExamStates.END) {
          return;
        }
        const { top } = this.staticTimer.current.getBoundingClientRect();
        if (top === 0 && !this.state.stickyTimeVisible) {
          this.setState({ stickyTimeVisible: true });
        } else if (top > 0 && this.state.stickyTimeVisible) {
          this.setState({ stickyTimeVisible: false });
        }
      };
    }
  }

  componentWillUnmount() {
    this.endExamTimeout && clearTimeout(this.endExamTimeout);
    this.forceCloseTimout && clearTimeout(this.forceCloseTimout);
  }

  goToTest = async () => {
    try {
      if (this.state.isAccessible) {
        await this.props.getTest(
          this.state.lessonId,
          getCurrentLanguage(this.props.history.location.pathname),
          this.state.isAccessible
        );
      }
      const {
        test: { questions },
      } = this.props;
      if (!questions) {
        this.closeTest();
        return;
      }

      this.questions.push({
        id: questions[0].id,
        answers: [],
      });
      this.setState({ examState: ExamStates.QUESTION, questionIndex: 0 });
    } catch (err) {
      console.error(err);
    }
  };

  closeTest = () => {
    const { history, t } = this.props;
    const lessonUrl = window.location.pathname.split('/' + t('url_test'))[0];
    history.replace(lessonUrl);
  };

  getCloseButton = () => {
    const { classes, t } = this.props;
    return (
      <Box
        className={classes.CloseButton}
        onClick={this.closeTest}
        tabIndex={0}
        role="button"
        aria-label={t('common_close')}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            this.closeTest();
          }
        }}
      >
        <Box className="CloseText" aria-hidden={true}>
          {t('common_close')}
        </Box>
        {closeButton()}
      </Box>
    );
  };

  openingContent = () => {
    const { classes, t } = this.props;
    return (
      <Box className={classNames(classes.InnerContent, classes.OpeningContent)}>
        {this.getCloseButton()}
        <Box className={classes.ImageContainer}>
          <Box mt={9} mb={2}>
            <Image className={classes.OpeningImage} src={OpeningPatch} aria-hidden={true} />
          </Box>
        </Box>
        <Box mb={7} className="PageTitleContainer">
          <h1 className="Title" tabIndex={0}>
            {t('test_page')}
          </h1>
          <div className="TitleBorder" />
        </Box>
        <Box className={classes.IntroText} tabIndex={0}>
          {t('test_page_intro_text1')}
        </Box>
        <Box className={classes.IntroText} tabIndex={0}>
          {t('test_page_intro_text2')}
        </Box>
      </Box>
    );
  };

  addLoader = async () => {
    await this.setState({ isLoading: true });
  };

  removeLoader = async () => {
    await this.setState({ isLoading: false });
  };

  endTestContent = () => {
    const { classes, t } = this.props;
    const { testResult, testSuccess } = this.state.testResult || {};

    return (
      <Box className={classNames(classes.InnerContent, classes.ClosingContent)}>
        {this.getCloseButton()}
        <Box className={classes.ImageContainer}>
          <Box mt={9} mb={2} className={classes.PatchBox}>
            <Image src={testSuccess ? ClosingPatchSuccess : ClosingPatchFailed} aria-hidden={true} />
            <Box className={classes.PatchResult}>
              <Box className="value" aria-hidden={true}>
                {decimals(testResult)}
              </Box>
              <Box className="percentage" aria-hidden={true}>
                %
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mb={5} pl={2} pr={2} className={classNames('PageTitleContainer', classes.EndTestTitle)}>
          <h1 className="Title" tabIndex={0}>
            {testSuccess ? t('test_succeeded') : t('test_failed')}
          </h1>
          <div className="TitleBorder" />
        </Box>
        <Box
          mb={3}
          className={classNames(classes.ColoredResult, { Green: testSuccess, Pink: !testSuccess })}
          tabIndex={0}
          aria-label={decimals(testResult) + ' ' + t('alt_percent')}
        >
          <div aria-hidden={true}>{`${decimals(testResult)}%`}</div>
        </Box>
        {testSuccess ? (
          <Box pl={2} pr={2} className={classes.ResultDescription} tabIndex={0}>
            {t('test_succeeded_description')}
          </Box>
        ) : (
          <Box pl={2} pr={2} className={classes.ResultDescription} tabIndex={0}>
            {t('test_failed_description')}
          </Box>
        )}
      </Box>
    );
  };

  endTestFooter = () => {
    const { classes, t } = this.props;
    const { isAccessible } = this.state;
    return (
      <Box className={classNames(classes.TestFooter, 'Center', { IOs: isIOS(), Accessible: isAccessible })}>
        <Button buttonClassName="Pink" onClick={this.closeTest}>
          {t('common_forward')}
        </Button>
      </Box>
    );
  };

  nextQuestion = (skipped) => {
    const {
      test: { questions },
    } = this.props;
    const { questionIndex } = this.state;
    if (skipped === true) {
      this.questions[questionIndex].answers = [];
    }
    if (questions[questionIndex + 1]) {
      this.setState({ questionIndex: questionIndex + 1, answered: false, correct: false }, () => {
        this.questions.push({
          id: questions[this.state.questionIndex].id,
          answers: [],
        });
        this.setState({
          examState: ExamStates.QUESTION,
          stickyTimeVisible: false,
        });
      });
    } else {
      this.goToEndTest();
    }
  };

  substituteAnswerCompare = (i) => {
    const {
      test: { questions },
    } = this.props;
    const { answer, questionIndex } = this.state;

    return (
      isObject(answer[i]) &&
      answer[i].words &&
      answer[i].words.toString() === questions[questionIndex].answers[i].goodWords.toString()
    );
  };

  pairingAnswerCompare = () => {
    const {
      test: { questions },
    } = this.props;
    const { answer, questionIndex } = this.state;

    return (
      isObject(answer[0]) &&
      answer[0].matchAnswers &&
      isEqual(
        answer[0].matchAnswers,
        zipObject(
          questions[questionIndex].answers.inOrderWords.wordsKey,
          questions[questionIndex].answers.inOrderWords.wordsValue
        )
      )
    );
  };

  isSimpleQuestion = () => {
    const {
      test: { questions },
    } = this.props;
    const { questionIndex } = this.state;

    return (
      questions[questionIndex].type !== QuestionTypes.SUBSTITUTION &&
      questions[questionIndex].type !== QuestionTypes.PAIRING
    );
  };

  checkAnswer = () => {
    const {
      test: { questions },
    } = this.props;
    const { answer, questionIndex } = this.state;
    let correct = true;
    if (isArray(questions[questionIndex].answers)) {
      for (let i = 0; i < questions[questionIndex].answers.length; i++) {
        if (
          (this.isSimpleQuestion() && answer.includes(i) && !questions[questionIndex].answers[i].isGood) ||
          (this.isSimpleQuestion() && !answer.includes(i) && questions[questionIndex].answers[i].isGood)
        ) {
          correct = false;
        } else if (!this.isSimpleQuestion() && !this.substituteAnswerCompare(i)) {
          correct = false;
        }
      }
    } else if (!this.isSimpleQuestion() && !this.pairingAnswerCompare()) {
      correct = false;
    }
    if (correct) {
      this.setState({ answered: true, correct: true }, () => {
        this.setState({ examState: ExamStates.QUESTION }, () => {
          const result = document.getElementById('result-text');
          result.focus();
        });
      });
    } else {
      this.setState({ answered: true, correct: false }, () => {
        this.setState({ examState: ExamStates.QUESTION }, () => {
          const result = document.getElementById('result-text');
          result.focus();
        });
      });
    }
  };

  outOfTime = () => {
    const {
      test: { questions },
    } = this.props;
    const { questionIndex } = this.state;
    for (let i = questionIndex + 1; i < questions.length; i++) {
      this.questions.push({
        id: questions[i].id,
        answers: [],
      });
    }
    this.goToEndTest();
  };

  goToEndTest = async () => {
    const { validateTest } = this.props;
    const reqParams = window.location.pathname.split('/');
    const lessonId = reqParams[reqParams.length - 2];
    try {
      await this.addLoader();
      const testResult = await validateTest(lessonId, { questions: this.questions });
      await this.removeLoader();
      if (testResult) {
        this.endTestTimeout && clearTimeout(this.endTestTimeout);
        this.forceCloseTimout && clearTimeout(this.forceCloseTimout);
        await this.removeLoader();
      }
      this.setState({ examState: ExamStates.END, testResult });
    } catch (e) {
      this.endTestTimeout = setTimeout(this.goToEndTest, 1000);
      if (!this.forceCloseTimout) {
        await this.addLoader();
        this.forceCloseTimout = setTimeout(this.forceClose, 60000);
      }
    }
  };

  forceClose = async () => {
    const { showNotification } = this.props;
    this.endTestTimeout && clearTimeout(this.endTestTimeout);
    this.forceCloseTimout && clearTimeout(this.forceCloseTimout);
    await this.removeLoader();
    showNotification({ translateKey: 'test_connection_failed_notification', type: NotificationTypes.error });
    this.closeTest();
  };

  isSubstituteQuestion = () => {
    const { test } = this.props;
    const { questions } = test;
    const { questionIndex } = this.state;

    return questions[questionIndex].type === QuestionTypes.SUBSTITUTION;
  };

  questionContent = () => {
    const { classes, test, t } = this.props;
    const { minutesToCompleteTest, questions } = test;
    const { questionIndex, answered, isAccessible } = this.state;
    const questionType = {
      0: t('question_type_select'),
      1: t('question_type_multiple_select'),
      2: t('question_type_true_false'),
      3: t('question_type_sentence_completion'),
      4: t('question_type_word_pairing'),
    }[questions[questionIndex].type];

    return (
      <Box ref={this.innerContent} className={classNames(classes.InnerContent, classes.QuestionContent)}>
        {this.getCloseButton()}
        <Box mt={4} mb={minutesToCompleteTest ? 4 : 6} className={classes.ProgressBarContainer}>
          <ProgressBar
            ref={this.progressBar}
            currentQuestion={questionIndex + 1}
            numberOfQuestions={questions.length}
          />
        </Box>
        {!minutesToCompleteTest ? null : (
          <>
            <Box
              ref={this.staticTimer}
              className={classNames('sticky-timer', classes.StickyTimeContainer, {
                [classes.StickyTimeVisible]: this.state.stickyTimeVisible,
                [classes.StickyTimeContainerIos]: isIOS(),
              })}
            >
              <RemainingTime timeLimitInSeconds={minutesToCompleteTest * 60} timeout={this.outOfTime} tabIndex={0} />
            </Box>
            <Box mb={6} />
          </>
        )}
        <QuestionImage questions={questions} questionIndex={questionIndex} />
        <Container>
          <Box className={classes.QuestionWithAnswersContainer}>
            <Box
              mb={5}
              className={classes.Question}
              tabIndex={0}
              role="application"
              aria-label={t('test_and_exam_question', {
                type: questionType,
                question: questions[questionIndex].question,
              })}
            >
              {questions[questionIndex].question}
              <div id={QuestionElementId} className={classes.QuestionLabel} />
            </Box>
          </Box>
          {questions[questionIndex].type === QuestionTypes.SINGLE_CHOICE ? (
            <SingleChoiceForm
              answers={questions[questionIndex].answers}
              setAnswer={this.setAnswer}
              answered={answered}
            />
          ) : questions[questionIndex].type === QuestionTypes.MULTIPLE_CHOICE ? (
            <MultipleChoiceForm
              answers={questions[questionIndex].answers}
              setAnswer={this.setAnswer}
              answered={answered}
              questionElementId={QuestionElementId}
            />
          ) : questions[questionIndex].type === QuestionTypes.TRUE_OR_FALSE ? (
            <TrueOrFalseForm
              answers={questions[questionIndex].answers}
              setAnswer={this.setAnswer}
              answered={answered}
            />
          ) : (this.isSubstituteQuestion() && isAccessible) ||
            (this.isSubstituteQuestion() && !isAccessible && isSmallWidth()) ? (
            <AccessibleSubstitutionForm
              answers={questions[questionIndex].answers}
              setAnswer={this.setAnswer}
              answered={answered}
            />
          ) : this.isSubstituteQuestion() && !isAccessible && !isSmallWidth() ? (
            <SubstitutionForm
              answers={questions[questionIndex].answers}
              setAnswer={this.setAnswer}
              answered={answered}
            />
          ) : questions[questionIndex].type === QuestionTypes.PAIRING ? (
            <PairingForm
              answers={questions[questionIndex].answers}
              setAnswer={this.setAnswer}
              answered={answered}
              innerContentRef={this.innerContent}
              isAccessible={isAccessible}
            />
          ) : null}
          <Box display="none" id="correct">
            {t('common_correct_answer')}
          </Box>
          <Box display="none" id="incorrect">
            {t('common_incorrect_answer')}
          </Box>
        </Container>
      </Box>
    );
  };

  questionFooter = () => {
    const { classes, t } = this.props;
    const { answered, correct, isAccessible } = this.state;
    return (
      <Box className={classNames(classes.TestFooter, { IOs: isIOS(), Accessible: isAccessible })}>
        <Box className={classes.DoubleButtonContainer}>
          {answered ? (
            <>
              <Box className={classes.ResultBox}>
                {correct ? (
                  <>
                    {correctAnswerIcon()}
                    <Box ml={2} className={classNames(classes.Answer, 'Correct')} role="alert" id="result-text">
                      {t('test_page_correct_answer')}
                    </Box>
                  </>
                ) : (
                  <>
                    {incorrectAnswerIcon()}
                    <Box ml={2} className={classNames(classes.Answer, 'Incorrect')} role="alert" id="result-text">
                      {t('test_page_incorrect_answer')}
                    </Box>
                  </>
                )}
              </Box>
              <Button buttonClassName="Cyan" onClick={this.nextQuestion}>
                {t('common_forward')}
              </Button>
            </>
          ) : (
            <>
              <Button buttonClassName="DarkBlue Secondary" onClick={() => this.nextQuestion(true)}>
                {t('common_skip')}
              </Button>
              <Button buttonClassName="Cyan" onClick={this.checkAnswer}>
                {t('common_check')}
              </Button>
            </>
          )}
        </Box>
      </Box>
    );
  };

  setAnswer = (answers) => {
    const {
      test: { questions },
    } = this.props;
    const { questionIndex } = this.state;
    this.questions[this.questions.length - 1].answers = answers.map((answer) => {
      if (isObject(answer) && !!answer.words) {
        return {
          id: answer.id,
          words: answer.words,
        };
      }
      if (isObject(answer) && !!answer.matchAnswers) {
        return { matchAnswers: answer.matchAnswers };
      }
      return {
        id: questions[questionIndex].answers[answer].id,
      };
    });
    this.setState({ answer: answers });
  };

  onAccessibilityChange = () => {
    const { isAccessible } = this.state;
    this.setState({ isAccessible: !isAccessible });
  };

  render() {
    const { classes, t } = this.props;
    const { examState, isLoading, isAccessible } = this.state;

    return (
      <Dialog
        open={true}
        isExamOrTest
        alwaysFullScreen
        onClose={this.closeTest}
        content={
          <Box className={classes.Content}>
            {examState === ExamStates.OPEN ? this.openingContent() : null}
            {examState === ExamStates.QUESTION ? this.questionContent() : null}
            {examState === ExamStates.END ? this.endTestContent() : null}

            {examState === ExamStates.OPEN ? (
              <ExamTestOpeningFooter
                label={t('test_accessibility_start')}
                buttonLabel={t('common_forward')}
                checked={isAccessible}
                onButtonClick={this.goToTest}
                onChange={this.onAccessibilityChange}
              />
            ) : null}
            {examState === ExamStates.QUESTION ? this.questionFooter() : null}
            {examState === ExamStates.END ? this.endTestFooter() : null}
            <div className={classNames(classes.AppLoader, { show: isLoading })}>
              <div className={classes.CircularLoaderContainer}>
                <div className={classes.CircularLoader} />
              </div>
            </div>
          </Box>
        }
        contentClass={classes.NoPadding}
        noClose
      />
    );
  }
}

export default hoistStatics(withTranslation()(withStyles(testPageStyles)(TestPage)), TestPage);
