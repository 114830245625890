import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import classNames from 'classnames';

import Box from '@material-ui/core/Box';
import withStyles from '@material-ui/core/styles/withStyles';
import Fade from '@material-ui/core/Fade';

import { topListPageStyles } from './styles';
import Button from 'components/Button/Button';
import Pagination from 'components/Pagination/Pagination';
import { NotificationTypes, SystemRoles, visiblePageLinks } from 'constants/Constants';
import { getTopList as getTopListServer, getTopListOfGroups as getTopListOfGroupsServer } from 'store/topList/actions';
import { Podium } from './Podium';
import { OutOfPodium } from './OutOfPodium';
import { TopListSearch } from './TopListSearch';
import { getProfileDetails } from 'store/profile/actions';
import { titleFocus } from 'utils/titleFocus';
import { ClassStyle } from '../../types/types';
import { isSpaceOrEnterEvent } from 'utils/eventUtils';
import { TopListTypes } from 'constants/Constants';

const MY_POSITION_SCROLL_OFFSET = 125;

class TopListPage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      TopListContainer: ClassStyle,
      SearchDescription: ClassStyle,
      MyPositionButtonContainer: ClassStyle,
      TabContainer: ClassStyle,
      Tab: ClassStyle,
    }),
    t: PropTypes.func,
    topList: PropTypes.object,
    getTopList: PropTypes.func,
    topListOfGroups: PropTypes.object,
    getTopListOfGroups: PropTypes.func,
    myId: PropTypes.number,
    myGroupId: PropTypes.number,
    myGroupPublic: PropTypes.bool,
    page: PropTypes.number,
    setPage: PropTypes.func,
    setPageOfGroups: PropTypes.func,
    showNotification: PropTypes.func,
    myRole: PropTypes.number,
    addCommonLoader: PropTypes.func,
    removeCommonLoader: PropTypes.func,
  };

  static pageTitleKey = 'page_title_top_list';

  myCard = createRef();

  state = {
    activeTab: TopListTypes.User,
    searchString: '',
    windowSize: 0,
    isMyCardSelected: false,
    inProp: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: TopListTypes.User,
      searchString: '',
      windowSize: 0,
      isMyCardSelected: false,
      inProp: true,
    };

    this.props.addCommonLoader();
  }

  static async getInitialData({ dispatch, language, token }) {
    try {
      if (token) {
        await Promise.all([
          dispatch(getTopListServer({ language, page: 1, token })),
          dispatch(getTopListOfGroupsServer({ language, page: 1, token })),
          dispatch(getProfileDetails({ language, token })),
        ]);
      }
      titleFocus(dispatch);
    } catch (err) {
      console.error(err);
    }
  }

  search = async ({ searchString }) => {
    const { getTopList, getTopListOfGroups } = this.props;
    const { activeTab } = this.state;
    this.setState({ searchString, isMyCardSelected: false });
    if (activeTab === TopListTypes.User) {
      await getTopList({ page: 1, searchText: searchString });
    } else {
      await getTopListOfGroups({ page: 1, searchText: searchString });
    }
  };

  paginate = async (to) => {
    const { setPage, setPageOfGroups, getTopList, getTopListOfGroups } = this.props;
    const { activeTab, windowSize } = this.state;
    if (activeTab === TopListTypes.User) {
      setPage(to);
      await getTopList({ page: to });
    } else {
      setPageOfGroups(to);
      await getTopListOfGroups({ page: to });
    }
    const yOffset = -60;
    let y = this.anchorRef.current.getBoundingClientRect().top + window.pageYOffset;
    if (windowSize < 1140) {
      y += yOffset;
    }
    this.setState({ isMyCardSelected: false });
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  switchView = async (tab) => {
    const { getTopList, getTopListOfGroups } = this.props;
    const { activeTab, searchString } = this.state;
    this.setState({ inProp: false });
    if (tab !== activeTab) {
      if (searchString) {
        this.clearSearch && this.clearSearch();
      }
      setTimeout(() => {
        getTopListOfGroups({ page: 1 });
        getTopList({ page: 1 });
        this.setState({ activeTab: tab, searchString: '', isMyCardSelected: false, inProp: true });
      }, 100);
    }
  };

  showMyPosition = async () => {
    const { getTopList, getTopListOfGroups, myId, myGroupId, myRole, showNotification } = this.props;
    const { activeTab } = this.state;
    if (myRole === SystemRoles.USER) {
      this.clearSearch && this.clearSearch();
      this.setState({ searchString: '' });
      activeTab === TopListTypes.User
        ? await getTopList({ userId: myId })
        : await getTopListOfGroups({ groupId: myGroupId });
      this.scrollToMyPosition();
    } else {
      showNotification({
        translateKey: activeTab === TopListTypes.User ? 'top_list_not_student' : 'top_list_not_student_company',
        type: NotificationTypes.warning,
      });
    }
  };

  scrollToMyPosition = () => {
    this.setState({ isMyCardSelected: true });
    if (this.myCard.current) {
      window.scrollTo(
        0,
        this.myCard.current.getBoundingClientRect().top + window.pageYOffset - MY_POSITION_SCROLL_OFFSET
      );
      this.myCard.current.focus();
    }
  };

  setClearSearch = (clearSearch) => {
    this.clearSearch = clearSearch;
  };

  setActiveTab = (activeTab) => {
    this.setState({ activeTab });
  };

  onTabKeyPress = (event, activeTab) => {
    if (isSpaceOrEnterEvent(event)) {
      event.preventDefault();
      this.setState({ inProp: false });
      setTimeout(() => {
        this.setActiveTab(activeTab);
        this.setState({ inProp: true });
      }, 100);
    }
  };

  handleResize = () => {
    const windowSize = window.innerWidth;
    this.setState({ windowSize });
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.setState({ windowSize: window.innerWidth });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  anchorRef = createRef();

  isInTop = (rank) => {
    const topRanks = [1, 2, 3];

    return topRanks.includes(rank);
  };

  myCardIsInTop = () => {
    const { topList, myId, topListOfGroups, myGroupId } = this.props;
    const { activeTab } = this.state;
    const myUser = topList.topUsers.some((user) => user.id === myId && this.isInTop(user.rank));
    const myGroup = topListOfGroups.topUsers?.some((group) => group.id === myGroupId && this.isInTop(group.rank));
    return (activeTab === TopListTypes.User && myUser) || (activeTab === TopListTypes.Group && myGroup);
  };

  render() {
    const {
      classes,
      t,
      topList: { count: userCount, topUsers: podiumUsers, data: outOfPodiumUsers, page },
      topList,
      topListOfGroups: { count: groupCount, topUsers: podiumGroups, data: outOfPodiumGroups, page: pageOfGroups },
      topListOfGroups,
      myId,
      myGroupId,
      myGroupPublic,
    } = this.props;
    const { activeTab, searchString, isMyCardSelected, inProp } = this.state;

    return !podiumUsers || !outOfPodiumUsers ? null : (
      <Box mb={12} className={classes.TopListContainer}>
        <Box role="tablist" mt={2} className={classes.TabContainer}>
          <Box
            mr={3}
            className={classNames(classes.Tab, { Active: activeTab === TopListTypes.User })}
            onClick={() => this.switchView(TopListTypes.User)}
            role="tab"
            tabIndex={0}
            aria-selected={activeTab === TopListTypes.User}
            onKeyPress={(event) => this.onTabKeyPress(event, TopListTypes.User)}
          >
            {t('top_list_user_view')}
          </Box>
          <Box
            mr={3}
            className={classNames(classes.Tab, { Active: activeTab === TopListTypes.Group })}
            onClick={() => {
              this.switchView(TopListTypes.Group);
            }}
            role="tab"
            tabIndex={0}
            aria-selected={activeTab === TopListTypes.Group}
            onKeyPress={(event) => this.onTabKeyPress(event, TopListTypes.Group)}
          >
            {t('top_list_group_view')}
          </Box>
        </Box>
        <Fade in={inProp} timeout={200}>
          <div>
            <Podium
              first={activeTab === TopListTypes.User ? podiumUsers[0] : podiumGroups[0]}
              second={activeTab === TopListTypes.User ? podiumUsers[1] : podiumGroups[1]}
              third={activeTab === TopListTypes.User ? podiumUsers[2] : podiumGroups[2]}
              activeTab={activeTab}
              myId={activeTab === TopListTypes.User ? myId : myGroupId}
              isMyCardSelected={isMyCardSelected}
              myCardRef={this.myCardIsInTop() ? this.myCard : undefined}
            />
            <Box ref={this.anchorRef} className={classes.SearchDescription} mb={3} tabIndex={0}>
              {t(
                activeTab === TopListTypes.User
                  ? 'top_list_search_user_description'
                  : 'top_list_search_group_description'
              )}
            </Box>
            <TopListSearch
              search={this.search}
              setClearSearch={this.setClearSearch}
              numberOfItems={activeTab === TopListTypes.User ? userCount : groupCount}
            />
            {(activeTab === TopListTypes.User || (activeTab === TopListTypes.Group && myGroupPublic)) && (
              <Box className={classes.MyPositionButtonContainer}>
                <Button buttonClassName="Cyan" onClick={this.showMyPosition}>
                  {activeTab === TopListTypes.User ? t('top_list_my_position') : t('top_list_my_group')}
                </Button>
              </Box>
            )}
            {searchString &&
            ((activeTab === TopListTypes.User && !outOfPodiumUsers.length) ||
              (activeTab === TopListTypes.Group && !outOfPodiumGroups.length)) ? (
              <Box mt={6}>{t('top_list_no_result')}</Box>
            ) : (
              <OutOfPodium
                entities={activeTab === TopListTypes.User ? outOfPodiumUsers : outOfPodiumGroups}
                activeTab={activeTab}
                myId={activeTab === TopListTypes.User ? myId : myGroupId}
                isMyCardSelected={isMyCardSelected}
                myCardRef={!this.myCardIsInTop() ? this.myCard : null}
              />
            )}
            <Pagination
              itemCount={activeTab === TopListTypes.User ? topList.count : topListOfGroups.count}
              itemPerPage={12}
              currentPage={activeTab === TopListTypes.User ? +page : +pageOfGroups}
              visiblePageLinks={visiblePageLinks}
              setCurrentPage={this.paginate}
            />
          </div>
        </Fade>
      </Box>
    );
  }
}

export default hoistStatics(withTranslation()(withStyles(topListPageStyles)(TopListPage)), TopListPage);
