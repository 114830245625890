import request from 'utils/request';
import { Methods } from 'utils/http';

const BASE_URL = '/tests';

export const getTest = async (lessonId, language, accessibility) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/${lessonId}/generate-test${accessibility ? '?accessibility=true' : ''}`,
    language,
  });
};

export const validateTest = async (lessonId, answers) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/${lessonId}/validate-test`,
    data: answers,
  });
};
