import {
  block,
  bold,
  capitalize,
  center,
  flex,
  flexEnd,
  flexStart,
  grid,
  none,
  normal,
  nowrap,
  pointer,
  spaceBetween,
  wrap,
} from 'constants/Jss';

export const searchPageStyles = (theme) => ({
  SearchPageContainer: {
    width: '100%',
  },
  SortingAndNumberOfCourses: {
    display: flex,
    justifyContent: spaceBetween,
    alignItems: center,
    color: theme.palette.grey70,
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  SearchControlContainer: {
    display: flex,
    alignItems: flexStart,
    justifyContent: spaceBetween,
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: block,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      whiteSpace: nowrap,
    },
  },
  TabContainer: {
    display: flex,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: theme.spacing(4),
    },
  },
  Tab: {
    fontSize: 21,
    fontWeight: bold,
    color: theme.palette.blackTitle,
    textTransform: capitalize,
    '&.Active': {
      borderBottom: `8px solid ${theme.palette.cyan}`,
    },
    '&:not(.Active)': {
      cursor: pointer,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      width: '50%',
      textAlign: center,
      margin: 0,
      whiteSpace: normal,
      paddingBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: `calc(25% - 45px)`,
      paddingRight: `calc(25% - 45px)`,
    },
  },
  LessonContainer: {
    marginTop: 35,
    width: 320,
    height: 228,
    paddingTop: theme.spacing(7),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      borderRadius: 18,
      marginLeft: 0,
      marginRight: 0,
    },
  },
  FilterContainer: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
      '&:nth-child(2)': {
        display: flex,
        justifyContent: flexEnd,
      },
    },
  },
  ResultContainer: {
    display: flex,
    justifyContent: flexStart,
    flexWrap: wrap,
    [theme.breakpoints.down('sm')]: {
      display: grid,
      '&.Course': {
        'grid-template-columns': 'repeat(auto-fill, minmax(270px, 1fr))',
      },
      '&.Lesson': {
        'grid-template-columns': 'repeat(auto-fill, minmax(340px, 1fr))',
      },
    },
    [theme.breakpoints.down('xs')]: {
      '&.Lesson': {
        borderRadius: '0px !important',
        'grid-template-columns': 'repeat(auto-fill, minmax(288px, 1fr))',
      },
    },
    [theme.breakpoints.down(400)]: {
      '&.Lesson': {
        marginRight: -theme.spacing(1.5),
      },
    },
  },
  ResultBox: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  FiltersContainer: {
    border: 0,
  },
  FilterBoxContainer: {
    border: 0,
    '& > div:last-child': {
      marginRight: 0,
    },
  },
  NotificationLink: {
    color: theme.palette.black,
    marginLeft: theme.spacing(1),
    '&:hover': {
      color: theme.palette.blueA0,
    },
    [theme.breakpoints.down('sm')]: {
      display: block,
    },
  },
});
