import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import i18 from 'i18n';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';

import { pageStyles } from './styles';
import { auto } from 'constants/Jss';
import { ClassStyle } from '../../types/types';
import { isTabEvent } from 'utils/eventUtils';

const Page = (WrappedComponent, ClassName, PageTitle) => {
  class PageComponent extends Component {
    static propTypes = {
      classes: PropTypes.shape({
        MainContainer: ClassStyle,
      }),
      isLoggedIn: PropTypes.bool,
    };

    static getInitialData = WrappedComponent.getInitialData;

    firstFocusRef = createRef();
    tabPressedRef = createRef(false);

    componentDidMount() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: auto,
      });
    }

    onKeyDown = (event) => {
      if (!this.tabPressedRef.current && isTabEvent(event)) {
        this.tabPressedRef.current = true;
        if (this.firstFocusRef.current) {
          event.preventDefault();
          this.firstFocusRef.current.focus();
        }
      }
    };

    onClick = () => {
      this.tabPressedRef.current = true;
    };

    render() {
      const { classes, isLoggedIn, ...props } = this.props;
      return (
        <div className={classes[ClassName]}>
          <Helmet>
            <title>{i18.t('page_title_template', { title: i18.t(WrappedComponent.pageTitleKey) })}</title>
          </Helmet>
          <Container maxWidth="lg" className={classNames('Page', classes.MainContainer)}>
            <Grid
              container
              spacing={4}
              className={classNames({ loggedIn: isLoggedIn })}
              onClick={this.onClick}
              onKeyDown={this.onKeyDown}
            >
              {PageTitle ? (
                <Box mb={5} className="PageTitleContainer">
                  <h1 className="Title" tabIndex={0} ref={this.firstFocusRef}>
                    {PageTitle}
                  </h1>
                  <div className="TitleBorder" />
                </Box>
              ) : null}
              <WrappedComponent {...props} />
            </Grid>
          </Container>
        </div>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      isLoggedIn: state.auth.isLoggedIn,
    };
  };

  return connect(mapStateToProps)(PageComponent);
};

Page.propTypes = {
  WrappedComponent: PropTypes.node.isRequired,
};

export default compose(withStyles(pageStyles), Page);
