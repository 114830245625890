import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';

import { Button, Dialog, OverallErrorMessages } from 'components';
import { auto } from 'constants/Jss.js';
import { Link } from 'react-router-dom';
import { useLoginDialogStyles, usePatches } from './styles';
import { History } from '../../types/types';

const ForgotPasswordDialog = ({ open, closeDialog, submit, t, history }) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('form_invalid_email'))
      .required(t('form_required')),
  });

  const classes = useLoginDialogStyles();
  const patchClasses = usePatches();

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      title={t('form_title_forgotten_password')}
      titleBoxClass={patchClasses.YellowPatch}
      aria-modal={true}
      role="dialog"
      aria-labelledby="AskForNewPasswordButton"
      content={
        <Formik initialValues={{ email: '' }} validationSchema={validationSchema} onSubmit={submit}>
          {({ values, errors, touched, handleChange, handleSubmit, setFieldTouched }) => (
            <form onSubmit={() => {}}>
              <OverallErrorMessages />
              <Box className={classes.FormBox} ml={auto} mr={auto}>
                <Box mb={3}>
                  <TextField
                    id="email"
                    name="email"
                    label={t('form_email')}
                    variant="outlined"
                    autoComplete="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched('email', true)}
                    error={!!errors.email && touched.email}
                    helperText={touched.email ? errors.email : ''}
                    fullWidth
                    autoFocus={true}
                  />
                </Box>
                <Box className={classNames(classes.NewPasswordBox, 'center')} mt={5} mb={5}>
                  <Button
                    className={classes.LoginButtonContainer}
                    buttonClassName="Pink"
                    onClick={handleSubmit}
                    type="submit"
                    id="AskForNewPasswordButton"
                    aria-label={t('form_ask_for_new_password')}
                  >
                    {t('form_ask_for_new_password')}
                  </Button>
                </Box>
                <Box className={classes.RegisterBox} mb={8}>
                  <Box tabIndex={0}>{t('form_dont_you_have_id')}</Box>
                  <Link
                    className={classNames('Bold', classes.Link)}
                    to={`${history.location.pathname}?dialog=${t('url_register')}`}
                    role="button"
                    aria-label={t('menu_item_register')}
                  >
                    {t('menu_item_register')}
                  </Link>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      }
    />
  );
};

ForgotPasswordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  history: History,
};

export default withTranslation()(ForgotPasswordDialog);
