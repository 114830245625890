import { useEffect, useRef, useState } from 'react';
import { createVoiceSearchHelper } from 'react-instantsearch-dom';
import { browser, Browsers } from 'utils/platform';

const INITIAL_STATE = {
  isBrowserSupported: false,
  isListening: false,
  isSpeechFinal: false,
  transcript: '',
  status: undefined,
  errorCode: undefined,
};

const supportedBrowsers = [Browsers.CHROME, Browsers.CHROME_MOBILE];

const getHelperState = (helper) => ({
  ...helper.getState(),
  isBrowserSupported: true,
  isListening: helper.isListening(),
});

export const useVoiceRecognizer = () => {
  const [state, setState] = useState(INITIAL_STATE);
  const toggleListeningRef = useRef();

  useEffect(() => {
    const helper = createVoiceSearchHelper({
      searchAsYouSpeak: false,
      onQueryChange: () => {},
      onStateChange: () => setState(getHelperState(helper)),
    });
    toggleListeningRef.current = helper.toggleListening;
    if (helper.isBrowserSupported() && supportedBrowsers.includes(browser)) {
      setState(getHelperState(helper));
    }

    return () => {
      if (helper) {
        helper.dispose();
      }
    };
  }, []);

  return [state, toggleListeningRef.current];
};
