import makeStyles from '@material-ui/core/styles/makeStyles';

import { center, flex } from 'constants/Jss';

export const useCardContainerStyles = makeStyles(() => ({
  ShowMoreBox: {
    width: '100%',
    display: flex,
    justifyContent: center,
  },
  ShowMoreButton: {
    height: 37,
  },
  NoResult: {},
}));
