import {
  GET_TOP_LIST,
  GET_TOP_LIST_FAILED,
  GET_TOP_LIST_OF_GROUPS,
  GET_TOP_LIST_OF_GROUPS_FAILED,
  GET_TOP_LIST_OF_GROUPS_SUCCESS,
  GET_TOP_LIST_SUCCESS,
  SET_PAGE,
  SET_PAGE_OF_GROUPS,
} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  topList: {},
  topListOfGroups: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TOP_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_TOP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        topList: action.payload || {},
      };
    case GET_TOP_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_TOP_LIST_OF_GROUPS:
      return {
        ...state,
        loading: true,
      };
    case GET_TOP_LIST_OF_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        topListOfGroups: action.payload || {},
      };
    case GET_TOP_LIST_OF_GROUPS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_PAGE:
      return {
        ...state,
        topList: {
          ...state.topList,
          page: action.payload,
        },
      };
    case SET_PAGE_OF_GROUPS:
      return {
        ...state,
        topListOfGroups: {
          ...state.topListOfGroups,
          page: action.payload,
        },
      };
    default:
      return state;
  }
};
