import request from 'utils/request';
import { Methods } from 'utils/http';

const BASE_URL = '/tests';

export const getExam = async (courseId, language, accessibility) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/${courseId}/generate-exam${accessibility ? '?accessibility=true' : ''}`,
    language,
  });
};

export const validateExam = async (courseId, answers) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/${courseId}/validate-exam`,
    data: answers,
  });
};
