import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { interval } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import Cookies from 'js-cookie';
import classNames from 'classnames';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { resetTimer } from 'store/auth/actions';
import { logout, refreshToken } from 'store/profile/actions';
import { getTime } from 'utils/time';
import { isRefreshNeeded } from 'utils/refreshToken';
import Settings from 'env';
import { useExpirationModalStyles } from './styles';

const ExpirationModal = ({ getAuthorizedRoutes }) => {
  const [open, setOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [ariaLabel, setAriaLabel] = useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { expiration, expirationReset } = useSelector((state) => {
    return state.auth;
  });
  const classes = useExpirationModalStyles();

  useEffect(() => {
    const subscription = interval(Settings.INTERVAL_IN_MS)
      .pipe(
        map(() => {
          const elapsedSec = Math.floor((Date.now() - expirationReset) / 1000);
          return Math.max(expiration - elapsedSec, 0);
        }),
        distinctUntilChanged()
      )
      .subscribe((remaingTime) => {
        setTimeLeft(remaingTime);
        if (remaingTime <= Settings.EXPIRATION_MODAL_TIME_IN_MS / 1000) {
          setOpen(true);
        }
        if (remaingTime === 0) {
          dispatch(logout(getAuthorizedRoutes));
        }
        if (isRefreshNeeded()) {
          dispatch(refreshToken(Cookies.get(Settings.API_TOKEN)));
        }
      });

    return () => subscription.unsubscribe();
  }, [expiration, getAuthorizedRoutes, dispatch, expirationReset]);

  const onClose = () => {
    if (open) {
      setOpen(false);

      dispatch(logout(getAuthorizedRoutes));
    }
  };

  const onConfirmation = () => {
    setOpen(false);
    dispatch(refreshToken(Cookies.get(Settings.API_TOKEN)));
    dispatch(resetTimer());
  };

  const onFocus = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft - minutes * 60;
    setAriaLabel(t('alt_expiration_modal_remaining_time', { minutes, seconds }));
  };

  return (
    <Dialog
      titleBoxClass={classes.PinkPatch}
      title={t('expiration_title')}
      open={open}
      onClose={() => onConfirmation()}
      content={
        <Box className={classes.DialogContent}>
          <Box mb={6} className={classes.Center}>
            <Typography tabIndex={0}>{t('expiration_warning')}</Typography>
            <Box onFocus={onFocus} aria-label={ariaLabel} tabIndex={0}>
              <Typography aria-hidden="true">{t('expiration_timeout')}</Typography>
              <Typography aria-hidden="true">{getTime(timeLeft)}</Typography>
            </Box>
            <Typography tabIndex={0}>{t('expiration_question')}</Typography>
            <Box my={3} className={classes.ActionContainer}>
              <Box mr={1}>
                <Button
                  buttonClassName={classNames('Pink', 'Secondary', classes.LogoutButton, classes.ExpirationButton)}
                  onClick={() => onClose()}
                >
                  {t('expiration_logout')}
                </Button>
              </Box>
              <Box ml={1}>
                <Button buttonClassName={classNames('Pink', classes.ExpirationButton)} onClick={() => onConfirmation()}>
                  {t('expiration_confirm')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

ExpirationModal.propTypes = {
  getAuthorizedRoutes: PropTypes.func,
};

export default ExpirationModal;
