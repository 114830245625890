import * as TestService from 'services/tests';
import {
  GET_TEST,
  GET_TEST_FAILED,
  GET_TEST_SUCCESS,
  VALIDATE_TEST,
  VALIDATE_TEST_FAILED,
  VALIDATE_TEST_SUCCESS,
} from './types';

export const getTest = (lessonId, language, accessibility) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_TEST });

      const data = await TestService.getTest(lessonId, language, accessibility);

      dispatch({
        type: GET_TEST_SUCCESS,
        payload: data,
      });

      return !!data.questions;
    } catch (error) {
      dispatch({ type: GET_TEST_FAILED });
      throw error;
    }
  };
};

export const validateTest = (lessonId, answers) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VALIDATE_TEST });

      const data = await TestService.validateTest(lessonId, answers);

      dispatch({
        type: VALIDATE_TEST_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({ type: VALIDATE_TEST_FAILED });
      throw error;
    }
  };
};
