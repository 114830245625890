import {
  GET_LESSONS,
  GET_LESSONS_SUCCESS,
  GET_LESSONS_FAILED,
  GET_LESSON_BY_ID,
  GET_LESSON_BY_ID_SUCCESS,
  GET_LESSON_BY_ID_FAILED,
  LOAD_MORE_LESSONS,
  LOAD_MORE_LESSONS_SUCCESS,
  LOAD_MORE_LESSONS_FAILED,
} from './types';
import * as LessonService from 'services/lessons';

export const getLessons = (params, language) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_LESSONS });

      const data = await LessonService.getLessons({ language, ...params });

      dispatch({
        type: GET_LESSONS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({ type: GET_LESSONS_FAILED });
      throw error;
    }
  };
};

export const loadMoreLessons = (params) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_MORE_LESSONS });

      const data = await LessonService.getLessons(params);

      dispatch({
        type: LOAD_MORE_LESSONS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({ type: LOAD_MORE_LESSONS_FAILED });
      throw error;
    }
  };
};

export const getLessonById = (lessonId, language, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_LESSON_BY_ID });

      const { data } = await LessonService.getLessonById(lessonId, language, token);

      dispatch({
        type: GET_LESSON_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({ type: GET_LESSON_BY_ID_FAILED });
      throw error;
    }
  };
};
